import {
  CategoryName,
  getCategories,
  getCategory,
  normalizeCategoryName,
  WithNomarizedName,
} from "apiv1/category";
import { getPayjpTenantExist } from "apiv1/payjp";
import { FeeType, patchProject, postImage, Visibility } from "apiv1/project";
import { getSCTAct, SCTAct } from "apiv1/user";
import BtnWithProcessing from "components/Btn/BtnWithProcessing";
import ModalGenre from "components/ModalGenre/ModalGenre";
import Select from "components/Select";
import { SpinnerCenter } from "components/Spinner";
import { useRelease } from "pages/Editor/EditorProvider";
import React, { useContext, useState, useEffect, useCallback } from "react";
import {
  PLAYERS_MAX_OPTIONS,
  PLAYERS_MIN_OPTIONS,
  PLAYING_HOUR_OPTIONS,
} from "utils/projects";
import InputThumbnail, { ImageFile } from "./InputThumbnail";
import styles from "./ModalPublish.module.css";
import { auth, storage } from "../../initialize";
import { getDownloadURL, ref } from "@firebase/storage";
import { Link } from "react-router-dom";
import { AuthContext } from "components/AuthProvider";
import { isSPLL } from "utils/license";
import { reload } from "firebase/auth";
import { useRecoilState } from "recoil";
import { projectState } from "pages/Editor/atom";
import GenerateThumbnail from "./GenerateThumbnail/GenerateThumbnail";
import Attachment from "./Attachment/Attachment";
import { useReleaseForLexical } from "pages/EditorLexical/hooks/useRelease";

type SupporterProgramId = "emoklore";

type SupporterProgramIdInfo = {
  categoryId: number;
  licenseId: SupporterProgramId;
};

const SUPPORTER_PROGRAM_IDS: SupporterProgramIdInfo[] = [
  { categoryId: 92, licenseId: "emoklore" },
];

const toSupporterProgramId = (
  categoryId: number
): SupporterProgramId | undefined => {
  return SUPPORTER_PROGRAM_IDS.find((info) => info.categoryId === categoryId)
    ?.licenseId;
};

const isSupporterProgram = (licenseId: string): boolean => {
  return SUPPORTER_PROGRAM_IDS.some((info) => info.licenseId === licenseId);
};

const validateLicenseId = (licenseId: string): boolean => {
  return isSPLL(licenseId) || isSupporterProgram(licenseId);
};

type ModalPublishProps = {
  variant: "draftjs" | "lexical";
  onReleased: () => void;
};

let categoriesCache: WithNomarizedName<CategoryName>[] = [];
const copyrightNoticeCache: Map<number, string> = new Map();

const calcTaltoFee = (price: number): number => {
  return Math.floor(price * 0.05) + 50;
};

const calcSpllFee = (
  price: number,
  taltoFee: number,
  licenseId: string | null
): number => {
  if (licenseId == null) {
    return 0;
  } else {
    return Math.floor((price - taltoFee) * 0.1);
  }
};

type NeedSettingsProps = {
  reloadSaleInfo: () => Promise<void>;
};

const NeedSettings: React.FC<NeedSettingsProps> = ({ reloadSaleInfo }) => {
  return (
    <div className={styles.box}>
      <label className={styles.label}>販売者情報</label>
      <p className={styles.txt}>
        シナリオの販売設定を行うためには、販売者情報の登録が必要です。
      </p>
      <p className={styles.note}>
        ※ 有料配布を希望されない方は、登録の必要はありません。
      </p>
      <div className={styles.flexRow}>
        <Link to="/sales" target="_blank" className={styles.btnDistributor}>
          販売者情報を登録する
        </Link>
        <div className={styles.reloadBtn}>
          <BtnWithProcessing
            labelProsessing="更新中"
            onClick={reloadSaleInfo}
            secondary
          >
            更新
          </BtnWithProcessing>
        </div>
      </div>
    </div>
  );
};

type PriceAreaProps = {
  ownerId: string;
  feeType: FeeType;
  setFeeType: (x: FeeType) => void;
  price: number | null;
  setPrice: (x: number | null) => void;
  licenseId: string | null;
  setLicenseId: (x: string | null) => void;
  agreeSale: boolean;
  setAgreeSale: (x: boolean) => void;
  supporterProgramId?: SupporterProgramId;
};

const priceFilter = (fee: number, price: number | null): string | number => {
  if (price === null || price < 100) {
    return "-";
  } else {
    return fee;
  }
};

const PriceArea: React.FC<PriceAreaProps> = ({
  ownerId,
  feeType,
  setFeeType,
  price,
  setPrice,
  licenseId,
  setLicenseId,
  agreeSale,
  setAgreeSale,
  supporterProgramId,
}) => {
  const user = useContext(AuthContext);
  const [payjpTenant, setPayjpTenant] = useState<
    "exist" | "loading" | "notfound"
  >("loading");
  const [sctact, setSctact] = useState<SCTAct | "loading" | "notfound">(
    "loading"
  );
  const [emailVerified, setEmailVerified] = useState(false);
  useEffect(() => {
    if (user.authed) {
      setEmailVerified(user.emailVerified);
    }
  }, [user]);

  const loadSaleInfo = useCallback(async (): Promise<void> => {
    await getSCTAct(ownerId)
      .then((res) => setSctact(res.data))
      .catch(() => setSctact("notfound"));
    await getPayjpTenantExist()
      .then(() => setPayjpTenant("exist"))
      .catch(() => setPayjpTenant("notfound"));
    if (auth.currentUser) {
      await reload(auth.currentUser).then(() => {
        if (auth.currentUser) setEmailVerified(auth.currentUser.emailVerified);
      });
    }
  }, [setSctact, setPayjpTenant]);

  useEffect(() => {
    loadSaleInfo().then();
  }, []);

  useEffect(() => {
    if (
      licenseId != null &&
      supporterProgramId != null &&
      licenseId !== supporterProgramId
    ) {
      setLicenseId(null);
    }
  }, [supporterProgramId, licenseId]);

  const needAction =
    !emailVerified || payjpTenant === "notfound" || sctact === "notfound";

  if (payjpTenant === "loading" || sctact === "loading") {
    return (
      <div className={styles.box}>
        <SpinnerCenter />
      </div>
    );
  }

  const taltoFee = calcTaltoFee(price || 0);
  const spllFee = calcSpllFee(price || 0, taltoFee, licenseId);
  const income = (price || 0) - taltoFee - spllFee;

  const handlePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isNaN(event.target.valueAsNumber)) {
      setPrice(null);
    } else {
      setPrice(Math.floor(event.target.valueAsNumber));
    }
  };

  const handleLicenseId = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setLicenseId(null);
    } else {
      setLicenseId(event.target.value);
    }
  };

  const handleFeeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.value === "attachments_only" ||
      event.target.value === "all_contents"
    ) {
      setFeeType(event.target.value);
    } else {
      setFeeType("free");
    }
  };

  const handleAgreeSale = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeSale(event.target.checked);
  };

  const handleSupporterProgram = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLicenseId(event.target.checked ? supporterProgramId || null : null);
  };

  return (
    <div className={styles.box}>
      <label className={styles.label}>頒布形式</label>
      <label className={styles.radioArea}>
        <input
          className={styles.radio}
          value="free"
          type="radio"
          name="feeType"
          checked={feeType === "free"}
          onChange={handleFeeType}
        />
        無料
      </label>
      <label className={styles.radioArea}>
        <input
          className={styles.radio}
          value="attachments_only"
          type="radio"
          name="feeType"
          checked={feeType === "attachments_only"}
          onChange={handleFeeType}
        />
        添付ファイルのみ有料
      </label>
      <label className={styles.radioArea}>
        <input
          className={styles.radio}
          value="all_contents"
          type="radio"
          name="feeType"
          checked={feeType === "all_contents"}
          onChange={handleFeeType}
        />
        有料
      </label>
      {feeType !== "free" && needAction && (
        <NeedSettings reloadSaleInfo={loadSaleInfo} />
      )}
      {feeType !== "free" && !needAction && (
        <>
          {supporterProgramId ? (
            <>
              <label className={styles.label}>TALTOで公式応援プログラム</label>
              <div className={styles.checkbox}>
                <input
                  className={styles.agree}
                  type="checkbox"
                  checked={licenseId === supporterProgramId}
                  onChange={handleSupporterProgram}
                />
                <label className={styles.labelTerms}>
                  TALTOで公式応援プログラムに参加する
                </label>
              </div>
              <ul className={styles.note}>
                <li>
                  ※
                  作品販売の利益の一部を、ゲームシステムの製作者に任意で還元し、支援することができるプログラムです。詳しくは
                  <a href="/supporter-program" target="_blank">
                    TALTOで公式応援プログラムとは
                  </a>
                  をご覧ください。
                </li>
              </ul>
            </>
          ) : (
            <>
              <label className={styles.label}>販売許諾番号</label>
              <input
                className={styles.input}
                type="text"
                name="licenseId"
                placeholder="必要に応じて販売許諾番号を入力"
                value={licenseId || undefined}
                onChange={handleLicenseId}
              />
              <ul className={styles.note}>
                <li>
                  <a href="/licenseid-terms" target="_blank">
                    ※ 販売許諾番号とは
                  </a>
                </li>
              </ul>
            </>
          )}
          <label className={styles.label}>
            販売価格 <span className={styles.required}>※必須</span>
          </label>
          <div className={styles.price}>
            <input
              className={styles.inputPrice}
              type="number"
              name="price"
              placeholder=""
              value={price || undefined}
              step={1}
              onChange={handlePrice}
              max={10000}
              min={100}
            />
          </div>
          {/* <dl className={styles.breakdown}>
            <dt>販売手数料</dt>
            <dd>¥ {priceFilter(taltoFee, price)}</dd>
          </dl> */}
          {/* {licenseId && (
            <dl className={styles.breakdown}>
              <dt className={styles.breakdown}>SPLL</dt>
              <dd>¥ {priceFilter(spllFee, price)}</dd>
            </dl>
          )} */}
          <dl className={styles.breakdown}>
            <dt className={styles.breakdown}>販売利益</dt>
            <dd>¥ {priceFilter(income, price)}</dd>
          </dl>
          <ul className={styles.note}>
            <li>
              「TALTO」の販売手数料は販売価格の 5% + ¥50
              となります。TALTOで公式応援プログラム参加作品及び販売許諾番号が入力されている場合には、上記に加え販売価格のうち上記の手数料を除く
              10% を「TALTO」が代理で徴収します。詳しい説明は、
              <a href="/sales-terms" target="_blank">
                作品販売に関する注意事項
              </a>
              をご覧ください。
            </li>
          </ul>
          <div className={styles.checkboxAgree}>
            <input
              className={styles.agree}
              type="checkbox"
              checked={agreeSale}
              onChange={handleAgreeSale}
            />
            <label className={styles.labelTerms}>
              <a href="/sales-terms" target="_blank">
                作品販売に関する注意事項
              </a>
              に同意します。
            </label>
          </div>
        </>
      )}
    </div>
  );
};

type Patch = {
  category_id: number;
  name: string;
  author: string;

  cover_url: string | null;
  fee_type: FeeType;
  price: number | null;
  license_id: string | null;

  tags: Array<string>;
  description: string;

  players_min: number | null;
  players_max: number | null;
  playing_hour_max: number | null;

  copyright_notice: string;
};

type ProjectValidateError =
  | "name/empty"
  | "price/conflict-with-feetype"
  | "price/should-set"
  | "price/out-range"
  | "license_id/invalid-format";

const validatePatchedProject = (patch: Patch): ProjectValidateError | null => {
  if (patch.name === "") return "name/empty";
  if (patch.fee_type === "free" && patch.price !== null)
    return "price/conflict-with-feetype";
  if (patch.fee_type !== "free") {
    if (patch.price == null) return "price/should-set";
    if (patch.price < 100 || 10000 < patch.price) return "price/out-range";
  }
  if (patch.license_id != null && patch.license_id !== "") {
    if (!validateLicenseId(patch.license_id))
      return "license_id/invalid-format";
    const supporterLicenseId = toSupporterProgramId(patch.category_id);
    if (supporterLicenseId != null && supporterLicenseId !== patch.license_id)
      return "license_id/invalid-format";
  }
  return null;
};

const messageValidationError = (error: ProjectValidateError): string => {
  switch (error) {
    case "name/empty":
      return "作品タイトルを設定してください";
    case "price/conflict-with-feetype":
      return "価格の設定に不備があります。内容を再確認してください";
    case "price/should-set":
      return "価格を設定してください";
    case "price/out-range":
      return "価格は100円以上、10000円以下としてください";
    case "license_id/invalid-format":
      return "販売許諾番号の形式に不備があります。入力内容を再確認してください。";
  }
};

const ModalPublish: React.FC<ModalPublishProps> = ({ variant, onReleased }) => {
  const [project, setProject] = useRecoilState(projectState);

  const [visibility, setVisibility] = useState<Visibility>(project.visibility);
  const changeVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibility(event.target.value as Visibility);
  };

  const [name, setName] = useState(project.name);
  const [author, setAuthor] = useState(project.author);
  const [coverUrl, setCoverUrl] = useState(project.cover_url);
  const [categoryId, setCategoryId] = useState(project.category_id);
  const [copyrightNotice, setCopyrightNotice] = useState(
    project.copyright_notice
  );
  const [playersMin, setPlayersMin] = useState(project.players_min);
  const [playersMax, setPlayersMax] = useState(project.players_max);
  const [playTime, setPlayTime] = useState(project.playing_hour_max);
  const [tags, setTags] = useState(project.tags.join(" "));
  const [description, setDescription] = useState(project.description);

  const [feeType, setFeeType] = useState<FeeType>(project.fee_type);
  const [price, setPrice] = useState<number | null>(project.price);
  const [licenseId, setLicenseId] = useState<string | null>(project.license_id);

  const [agreeSale, setAgreeSale] = useState(false);

  useEffect(() => {
    setName(project.name);
  }, [project.name]);

  // temporary image
  const [coverImageFile, setCoverImageFile] = useState<ImageFile>();

  // category
  const [categories, setCategories] =
    useState<WithNomarizedName<CategoryName>[]>(categoriesCache);
  const [defaultCopyrightNotice, setDefaultCopyrightNotice] = useState("");
  const [openCategorySelectModal, setOpenCategorySelectModal] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const handleChangeCategory = useCallback(
    (id: number | undefined) => {
      setCategoryId(id || 0);
    },
    [setCategoryId]
  );

  const openModal = useCallback(() => {
    setOpenCategorySelectModal(true);
  }, [setOpenCategorySelectModal]);

  const closeModal = useCallback(() => {
    setOpenCategorySelectModal(false);
  }, [setOpenCategorySelectModal]);

  useEffect(() => {
    if (categories.length === 0) {
      getCategories().then((resp) => {
        categoriesCache = resp.data.map((category) => {
          return {
            ...category,
            nomalizedName: normalizeCategoryName(category.name),
          };
        });
        setCategories(categoriesCache);
      });
    }
  }, []);

  useEffect(() => {
    const notice = copyrightNoticeCache.get(categoryId);
    if (notice != null) {
      setDefaultCopyrightNotice(notice);
    } else {
      getCategory(categoryId)
        .then((resp) => {
          setDefaultCopyrightNotice(resp.data.copyright_notice || "");
          copyrightNoticeCache.set(
            categoryId,
            resp.data.copyright_notice || ""
          );
        })
        .catch(() => setDefaultCopyrightNotice(""));
    }
  }, [categoryId]);

  const releaseDraftjs = useRelease();
  const releaseLexical = useReleaseForLexical();
  const releaseBody = variant === "draftjs" ? releaseDraftjs : releaseLexical;

  const release = async () => {
    if (name.length === 0) {
      return window.alert("作品タイトルを設定してください");
    }

    const patchPrice = feeType === "free" ? null : price;

    const newProject: Patch = {
      name,
      author,
      cover_url: coverUrl,
      category_id: categoryId,
      copyright_notice: copyrightNotice,
      players_min: playersMin,
      players_max: playersMax,
      playing_hour_max: playTime,
      tags: tags.split(/\s/).filter((tag) => tag.length > 0),
      description,
      price: patchPrice,
      fee_type: feeType,
      license_id: licenseId,
    };
    const error = validatePatchedProject(newProject);
    if (error != null) {
      alert(messageValidationError(error));
      return;
    }

    if (coverImageFile) {
      try {
        const res = await postImage(project.id, coverImageFile.file);
        newProject.cover_url = await getDownloadURL(
          ref(storage, res.data.fileName)
        );
        setCoverUrl(newProject.cover_url);
        URL.revokeObjectURL(coverImageFile.objectURL);
        setCoverImageFile(undefined);
      } catch {
        alert(
          "サムネイル画像のアップロードに失敗したため、公開処理を中断しました"
        );
        return;
      }
    }

    try {
      await patchProject(project.id, newProject);
      setProject({
        ...project,
        ...newProject,
      });

      await releaseBody(project.id, visibility);
      onReleased();
    } catch {
      alert("公開処理に失敗しました。");
    }
  };

  const handleChange =
    (set: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      set(event.target.value);
    };
  const handleChangeDescription = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(event.target.value);
  };
  const handleChangeTextArea =
    (set: (value: string) => void) =>
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      set(event.target.value);
    };
  const handleChangeSelect =
    (set: (value: number) => void) =>
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      set(parseInt(event.target.value, 10));
    };

  const categoryName = categories.find((c) => c.id === categoryId)?.name || "-";

  useEffect(() => {
    if (feeType !== "free" && !agreeSale) {
      setErrorMessage("");
    } else if (feeType !== "free" && price == null) {
      setErrorMessage("価格を設定してください");
    } else if (name.length === 0) {
      setErrorMessage("作品タイトルを設定してください");
    } else if (
      feeType === "attachments_only" &&
      project.attachments.items.length === 0
    ) {
      setErrorMessage(
        "『添付ファイルのみ有料』で頒布するには添付ファイルを一つ以上設定してください"
      );
    } else {
      setErrorMessage(undefined);
    }
  }, [feeType, agreeSale, price, name, project.attachments.items]);

  return (
    <div className={styles.container}>
      <h2 className={styles.ttl}>公開設定</h2>
      <div className={styles.box}>
        <label className={styles.label}>
          作品タイトル <span className={styles.required}>※必須</span>
        </label>
        <input
          className={styles.input}
          type="text"
          name="name"
          placeholder="タイトルを入力"
          value={name}
          onChange={handleChange(setName)}
        />
        <label className={styles.label}>著者名（表記）</label>
        <input
          className={styles.input}
          type="text"
          name="author"
          placeholder="著者名を入力"
          value={author}
          onChange={handleChange(setAuthor)}
        />
        <label className={styles.label}>表紙画像</label>
        <InputThumbnail
          {...{ coverUrl, setCoverUrl, coverImageFile, setCoverImageFile }}
        />
        <GenerateThumbnail
          name={name}
          categoryName={categoryName}
          setCoverImageFile={setCoverImageFile}
        />
        <p className={styles.note}>
          ※ 2MBまでの画像を追加できます。画像の推奨サイズは 1280 × 720 ( 16：9 )
          です。
        </p>
        <label className={styles.label}>作品ジャンル</label>
        <button className={styles.genreBtn} onClick={openModal}>
          <div className={styles.categoryName}>{categoryName}</div>
        </button>
        <p className={styles.note}>
          <a href="/help#how_to_request_genre" target="_blank">
            ※ 投稿したい作品ジャンルがない場合
          </a>
        </p>
        <ModalGenre
          open={openCategorySelectModal}
          handleClose={closeModal}
          onChange={handleChangeCategory}
          categories={categories}
          allowNotSet={false}
        />
        <label className={styles.label}>権利表記</label>
        <textarea
          className={styles.copyright}
          name="copyright"
          rows={5}
          placeholder={defaultCopyrightNotice}
          value={copyrightNotice}
          onChange={handleChangeTextArea(setCopyrightNotice)}
        />
        <p className={styles.note}>
          ※
          特に変更がない場合、権利表記の項目には現在表示されているデフォルトのテキストが自動で反映されます。
          <br />
          正しい権利表記については確認中のものもあるため、念のためご自身でもご確認ください。変更が必要な方は、個別にテキストの編集をお願いします。
        </p>
        <label className={styles.label}>最小プレイヤー数</label>
        <Select
          name="playersMin"
          value={playersMin || undefined}
          onChange={handleChangeSelect(setPlayersMin)}
        >
          {PLAYERS_MIN_OPTIONS.map((players) => {
            return (
              <option
                value={players.value || "null"}
                hidden={players.value == null}
              >
                {players.text}
              </option>
            );
          })}
        </Select>
        <label className={styles.label}>最大プレイヤー数</label>
        <Select
          name="playersMax"
          value={playersMax || undefined}
          onChange={handleChangeSelect(setPlayersMax)}
        >
          {PLAYERS_MAX_OPTIONS.map((players) => {
            const value = players.value || "null";
            return (
              <option key={value} value={value} hidden={players.value == null}>
                {players.text}
              </option>
            );
          })}
        </Select>
        <label className={styles.label}>予定プレイ時間</label>
        <Select
          name="player"
          placeholder="選択"
          value={playTime || undefined}
          onChange={handleChangeSelect(setPlayTime)}
        >
          {PLAYING_HOUR_OPTIONS.map((hour) => {
            const value = hour.value || "null";
            return (
              <option key={value} value={value} hidden={hour.value == null}>
                {hour.text}
              </option>
            );
          })}
        </Select>
        <label className={styles.label}>タグ</label>
        <input
          className={styles.input}
          type="text"
          name=""
          placeholder="タグを追加"
          value={tags}
          onChange={handleChange(setTags)}
        />
        <p className={styles.note}>
          ※ 半角スペースで区切ると1つのタグとして反映されます。
        </p>
        <label className={styles.label}>紹介文</label>
        <textarea
          className={styles.description}
          name="description"
          rows={8}
          value={description}
          onChange={handleChangeDescription}
        />
      </div>
      <div className={styles.box}>
        <Attachment />
      </div>
      <div className={styles.box}>
        <label className={styles.label}>公開範囲</label>
        <label className={styles.radioArea}>
          <input
            className={styles.radio}
            value="public"
            type="radio"
            name="visibility"
            checked={visibility === "public"}
            onChange={changeVisibility}
          />
          全体に公開
        </label>
        <label className={styles.radioArea}>
          <input
            className={styles.radio}
            value="private"
            type="radio"
            name="visibility"
            checked={visibility === "private"}
            onChange={changeVisibility}
          />
          限定公開
        </label>
        <label className={styles.radioArea}>
          <input
            className={styles.radio}
            value="closed"
            type="radio"
            name="visibility"
            checked={visibility === "closed"}
            onChange={changeVisibility}
          />
          自分だけ公開
        </label>
      </div>
      <PriceArea
        ownerId={project.owner_id}
        {...{
          feeType,
          setFeeType,
          price,
          setPrice,
          licenseId,
          setLicenseId,
          agreeSale,
          setAgreeSale,
          supporterProgramId: toSupporterProgramId(categoryId),
        }}
      />
      {errorMessage && errorMessage.length > 0 && (
        <div className={styles.errorMessage}>
          <p>{errorMessage}</p>
        </div>
      )}
      <div className={styles.submitArea}>
        <BtnWithProcessing
          labelProsessing="公開処理中"
          onClick={release}
          disabled={errorMessage != null}
        >
          公開する
        </BtnWithProcessing>
      </div>
    </div>
  );
};

export default React.memo(ModalPublish);
