import { withIdTokenIfAuthed } from "apiv1";
import axios from "axios";

// -----
// GET: /v1/projects/:projectId/attachments/:fileName
// -----
export type SignedUrl = {
  url: string;
};

export const getSignedUrlForDownloadingAttachment = async (
  projectId: string,
  fileName: string
) => {
  return axios.get<SignedUrl>(
    `/v1/projects/${projectId}/attachments/${encodeURI(
      fileName
    )}/signedUrlToDownload`,
    await withIdTokenIfAuthed()
  );
};
