import React from "react";
import styles from "./Btn.module.css";

export type BtnProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  secondary?: boolean;
};

const Btn: React.FC<BtnProps> = ({ secondary = false, children, ...props }) => {
  return (
    <button className={styles.btn} data-secondary={secondary} {...props}>
      {children}
    </button>
  );
};

export default Btn;
