import { memo } from "react";
import styles from "./ButtonOutline.module.css";

const ButtonOutline = ({
  children,
  className,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => {
  let buttonClassName = styles.button;
  if (className != null) {
    buttonClassName = `${buttonClassName} ${className}`;
  }

  return (
    <button {...props} className={buttonClassName}>
      {children}
    </button>
  );
};

export default memo(ButtonOutline);
