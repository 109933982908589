import React, { useState, useEffect } from "react";
import styles from "./TopFillterSearch.module.css";
import { ReactComponent as IconSearch } from "./IconSearch.svg";
import { ReactComponent as IconArrowDrop } from "./IconArrowDrop.svg";
import { PLAYERS_MAX_OPTIONS, PLAYING_HOUR_OPTIONS } from "utils/projects";
import ModalGenre from "components/ModalGenre/ModalGenre";
import {
  CategoryName,
  getCategories,
  normalizeCategoryName,
  WithNomarizedName,
} from "apiv1/category";
import { FeeType, isFeeType } from "apiv1/project";
import { useHistory } from "react-router-dom";
import produce from "immer";

export type Params = {
  q: string;
  category?: number;
  playersMin?: number;
  playersMax?: number;
  playTime?: number;
  feeType?: FeeType;
};

export type TopFillterSearchProps = {
  params: Params;
  setParams: (x: Params) => void;
};

let cacheCategoryName: WithNomarizedName<CategoryName>[] = [];

const TopFillterSearch: React.FC<TopFillterSearchProps> = ({
  params,
  setParams,
}) => {
  const [openAtMobile, setOpenAtMobile] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [keyword, setKeyword] = useState<string>(params.q);
  const [categories, setCategories] =
    useState<WithNomarizedName<CategoryName>[]>(cacheCategoryName);
  const history = useHistory();

  useEffect(() => {
    setKeyword(params.q);
  }, [params.q]);

  useEffect(() => {
    if (categories.length === 0) {
      getCategories().then((resp) => {
        cacheCategoryName = resp.data.map((category) => {
          return {
            ...category,
            nomalizedName: normalizeCategoryName(category.name),
          };
        });
        setCategories(cacheCategoryName);
      });
    }
  }, []);

  const handleChangeCategoryId = (categoryId: number | undefined) => {
    if (categoryId == null) {
      history.push("/");
    }

    const category = categories.find((c) => c.id === categoryId);
    if (category) {
      history.push(`/c/${category.slug}`);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setParams(
      produce(params, (draft) => {
        draft.q = keyword;
      })
    );
  };

  const clickSubmit = () => {
    setParams(
      produce(params, (draft) => {
        draft.q = keyword;
      })
    );
  };

  const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const handleChangePlayersMax = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const players = parseInt(event.target.value, 10);
    setParams(
      produce(params, (draft) => {
        draft.playersMax = players === 0 ? undefined : players;
      })
    );
  };

  const handleChangePlayTimes = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const playTime = parseInt(event.target.value, 10);
    setParams(
      produce(params, (draft) => {
        draft.playTime = playTime === 0 ? undefined : playTime;
      })
    );
  };

  const handleChangeFeeType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setParams(
      produce(params, (draft) => {
        draft.feeType = isFeeType(event.target.value)
          ? event.target.value
          : undefined;
      })
    );
  };

  const openModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setOpenCategoryModal(true);
  };

  const category = categories.find(
    (category) => category.id === params.category
  );
  const categoryName = category ? category.name : "指定なし";

  const toggleOpenAtMobile = () => {
    setOpenAtMobile(!openAtMobile);
  };

  const listClassName = openAtMobile ? styles.list : styles.listClosed;

  return (
    <div className={styles.container}>
      <button
        className={styles.btnDropbox}
        onClick={toggleOpenAtMobile}
        data-open={openAtMobile}
      >
        <span className={styles.btnSearch}>
          <IconSearch></IconSearch>
          <span>検索条件を設定</span>
        </span>
        <span className={styles.btnToggle} data-open={openAtMobile}>
          <IconArrowDrop></IconArrowDrop>
        </span>
      </button>
      <ul className={listClassName}>
        <li className={styles.keywords}>
          <label className={styles.label}>キーワード・タグ検索</label>
          <div className={styles.searchArea}>
            <form onSubmit={handleSubmit} className={styles.searchFocus}>
              <input
                type="search"
                name="search"
                placeholder="検索"
                className={styles.search}
                value={keyword}
                onChange={handleChangeKeyword}
              />
              <span className={styles.searchIcon}>
                <IconSearch></IconSearch>
              </span>
            </form>
            <button className={styles.searchSubmit} onClick={clickSubmit}>
              検索
            </button>
          </div>
        </li>
        <li className={styles.genre}>
          <label className={styles.label}>ジャンル</label>
          <button
            className={
              category == null ? styles.genreBtn : styles.genreBtnActive
            }
            onClick={openModal}
          >
            {categoryName}
          </button>
          <ModalGenre
            open={openCategoryModal}
            handleClose={() => setOpenCategoryModal(false)}
            value={params.category}
            onChange={handleChangeCategoryId}
            categories={categories}
          />
        </li>
        <li className={styles.player}>
          <label className={styles.label}>プレイヤー数</label>
          <div className={styles.selectArea}>
            <select
              className={
                params.playersMax != null ? styles.selectActive : styles.select
              }
              name="max"
              placeholder="指定なし"
              value={params.playersMax}
              onChange={handleChangePlayersMax}
            >
              {PLAYERS_MAX_OPTIONS.map((players) => {
                return (
                  <option value={players.value || 0} key={players.value || 0}>
                    {players.text}
                  </option>
                );
              })}
            </select>
          </div>
        </li>
        <li className={styles.times}>
          <label className={styles.label}>プレイ時間</label>
          <div className={styles.selectArea}>
            <select
              className={
                params.playTime != null ? styles.selectActive : styles.select
              }
              name="time"
              placeholder="指定なし"
              value={params.playTime}
              onChange={handleChangePlayTimes}
            >
              {PLAYING_HOUR_OPTIONS.map((hour) => {
                const value = hour.value || 0;
                return (
                  <option value={value} key={value}>
                    {hour.text}
                  </option>
                );
              })}
            </select>
          </div>
        </li>
        <li className={styles.price}>
          <label className={styles.label}>価格</label>
          <div className={styles.selectArea}>
            <select
              className={
                params.feeType != null ? styles.selectActive : styles.select
              }
              name="price"
              placeholder="指定なし"
              onChange={handleChangeFeeType}
            >
              <option value="undefined">指定なし</option>
              <option value="free">無料</option>
              <option value="all_contents">有料</option>
              <option value="attachments_only">添付ファイルのみ有料</option>
            </select>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default TopFillterSearch;
