import { getUserProfile, UserProfile } from "apiv1/user";
import { SpinnerFullDisplay } from "components/Spinner";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import NotFound from "pages/NotFound";
import styles from "./Profile.module.css";
import ProfileList from "./ProfileList/ProfileList";
import ImgAccount from "components/images/DefaultUserIcon.jpg";
import { ReactComponent as IconContact } from "./IconContact.svg";
import Modal from "components/Modal";
import ModalTokusho from "./ModalTokusho";
import DefaultLayout from "components/DefaultLayout";
import { resetDocumentTitle } from "utils/title";

type ProfileParams = {
  uid: string;
};

const ERROR_MSG = "エラー：クリップボードへのコピーが失敗しました。";

const filteredDisplayName = (displayName: string | null): string => {
  if (displayName == null || displayName.length === 0) {
    return "ユーザー名未設定";
  }

  return displayName;
};

const Profile: React.VFC = () => {
  const { uid } = useParams<ProfileParams>();
  const [userProfile, setUserProfile] = useState<
    UserProfile | "loading" | "notfound"
  >("loading");
  const [openSctact, setOpenSctact] = useState(false);

  useEffect(() => {
    getUserProfile(uid)
      .then((res) => {
        setUserProfile(res.data);
      })
      .catch(() => {
        setUserProfile("notfound");
      });
  }, [uid]);

  useEffect(() => {
    if (userProfile !== "loading" && userProfile !== "notfound") {
      window.document.title = filteredDisplayName(
        userProfile.profile.display_name
      );
      return resetDocumentTitle;
    }
  }, [userProfile]);

  if (userProfile === "loading") {
    return <SpinnerFullDisplay />;
  }

  if (userProfile === "notfound") {
    return <NotFound />;
  }

  const displayName = filteredDisplayName(userProfile.profile.display_name);

  const hasContact =
    userProfile.profile.contact != null &&
    userProfile.profile.contact.length > 0;

  const projects = userProfile.projects.map((project) => {
    return {
      projectId: project.id,
      name: project.name,
      category: project.category_name,
      thumnail: project.cover_url,
    };
  });

  const copyContactToClickboard = () => {
    if (userProfile.profile.contact == null) {
      return;
    }

    if (!navigator.clipboard) {
      alert(ERROR_MSG);
      return;
    }

    navigator.clipboard
      .writeText(userProfile.profile.contact)
      .then(() => alert("クリップボードにコピーしました"))
      .catch(() => alert(ERROR_MSG));
  };

  return (
    <DefaultLayout>
      <div className={styles.profile}>
        <div className={styles.inner}>
          <div className={styles.img}>
            <img
              src={userProfile.profile.photo_url || ImgAccount}
              alt="ImgAccount"
            />
          </div>
          <h2 className={styles.name}>{displayName}</h2>
          <p className={styles.description}>{userProfile.profile.bio}</p>
          {hasContact && (
            <button
              className={styles.btn}
              title="クリップボードにコピー"
              onClick={copyContactToClickboard}
            >
              <IconContact />
              {userProfile.profile.contact}
            </button>
          )}
        </div>
      </div>
      <ProfileList projects={projects} />
      {userProfile.sctact && (
        <div className={styles.bottom}>
          <button
            className={styles.btnTerms}
            onClick={() => setOpenSctact(true)}
          >
            特定商取引法に基づく表記
          </button>
          <Modal open={openSctact} onClose={() => setOpenSctact(false)}>
            <ModalTokusho sctact={userProfile.sctact} />
          </Modal>
        </div>
      )}
    </DefaultLayout>
  );
};

export default Profile;
