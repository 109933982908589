import { CategoryWithOfficialProject, getCategoryBySlug } from "apiv1/category";
import DefaultLayout from "components/DefaultLayout";
import NotFound from "pages/NotFound";
import { SearchArea } from "pages/Top/Top";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainVisual from "./MainVisual";

type LoadStatus = "loading" | "loaded" | "notfound";

const Category: React.FC = () => {
  const { categorySlug } = useParams<{ categorySlug: string }>();
  const [category, setCategory] = useState<CategoryWithOfficialProject>();
  const [loadStatus, setLoadStatus] = useState<LoadStatus>("loading");

  useEffect(() => {
    getCategoryBySlug(categorySlug)
      .then((res) => {
        setCategory(res.data);
        setLoadStatus("loaded");
      })
      .catch(() => setLoadStatus("notfound"));
  }, [categorySlug]);

  if (loadStatus === "notfound") {
    return <NotFound />;
  }

  return (
    <DefaultLayout>
      <MainVisual
        loading={loadStatus === "loading"}
        bannerPath={category?.banner_path || undefined}
        categoryName={category?.name}
        urlOfficialSite={category?.url_official_site || undefined}
      />
      <SearchArea
        loading={loadStatus === "loading"}
        categoryId={category?.id}
        categoryName={category?.name}
        officialProjects={category?.official_projects}
      />
    </DefaultLayout>
  );
};

export default Category;
