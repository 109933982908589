import React from "react";
import styles from "./TopFillter.module.css";
import TopFillterSearch, {
  TopFillterSearchProps,
} from "./TopFillterSearch/TopFillterSearch";
import TopFillterGenre from "./TopFillterGenre/TopFillterGenre";

const TopFillter: React.VFC<TopFillterSearchProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.containerSP}>
        <TopFillterSearch {...props} />
      </div>
      <TopFillterGenre />
    </div>
  );
};

export default TopFillter;
