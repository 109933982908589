import React from "react";
import styles from "./InputThumbnail.module.css";
import { ReactComponent as IconImgDelete } from "./IconImgDelete.svg";
import { ReactComponent as IconImage } from "./IconImage.svg";
import { useDropzone } from "react-dropzone";

const THUMBNAIL_SIZE_LIMIT = 2 * 1024 * 1024; // 2 MiB

export type ImageFile = {
  file: File;
  objectURL: string;
};

type InputThumbnailProps = {
  coverUrl: string | null;
  setCoverUrl: (x: string | null) => void;
  coverImageFile: ImageFile | undefined;
  setCoverImageFile: (x: ImageFile | undefined) => void;
};

const InputThumbnail: React.FC<InputThumbnailProps> = ({
  coverUrl,
  setCoverUrl,
  coverImageFile,
  setCoverImageFile,
}) => {
  const onDrop = (files: File[]) => {
    const file = files[0];

    if (file.size > THUMBNAIL_SIZE_LIMIT) {
      alert("表紙画像は2MB以下としてください");
      return;
    }

    const objectURL = URL.createObjectURL(file);
    setCoverImageFile({ file, objectURL });
  };

  const dropzone = useDropzone({
    onDrop,
    maxFiles: 1,
    noClick: false,
  });

  const resetThumbnail = (event: React.MouseEvent) => {
    event.stopPropagation();
    setCoverUrl(null);
    if (coverImageFile) {
      URL.revokeObjectURL(coverImageFile.objectURL);
      setCoverImageFile(undefined);
    }
  };

  const src = coverImageFile?.objectURL || coverUrl;

  if (src) {
    return (
      <div className={styles.thumbnailWrap} {...dropzone.getRootProps()}>
        <input {...dropzone.getInputProps()} />
        <img className={styles.thumbnail} src={src} />
        <button className={styles.thumbnailDelete} onClick={resetThumbnail}>
          <IconImgDelete />
        </button>
      </div>
    );
  } else {
    return (
      <div className={styles.img} {...dropzone.getRootProps()}>
        <input {...dropzone.getInputProps()} />
        <IconImage />
        <p className={styles.note}>
          ここに表紙のプレビューが表示されます
          <br />
          <span>推奨サイズ 1280 × 720 ( 16：9 )</span>
        </p>
      </div>
    );
  }
};

export default InputThumbnail;
