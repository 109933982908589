import { Attachment } from "apiv1/project";
import { Release } from "apiv1/release";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./ItemContents.module.css";

type ItemContentsProps = {
  release: Release;
  attachments: Attachment[];
  copyrightNotice: string | null;
  disabledBookletLink: boolean;
};

const ItemContents: React.VFC<ItemContentsProps> = ({
  release,
  attachments,
  copyrightNotice,
  disabledBookletLink,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.ttl}>内容物</p>
      <p className={styles.booklet}>冊子</p>
      <ul className={styles.list}>
        {release.booklet_infos.booklets.map((booklet) => {
          const path = `/projects/${release.project_id}/rev/${release.rev}/booklets/${booklet.id}`;
          return (
            <li className={styles.flex} key={booklet.id}>
              <p className={styles.name}>
                {!disabledBookletLink ? (
                  <Link to={path} target="_blank">
                    {booklet.name}
                  </Link>
                ) : (
                  booklet.name
                )}
              </p>
              <p className={styles.count}>{booklet.charCount}文字</p>
            </li>
          );
        })}
      </ul>
      {attachments.length > 0 && (
        <>
          <p className={styles.attachment}>ファイル</p>
          <ul className={styles.list}>
            {attachments.map((file) => {
              return (
                <li key={file.url} className={styles.fileName}>
                  {file.name}
                </li>
              );
            })}
          </ul>
        </>
      )}
      {copyrightNotice != null && copyrightNotice.length > 0 && (
        <div className={styles.note}>
          <p>※ {copyrightNotice}</p>
        </div>
      )}
    </div>
  );
};

export default ItemContents;
