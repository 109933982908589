import React, { useContext, useEffect, useState } from "react";
import DefaultLayout from "components/DefaultLayout";
import styles from "./Coupons.module.css";
import ModalNewCoupon from "./ModalNewCoupon";
import { AuthContext } from "components/AuthProvider";
import NotFound from "pages/NotFound";
import { CouponInfo, getUserCoupons, postCouponsRevoke } from "apiv1/coupons";
import { WithLoad } from "utils/types";
import { SpinnerCenter } from "components/Spinner";
import produce from "immer";
import Btn from "components/Btn";
import { Link } from "react-router-dom";

type CouponRowProps = {
  coupon: CouponInfo;
  onRevoke: (id: string) => void;
};

const describe = (coupon: CouponInfo): string => {
  switch (coupon.coupon_type) {
    case "free":
      return "無料";
    case "fixed_amount_off":
      return `${coupon.amount}円引き`;
    case "percent_off":
      return `${coupon.percent}%オフ`;
  }
};

export const formatCreatedAt = (created_at: string) => {
  const d = new Date(created_at);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const hour = d.getHours().toString().padStart(2, "0");
  const min = d.getMinutes().toString().padStart(2, "0");
  return `${year}年${month}月${date}日 ${hour}:${min}`;
};

const CouponRow: React.VFC<CouponRowProps> = ({ coupon, onRevoke }) => {
  const handleCopy = () => {
    if (!navigator.clipboard) {
      alert("クリップボードへのコピーに失敗しました");
    }

    navigator.clipboard
      .writeText(coupon.id)
      .then(() => alert("コードをクリップボードにコピーしました"))
      .catch(() => alert("クリップボードへのコピーに失敗しました"));
  };

  const handleRevoke = () => {
    if (!window.confirm(`シナチケ "${coupon.id}" を無効にしますか？`)) {
      return;
    }

    postCouponsRevoke(coupon.id)
      .then(() => {
        alert(`シナチケ "${coupon.id}" を無効にしました`);
        onRevoke(coupon.id);
      })
      .catch(() => {
        alert(
          `シナチケの無効化に失敗しました。通信状況が正常かをご確認ください。`
        );
      });
  };

  const revoked = coupon.revoked_at != null;

  return (
    <tr className={styles.box}>
      <td data-label="コード">
        <span
          className={styles.couponCode}
          onClick={handleCopy}
          title="コードをコピー"
        >
          {revoked ? <del>{coupon.id}</del> : <>{coupon.id}</>}
        </span>
      </td>
      <td data-label="内容">{describe(coupon)}</td>
      <td data-label="対象作品">{coupon.project_name}</td>
      <td data-label="発行日時">{formatCreatedAt(coupon.created_at)}</td>
      <td className={styles.tdRevoke}>
        <Btn onClick={handleRevoke} disabled={revoked} secondary>
          無効にする
        </Btn>
      </td>
    </tr>
  );
};

type CouponTableProps = {
  uid: string;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const CouponTable: React.VFC<CouponTableProps> = ({ uid, open, setOpen }) => {
  const [coupons, setCoupons] = useState<WithLoad<CouponInfo[]>>("loading");

  useEffect(() => {
    getUserCoupons(uid)
      .then((res) => setCoupons(res.data))
      .catch(() => setCoupons("notfound"));
  }, []);

  if (coupons === "loading") {
    return <SpinnerCenter />;
  }

  if (coupons === "notfound") {
    return <p>データの取得に失敗しました</p>;
  }

  const handleRevoke = (id: string) => {
    const index = coupons.findIndex((coupon) => coupon.id === id);
    if (index < 0) {
      return;
    }

    const newCoupons = produce(coupons, (draft) => {
      draft[index] = {
        ...coupons[index],
        revoked_at: new Date().toISOString(),
      };
    });

    setCoupons(newCoupons);
  };

  const appendCoupon = (coupon: CouponInfo) => {
    setCoupons([coupon, ...coupons]);
  };

  return (
    <>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            <th>コード</th>
            <th>内容</th>
            <th>対象作品</th>
            <th>発行日時</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {coupons.map((coupon) => (
            <CouponRow
              key={coupon.id}
              coupon={coupon}
              onRevoke={handleRevoke}
            />
          ))}
        </tbody>
      </table>
      {coupons.length === 0 && <p>過去に発行したシナチケはありません</p>}
      <ModalNewCoupon
        uid={uid}
        onCreated={appendCoupon}
        open={open}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

const Coupons: React.VFC = () => {
  const [open, setOpen] = useState(false);
  const user = useContext(AuthContext);

  if (!user.authed) {
    return <NotFound />;
  }

  return (
    <DefaultLayout>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>シナチケ管理</h1>
          <Btn onClick={() => setOpen(true)}>シナチケを作成</Btn>
        </div>
        <div className={styles.help}>
          <Link
            to={{ pathname: "/help", hash: "what_is_scenario_ticket" }}
            target="_blank"
          >
            シナチケとは?
          </Link>
        </div>
        <CouponTable uid={user.uid} open={open} setOpen={setOpen} />
      </div>
    </DefaultLayout>
  );
};

export default Coupons;
