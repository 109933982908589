import { useState, useEffect, useMemo } from "react";
import { useLocation, useParams } from "react-router";
import { SpinnerFullDisplay } from "components/Spinner";
import NotFound from "pages/NotFound";
import { getProject, Project } from "apiv1/project";
import { getRelease, ReleaseDataV1, ReleaseDataV2 } from "apiv1/release";
import { ViewerPage } from "./Viewer";
import { ViewerLexical } from "components/Viewer";

type ViewerParams = {
  projectId: string;
  rev: string;
  bookletId: string;
};

const ViewerSingleBooklet = () => {
  const location = useLocation();
  const { projectId, rev, bookletId } = useParams<ViewerParams>();
  const [currentBookletId, setCurrentBookletId] = useState(bookletId);
  const [project, setProject] = useState<Project | "loading" | "notfound">(
    "loading"
  );
  const [release, setRelease] = useState<
    ReleaseDataV1 | ReleaseDataV2 | "loading" | "notfound"
  >("loading");

  const token = useMemo(() => {
    const query = new URLSearchParams(location.search);
    return query.get("token");
  }, [location.search]);

  useEffect(() => {
    getProject(projectId)
      .then((res) => setProject(res.data))
      .catch(() => setProject("notfound"));
    getRelease(projectId, rev, token)
      .then((release) => {
        setRelease(release.data);
      })
      .catch(() => setRelease("notfound"));
  }, [projectId, rev]);

  if (project === "notfound" || release === "notfound") {
    return <NotFound />;
  } else if (project === "loading" || release === "loading") {
    return <SpinnerFullDisplay />;
  }

  if (release.version === "v2") {
    const booklet = release.booklets.find((b) => b.id === bookletId);
    if (booklet == null) {
      return <NotFound />;
    }

    const releaseSingle: ReleaseDataV2 = {
      ...release,
      booklets: [booklet],
    };

    return (
      <ViewerLexical
        projectName={project.name}
        projectId={project.id}
        rev={release.rev}
        bookletId={currentBookletId}
        onChangeBooklet={setCurrentBookletId}
        date={release.created_at}
        scenarioData={releaseSingle}
        enableShareButton={false}
      />
    );
  }

  const booklet = release.booklets.find((b) => b.id === bookletId);
  if (booklet == null) {
    return <NotFound />;
  }

  const releaseSingle: ReleaseDataV1 = {
    ...release,
    booklets: [booklet],
  };

  return (
    <ViewerPage
      project={project}
      release={releaseSingle}
      currentBookletId={currentBookletId}
      setCurrentBookletId={setCurrentBookletId}
      changeDocTitle={true}
      shareBtn={false}
      preview={false}
    />
  );
};

export default ViewerSingleBooklet;
