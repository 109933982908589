import React from "react";
import { Link } from "react-router-dom";
import styles from "./SalesMainRequired.module.css";

type SalesMainRequiredProps = {
  emailVerified: boolean;
  tenantRegisterd: boolean;
  sctactRegisterd: boolean;
};

const SalesMainRequired: React.FC<SalesMainRequiredProps> = ({
  emailVerified,
  tenantRegisterd,
  sctactRegisterd,
}) => {
  return (
    <div className={styles.container}>
      <p className={styles.note}>
        ※
        販売者情報の登録がされていないためシナリオの販売ができません。シナリオの販売を開始するには、以下の情報を登録してください。
      </p>
      {!emailVerified && (
        <>
          <p className={styles.border}>メールアドレス</p>
          <p className={styles.txt}>メールアドレスの登録と確認が必要です。</p>
          <p className={styles.coments}>
            ※SNS認証でTALTOに登録すると、メールアドレスが登録されていないことがあります。
          </p>
          <p className={styles.coments}>
            ※メールアドレスの確認状況が検証済みになる必要があります。
          </p>
          <Link to="/settings/account" className={styles.btn}>
            アカウント設定
          </Link>
        </>
      )}
      {(!tenantRegisterd || !sctactRegisterd) && (
        <>
          <p className={styles.border}>販売者情報</p>
          <p className={styles.txt}>
            口座情報と特定商取引法に基づく表記の登録が必要です。
          </p>
          <Link to="/settings/sales" className={styles.btn}>
            販売者情報を登録
          </Link>
        </>
      )}
    </div>
  );
};

export default SalesMainRequired;
