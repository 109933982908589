import React from "react";
import styles from "./MainVisual.module.css";
import { ReactComponent as IconBlank } from "./IconBlank.svg";

type MainVisualProps = {
  loading: boolean;
  bannerPath?: string;
  categoryName?: string;
  urlOfficialSite?: string;
};

const MainVisual: React.FC<MainVisualProps> = ({
  loading,
  bannerPath,
  categoryName,
  urlOfficialSite,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.bannerFrame} data-loading={loading}>
        {bannerPath ? (
          <img className={styles.banner} src={bannerPath} />
        ) : (
          <div className={styles.bannerDefault}>
            <div className={styles.bannerTitle}>{categoryName}</div>
          </div>
        )}
      </div>
      <div className={styles.buttonArea} hidden={loading}>
        {/* <button className={styles.btnWrite}>シナリオを執筆する</button> */}
        {urlOfficialSite && (
          <button className={styles.btnOfficial}>
            <a href={urlOfficialSite} target="_blank" rel="noopener">
              <span className={styles.txt}>公式サイト</span>
              <span className={styles.img}>
                <IconBlank></IconBlank>
              </span>
            </a>
          </button>
        )}
      </div>
    </div>
  );
};

export default React.memo(MainVisual);
