import { Order } from "apiv1/sales";
import BtnWithProcessing from "components/Btn/BtnWithProcessing";
import Modal from "components/Modal";
import { SpinnerCenter } from "components/Spinner";
import React, { useState } from "react";
import { paymentMethod } from "utils/order";
import { WithLoad } from "utils/types";
import ModalSalesOrder from "./ModalSalesOrder";
import styles from "./SalesMainOrders.module.css";

const dateToString = (d: Date) => {
  const year = d.getFullYear();
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const date = d.getDate().toString().padStart(2, "0");
  return `${year}/${month}/${date}`;
};

type OrderRowProps = {
  order: Order;
  openModal: (order: Order) => void;
};

const OrderRow: React.FC<OrderRowProps> = ({ order, openModal }) => {
  const income = order.price - order.talto_fee - order.license_fee;
  const openDetail = () => {
    openModal(order);
  };

  return (
    <tr className={styles.tr}>
      <td className={styles.statusRed}>
        {dateToString(new Date(order.created_at))}
      </td>
      <td className={styles.ttl}>{order.project_name}</td>
      <td className={styles.number}>{order.order_id}</td>
      <td>{paymentMethod(order.price, order.coupon_id)}</td>
      <td className={styles.price}>¥{order.price.toLocaleString("ja-JP")}</td>
      <td className={styles.fee}>¥{order.talto_fee.toLocaleString("ja-JP")}</td>
      <td className={styles.spll}>
        {order.license_fee === 0
          ? "-"
          : `¥${order.license_fee.toLocaleString("ja-JP")}`}
      </td>
      <td className={styles.profit}>¥{income.toLocaleString("ja-JP")}</td>
      <td className={styles.other}>
        <button className={styles.btn} onClick={openDetail}>
          詳細
        </button>
      </td>
    </tr>
  );
};

type SalesMainOrdersProps = {
  orders: WithLoad<Order[]>;
  fetched: boolean;
  fetchAllOrders: () => Promise<void>;
};

const SalesMainOrders: React.FC<SalesMainOrdersProps> = ({
  orders,
  fetched,
  fetchAllOrders,
}) => {
  const [order, setOrder] = useState<Order>();
  const [open, setOpen] = useState(false);

  if (orders === "loading") {
    return <SpinnerCenter />;
  }

  if (orders === "notfound") {
    return (
      <div className={styles.container}>
        <p>注文履歴の取得に失敗しました。</p>
      </div>
    );
  }

  const openModal = (order: Order) => {
    setOrder(order);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <tr className={styles.tr}>
          <th>支払完了日</th>
          <th>作品名</th>
          <th>注文番号</th>
          <th>支払方法</th>
          <th>販売価格</th>
          <th>販売手数料</th>
          <th>販売許諾料</th>
          <th>販売利益</th>
          <th>操作</th>
        </tr>
        {orders.map((order) => (
          <OrderRow key={order.order_id} order={order} openModal={openModal} />
        ))}
      </table>
      <Modal open={open} onClose={closeModal}>
        <ModalSalesOrder order={order} />
      </Modal>
      {!fetched && (
        <div className={styles.showAllBtn}>
          <BtnWithProcessing
            labelProsessing="取得中"
            onClick={fetchAllOrders}
            secondary
          >
            全ての注文を表示する
          </BtnWithProcessing>
        </div>
      )}
    </div>
  );
};

export default SalesMainOrders;
