import { CategoryName, normalizeCategoryName } from "apiv1/category";
import Modal from "components/Modal";
import React, { useState, useMemo } from "react";
import styles from "./ModalGenre.module.css";

type WithNomarizedName<T> = T & {
  nomalizedName: string;
};

type ModalGenreProps = {
  open: boolean;
  handleClose: () => void;
  categories: WithNomarizedName<CategoryName>[];
  value?: number;
  onChange: (id: number | undefined) => void;
  allowNotSet?: boolean;
};

const ModalGenre: React.FC<ModalGenreProps> = ({
  open,
  handleClose,
  categories,
  value,
  onChange,
  allowNotSet = true,
}) => {
  const [searchText, setSearchText] = useState<string>("");
  const handleChange =
    (id: number | undefined) => (event: React.MouseEvent) => {
      event.preventDefault();
      onChange(id);
      handleClose();
    };

  const handleSeacrhInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value;
    setSearchText(value || "");
  };

  const onClose = (event: React.MouseEvent) => {
    event.preventDefault();
    handleClose();
  };

  const filteredCategories = useMemo(() => {
    const text = normalizeCategoryName(searchText);
    return categories.filter((category) => {
      return category.nomalizedName.indexOf(text) > -1;
    });
  }, [categories, searchText]);

  const className = value == null ? styles.active : undefined;
  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.ttl}>ジャンル</h2>
          <div className={styles.searchArea}>
            <input
              type="text"
              onChange={handleSeacrhInputChange}
              value={searchText}
              className={styles.search}
              placeholder="検索"
            />
          </div>
        </div>
        <ul className={styles.list}>
          {allowNotSet && (
            <li className={className} onClick={handleChange(undefined)}>
              指定なし
            </li>
          )}
          {filteredCategories.map((category) => {
            const className = value === category.id ? styles.active : undefined;
            return (
              <li
                className={className}
                onClick={handleChange(category.id)}
                key={category.name}
              >
                {category.name}
              </li>
            );
          })}
        </ul>
      </div>
    </Modal>
  );
};

export default React.memo(ModalGenre);
