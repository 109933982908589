import styles from "./Error.module.css";
import ImgMain from "./ImgMain.jpg";
import { Link } from "react-router-dom";
import DefaultLayout from "components/DefaultLayout";

export const NotFoundBody = () => {
  return (
    <div className={styles.main}>
      <h2 className={styles.ttl}>404 Not Found</h2>
      <img src={ImgMain} alt="ImgMain" />
      <p className={styles.txt}>
        お探しのページはこのTRPGのように存在しませんでした。
        <br />
        リンクに問題があるか、ページが削除された可能性があります。
      </p>
      <Link to="/" className={styles.btn}>
        トップに戻る
      </Link>
    </div>
  );
};

const NotFound = () => {
  return (
    <DefaultLayout>
      <NotFoundBody />
    </DefaultLayout>
  );
};

export default NotFound;
