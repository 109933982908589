import { Link } from "react-router-dom";
import styles from "./Terms.module.css";

const BusinessTerms = () => {
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <ul className={styles.sidebarList}>
          <li className={styles.sidebarItem}>
            <Link to="/terms">利用規約</Link>
          </li>
          <li className={styles.sidebarItem}>
            <Link to="/business-terms" data-active="true">
              事業者規約
            </Link>
          </li>
        </ul>
      </div>
      <div className={styles.main}>
        <h1>事業者規約</h1>
        <p>
          本事業者規約（以下「本規約」といいます。）には、ココフォリア株式会社（以下「運営者」といいます。）が提供するサービス「TALTO」（以下「本サービス」といいます。）の事業者ユーザー（ユーザーのうち、運営者が事業者ユーザーとして指定し本規約に同意することにより運営者との間で事業者契約を締結したユーザーをいいます。）に対する権利義務関係が定められています。事業者ユーザーは、本規約及び利用規約に同意したうえで、本サービスを利用するものとします。
        </p>

        <h2>用語の定義</h2>
        <p>
          本規約において使用する用語は、本規約において明記がない限り、利用規約の定義に従うものとします。
        </p>

        <h2>適用</h2>
        <ul>
          <li>
            本規約の内容と、利用規約その他の本規約外における本サービスの説明等とが異なる場合は、運営者が明示しない限り、本規約の規定が優先して適用されるものとします。
          </li>
          <li>
            本規約に規定のない事項は、利用規約の定めが適用されるものとします。
          </li>
        </ul>

        <h2>アカウント情報の保有</h2>
        <ul>
          <li>
            事業者ユーザーは1社につき1つのアカウントを保有するものとします。ただし、本サービスでの活動に必要性が認められる場合においては、その他の利用規約に反しない範囲において運営者が承諾した場合、複数アカウントの保有を認めるものとします。
          </li>
          <li>
            事業者ユーザーはいかなる場合においても、アカウント情報を第三者に譲渡又は貸与することはできません。
          </li>
          <li>
            事業者ユーザーがアカウント情報を紛失、削除した場合、本サイトに登録された全データを利用することができません。また、これにより生じた損害に対し運営者は一切責任を負いません。
          </li>
        </ul>

        <h2>解除</h2>
        <ul>
          <li>
            事業者ユーザーが以下の各号のいずれかの事由に該当する場合、運営者は、事業者ユーザーに事前に通知又は催告することなく、事業者ユーザーによる本サービスの利用を一時的に制限し、又は本利用契約を解除することができます。
          </li>
          <li>
            <ul>
              <li>
                本規約又は利用規約のいずれかの条項又は保証した事項に違反した場合（民法第541条ただし書は適用しません。）
              </li>
              <li>利用規約に定める登録拒否事由のいずれかに該当した場合</li>
              <li>
                支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算若しくはこれらに類する手続の開始の申立てがあった場合
              </li>
              <li>解散又は清算の手続開始が決議又は決定された場合</li>
              <li>
                個人事業主である事業者ユーザーが死亡し、又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
              </li>
              <li>
                運営者からの問い合わせその他の回答を求める連絡に対して７日間以上応答がない場合
              </li>
              <li>6か月以上本サービスの利用がない場合</li>
              <li>その他、運営者が事業者ユーザーとして不適当と判断した場合</li>
            </ul>
          </li>
          <li>
            事業者ユーザーは、前項各号のいずれかに該当した場合、運営者に対して負っている債務の一切について当然に期限の利益を失い、直ちに運営者に対してすべての債務の支払を行わなければなりません。
          </li>
          <li>
            運営者は、本条に基づき運営者が行った措置により事業者ユーザーに生じた損害について一切の責任を負わず、有料サービスの利用料の返金を行いません。
          </li>
        </ul>

        <div className={styles.tar}>
          <p>制定：2023年4月9日</p>
        </div>
      </div>
    </div>
  );
};

export default BusinessTerms;
