import React, { useState } from "react";
import { Attachment, uploadAttachment } from "api";
import { useDropzone } from "react-dropzone";
import styles from "./EditorAttachmentDropArea.module.css";
import { ReactComponent as IconDragDrop } from "./IconDragDrop.svg";
import Spinner from "components/Spinner";

const ATTACHMENT_SIZE_LIMIT = 50 * 1024 * 1024; // 50 MiB

type EditorAttachmentDropAreaProps = {
  projectId: string;
  appendItem: (attachment: Attachment) => void;
  checkDupFile: (fileName: string) => boolean;
};

const EditorAttachmentDropArea: React.FC<EditorAttachmentDropAreaProps> = ({
  projectId,
  appendItem,
  checkDupFile,
}) => {
  const [uploadState, setUploadState] = useState<"waiting" | "uploading">(
    "waiting"
  );

  const onDrop = (files: File[]) => {
    if (checkDupFile(files[0].name)) {
      const ok = window.confirm(
        "同じ名前の添付ファイルがすでにあります。上書き保存しますがよろしいですか？"
      );
      if (!ok) {
        return;
      }
    }

    if (files[0].size > ATTACHMENT_SIZE_LIMIT) {
      alert("添付ファイルのサイズは50MB以下としてください");
      return;
    }

    setUploadState("uploading");
    uploadAttachment(projectId, files[0])
      .then(async (res) => {
        if (res.ok) {
          const now = new Date();
          appendItem({
            name: files[0].name,
            mimetype: files[0].type,
            url: "#",
            createdAt: now.toISOString(),
          });
        } else {
          throw "error";
        }
      })
      .catch((err) => {
        console.log(err);
        alert("アップロードに失敗しました");
      })
      .finally(() => setUploadState("waiting"));
  };

  const dropzone = useDropzone({
    onDrop,
    maxFiles: 1,
    noClick: false,
  });

  if (uploadState === "uploading") {
    return (
      <div className={styles.container}>
        <label className={styles.label}>ファイルをアップロード</label>
        <div className={styles.drop}>
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.container} {...dropzone.getRootProps()}>
        <input {...dropzone.getInputProps()} />
        <label className={styles.label}>ファイルをアップロード</label>
        <div className={styles.drop}>
          <div className={styles.center}>
            <IconDragDrop></IconDragDrop>
            <p className={styles.txt}>
              ファイル選択またはドラッグ&amp;ドロップ
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditorAttachmentDropArea;
