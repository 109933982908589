import React from "react";
import styles from "./TopItemEvent.module.css";

import taltoCalendar2022 from "./banners/talto_calendar_2022.jpg";
import taltoMagazine from "./banners/talto_magazine.jpg";

type Banner = {
  img: string;
  url: string;
};

export const BANNERS: Banner[] = [
  {
    img: taltoCalendar2022,
    url: "https://campaign.talto.cc/talto_cal2022/calendar/",
  },
  {
    img: taltoMagazine,
    url: "https://twitter.com/search?q=%23TALTOMAGAZINE%20from%3Atalto_trpg_bot&src=typed_query&f=live",
  },
];

const TopItemEvent: React.VFC = () => {
  if (BANNERS.length === 0) {
    return null;
  }

  return (
    <div className={styles.wrap}>
      <section className={styles.container}>
        <div className={styles.flex}>
          <h1 className={styles.category}>イベント情報</h1>
        </div>
        <ul className={styles.list}>
          {BANNERS.map((banner) => {
            return (
              <li key={banner.url} className={styles.item}>
                <a href={banner.url} target="_blank" rel="noreferrer">
                  <div className={styles.thumbnail}>
                    <img src={banner.img} alt="banner" loading="lazy" />
                  </div>
                </a>
              </li>
            );
          })}
        </ul>
      </section>
    </div>
  );
};

export default TopItemEvent;
