import React, { useEffect } from "react";
import styles from "./ModalWide.module.css";
import { ReactComponent as IconClose } from "./IconClose.svg";
import ReactDOM from "react-dom";
import { useHistory } from "react-router";

const ModalWideWithHistory: React.FC = ({ children }) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = React.useState(false);

  useEffect(() => {
    setIsOpen(true);
  }, [children]);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => {
      history.goBack();
    }, 400);
  };

  return (
    <ModalWide open={isOpen} onClose={handleClose}>
      {children}
    </ModalWide>
  );
};

type ModalProps = {
  open: boolean;
  onClose: (event: React.MouseEvent) => void;
};

const getScrollbarWidth = (): number => {
  const scrollDiv = document.createElement("div");
  scrollDiv.style.cssText =
    "position: absolute; top: -9999px; width: 50px; height: 50px; overflow: scroll;";
  document.body.appendChild(scrollDiv);
  const scrollbarWidth =
    scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const appendScrollbarPadding = () => {
  const rect = document.body.getBoundingClientRect();
  const isBodyOverflowing =
    Math.round(rect.left + rect.right) < window.innerWidth;

  if (isBodyOverflowing) {
    const scrollbarWidth = getScrollbarWidth();
    document.body.style.setProperty(
      "padding-right",
      `${scrollbarWidth}px`,
      "important"
    );
  }
};

const removeBodyStyleProperties = () => {
  document.body.style.removeProperty("overflow");
  document.body.style.removeProperty("padding-right");
};

export const ModalWide: React.FC<ModalProps> = ({
  children,
  open,
  onClose,
}) => {
  useEffect(() => {
    if (open) {
      appendScrollbarPadding();
      document.body.style.overflow = "hidden";
      return removeBodyStyleProperties;
    } else {
      removeBodyStyleProperties();
    }
  }, [open]);

  const container = document.getElementById("modal-root");
  if (container == null) {
    return null;
  }

  return ReactDOM.createPortal(
    <div>
      <div className={styles.bg} data-open={open}>
        {children}
      </div>
      <div onClick={onClose} className={styles.overlay} data-open={open}></div>
      <div className={styles.close} data-open={open} onClick={onClose}>
        閉じる<IconClose></IconClose>
      </div>
    </div>,
    container
  );
};

export default ModalWideWithHistory;
