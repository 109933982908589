import { blankProject, Project } from "apiv1/project";
import { EditorState } from "draft-js";
import { atom } from "recoil";
import { Booklet } from "./EditorProvider";

export const projectState = atom<Project>({
  key: "editor/projectState",
  default: blankProject(),
});

export const previewState = atom<boolean>({
  key: "editor/previewState",
  default: false,
});

const BLANK_BOOKLET_ID = "0";

export const currentBookletIdState = atom<string>({
  key: "editor/currentBookletIdState",
  default: BLANK_BOOKLET_ID,
});

export const bookletsState = atom<Booklet[]>({
  key: "editor/bookletsState",
  default: [
    { id: BLANK_BOOKLET_ID, name: "", editorState: EditorState.createEmpty() },
  ],
});

export const prevSeqState = atom<number>({
  key: "editor/prevSeqState",
  default: 0,
});

export const editedAtState = atom<Date | undefined>({
  key: "editor/editedAtState",
  default: undefined,
});

export const savedAtState = atom<Date | undefined>({
  key: "editor/savedAtState",
  default: undefined,
});
