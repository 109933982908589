import { withIdToken, withoutToken } from "apiv1";
import axios from "axios";
import { Bookmark } from "./bookmark";

export type User = {
  uid: string;
  appid: string;
  display_name: string | null;
  bio: string | null;
  photo_url: string | null;
  contact: string | null;
  created_at: string;
  deleted_at: string | null;
};

export const getUser = async (uid: string) => {
  return axios.get<User>(`/v1/users/${uid}`, await withIdToken());
};

export type PatchUser = Partial<
  Pick<User, "display_name" | "bio" | "photo_url" | "contact">
>;

export const patchUser = async (uid: string, patch: PatchUser) => {
  return axios.patch<never>(`/v1/users/${uid}`, patch, await withIdToken());
};

export type PhotoUrl = {
  fileName: string;
};

export const postUserSignup = async (uid: string) => {
  return axios.post(`/v1/users/${uid}/signup`, undefined, await withIdToken());
};

export const deleteIncompleteSignup = async (uid: string) => {
  return axios.delete(`/v1/users/${uid}/firebaseUser`, await withIdToken());
};

export const postUserPhoto = async (uid: string, file: File) => {
  return axios.post<PhotoUrl>(
    `/v1/users/${uid}/photo`,
    file,
    await withIdToken({ headers: { "content-type": file.type } })
  );
};

type Profile = {
  display_name: string | null;
  bio: string | null;
  photo_url: string | null;
  contact: string | null;
};

type UserProject = {
  id: string;
  name: string;
  category_name: string;
  cover_url: string | null;
  released_at: string;
};

export type UserProfile = {
  profile: Profile;
  projects: UserProject[];
  sctact: SCTAct | undefined;
};

export const getUserProfile = (uid: string) => {
  return axios.get<UserProfile>(`/v1/users/${uid}/profile`, withoutToken());
};

export type AgreementDate = {
  uid: string;
  agreed_at: string;
};

export const getAgreementDate = async (uid: string) => {
  return axios.get<AgreementDate>(
    `/v1/users/${uid}/agreementDate`,
    await withIdToken()
  );
};

export const postAgreementDate = async (uid: string) => {
  return axios.post<AgreementDate>(
    `/v1/users/${uid}/agreementDate`,
    undefined,
    await withIdToken()
  );
};

export type SCTAct = {
  uid: string;
  name_and_contact: string;
  price: string;
  email: string;
  others: string;
};

export const getSCTAct = (uid: string) => {
  return axios.get<SCTAct>(`/v1/users/${uid}/sctact`, withoutToken());
};

export const postSCTAct = async (uid: string, sctact: SCTAct) => {
  return axios.post<SCTAct>(
    `/v1/users/${uid}/sctact`,
    sctact,
    await withIdToken()
  );
};

export type OrderInfo = {
  order_id: string;
  project_id: string;
  coupon_id: string | null;
  gift_id: string | null;
  gift_received_at: string | null;
  price: number;
  captured_at: string; // 支払い確定日
  project_name: string;
  cover_url: string | null;
  category_name: string;
};

export const getUserOrders = async (uid: string) => {
  return axios.get<OrderInfo[]>(`/v1/users/${uid}/orders`, await withIdToken());
};

export type ReceivedGift = {
  gift_id: string;
  project_id: string;
  received_at: string; // 受け取り日
  project_name: string;
  cover_url: string | null;
  category_name: string;
};

export const getUserReceivedGifts = async (uid: string) => {
  return axios.get<ReceivedGift[]>(
    `/v1/users/${uid}/receivedGifts`,
    await withIdToken()
  );
};

export const getPurchasedProjects = async (uid: string) => {
  return axios.get<Bookmark[]>(
    `/v1/users/${uid}/purchasedProjects`,
    await withIdToken()
  );
};
