import React, { useState, MutableRefObject, useRef, useCallback } from "react";
import styles from "./EditorHeader.module.css";
import { ReactComponent as IconAroowRight } from "./IconAroowRight.svg";
import { ReactComponent as IconAroowLeft } from "./IconAroowLeft.svg";
// import { ReactComponent as IconText } from "./IconText.svg";
// import { ReactComponent as IconLink } from "./IconLink.svg";
import { ReactComponent as IconMedia } from "./IconMedia.svg";
import HeaderUserIcon from "components/HeaderUserIcon";
import Modal from "components/Modal";
import ModalPublish from "components/ModalPublish";
import ModalReleased from "components/ModalReleased/ModalReleased";
import { ReactComponent as IconHelp } from "./IconHelp.svg";
import { ModalWide } from "components/ModalWide/ModalWide";
import Editor from "@draft-js-plugins/editor";
import { uploadImage } from "plugins/dropUpload";
import { currentEditorStateSelector } from "pages/Editor/selector";
import { useRecoilValue } from "recoil";
import { projectState } from "pages/Editor/atom";
import ButtonToggleBlockType from "./ButtonToggleBlockType";
import ButtonToggleInlineStyle from "./ButtonToggleInlineStyle";

type ToolbarProps = {
  editorRef: MutableRefObject<Editor | null>;
};

const Toolbar: React.FC<ToolbarProps> = ({ editorRef }) => {
  const project = useRecoilValue(projectState);
  const editorState = useRecoilValue(currentEditorStateSelector);

  const focus = useCallback(() => {
    // [!] 非同期じゃないとうまくいかない
    setTimeout(() => {
      editorRef.current?.focus();
    }, 10);
  }, [editorRef]);

  // Image
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleSelectImage = (event: React.MouseEvent) => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const onChangeFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      editorRef.current &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      uploadImage(
        event.target.files[0],
        project.id,
        editorRef.current.getPluginMethods()
      );
    }

    event.target.value = "";
  };

  if (editorState == null) {
    return null;
  }

  const currentInlineStyle = editorState.getCurrentInlineStyle();

  const type = editorState
    .getCurrentContent()
    .getBlockForKey(editorState.getSelection().getStartKey())
    ?.getType();

  return (
    <div className={styles.toolBar}>
      <ul className={styles.buttons}>
        <ButtonToggleBlockType
          blockType="header-one"
          title="見出し1"
          active={type === "header-one"}
          focus={focus}
        />
        <ButtonToggleBlockType
          blockType="header-two"
          title="見出し2"
          active={type === "header-two"}
          focus={focus}
        />
        <ButtonToggleBlockType
          blockType="header-three"
          title="見出し3"
          active={type === "header-three"}
          focus={focus}
        />
        <ButtonToggleBlockType
          blockType="block-note"
          title="注釈"
          active={type === "block-note"}
          focus={focus}
        />
      </ul>
      <ul className={styles.buttonsRight}>
        <ButtonToggleInlineStyle
          inlineStyle="BOLD"
          title="太字"
          active={currentInlineStyle?.has("BOLD") || false}
          focus={focus}
        />
        <ButtonToggleInlineStyle
          inlineStyle="UNDERLINE"
          title="下線"
          active={currentInlineStyle?.has("UNDERLINE") || false}
          focus={focus}
        />
      </ul>
      <ul className={styles.buttonsRight}>
        <li title="画像の挿入" onClick={handleSelectImage}>
          <IconMedia />
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            hidden
            onChange={onChangeFileSelect}
          />
        </li>
      </ul>
    </div>
  );
};

type EditorHeaderProps = {
  showSidebar: boolean;
  toggleSidebar: () => void;
  editorRef: MutableRefObject<Editor | null>;
};

const EditorHeader: React.FC<EditorHeaderProps> = ({
  showSidebar,
  toggleSidebar,
  editorRef,
}) => {
  const project = useRecoilValue(projectState);

  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const openReleaseModal = () => {
    setShowReleaseModal(true);
  };
  const closeReleaseModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowReleaseModal(false);
  };

  const [showPublishedModal, setShowPublishedModal] = useState(false);
  const closePublishedModal = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowPublishedModal(false);
  };

  const onReleased = useCallback(() => {
    setShowReleaseModal(false);
    setShowPublishedModal(true);
    return null;
  }, [setShowReleaseModal, setShowPublishedModal]);

  const arrowIcon = showSidebar ? <IconAroowLeft /> : <IconAroowRight />;

  return (
    <div className={styles.container}>
      <Toolbar editorRef={editorRef} />
      <div>
        <button className={styles.btnMenu} onClick={toggleSidebar}>
          {arrowIcon}
        </button>
      </div>
      <ul className={styles.list}>
        <li>
          <a
            href="/help"
            target="_blank"
            title="ヘルプ"
            className={styles.help}
          >
            <IconHelp></IconHelp>
          </a>
        </li>
        <li>
          <button className={styles.btnRelease} onClick={openReleaseModal}>
            公開
          </button>
          <ModalWide open={showReleaseModal} onClose={closeReleaseModal}>
            <ModalPublish variant="draftjs" onReleased={onReleased} />
          </ModalWide>
          <Modal open={showPublishedModal} onClose={closePublishedModal}>
            <ModalReleased projectId={project.id} />
          </Modal>
        </li>
        <li>
          <HeaderUserIcon />
        </li>
      </ul>
    </div>
  );
};

export default EditorHeader;
