import React, { useState, useEffect, useContext } from "react";
import {
  convertFromRaw,
  convertToRaw,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import { useParams } from "react-router";
import { nanoid } from "nanoid";
import debounce from "lodash/debounce";
import { SpinnerFullDisplay } from "components/Spinner";
import { getProject, Visibility } from "apiv1/project";
import NotFound from "pages/NotFound";
import { BookletInfo, BookletInfos, postRelease } from "apiv1/release";
import { AuthContext } from "components/AuthProvider";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import {
  bookletsState,
  currentBookletIdState,
  editedAtState,
  prevSeqState,
  projectState,
  savedAtState,
} from "../atom";
import { editedSelector } from "../selector";
import produce from "immer";
import { getDraft, putDraft } from "apiv1/draft";
import Axios from "axios";
import { useHistory } from "react-router-dom";

const DEBOUNCE_SAVE_DELAY_MS = 1500; // 1.5 secs

const debouncedSave = debounce((save) => {
  save();
}, DEBOUNCE_SAVE_DELAY_MS);

let saving = false;

export const useSaveDraft = () => {
  const save = useRecoilCallback(({ snapshot, set }) => async () => {
    if (saving) {
      return;
    }

    try {
      saving = true;
      const booklets = await snapshot.getPromise(bookletsState);
      const { id } = await snapshot.getPromise(projectState);
      const prevSeq = await snapshot.getPromise(prevSeqState);
      const editedAt = await snapshot.getPromise(editedAtState);

      const rawDraft = convertToSavable(booklets);

      const resp = await putDraft(id, rawDraft, prevSeq);
      set(savedAtState, editedAt);
      set(prevSeqState, resp.data.seq);
    } catch (error) {
      if (Axios.isAxiosError(error) && error.response?.status === 409) {
        alert(
          "他のブラウザでの編集を検知したため、自動保存に失敗しました。編集するには、ページをリロードしてください。"
        );
      } else {
        alert("自動保存に失敗しました");
      }
    } finally {
      saving = false;
    }
  });

  return () => debouncedSave(save);
};

export const useSaveDraftForce = () => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        debouncedSave.cancel();
        const edited = await snapshot.getPromise(editedSelector);
        if (!edited || saving) {
          return Promise.resolve();
        }

        const booklets = await snapshot.getPromise(bookletsState);
        const { id } = await snapshot.getPromise(projectState);
        const prevSeq = await snapshot.getPromise(prevSeqState);
        const editedAt = await snapshot.getPromise(editedAtState);

        const rawDraft = convertToSavable(booklets);
        return putDraft(id, rawDraft, prevSeq)
          .then((resp) => {
            set(savedAtState, editedAt);
            set(prevSeqState, resp.data.seq);
          })
          .catch((error) => {
            if (Axios.isAxiosError(error) && error.response?.status === 409) {
              alert("他のブラウザからの変更を検知したため、保存に失敗しました");
            } else {
              alert("保存に失敗しました");
            }
          });
      },
    []
  );
};

export const useAppendNewBooklet = () => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const booklets = await snapshot.getPromise(bookletsState);

        const newBooklet = generateBlankBooklet();
        const newBooklets = produce(booklets, (draft) => {
          draft.push(newBooklet);
        });

        set(bookletsState, newBooklets);
        set(currentBookletIdState, newBooklet.id);
      },
    []
  );
};

export const useRelease = () => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (projectId: string, visibility: Visibility) => {
        debouncedSave.cancel();
        const booklets = await snapshot.getPromise(bookletsState);
        const prevSeq = await snapshot.getPromise(prevSeqState);
        const editedAt = await snapshot.getPromise(editedAtState);

        const rawDraft = convertToSavable(booklets);
        const res = await putDraft(projectId, rawDraft, prevSeq);

        set(prevSeqState, res.data.seq);
        set(savedAtState, editedAt);

        const retCreate = await postRelease(projectId, {
          version: "v1",
          visibility,
          booklets: rawDraft.booklets,
          bookletInfos: convertToBookletInfos(booklets),
        });

        return retCreate.data;
      }
  );
};

export type Booklet = {
  id: string;
  name: string;
  editorState: EditorState;
};

export type SaveableBooklet = {
  id: string;
  name: string;
  rawDraftContentState: RawDraftContentState;
};

export type RawDraft = {
  booklets: SaveableBooklet[];
};

const generateBookletId = () => nanoid(10);

const generateBlankBooklet = (): Booklet => {
  return {
    id: generateBookletId(),
    name: "新しい冊子",
    editorState: EditorState.createEmpty(),
  };
};

export const convertBookletToSavable = (booklet: Booklet): SaveableBooklet => {
  return {
    id: booklet.id,
    name: booklet.name,
    rawDraftContentState: convertToRaw(booklet.editorState.getCurrentContent()),
  };
};

const convertToSavable = (booklets: Booklet[]): RawDraft => {
  return {
    booklets: booklets.map(convertBookletToSavable),
  };
};

const convertFromSavable = (rawDraft: RawDraft): Booklet[] => {
  return rawDraft.booklets.map((b) => {
    return {
      id: b.id,
      name: b.name,
      editorState: EditorState.createWithContent(
        convertFromRaw(b.rawDraftContentState)
      ),
    };
  });
};

const convertToBookletInfo = (booklet: Booklet): BookletInfo => {
  const charCount = booklet.editorState
    .getCurrentContent()
    .getBlocksAsArray()
    .reduce((sum, block) => sum + block.getLength(), 0);
  return {
    id: booklet.id,
    name: booklet.name,
    charCount,
  };
};

const convertToBookletInfos = (booklets: Booklet[]): BookletInfos => {
  return {
    booklets: booklets.map(convertToBookletInfo),
  };
};

const EditorProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const user = useContext(AuthContext);
  const { projectId } = useParams<{ projectId: string }>();

  const setCurrentBookletId = useSetRecoilState(currentBookletIdState);
  const setBooklets = useSetRecoilState(bookletsState);
  const setPrevSeq = useSetRecoilState(prevSeqState);
  const setProject = useSetRecoilState(projectState);

  const [notFound, setNotFound] = useState<boolean>(false);
  const [loadedBooklets, setloadedBooklets] = useState<boolean>(false);
  const [loadedProject, setloadedProject] = useState<boolean>(false);

  useEffect(() => {
    saving = false;
  });

  useEffect(() => {
    debouncedSave.cancel();

    getProject(projectId)
      .then((resp) => {
        if (!user.authed || resp.data.owner_id !== user.uid) {
          setNotFound(true);
        }
        setProject(resp.data);
        setloadedProject(true);
      })
      .catch(() => setNotFound(true));
    getDraft(projectId)
      .then((resp) => {
        const draft = resp.data;
        if (draft.version === "v2") {
          history.replace("./edit2");
          throw new Error("old version");
        }

        const loadedBooklets: Booklet[] =
          draft.data != null
            ? convertFromSavable(draft.data)
            : [generateBlankBooklet()];
        setBooklets(loadedBooklets);
        setPrevSeq(draft.seq);
        setCurrentBookletId(loadedBooklets[0].id);
        setloadedBooklets(true);
      })
      .catch(() => setNotFound(true));
  }, [projectId]);

  if (notFound) {
    return <NotFound />;
  }

  if (!loadedBooklets || !loadedProject) {
    return <SpinnerFullDisplay />;
  }

  return <>{children}</>;
};

export default EditorProvider;
