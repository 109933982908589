import React from "react";
import styles from "./TopHeaderSP.module.css";
import UserMenu from "./UserMenu";
import { Link } from "react-router-dom";
import { ReactComponent as IconLogo } from "../IconLogo.svg";

const TopHeaderSP: React.VFC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <h1 className={styles.logo}>
          <Link to="/">
            <IconLogo />
          </Link>
        </h1>
        <UserMenu />
      </div>
    </div>
  );
};

export default TopHeaderSP;
