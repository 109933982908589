import { EditorPlugin } from "@draft-js-plugins/editor";
import { KeyBindingUtil, RichUtils } from "draft-js";

const { hasCommandModifier } = KeyBindingUtil;

const shortcutPlugin: EditorPlugin = {
  keyBindingFn: (event) => {
    if (hasCommandModifier(event as any)) {
      if (event.key === "1") {
        return "talto-h1";
      }
      if (event.key === "2") {
        return "talto-h2";
      }
      if (event.key === "3") {
        return "talto-h3";
      }
    }
  },
  handleKeyCommand: (
    command,
    editorState,
    eventTimeStamp,
    { setEditorState }
  ) => {
    if (command === "bold") {
      setEditorState(RichUtils.toggleInlineStyle(editorState, "BOLD"));
      return "handled";
    }
    if (command === "underline") {
      setEditorState(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));
      return "handled";
    }
    if (command === "talto-h1") {
      setEditorState(RichUtils.toggleBlockType(editorState, "header-one"));
      return "handled";
    }
    if (command === "talto-h2") {
      setEditorState(RichUtils.toggleBlockType(editorState, "header-two"));
      return "handled";
    }
    if (command === "talto-h3") {
      setEditorState(RichUtils.toggleBlockType(editorState, "header-three"));
      return "handled";
    }
    return "not-handled";
  },
};

export default shortcutPlugin;
