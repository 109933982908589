import React from "react";
import styles from "./BlankThumbnail.module.css";

type BlankThumbnailProps = {
  title: string;
};

const BlankThumbnail: React.VFC<BlankThumbnailProps> = ({ title }) => {
  return (
    <div className={styles.imgBlank}>
      <p>{title}</p>
    </div>
  );
};

export default React.memo(BlankThumbnail);
