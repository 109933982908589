import React, { useState } from "react";
import styles from "./DistributorSettingPayout.module.css";
import { ReactComponent as IconTargetBlank } from "./IconTargetBlank.svg";
import { ReactComponent as IconArrowLeft } from "./IconArrowLeft.svg";
import { Link, useHistory } from "react-router-dom";
import { putPayjpTenant } from "apiv1/payjp";

const DistributorSettingPayout = () => {
  const history = useHistory();
  const [bankCode, setBankCode] = useState("");
  const [bankBranchCode, setBankBranchCode] = useState("");
  const [bankAccountType, setBankAccountType] = useState("普通");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankAccountHolderName, setBankAccountHolderName] = useState("");

  const handleChange =
    (set: (s: string) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      set(event.target.value);
    };
  const handleChangeAccountType = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setBankAccountType(event.target.value);
  };
  const submitTenant = () => {
    const params = {
      bank_code: bankCode,
      bank_branch_code: bankBranchCode,
      bank_account_type: bankAccountType,
      bank_account_number: bankAccountNumber,
      bank_account_holder_name: bankAccountHolderName,
    };

    putPayjpTenant(params)
      .then(() => {
        alert("口座情報を更新しました");
        history.push("/settings/sales");
      })
      .catch(() =>
        alert(
          "口座情報の登録に失敗しました。入力内容に間違いがないか確認してください。"
        )
      );
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.ttl}>口座情報の設定</h1>
      </div>
      <label className={styles.label}>
        金融機関コード（4桁） <span className={styles.required}>※必須</span>
      </label>
      <input
        className={styles.input}
        type="text"
        name="bankCode"
        value={bankCode}
        onChange={handleChange(setBankCode)}
        placeholder="テキストを入力"
        autoComplete="off"
      />
      {/* <label className={styles.label}>金融機関名</label>
      <input
        className={styles.input}
        type="text"
        name="name"
        placeholder="テキストを入力"
      /> */}
      <label className={styles.label}>
        支店コード（3桁） <span className={styles.required}>※必須</span>
      </label>
      <input
        className={styles.input}
        type="text"
        name="bankBranchCode"
        value={bankBranchCode}
        onChange={handleChange(setBankBranchCode)}
        placeholder="テキストを入力"
        autoComplete="off"
      />
      <label className={styles.label}>
        預金種別 <span className={styles.required}>※必須</span>
      </label>
      <div className={styles.selectArea}>
        <select
          className={styles.select}
          name="bankAccountType"
          value={bankAccountType}
          onChange={handleChangeAccountType}
        >
          <option value="普通">普通</option>
          <option value="当座">当座</option>
        </select>
      </div>
      <label className={styles.label}>
        口座番号 <span className={styles.required}>※必須</span>
      </label>
      <input
        className={styles.input}
        type="text"
        name="bankAccountNumber"
        value={bankAccountNumber}
        onChange={handleChange(setBankAccountNumber)}
        placeholder="テキストを入力"
        autoComplete="off"
      />
      <label className={styles.label}>
        口座名義カナ <span className={styles.required}>※必須</span>
      </label>
      <input
        className={styles.input}
        type="text"
        name="bankAccountHolderName"
        value={bankAccountHolderName}
        onChange={handleChange(setBankAccountHolderName)}
        placeholder="テキストを入力"
        autoComplete="off"
      />
      <div className={styles.note}>
        <p>
          金融機関コード（銀行コード）とは、金融機関に割り当てられている4桁の番号と金融機関の本支店に割り当てられている3桁の番号です。金融機関コードは、以下のページをご参照ください。
          <br />
          金融機関コード・銀行コード検索：
          <a href="http://zengin.ajtw.net/" target="_blank" rel="noreferrer">
            http://zengin.ajtw.net/<IconTargetBlank></IconTargetBlank>
          </a>
        </p>
        <p>
          売上などを受け取る口座に、ゆうちょ銀行の口座を登録するには、振込用の「店名・預金種目・口座番号」が必要です。「店名・預金種目・口座番号」については、以下のページをご参照ください。
          <br />
          ゆうちょ銀行：
          <a
            href="https://www.jp-bank.japanpost.jp/kojin/sokin/furikomi/kouza/kj_sk_fm_kz_1.html"
            target="_blank"
            rel="noreferrer"
          >
            https://www.jp-bank.japanpost.jp/kojin/sokin/furikomi/kouza/kj_sk_fm_kz_1.html
            <IconTargetBlank></IconTargetBlank>
          </a>
        </p>
      </div>
      <button className={styles.btn} onClick={submitTenant}>
        口座情報を登録
      </button>
      {/* <p className={styles.delete}>
        <a href="#">口座情報の削除</a>はこちら
      </p> */}
      <p className={styles.back}>
        <Link to="/settings/sales">
          <IconArrowLeft></IconArrowLeft>販売者情報へ戻る
        </Link>
      </p>
    </div>
  );
};

export default DistributorSettingPayout;
