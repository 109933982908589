import { withIdToken } from "apiv1";
import axios from "axios";
import { FeeType, Visibility } from "./project";

export type SalesSummary = {
  project_id: string;
  project_name: string;
  category_name: string;
  cover_url: string | null;
  visibility: Visibility;
  fee_type: FeeType;
  price: number | null;
  count: number | string;
  total_sales: number | string | null;
  deleted_at: string | null;
};

export const getSalesSummary = async (uid: string) => {
  return axios.get<SalesSummary[]>(
    `/v1/users/${uid}/sales/summary`,
    await withIdToken()
  );
};

export type Order = {
  order_id: string;
  project_id: string;
  coupon_id: string | null;
  tenant_uid: string;
  license_id: string | null; // SPLLなどの許諾番号
  price: number; // 売った価格
  talto_fee: number;
  license_fee: number;
  created_at: string;
  project_name: string;
};

export const getSalesOrders = async (uid: string, limit?: number) => {
  return axios.get<Order[]>(
    `/v1/users/${uid}/sales/orders`,
    await withIdToken({ params: { limit } })
  );
};
