export const paymentMethod = (
  price: number,
  couponId: string | null
): string => {
  if (couponId == null) {
    return "クレジットカード";
  } else if (price === 0) {
    return "シナチケ";
  } else {
    return "クレジットカード（シナチケ値引き利用）";
  }
};
