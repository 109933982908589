import PluginEditor, { EditorPlugin } from "@draft-js-plugins/editor";
import { ContentState, EditorState, Modifier } from "draft-js";
import React from "react";

interface EditorOrigin {
  getClipboard: () => unknown;
  getEditorKey: () => string;
}

const isOrigin = (x: unknown): x is EditorOrigin => {
  return (
    typeof (x as EditorOrigin).getClipboard === "function" &&
    typeof (x as EditorOrigin).getEditorKey === "function"
  );
};

export const createPastePlugin = (
  ref: React.RefObject<PluginEditor>
): EditorPlugin => {
  const betterPastePlugin: EditorPlugin = {
    handlePastedText: (text, html, editorState, { setEditorState }) => {
      const e: unknown = ref.current?.editor;
      if (
        html == null ||
        (isOrigin(e) &&
          e.getClipboard() &&
          html.indexOf(e.getEditorKey()) !== -1)
      ) {
        return "not-handled";
      }

      const convertedText = ContentState.createFromText(text);

      const contentState = Modifier.replaceWithFragment(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        convertedText.getBlockMap()
      );
      setEditorState(
        EditorState.push(editorState, contentState, "insert-fragment")
      );

      return "handled";
    },
  };

  return betterPastePlugin;
};
