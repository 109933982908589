import React from "react";
import styles from "./Terms.module.css";

const SupporterProgramTerms: React.VFC = () => {
  const date = new Date();
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <h1>TALTOで公式応援プログラムとは</h1>
        <p>
          作品販売の利益の一部（10%）を、ゲームシステムの製作者に任意で還元し、支援することができるプログラムです。義務感が発生するのを避けるため、プログラムへの参加状況は他のユーザーからは判別できないようになっています。TALTOで公式応援プログラムは、作品ごとに個別に設定します。途中で設定を切り替えることも可能です。（販売開始後1週間だけ参加するなど）
        </p>
        <p>
          <small>
            本プログラムへの参加は、利益還元のみならず自身の活動をオフィシャルに直接認知してもらえるひとつの機会でもあります。このプログラムを通じて、オフィシャルとユーザーがより一体となって、TRPG全体を盛り上げていけるような空気感を醸成してゆくことを目標としています。
          </small>
        </p>

        <h2>設定方法</h2>
        <p>
          作品公開設定で、TALTOで公式応援プログラム対応ジャンルを選択の上、販売設定の欄で「TALTOで公式応援プログラムに参加する」にチェックを入れます。販売価格より「TALTO」の手数料を除く10%が自動的に徴収されます。
        </p>

        <h2>提携先に共有されるデータ</h2>
        <p>
          作品の公開情報及びTALTOで公式応援プログラム参加設定期間中の売上計（還元される額の計算のため）が月毎に集計され本プログラムの提携先に共有されます。登録メールアドレスを含み、販売者の個人データは共有されません。
        </p>

        <h2>注意事項</h2>
        <p>
          {date.getFullYear()}年{date.getMonth() + 1}
          時点では、ダイスタス・チームが提供する
          <a
            href="https://emoklore.dicetous.com/"
            target="_blank"
            rel="noreferrer"
          >
            「エモクロアTRPG」
          </a>
          を通じて試験的にこのプログラムが提供されています。投稿ジャンルに
          <a
            href="https://emoklore.dicetous.com/"
            target="_blank"
            rel="noreferrer"
          >
            「エモクロアTRPG」
          </a>
          を設定した場合のみ、TALTOで公式応援プログラムに参加する設定を行うことができます。
        </p>
        <p>
          <small>
            ※このプログラムはあくまで支援の手段のひとつとして設計されたものであり、参加することによって、権利者から何かしらの保障や許諾を受けることができるものではありません。あらかじめご了承ください。
          </small>
        </p>
      </div>
    </div>
  );
};

export default SupporterProgramTerms;
