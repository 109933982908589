import React from "react";
import styles from "./ButtonGift.module.css";

type ButtonGiftProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonGift: React.FC<ButtonGiftProps> = ({ children, ...props }) => {
  return (
    <button className={styles.button} {...props}>
      {children}
    </button>
  );
};

export default ButtonGift;
