import React, { useState, useEffect, useCallback } from "react";
import styles from "./Editor.module.css";
import EditorSidebar from "./EditorSidebar/EditorSidebar";
import EditorMainContainer from "./EditorMainContainer/EditorMainContainer";
import EditorProvider, { convertBookletToSavable } from "./EditorProvider";
import { ViewerPage } from "pages/Viewer/Viewer";
import EditorFloating from "./EditorMainContainer/EditorFloating/EditorFloating";
import { ReleaseDataV1 } from "apiv1/release";
import { useParams } from "react-router-dom";
import { RecoilRoot, useRecoilValue } from "recoil";
import {
  bookletsState,
  currentBookletIdState,
  previewState,
  projectState,
} from "./atom";
import Autosave from "./Autosave";
import { resetDocumentTitle } from "utils/title";

const EditorPreview: React.FC = () => {
  const project = useRecoilValue(projectState);
  const booklets = useRecoilValue(bookletsState);
  const currentBookletId = useRecoilValue(currentBookletIdState);

  const [bookletId, setBookletId] = useState(currentBookletId);

  const release: ReleaseDataV1 = {
    project_id: project.id,
    rev: "xxxxxx",
    booklets: booklets.map(convertBookletToSavable),
    created_at: new Date().toISOString(),
  };

  return (
    <div className={styles.container}>
      <ViewerPage
        project={project}
        release={release}
        currentBookletId={bookletId}
        setCurrentBookletId={setBookletId}
        shareBtn={false}
        preview={true}
      />
      <EditorFloating></EditorFloating>
    </div>
  );
};

const Editor = () => {
  const project = useRecoilValue(projectState);
  const preview = useRecoilValue(previewState);
  const [showSidebar, setShowSidebar] = useState(false);

  useEffect(() => {
    window.document.title = `${project.name} - エディタ`;
    return resetDocumentTitle;
  }, [project.name]);

  const closeSidebar = useCallback(() => {
    setShowSidebar(false);
  }, [setShowSidebar]);

  const toggleSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [setShowSidebar, showSidebar]);

  if (preview) {
    return <EditorPreview />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <EditorSidebar
          show={showSidebar}
          closeSidebar={closeSidebar}
        ></EditorSidebar>
        <EditorMainContainer
          showSidebar={showSidebar}
          toggleSidebar={toggleSidebar}
        ></EditorMainContainer>
      </div>
    </div>
  );
};

const EditorPage = () => {
  const { projectId } = useParams<{ projectId: string }>();

  return (
    <RecoilRoot>
      <EditorProvider key={projectId}>
        <Editor />
        <Autosave />
      </EditorProvider>
    </RecoilRoot>
  );
};

export default EditorPage;
