import { getGiftProject, GiftedProjectInfo, postReceiveGift } from "apiv1/gift";
import axios from "axios";
import { AuthContext } from "components/AuthProvider";
import BtnWithProcessing from "components/Btn/BtnWithProcessing";
import DefaultLayout from "components/DefaultLayout";
import { SpinnerCenter } from "components/Spinner";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { WithLoad } from "utils/types";
import InvalidGift from "./InvalidGift";
import styles from "./ReceiveGift.module.css";

const useQueryValue = (key: string): string | null => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(key);
};

interface AppError {
  code: string;
}

const isAppError = (data: any): data is AppError => {
  return typeof data.code === "string";
};

const errorMessage = (err: unknown): string => {
  if (!axios.isAxiosError(err)) {
    return "ギフトの受け取りができませんでした。通信状況が正常化確認してください。";
  }

  const data = err.response?.data;
  if (!isAppError(data)) {
    return "ギフトの受け取りができませんでした。通信状況が正常化確認してください。";
  }

  switch (data.code) {
    case "gift/already_received":
      return "ギフトが既に使われているため、受け取りができませんでした。";
    case "gift/can_not_use_own_gift":
      return "自分で購入したギフトは使用できません。";
    case "gift/can_not_receive_own_project":
      return "自分の作品のギフトは受け取れません。";
    case "gift/already_have":
      return "既に所持している作品のギフトです。ギフトの受け取りを中断しました。";
    case "gift/internal-error":
    default:
      return "内部エラーにより、ギフトの受け取りに失敗しました。";
  }
};

type GiftBtnProps = {
  needLogin: boolean;
  isOwnProject: boolean;
  onReceive: () => Promise<void>;
};

const GiftBtn: React.VFC<GiftBtnProps> = ({
  needLogin,
  isOwnProject,
  onReceive,
}) => {
  const location = useLocation();
  if (needLogin) {
    const redirectRef = location.pathname + location.search;
    const search = new URLSearchParams({ ref: redirectRef });
    return (
      <Link
        className={styles.btn}
        to={{ pathname: "/login", search: search.toString() }}
      >
        ログインしてギフトを受け取る
      </Link>
    );
  }

  return (
    <BtnWithProcessing
      labelProsessing="処理中"
      onClick={onReceive}
      disabled={isOwnProject}
    >
      ギフトを受け取る
    </BtnWithProcessing>
  );
};

type ThumbnailProps = {
  src: string | null;
};

const Thumbnail: React.VFC<ThumbnailProps> = ({ src }) => {
  if (src == null) {
    return <div className={styles.blankThumbnail} />;
  }

  return (
    <div className={styles.thumbnail}>
      <img src={src} alt="ギフト" />
    </div>
  );
};

const ReceiveGiftBody: React.VFC = () => {
  const auth = useContext(AuthContext);
  const history = useHistory();
  const code = useQueryValue("code");
  const [project, setProject] =
    useState<WithLoad<GiftedProjectInfo>>("loading");

  useEffect(() => {
    if (code == null) {
      return;
    }

    getGiftProject(code)
      .then((res) => setProject(res.data))
      .catch(() => setProject("notfound"));
  }, [code]);

  if (code == null || project === "notfound") {
    return <InvalidGift />;
  }

  if (project === "loading") {
    return <SpinnerCenter />;
  }

  const isOwnProject = auth.authed ? project.owner_id === auth.uid : false;

  const receiveGift = () => {
    return postReceiveGift(code)
      .then(() => {
        alert("ギフトの受け取りが完了しました！\n作品のページへ移動します");
        history.push(`/projects/${project.project_id}`);
      })
      .catch((err) => {
        alert(errorMessage(err));
      });
  };

  return (
    <>
      <h1 className={styles.header}>ギフトが届いています</h1>
      <div className={styles.card}>
        <Thumbnail src={project.cover_url} />
        <div className={styles.cardBody}>
          <div className={styles.categoryName}>{project.category_name}</div>
          <h2 className={styles.projectName}>{project.project_name}</h2>
        </div>
      </div>

      <div className={styles.receiveBtn}>
        <GiftBtn
          needLogin={!auth.authed}
          isOwnProject={isOwnProject}
          onReceive={receiveGift}
        />
      </div>
    </>
  );
};

const ReceiveGift: React.VFC = () => {
  return (
    <DefaultLayout>
      <div className={styles.container}>
        <ReceiveGiftBody />
      </div>
    </DefaultLayout>
  );
};

export default ReceiveGift;
