export const clearTaltoLocalStorageItems = () => {
  const targetKeys: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key?.startsWith("TALTO.")) {
      targetKeys.push(key);
    }
  }

  for (const key of targetKeys) {
    localStorage.removeItem(key);
  }
};
