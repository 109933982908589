import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { editedAtState } from "./atom";
import { useSaveDraft } from "./EditorProvider";
import { editedSelector } from "./selector";

const Autosave: React.FC = () => {
  const edited = useRecoilValue(editedSelector);
  const editedAt = useRecoilValue(editedAtState);
  const saveDraftWithDebounce = useSaveDraft();

  useEffect(() => {
    if (edited) {
      saveDraftWithDebounce();
    }
  }, [edited, editedAt]);

  return null;
};

export default Autosave;
