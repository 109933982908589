import { useEffect, useState } from "react";
import styles from "../../pages/Viewer/ViewerSidebar/ViewerSidebar.module.css";

import { ReactComponent as IconArrowOpen } from "./IconArrowOpen.svg";
import { ReactComponent as IconArrowClose } from "./IconArrowClose.svg";
import { ScenarioData } from "pages/EditorLexical/datastore";
import { isHeadingNode, isTextNode } from "./Viewer";

type SidebarBookletProps = {
  booklet: ScenarioData["booklets"][0];
  currentBookletId: string;
  changeBooklet: (id: string, hash?: string) => void;
  closeIndex: () => void;
};

const SidebarBooklet = ({
  booklet,
  currentBookletId,
  changeBooklet,
  closeIndex,
}: SidebarBookletProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (booklet.id === currentBookletId) {
      setShow(true);
    }
  }, [currentBookletId]);

  const blocks = booklet.data?.root.children || [];

  const handleClick = (key: string) => () => {
    changeBooklet(booklet.id, key);
    closeIndex();
  };

  const handleTitleClick = () => {
    changeBooklet(booklet.id);
    closeIndex();
  };

  const toggleShow = () => setShow(!show);

  return (
    <>
      <p
        className={styles.bookletTitle}
        data-active={booklet.id === currentBookletId}
      >
        {show ? (
          <IconArrowOpen onClick={toggleShow} />
        ) : (
          <IconArrowClose onClick={toggleShow} />
        )}
        <span onClick={handleTitleClick}>{booklet.title}</span>
      </p>
      <ul className={styles.list} hidden={!show}>
        {blocks.map((node, index) => {
          if (
            !isHeadingNode(node) ||
            !(node.tag === "h1" || node.tag === "h2")
          ) {
            return null;
          }

          const className = node.tag === "h1" ? styles.anc : styles.ancH2;
          const title = node.children.reduce((str, node) => {
            if (isTextNode(node)) {
              return str + node.text;
            }

            return str;
          }, "");
          return (
            <li className={className}>
              <span onClick={handleClick(index.toString())}>{title}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default SidebarBooklet;
