import React, { useContext, useState } from "react";
import styles from "./ItemSide.module.css";
import { ReactComponent as IconFile } from "./IconFile.svg";
import { AuthContext } from "components/AuthProvider";
import { deleteBookmarks, postBookmarks } from "apiv1/bookmark";

type BookmarkButttonProps = {
  projectId: string;
  bookmarked: boolean;
};

const BookmarkButtton: React.VFC<BookmarkButttonProps> = ({
  projectId,
  bookmarked,
}) => {
  const user = useContext(AuthContext);
  const [bookmark, setBookmark] = useState(bookmarked);

  const handleBookmark = () => {
    if (!user.authed) {
      alert("作品を本棚に追加するにはログインが必要です");
      return;
    }

    if (bookmark) {
      setBookmark(false);
      deleteBookmarks(user.uid, projectId).catch(() => {});
    } else {
      setBookmark(true);
      postBookmarks(user.uid, projectId).catch(() => {});
    }
  };

  const label = bookmark ? "追加済み" : "本棚に追加";
  const className = bookmark ? styles.buttonActionActive : styles.buttonAction;

  return (
    <button className={className} onClick={handleBookmark}>
      <IconFile />
      {label}
    </button>
  );
};

export default React.memo(BookmarkButtton);
