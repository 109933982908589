import React from "react";
import styles from "./ItemSide.module.css";
import { FeeType } from "apiv1/project";
import { feeTypeToString } from "utils/projects";
import { formatDateSlash } from "utils/format";
import BookmarkButton from "./BookmarkButton";
import ButtonOutline from "./ButtonOutline";
import ButtonPrimary from "./ButtonPrimary";
import ButtonGift from "./ButtonGift";
import ItemShare from "../ItemShare/ItemShare";
import { ReactComponent as IconGift } from "./IconGift.svg";
import { ReactComponent as IconRead } from "./IconRead.svg";
import { ReactComponent as IconZip } from "./IconZip.svg";
import { Link } from "react-router-dom";

type ItemSideProps = {
  projectId: string;
  projectName: string;
  feeType: FeeType;
  price: number | null;
  licenseId: string | null;
  updatedAt: string;
  bookmarked: boolean;
  bookletURL: string;
  purchased: boolean;
  onOpenAttach: () => void;
  onOpenBuy: () => void;
  onOpenGift: () => void;
  disabledBookletLink: boolean;
  disabledAttachButton: boolean;
};

const ItemSide: React.VFC<ItemSideProps> = ({
  projectId,
  projectName,
  feeType,
  price,
  licenseId,
  updatedAt,
  bookmarked,
  bookletURL,
  purchased,
  onOpenAttach,
  onOpenBuy,
  onOpenGift,
  disabledBookletLink,
  disabledAttachButton,
}) => {
  const labelBuyButton = purchased ? "購入済み" : "自分用に購入";

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        {feeType !== "free" && (
          <div className={styles.top}>
            {licenseId && (
              <p className={styles.spll}>SPLL申請番号：{licenseId}</p>
            )}
            <div className={styles.flexSP}>
              <p className={styles.price}>¥{price?.toLocaleString("ja-JP")}</p>
              <p className={styles.fee}>{feeTypeToString(feeType)}</p>
            </div>
            <ButtonPrimary disabled={purchased} onClick={onOpenBuy}>
              {labelBuyButton}
            </ButtonPrimary>
            <ButtonGift onClick={onOpenGift}>
              <IconGift></IconGift>ギフトとして購入
            </ButtonGift>
            <Link className={styles.help} to="/help#gift" target="_blank">
              ギフトの利用方法
            </Link>
          </div>
        )}
        <div className={styles.bottom}>
          <ButtonOutline
            to={bookletURL}
            target="_blank"
            disabled={disabledBookletLink}
          >
            <IconRead />
            シナリオを読む
          </ButtonOutline>
          <ButtonOutline onClick={onOpenAttach} disabled={disabledAttachButton}>
            <IconZip />
            ダウンロード
          </ButtonOutline>
          <div className={styles.flex}>
            <p className={styles.update}>最終更新日</p>
            <p className={styles.date}>
              {formatDateSlash(new Date(updatedAt))}
            </p>
          </div>
        </div>
      </div>
      <ul className={styles.action}>
        <li>
          <BookmarkButton projectId={projectId} bookmarked={bookmarked} />
        </li>
        {/* <li>
          <button className={styles.buttonAction}>
            <IconPin></IconPin>遊んだ！
          </button>
        </li> */}
      </ul>
      <div className={styles.pcb}>
        <ItemShare projectName={projectName} />
      </div>
    </div>
  );
};

export default React.memo(ItemSide);
