import { withIdToken, withIdTokenIfAuthed, withoutToken } from "apiv1";
import axios from "axios";
import { Category } from "./category";
import { Release } from "./release";

export type Visibility = "closed" | "private" | "public";
export type FeeType = "free" | "all_contents" | "attachments_only";

export const isFeeType = (x: unknown): x is FeeType => {
  return x === "free" || x === "all_contents" || x === "attachments_only";
};

export type Attachment = {
  name: string;
  mimetype: string;
  url: string;
  createdAt: string;
};

export type Attachments = {
  items: Attachment[];
};

export type Project = {
  id: string;
  category_id: number;
  name: string;
  author: string;
  owner_id: string;

  cover_url: string | null;
  fee_type: FeeType;
  price: number | null;
  license_id: string | null;

  visibility: Visibility;
  tags: Array<string>;
  description: string;
  like_count: number;
  watch_count: number;

  players_min: number | null;
  players_max: number | null;
  playing_hour_min: number | null;
  playing_hour_max: number | null;

  attachments: Attachments;
  copyright_notice: string;

  created_at: string;
  released_at: string | null;
  deleted_at: string | null;
};

export const blankProject = (): Project => {
  return {
    id: "",
    category_id: 0,
    name: "",
    author: "",
    owner_id: "",

    cover_url: null,
    fee_type: "free",
    price: null,
    license_id: null,

    visibility: "closed",
    tags: [],
    description: "",
    like_count: 0,
    watch_count: 0,

    players_min: null,
    players_max: null,
    playing_hour_min: null,
    playing_hour_max: null,

    attachments: { items: [] },
    copyright_notice: "",

    created_at: "2020-02-02T20:20:20Z",
    released_at: null,
    deleted_at: null,
  };
};

export type Purchases = {
  uid: string;
  project_id: string;
  session_id: string;
  created_at: string;
};

export const getProject = async (projectId: string) => {
  return axios.get<Project>(
    `/v1/projects/${projectId}`,
    await withIdTokenIfAuthed()
  );
};

export type ProjectWithReleases = {
  project: Project;
  category: Category;
  releases: Release[];
  liked: boolean;
  bookmarked: boolean;
  purchase?: Purchases;
  ownerProfile: { displayName: string | null };
};

export const getProjectWithReleases = async (projectId: string) => {
  return axios.get<ProjectWithReleases>(
    `/v1/projects/${projectId}/forDetailPage`,
    await withIdTokenIfAuthed()
  );
};

export type ResPostProject = {
  id: string;
};

export const postProject = async () => {
  return axios.post<ResPostProject>(
    `/v1/projects`,
    undefined,
    await withIdToken()
  );
};

export type PatchedProject = Partial<{
  category_id: number;
  name: string;
  author: string;

  cover_url: string | null;
  fee_type: FeeType;
  price: number | null;

  visibility: Visibility;
  tags: Array<string>;
  description: string;

  players_min: number | null;
  players_max: number | null;
  playing_hour_min: number | null;
  playing_hour_max: number | null;

  attachments: Attachments;
  copyright_notice: string;
}>;

export const patchProject = async (
  projectId: string,
  project: PatchedProject
) => {
  return axios.patch(`/v1/projects/${projectId}`, project, await withIdToken());
};

export const deleteProject = async (projectId: string) => {
  return axios.delete<never>(`/v1/projects/${projectId}`, await withIdToken());
};

export type ResPostImage = {
  fileName: string;
};

export const postImage = async (projectId: string, file: Blob) => {
  return axios.post<ResPostImage>(
    `/v1/projects/${projectId}/images`,
    file,
    await withIdToken({ headers: { "content-type": file.type } })
  );
};

type PostBuyBody = {
  price: number;
  cardId: string;
  couponId?: string;
};

export const postBuy = async (projectId: string, body: PostBuyBody) => {
  return axios.post<void>(
    `/v1/projects/${projectId}/buy`,
    body,
    await withIdToken()
  );
};

type PostBuyGiftBody = {
  price: number;
  cardId: string;
};

type ResBuyGift = {
  giftId: string;
};

export const postBuyGift = async (projectId: string, body: PostBuyGiftBody) => {
  return axios.post<ResBuyGift>(
    `/v1/projects/${projectId}/buyGift`,
    body,
    await withIdToken()
  );
};

export const postGetByCoupon = async (projectId: string, couponId: string) => {
  return axios.post<void>(
    `/v1/projects/${projectId}/getByCoupon`,
    {
      couponId,
    },
    await withIdToken()
  );
};

export type ResReadToken = {
  token: string;
};

export const postReadToken = async (projectId: string, rev: string) => {
  return axios.post<ResReadToken>(
    `/v1/projects/${projectId}/releases/${rev}/readToken`,
    undefined,
    await withIdToken()
  );
};

export const postProjectViewed = async (projectId: string) => {
  return axios.post(
    `/v1/projects/${projectId}/viewed`,
    undefined,
    withoutToken()
  );
};

export type SearchedProject = {
  id: string;
  name: string;
  author: string;
  cover_url: string | null;
  category_id: number;
  category_name: string;
  tags: string[];
  description: string;
  fee_type: FeeType;
  price: number | null;
  likes: number;
  players_min: number | null;
  players_max: number | null;
  playing_hour_max: number | null;
  released_at: string;
  published_at: string;
};

export type SearchParams = {
  q?: string;
  category?: number;
  tags?: string[];
  playersMin?: number;
  playersMax?: number;
  playTime?: number;
  feeType?: FeeType;
  offset?: number;
  limit?: number;
};

export const searchProjects = async (params: SearchParams) => {
  return axios.get<SearchedProject[]>(`/v1/projects`, {
    params,
  });
};

export type UserProjectInfo = {
  id: string;
  name: string;
  cover_url: string;
  visibility: Visibility;
  fee_type: FeeType;
  category_name: string;
  created_at: string;
  updated_at: string;
  released_at: string | null;
};

export const getUserProjects = async (uid: string) => {
  return axios.get<UserProjectInfo[]>(
    `/v1/users/${uid}/projects`,
    await withIdToken()
  );
};
