import { typeTextStyles } from "./GenerateThumbnail";

export const centerTemplate: typeTextStyles = {
  name: {
    position: { x: 640, y: 340 },
    font: "serif",
    fontSize: 60,
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 600,
    color: "rgba(0,0,0,1)",
    linePadding: 10,
  },
  categoryName: {
    position: { x: 640, y: 500 },
    font: "serif",
    fontSize: 40,
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "top",
    textMaxWidth: 800,
    color: "rgba(0,0,0,1)",
    linePadding: 0,
  },
};

export const centerTemplate2: typeTextStyles = {
  name: {
    position: { x: 640, y: 330 },
    font: "serif",
    fontSize: 60,
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 600,
    color: "rgba(0,0,0,1)",
    linePadding: 35,
  },
  categoryName: {
    position: { x: 640, y: 525 },
    font: "serif",
    fontSize: 40,
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "top",
    textMaxWidth: 800,
    color: "rgba(0,0,0,1)",
    linePadding: 0,
  },
};

export const centerNeonTemplate: typeTextStyles = {
  name: {
    position: { x: 640, y: 325 },
    font: "sans-serif",
    fontStyle: "bold",
    fontSize: 60,
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 600,
    color: "rgba(42,209,197,1)",
    linePadding: 40,
  },
  categoryName: {
    position: { x: 640, y: 530 },
    font: "sans-serif",
    fontSize: 38,
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "top",
    textMaxWidth: 800,
    color: "rgba(42,209,197,1)",
    linePadding: 0,
  },
};

export const centerWhiteTemplate: typeTextStyles = {
  name: {
    position: { x: 640, y: 340 },
    font: "sans-serif",
    fontStyle: "bold",
    fontSize: 72,
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 730,
    color: "rgb(255,255,255)",
    linePadding: 40,
  },
  categoryName: {
    position: { x: 640, y: 570 },
    font: "sans-serif",
    fontSize: 38,
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "top",
    textMaxWidth: 800,
    color: "rgb(255,255,255)",
    linePadding: 0,
  },
};

export const emokloreTemplate: typeTextStyles = {
  name: {
    position: { x: 640, y: 330 },
    font: "sans-serif",
    fontSize: 70,
    fontStyle: "bold",
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 800,
    color: "rgb(255,255,255)",
    linePadding: 25,
  },
  categoryName: {
    position: { x: 640, y: 500 },
    font: "sans-serif",
    fontSize: 40,
    fontStyle: "bold",
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "top",
    textMaxWidth: 800,
    color: "rgb(255,255,255)",
    linePadding: 0,
  },
};

export const horrorTemplate: typeTextStyles = {
  name: {
    position: { x: 640, y: 340 },
    font: "sans-serif",
    fontSize: 70,
    fontStyle: "bold",
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 800,
    color: "rgb(0,0,0)",
    linePadding: 20,
  },
  categoryName: {
    position: { x: 640, y: 500 },
    font: "sans-serif",
    fontSize: 40,
    fontStyle: "bold",
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "top",
    textMaxWidth: 800,
    color: "rgb(0,0,0)",
    linePadding: 0,
  },
};

export const centerBigTemplateWhite: typeTextStyles = {
  name: {
    position: { x: 640, y: 340 },
    font: "sans-serif",
    fontSize: 100,
    fontStyle: "bold",
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 1000,
    color: "rgb(255,255,255)",
    linePadding: 20,
  },
  categoryName: {
    position: { x: 640, y: 600 },
    font: "sans-serif",
    fontSize: 40,
    fontStyle: "bold",
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "top",
    textMaxWidth: 800,
    color: "rgb(255,255,255)",
    linePadding: 0,
  },
};

export const centerBigTemplateBlack: typeTextStyles = {
  name: {
    position: { x: 640, y: 340 },
    font: "sans-serif",
    fontSize: 100,
    fontStyle: "bold",
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 1000,
    color: "rgba(0,0,0,1)",
    linePadding: 20,
  },
  categoryName: {
    position: { x: 640, y: 600 },
    font: "sans-serif",
    fontSize: 40,
    fontStyle: "bold",
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "top",
    textMaxWidth: 800,
    color: "rgba(0,0,0,1)",
    linePadding: 0,
  },
};

export const rightTemplate: typeTextStyles = {
  name: {
    position: { x: 580, y: 340 },
    font: "serif",
    fontSize: 60,
    maxLine: 3,
    horizontalAlign: "left",
    verticalAlign: "center",
    textMaxWidth: 610,
    color: "rgba(0,0,0,1)",
    linePadding: 10,
  },
  categoryName: {
    position: { x: 680, y: 670 },
    font: "serif",
    fontSize: 30,
    maxLine: 0,
    horizontalAlign: "right",
    verticalAlign: "center",
    textMaxWidth: 550,
    color: "rgb(146,146,146)",
    linePadding: 0,
  },
};

export const leftTemplate: typeTextStyles = {
  name: {
    position: { x: 440, y: 330 },
    font: "sans-serif",
    fontStyle: "bold",
    fontSize: 60,
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 610,
    color: "rgba(0,0,0,1)",
    linePadding: 30,
  },
  categoryName: {
    position: { x: 440, y: 535 },
    font: "sans-serif",
    fontStyle: "bold",
    fontSize: 35,
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 550,
    color: "rgba(0,0,0,1)",
    linePadding: 0,
  },
};

export const leftRightTemplate: typeTextStyles = {
  name: {
    position: { x: 100, y: 50 },
    font: "sans-serif",
    fontStyle: "bold",
    fontSize: 55,
    maxLine: 1,
    horizontalAlign: "left",
    verticalAlign: "center",
    textMaxWidth: 800,
    color: "rgba(0,0,0,1)",
    linePadding: 10,
  },
  categoryName: {
    position: { x: 620, y: 650 },
    font: "sans-serif",
    fontSize: 30,
    fontStyle: "bold",
    maxLine: 0,
    horizontalAlign: "right",
    verticalAlign: "center",
    textMaxWidth: 550,
    color: "rgb(0,0,0)",
    linePadding: 0,
  },
};

export const presentTemplate: typeTextStyles = {
  name: {
    position: { x: 640, y: 355 },
    font: "sans-serif",
    fontStyle: "bold",
    fontSize: 60,
    maxLine: 3,
    horizontalAlign: "center",
    verticalAlign: "center",
    textMaxWidth: 610,
    color: "rgba(36,76,104,1)",
    linePadding: 45,
  },
  categoryName: {
    position: { x: 640, y: 590 },
    font: "sans-serif",
    fontSize: 40,
    maxLine: 0,
    horizontalAlign: "center",
    verticalAlign: "top",
    textMaxWidth: 800,
    color: "rgba(36,76,104,1)",
    linePadding: 0,
  },
};
