import { useModal } from "pages/EditorLexical/components/Modal";
import { ReactNode } from "react";
import { createPortal } from "react-dom";
import styles from "./ModalLazy.module.css";

type ModalLazyProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
};

const ModalLazy = ({ open, onClose, children }: ModalLazyProps) => {
  useModal(open);

  if (!open) {
    return null;
  }

  const container = document.getElementById("modal-root");
  if (container == null) {
    return null;
  }

  return createPortal(
    <>
      <div className={styles.modal}>
        <div className={styles.bg}>{children}</div>
      </div>
      <div className={styles.overlay} onClick={onClose}></div>
    </>,
    container
  );
};

export default ModalLazy;
