import React from "react";
import { Link } from "react-router-dom";
import styles from "./ButtonOutline.module.css";

type ButtonOutlineProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  to?: string;
  target?: React.HTMLAttributeAnchorTarget;
};

const ButtonOutline: React.FC<ButtonOutlineProps> = ({
  to,
  target,
  disabled,
  children,
  ...props
}) => {
  if (to != null && !disabled) {
    return (
      <Link className={styles.link} to={to} target={target}>
        <button className={styles.button} {...props}>
          {children}
        </button>
      </Link>
    );
  }

  return (
    <button className={styles.button} disabled={disabled} {...props}>
      {children}
    </button>
  );
};

export default ButtonOutline;
