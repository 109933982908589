import React, { useEffect, useState } from "react";
import { getSalesSummary, SalesSummary } from "apiv1/sales";
import { WithLoad } from "utils/types";
import { postCoupons, CouponType, CouponInfo } from "apiv1/coupons";
import BtnWithProcessing from "components/Btn/BtnWithProcessing";
import styles from "./ModalNewCoupon.module.css";
import Select from "components/Select";
import Modal from "components/Modal";

type ModalNewCouponProps = {
  uid: string;
  onCreated: (coupon: CouponInfo) => void;
  open: boolean;
  onClose: () => void;
};

const ModalNewCoupon: React.VFC<ModalNewCouponProps> = ({
  uid,
  onCreated,
  open,
  onClose,
}) => {
  const [projectId, setProjectId] = useState<string>();
  const [couponType, setCouponType] = useState<CouponType>("free");
  const [amount, setAmount] = useState<number>();

  const handleChangeCouponType: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => setCouponType(event.target.value as CouponType);

  const handleChangeProjectId: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    setProjectId(event.target.value);
  };

  const handleChangeAmount: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (Number.isSafeInteger(event.target.valueAsNumber)) {
      setAmount(event.target.valueAsNumber);
    } else {
      setAmount(undefined);
    }
  };

  const [summaries, setSummaries] =
    useState<WithLoad<SalesSummary[]>>("loading");

  useEffect(() => {
    getSalesSummary(uid)
      .then((res) => setSummaries(res.data))
      .catch(() => setSummaries("notfound"));
  }, []);

  if (summaries === "loading" || summaries === "notfound") {
    return null;
  }

  const project = summaries.find((p) => p.project_id === projectId);
  const discountedPrice =
    project == null ? null : (project.price || 0) - (amount || 0);

  const discountedPriceText =
    discountedPrice == null ? "-" : discountedPrice.toLocaleString("ja-JP");

  const invalidAmountOff =
    discountedPrice == null ||
    discountedPrice < 100 ||
    amount == null ||
    amount <= 0;

  const invalid =
    project == null || (couponType === "fixed_amount_off" && invalidAmountOff);

  let errorMessage: string | null = null;
  if (discountedPrice != null && discountedPrice < 100) {
    errorMessage = "値引き後価格は100円以上になるようにしてください";
  } else if (amount != null && amount <= 0) {
    errorMessage = "値引き額は1円以上としてください";
  }

  const handleClose = () => {
    onClose();
    setProjectId(undefined);
    setCouponType("free");
    setAmount(undefined);
  };

  const handleSubmit = async () => {
    if (projectId == null || project == null) {
      return null;
    }

    let sendAmount = couponType === "free" ? undefined : amount;
    const coupon = (await postCoupons(projectId, couponType, sendAmount)).data;
    alert(`シナチケ "${coupon.id}" を作成しました`);

    onCreated({
      ...coupon,
      project_id: projectId,
      project_name: project.project_name,
      cover_url: project.cover_url,
    });
    handleClose();
  };

  if (!open) {
    return null;
  }

  return (
    <Modal open={open} onClose={handleClose} title="シナチケを発行">
      <div className={styles.box}>
        <div>
          <label className={styles.label}>対象作品</label>
          <Select value={projectId} onChange={handleChangeProjectId} fullwidth>
            <option hidden>未設定</option>
            {summaries.map((project) => (
              <option key={project.project_id} value={project.project_id}>
                {project.project_name}
              </option>
            ))}
          </Select>
        </div>
        <div>
          <label className={styles.label}>シナチケ種別</label>
          <label className={styles.radioArea}>
            <input
              className={styles.radio}
              value="free"
              type="radio"
              name="couponType"
              checked={couponType === "free"}
              onChange={handleChangeCouponType}
            />
            無料配布
          </label>
          <label className={styles.radioArea}>
            <input
              className={styles.radio}
              value="fixed_amount_off"
              type="radio"
              name="couponType"
              checked={couponType === "fixed_amount_off"}
              onChange={handleChangeCouponType}
            />
            値引き
          </label>
        </div>
        {couponType === "fixed_amount_off" && (
          <div>
            <label className={styles.label}>値引き額</label>
            <div className={styles.price}>
              <input
                className={styles.inputPrice}
                type="number"
                value={amount}
                onChange={handleChangeAmount}
              />
            </div>
            <dl className={styles.breakdown}>
              <dt className={styles.breakdown}>値引き後の価格</dt>
              <dd>¥ {discountedPriceText}</dd>
            </dl>
            <p className={styles.note}>
              ※
              作品の頒布価格が変更をした場合、値引き後の価格が100円未満となるような値引きシナチケは自動で無効化されます。
            </p>
            {errorMessage && errorMessage.length > 0 && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}
          </div>
        )}
        <div className={styles.submitArea}>
          <BtnWithProcessing
            onClick={handleSubmit}
            labelProsessing="処理中"
            disabled={invalid}
          >
            シナチケを発行
          </BtnWithProcessing>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNewCoupon;
