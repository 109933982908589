import axios from "axios";
import { withoutToken } from "apiv1";
import { SearchedProject } from "./project";

export const getHotProjects = async () => {
  return axios.get<SearchedProject[]>(
    "/v1/recommend/hot",
    await withoutToken()
  );
};

export const getPickupProjects = async () => {
  return axios.get<SearchedProject[]>(
    "/v1/recommend/pickup",
    await withoutToken()
  );
};

export type HotCategory = {
  name: string;
  slug: string;
};

export const getHotCategories = async () => {
  return axios.get<HotCategory[]>(
    "/v1/recommend/hotCategories",
    await withoutToken()
  );
};
