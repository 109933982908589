import { ReactComponent as IconSaved } from "./IconSaved.svg";
import { ReactComponent as IconSync } from "./IconSync.svg";
import { ReactComponent as IconSyncProblem } from "./IconSyncProblem.svg";
import "./Footer.css";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { $getRoot, EditorState } from "lexical";
import debounce from "lodash/debounce";
import { useRecoilValue } from "recoil";
import {
  hasSaveErrorState,
  openSidebarState,
  savedAtState,
} from "../../recoil/atom";
import { isSavedSelector } from "../../recoil/selector";

const Footer = () => {
  const openSidebar = useRecoilValue(openSidebarState);

  return (
    <div className="Footer__root" data-open-sidebar={openSidebar}>
      <div className="Footer__container">
        <div className="Footer__lastSaved">
          <SavedAt />
          <SaveStatus />
        </div>
        <Counter />
      </div>
    </div>
  );
};

const formatDate = (date: Date | null): string => {
  if (date == null) {
    return "-";
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours().toString().padStart(2, "0");
  const min = date.getMinutes().toString().padStart(2, "0");
  const sec = date.getSeconds().toString().padStart(2, "0");
  return `${year}年${month}月${day}日 ${hour}:${min}:${sec}`;
};

const SavedAt = () => {
  const savedAt = useRecoilValue(savedAtState);

  return <>最終保存 {formatDate(savedAt)}</>;
};

const SaveStatus = () => {
  const isSaved = useRecoilValue(isSavedSelector);
  const hasSaveError = useRecoilValue(hasSaveErrorState);
  if (hasSaveError) {
    return <IconSyncProblem className="Footer__iconSaved" />;
  } else if (isSaved) {
    return <IconSaved className="Footer__iconSaved" />;
  } else {
    return <IconSync className="Footer__iconSaved" />;
  }
};

const DELAY_MS = 100;

const Counter = () => {
  const [editor] = useLexicalComposerContext();
  const [length, setLength] = useState(0);
  const isFirst = useRef<boolean>(true);

  const updateLength = useMemo(() => {
    return debounce((editorState: EditorState) => {
      editorState.read(() => {
        const root = $getRoot();
        setLength(root.getTextContentSize());
      });
    }, DELAY_MS);
  }, [setLength]);

  useEffect(() => {
    return editor.registerUpdateListener(
      ({ editorState, dirtyElements, dirtyLeaves }) => {
        if (
          !isFirst.current &&
          dirtyElements.size === 0 &&
          dirtyLeaves.size === 0
        ) {
          return;
        }

        updateLength(editorState);
        isFirst.current = false;
      }
    );
  }, [editor, updateLength]);

  return <div className="Footer__count">{length}文字</div>;
};

export default Footer;
