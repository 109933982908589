import React, { useContext, useEffect, useState } from "react";
import DistributorTabMenu, {
  TabType,
} from "./DistributorTabMenu/DistributorTabMenu";
import DistributorMainContainer from "./DistributorMainContainer/DistributorMainContainer";
import { getPayjpTenant, Tenant } from "apiv1/payjp";
import { getSCTAct, SCTAct } from "apiv1/user";
import { AuthContext } from "components/AuthProvider";
import NotFound from "pages/NotFound";
import DefaultLayout from "components/DefaultLayout";

const Distributor: React.VFC = () => {
  const user = useContext(AuthContext);
  const [tab, setTab] = useState<TabType>("bank");
  const [tenant, setTenant] = useState<Tenant | "loading" | "notfound">(
    "loading"
  );
  const [sctact, setSctact] = useState<SCTAct | "loading" | "notfound">(
    "loading"
  );
  useEffect(() => {
    if (user.authed) {
      getPayjpTenant()
        .then((res) => setTenant(res.data))
        .catch(() => setTenant("notfound"));
      getSCTAct(user.uid)
        .then((res) => setSctact(res.data))
        .catch(() => setSctact("notfound"));
    }
  }, []);

  if (!user.authed) {
    return <NotFound />;
  }

  const handleChangeSctact = (sctact: SCTAct) => {
    setSctact(sctact);
  };

  return (
    <DefaultLayout>
      <DistributorTabMenu
        active={tab}
        onChange={setTab}
        warnBank={tenant === "notfound"}
        warnTransactionAct={sctact === "notfound"}
      />
      <DistributorMainContainer
        tab={tab}
        tenant={tenant}
        sctact={sctact}
        onChangeSctact={handleChangeSctact}
      />
    </DefaultLayout>
  );
};

export default Distributor;
