import React, { useRef } from "react";
import styles from "./EditorMainContainer.module.css";
import EditorHeader from "./EditorHeader/EditorHeader";
import EditorFloating from "./EditorFloating/EditorFloating";
import EditorEditArea from "./EditorEditArea";
import Editor from "@draft-js-plugins/editor";

type EditorMainContainerProps = {
  showSidebar: boolean;
  toggleSidebar: () => void;
};

const EditorMainContainer: React.FC<EditorMainContainerProps> = ({
  showSidebar,
  toggleSidebar,
}) => {
  const editorRef = useRef<Editor | null>(null);

  return (
    <div className={styles.container}>
      <EditorHeader
        showSidebar={showSidebar}
        toggleSidebar={toggleSidebar}
        editorRef={editorRef}
      ></EditorHeader>
      <EditorEditArea editorRef={editorRef} />
      <EditorFloating></EditorFloating>
    </div>
  );
};

export default EditorMainContainer;
