import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $getSelection,
  $isNodeSelection,
  $isRangeSelection,
  BLUR_COMMAND,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_CRITICAL,
  COMMAND_PRIORITY_EDITOR,
  FOCUS_COMMAND,
  FORMAT_TEXT_COMMAND,
  INSERT_LINE_BREAK_COMMAND,
  KEY_MODIFIER_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from "lexical";
import {
  $createHeadingNode,
  $createQuoteNode,
  $isHeadingNode,
  HeadingTagType,
} from "@lexical/rich-text";
import { mergeRegister } from "@lexical/utils";
import { $wrapNodes } from "@lexical/selection";
import {
  ChangeEvent,
  CSSProperties,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ReactComponent as IconAddImage } from "./IconAddImage.svg";
import { ReactComponent as IconBold } from "./IconBold.svg";
import { ReactComponent as IconEnter } from "./IconEnter.svg";
import { ReactComponent as IconH1 } from "./IconH1.svg";
import { ReactComponent as IconH2 } from "./IconH2.svg";
import { ReactComponent as IconH3 } from "./IconH3.svg";
import { ReactComponent as IconMenu } from "./IconMenu.svg";
import { ReactComponent as IconNote } from "./IconNote.svg";
import { ReactComponent as IconRedo } from "./IconRedo.svg";
import { ReactComponent as IconUnderline } from "./IconUnderline.svg";
import { ReactComponent as IconUndo } from "./IconUndo.svg";
import "./Toolbar.css";
import {
  IMAGE_SIZE_LIMIT,
  INSERT_IMAGE_COMMAND,
} from "../../nodes/ImageNode/ImagesPlugin";
import { controlOrMeta } from "../../shared/keyboard";
import { IS_APPLE, IS_IOS } from "../../shared/environment";
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from "recoil";
import {
  openSidebarMobileState,
  openSidebarState,
  openUploadingState,
} from "../../recoil/atom";
import { postImage } from "apiv1/project";
import { storage } from "../../../../initialize";
import { getDownloadURL, ref } from "@firebase/storage";
import { useParams } from "react-router-dom";
import HeaderUserIcon from "components/HeaderUserIcon";
import debounce from "lodash/debounce";
import { createPortal } from "react-dom";

const blockTypeToBlockName = {
  bullet: "Bulleted List",
  check: "Check List",
  code: "Code Block",
  h1: "Heading 1",
  h2: "Heading 2",
  h3: "Heading 3",
  h4: "Heading 4",
  h5: "Heading 5",
  h6: "Heading 6",
  image: "Image",
  number: "Numbered List",
  paragraph: "Normal",
  quote: "Quote",
};

const keyeventToHeading = (
  event: KeyboardEvent
): HeadingTagType | undefined => {
  if (controlOrMeta(event)) {
    if (event.key === "1") {
      return "h1";
    }
    if (event.key === "2") {
      return "h2";
    }
    if (event.key === "3") {
      return "h3";
    }
  }

  return;
};

const DELAY_MS = 50;

const useTrackForIos = (): CSSProperties | undefined => {
  const [editor] = useLexicalComposerContext();
  const [offset, setOffset] = useState<number>(0);
  const prevOffset = useRef<number>(0);
  const focused = useRef(false);

  useEffect(() => {
    if (!IS_IOS) {
      return;
    }

    return mergeRegister(
      editor.registerCommand(
        FOCUS_COMMAND,
        () => {
          focused.current = true;
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          focused.current = false;
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      )
    );
  }, [editor]);

  useEffect(() => {
    if (!IS_IOS) {
      return;
    }

    const listener = debounce(() => {
      if (focused.current && prevOffset.current !== window.scrollY) {
        prevOffset.current = window.scrollY;
        setOffset(window.scrollY);
      }
    }, DELAY_MS);
    window.addEventListener("scroll", listener);
    return () => window.removeEventListener("scroll", listener);
  }, [focused, prevOffset, setOffset]);

  return focused.current
    ? {
        position: "absolute",
        top: offset,
      }
    : undefined;
};

const Toolbar = () => {
  const [editor] = useLexicalComposerContext();
  const [activeEditor, setActiveEditor] = useState(editor);
  const [blockType, setBlockType] =
    useState<keyof typeof blockTypeToBlockName>("paragraph");
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  const style = useTrackForIos();

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = activeEditor.getElementByKey(elementKey);

      setIsBold(selection.hasFormat("bold"));
      setIsUnderline(selection.hasFormat("underline"));

      if (elementDOM !== null) {
        const type = $isHeadingNode(element)
          ? element.getTag()
          : element.getType();
        if (type in blockTypeToBlockName) {
          setBlockType(type as keyof typeof blockTypeToBlockName);
        }
      }
    } else if ($isNodeSelection(selection)) {
      setBlockType("paragraph");
      setIsBold(false);
      setIsUnderline(false);
    }
  }, [activeEditor]);

  useEffect(() => {
    return editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      (_payload, newEditor) => {
        updateToolbar();
        setActiveEditor(newEditor);
        return false;
      },
      COMMAND_PRIORITY_CRITICAL
    );
  }, [editor, updateToolbar]);

  const toggleHeading = useCallback(
    (headingSize: HeadingTagType) => {
      editor.update(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
          const anchorNode = selection.anchor.getNode();
          const element =
            anchorNode.getKey() === "root"
              ? anchorNode
              : anchorNode.getTopLevelElementOrThrow();
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();

          $wrapNodes(selection, () => {
            if (type !== headingSize) {
              return $createHeadingNode(headingSize);
            } else {
              return $createParagraphNode();
            }
          });
        }
      });
    },
    [editor]
  );

  const toggleQuote = useCallback(() => {
    if (blockType !== "quote") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createQuoteNode());
        }
      });
    } else {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $wrapNodes(selection, () => $createParagraphNode());
        }
      });
    }
  }, [editor, blockType]);

  const insertLineBreak = useCallback(() => {
    console.log("INSERT");
    editor.dispatchCommand(INSERT_LINE_BREAK_COMMAND, false);
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      activeEditor.registerCommand<boolean>(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand<boolean>(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      activeEditor.registerCommand(
        KEY_MODIFIER_COMMAND,
        (payload) => {
          const headingType = keyeventToHeading(payload);
          if (headingType != null) {
            toggleHeading(headingType);
            payload.preventDefault();
            return true;
          }

          if (controlOrMeta(payload) && payload.key === "]") {
            toggleQuote();
            payload.preventDefault();
            return true;
          }

          return false;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [activeEditor, updateToolbar, toggleHeading, toggleQuote]);

  const refImageInput = useRef<HTMLInputElement>(null);

  const insertImage = () => {
    if (refImageInput.current != null) {
      refImageInput.current.click();
    }
  };

  const openSidebar = useRecoilValue(openSidebarState);
  const onOpenSidebar = useRecoilCallback(
    ({ set }) =>
      () => {
        set(openSidebarState, true);
        set(openSidebarMobileState, true);
      },
    []
  );

  const container = document.getElementById("modal-root");
  if (container == null) {
    return null;
  }

  return createPortal(
    <div
      className="Toolbar__root"
      data-open-sidebar={openSidebar}
      style={style}
    >
      <IconMenu
        className="Toolbar__menuButton"
        role="button"
        onClick={onOpenSidebar}
      />
      <div className="Toolbar__container">
        <div className="Toolbar__buttonGroup">
          <Tooltip
            text={IS_APPLE ? "元に戻す (⌘Z)" : "元に戻す (Ctrl+Z)"}
            disabled={!canUndo}
          >
            <IconUndo
              className="Toolbar__button"
              role="button"
              data-disabled={!canUndo}
              onClick={() =>
                activeEditor.dispatchCommand(UNDO_COMMAND, undefined)
              }
            />
          </Tooltip>
          <Tooltip
            text={IS_APPLE ? "やり直し (⌘⇧Z)" : "やり直し (Ctrl+Shift+Z)"}
            disabled={!canRedo}
          >
            <IconRedo
              className="Toolbar__button"
              role="button"
              data-disabled={!canRedo}
              onClick={() =>
                activeEditor.dispatchCommand(REDO_COMMAND, undefined)
              }
            />
          </Tooltip>
        </div>
        <div className="Toolbar__buttonGroup">
          <Tooltip text={IS_APPLE ? "見出し (⌘1)" : "見出し (Ctrl+1)"}>
            <IconH1
              className="Toolbar__button"
              role="button"
              data-active={blockType === "h1"}
              onClick={() => toggleHeading("h1")}
            />
          </Tooltip>

          <Tooltip text={IS_APPLE ? "見出し (⌘2)" : "見出し (Ctrl+2)"}>
            <IconH2
              className="Toolbar__button"
              role="button"
              data-active={blockType === "h2"}
              onClick={() => toggleHeading("h2")}
            />
          </Tooltip>
          <Tooltip text={IS_APPLE ? "見出し (⌘3)" : "見出し (Ctrl+3)"}>
            <IconH3
              className="Toolbar__button"
              role="button"
              data-active={blockType === "h3"}
              onClick={() => toggleHeading("h3")}
            />
          </Tooltip>
          <Tooltip text={IS_APPLE ? "注釈 (⌘])" : "注釈 (Ctrl+])"}>
            <IconNote
              className="Toolbar__button"
              role="button"
              data-active={blockType === "quote"}
              onClick={toggleQuote}
            />
          </Tooltip>
          <Tooltip text="ブロック内改行 (Shift+Enter)">
            <IconEnter
              className="Toolbar__button"
              role="button"
              onClick={insertLineBreak}
            />
          </Tooltip>
        </div>
        <div className="Toolbar__buttonGroup">
          <Tooltip text={IS_APPLE ? "太字 (⌘B)" : "太字 (Ctrl+B)"}>
            <IconBold
              className="Toolbar__button"
              role="button"
              data-active={isBold}
              onClick={() =>
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")
              }
            />
          </Tooltip>
          <Tooltip text={IS_APPLE ? "下線 (⌘U)" : "下線 (Ctrl+U)"}>
            <IconUnderline
              className="Toolbar__button"
              role="button"
              data-active={isUnderline}
              onClick={() =>
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")
              }
            />
          </Tooltip>
        </div>
        <div className="Toolbar__buttonGroup">
          <Tooltip text="画像">
            <IconAddImage
              className="Toolbar__button"
              role="button"
              onClick={insertImage}
            />
          </Tooltip>
          <InputImage ref={refImageInput} />
        </div>
      </div>
      <div className="Toolbar__userButton">
        <HeaderUserIcon variant="small" />
      </div>
    </div>,
    container
  );
};

type TooltipProps = {
  text: string;
  disabled?: boolean;
  children: ReactNode;
};

const Tooltip = ({ text, disabled, children }: TooltipProps) => {
  return (
    <div className="Toolbar__Tooltip_root">
      <div className="Toolbar__Tooltip_children">{children}</div>
      <div className="Toolbar__Tooltip_tooltip" data-disabled={disabled}>
        {text}
      </div>
    </div>
  );
};

const InputImage = forwardRef<HTMLInputElement>((_props, refInput) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [editor] = useLexicalComposerContext();
  const setOpenUploading = useSetRecoilState(openUploadingState);

  const onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null) {
      return;
    }

    const file = event.target.files.item(0);
    if (file == null) {
      return;
    }

    if (!["image/jpeg", "image/png"].includes(file.type)) {
      window.alert(
        "本文に貼り付けできるのは、画像ファイルのJPEGかPNGのみです。"
      );
      return;
    }

    if (file.size > IMAGE_SIZE_LIMIT) {
      window.alert("本文に貼り付ける画像は2MB以下としてください");
      return;
    }

    setOpenUploading(true);
    try {
      const res = await postImage(projectId, file);
      const src = await getDownloadURL(ref(storage, res.data.fileName));
      editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
        src,
        size: "small",
      });
    } finally {
      event.target.value = "";
      setOpenUploading(false);
    }
  };

  return (
    <input
      type="file"
      accept="image/png,image/jpeg"
      hidden
      ref={refInput}
      onChange={onChange}
    />
  );
});

export default Toolbar;
