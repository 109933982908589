import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import styles from "./Login.module.css";
import { ReactComponent as IconGoogle } from "./IconGoogle.svg";
import { ReactComponent as IconTwitter } from "./IconTwitter.svg";
import { Redirect, useHistory, useLocation } from "react-router";
import {
  signInWithEmailAndPassword,
  TwitterAuthProvider,
  signInWithRedirect,
  GoogleAuthProvider,
  getRedirectResult,
} from "firebase/auth";
import { getDBUserWithCache } from "components/AuthProvider";
import { Link } from "react-router-dom";
import { authErrorToJpMessage, signOut, useRedirectRef } from "utils/auth";
import { auth } from "initialize";
import { deleteIncompleteSignup } from "apiv1/user";
import { SpinnerFullDisplay } from "components/Spinner";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const location = useLocation();
  const redirectRef = useRedirectRef();

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        history.push(redirectRef);
      })
      .catch((error) => {
        alert(authErrorToJpMessage(error));
      });
  };

  const handleTwitter = () => {
    signInWithRedirect(auth, new TwitterAuthProvider());
  };

  const handleGoogle = () => {
    signInWithRedirect(auth, new GoogleAuthProvider());
  };

  return (
    <div className={styles.wrap}>
      <div>
        <h2 className={styles.ttl}>ログイン</h2>
        <div className={styles.sns}>
          <button className={styles.btnGoogle} onClick={handleGoogle}>
            <IconGoogle />
            Googleでログイン
          </button>
          <button className={styles.btnTwitter} onClick={handleTwitter}>
            <IconTwitter />
            Twitterでログイン
          </button>
        </div>
        <p className={styles.border}>or</p>
        <form onSubmit={handleSubmit}>
          <label className={styles.label}>メールアドレス</label>
          <input
            className={styles.input}
            onChange={handleEmailChange}
            type="email"
            name="email"
            placeholder="メールアドレスを入力"
          />
          <label className={styles.label}>パスワード</label>
          <input
            className={styles.input}
            onChange={handlePasswordChange}
            type="password"
            name="password"
            placeholder="パスワードを入力"
          />
          <button className={styles.btnSubmit} type="submit">
            ログイン
          </button>
        </form>
        <p className={styles.login}>
          <Link to={{ pathname: "/signup", search: location.search }}>
            新規登録
          </Link>
          はこちら
        </p>
      </div>
    </div>
  );
};

const LoginContainer = () => {
  const redirectRef = useRedirectRef();
  const [signupState, setSignupState] = useState<SignupStatus | "loading">(
    "loading"
  );

  useEffect(() => {
    checkSignup()
      .then((state) => setSignupState(state))
      .catch(() => {
        window.alert(
          "ログイン処理でエラーが発生しました。ページをリロードします。"
        );
        signOut().finally(window.location.reload);
      });
  }, []);

  if (signupState === "loading") {
    return <SpinnerFullDisplay />;
  }

  if (signupState === "authed") {
    return <Redirect to={redirectRef} />;
  }

  return <Login />;
};

type SignupStatus = "noauth" | "authed";

const checkSignup = async (): Promise<SignupStatus> => {
  if (auth.currentUser == null) {
    return "noauth";
  }

  const hasDBUser = await getDBUserWithCache(auth.currentUser.uid);

  if (hasDBUser) {
    return "authed";
  }

  const result = await getRedirectResult(auth);
  await deleteIncompleteSignup(auth.currentUser.uid);
  if (result != null) {
    window.alert(
      "登録されてないSNSアカウントです。アカウントの新規登録を行なってください。"
    );
  }
  window.location.reload();
  return "noauth";
};

export default LoginContainer;
