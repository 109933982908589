import React from "react";
import styles from "./Terms.module.css";

const LicenseIdTerms: React.VFC = () => {
  const date = new Date();
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <h1>販売許諾番号とは</h1>
        <p>
          <a
            href="http://www.arclight.co.jp/trpg-rights/"
            target="_blank"
            rel="noreferrer"
          >
            「TRPGライツ事務局」
          </a>
          の発行するSPLL申請番号をはじめとして、作品の販売に関して何かしらの許諾を得ていることを示すために自己申告で入力する項目です。
          {date.getFullYear()}年{date.getMonth() + 1}
          月時点ではSPLL申請番号のみを取り扱っており、対象外の作品に関してはこの項目を入力する必要はありません。
        </p>

        <h2>設定方法</h2>
        <p>
          販売許諾番号の欄に、予め取得したSPLL申請番号を入力した上で公開してください。販売許諾番号が設定されている場合、販売価格より「TALTO」の手数料を除く10%が販売許諾料として自動的に徴収されます。
        </p>

        <h2>許諾元に共有されるデータ</h2>
        <p>
          販売許諾番号の欄に入力された文字列及び作品の公開情報、売上計（許諾料の計算のため）が月毎に集計され許諾元に共有されます。登録メールアドレスを含み、販売者の個人データは共有されません。
        </p>

        <p>
          SPLL申請番号に関する詳しい情報は
          <a
            href="http://www.arclight.co.jp/trpg-rights/"
            target="_blank"
            rel="noreferrer"
          >
            テーブルトークRPGに関する二次創作活動のガイドライン策定について
          </a>
          をご覧ください。
        </p>
      </div>
    </div>
  );
};

export default LicenseIdTerms;
