import "./Sidebar.css";

const SidebarSkeleton = () => {
  return (
    <div className="Sidebar__root" data-open="true" data-open-mobile="false">
      <div className="Sidebar__title"></div>
    </div>
  );
};

export default SidebarSkeleton;
