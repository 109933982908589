import { User } from "@firebase/auth";
import { getAgreementDate, getUser } from "apiv1/user";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { auth } from "../initialize";
import { SpinnerFullDisplay } from "./Spinner";

export type Authed = {
  state: "authed";
  uid: string;
  displayName: string;
  authed: true;
  photoURL: string | null;
  agreementDate: string;
  emailVerified: boolean;
};

type NoAuth = {
  state: "noauth";
  authed: false;
  signedInFirebaseOnly: boolean;
};

type Checking = {
  state: "checking";
  authed: false;
};

const NOAUTH: NoAuth = {
  state: "noauth",
  authed: false,
  signedInFirebaseOnly: false,
};

const NOAUTH_FIREBASE_ONLY: NoAuth = {
  state: "noauth",
  authed: false,
  signedInFirebaseOnly: true,
};

const CHECKING: Checking = {
  state: "checking",
  authed: false,
};

export type AuthState = Authed | NoAuth;

export const AuthContext = React.createContext<AuthState>(NOAUTH);

export const LATEST_TERMS = Date.parse("2023-04-09T11:00:00Z");

const getAgreementDateWithCache = async (user: User): Promise<string> => {
  const key = `TALTO.${user.uid}.agreementDate`;
  const cached = localStorage.getItem(key);
  if (cached && Date.parse(cached) >= LATEST_TERMS) return cached;

  const remote = await getAgreementDate(user.uid)
    .then((res) => res.data.agreed_at)
    .catch(() => user.metadata.creationTime || "2021-08-16T00:00:00Z");

  const date = new Date(remote).toISOString();
  localStorage.setItem(key, date);
  return date;
};

export const getDBUserWithCache = async (uid: string): Promise<boolean> => {
  const key = `TALTO.${uid}.userExist`;
  const cached = localStorage.getItem(key);
  if (cached === "true") return true;

  const exist = await getUser(uid)
    .then(() => true)
    .catch((error) => {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        return false;
      }

      throw error;
    });

  console.log("exist:", exist);
  localStorage.setItem(key, exist.toString());
  return exist;
};

const AuthProvider: React.FC = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState | Checking>(CHECKING);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(
      async (user) => {
        if (user == null) {
          setAuthState(NOAUTH);
          return;
        }

        const exist = await getDBUserWithCache(user.uid);
        if (!exist) {
          setAuthState(NOAUTH_FIREBASE_ONLY);
          return;
        }

        const agreementDate = await getAgreementDateWithCache(user);

        setAuthState({
          state: "authed",
          uid: user.uid,
          displayName: user.displayName || "",
          authed: true,
          photoURL: user.photoURL,
          agreementDate,
          emailVerified: user.emailVerified,
        });
      },
      (error) => {
        if (navigator.userAgent.match(/applebot/i)) {
          return;
        }

        throw error;
      }
    );

    return unsubscribe;
  }, []);

  if (authState.state === "checking") {
    return <SpinnerFullDisplay />;
  }

  return (
    <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;
