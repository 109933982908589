import DefaultLayout from "components/DefaultLayout";
import { SearchArea } from "./Top";

const Search = () => {
  return (
    <DefaultLayout>
      <SearchArea />
    </DefaultLayout>
  );
};

export default Search;
