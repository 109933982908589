import { $getNodeByKey, $isElementNode, $setSelection } from "lexical";
import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { useRecoilValue } from "recoil";
import { focusNodeState } from "../../recoil/atom";
import { IS_IOS } from "pages/EditorLexical/shared/environment";

export const NODES = [HeadingNode, QuoteNode];

type AutoFocusPluginProps = {
  bookletId: string;
};

const AutoFocusPlugin = ({ bookletId }: AutoFocusPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const focus = useRecoilValue(focusNodeState);

  useEffect(() => {
    if (focus == null && IS_IOS) {
      return;
    }

    if (focus == null) {
      editor.focus(undefined, { defaultSelection: "rootStart" });
      window.scrollTo({ top: 0 });
      return;
    }

    if (focus.bookletId !== bookletId) {
      return;
    }

    editor.update(() => {
      const node = $getNodeByKey(focus.nodeKey);
      if ($isElementNode(node)) {
        const selection = node.selectEnd();
        $setSelection(selection);
      }
    });

    const domElement = editor.getElementByKey(focus.nodeKey);
    if (domElement !== null) {
      const top = window.scrollY + domElement.getBoundingClientRect().top;
      window.scrollTo({ top: top - 100, behavior: focus.behavior });
    }
  }, [editor, focus, bookletId]);

  return null;
};

export default AutoFocusPlugin;
