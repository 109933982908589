import React from "react";
import styles from "./DefaultLayout.module.css";
import TopHeader from "../TopHeader";
import Footer from "../Footer/Footer";

const DefaultLayout: React.FC = ({ children }) => {
  return (
    <div className={styles.container}>
      <TopHeader />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
