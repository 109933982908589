import React from "react";
import styles from "./Attachment.module.css";
import { Attachment, PatchedProject, patchProject } from "apiv1/project";
import EditorAttachmentList from "pages/Editor/EditorMainContainer/EditorMainAttachment/EditorAttachmentList/EditorAttachmentList";
import { deleteAttachment } from "api";
import EditorAttachmentDropArea from "pages/Editor/EditorMainContainer/EditorMainAttachment/EditorAttachmentDropArea/EditorAttachmentDropArea";
import { useRecoilState } from "recoil";
import { projectState } from "pages/Editor/atom";

const AttachmentArea: React.VFC = () => {
  const [project, setProject] = useRecoilState(projectState);

  const appendItem = (attachment: Attachment) => {
    const items = [...project.attachments.items];
    const index = project.attachments.items.findIndex(
      (v) => v.name === attachment.name
    );
    if (index >= 0) {
      items[index] = attachment;
    } else {
      items.push(attachment);
    }

    const patch: PatchedProject = {
      attachments: { items },
    };

    setProject({
      ...project,
      ...patch,
    });

    return patchProject(project.id, patch);
  };

  const checkDupFile = (fileName: string) => {
    const index = project.attachments.items.findIndex(
      (v) => v.name === fileName
    );
    return index >= 0;
  };

  const dropItem = async (fileName: string) => {
    const patch: PatchedProject = {
      attachments: {
        items: project.attachments.items.filter(
          (attachment) => attachment.name !== fileName
        ),
      },
    };

    const res = await deleteAttachment(project.id, fileName);
    if (!res.ok) {
      throw "error";
    }

    await patchProject(project.id, patch);

    setProject({
      ...project,
      ...patch,
    });
  };

  return (
    <>
      <EditorAttachmentDropArea
        projectId={project.id}
        appendItem={appendItem}
        checkDupFile={checkDupFile}
      />
      <p className={styles.note}>
        ※添付ファイル管理で変更した内容は、設定中の公開範囲に関係なく、作品ページにすぐに反映されます。
      </p>
      <EditorAttachmentList
        items={project.attachments.items}
        dropItem={dropItem}
      />
    </>
  );
};

export default AttachmentArea;
