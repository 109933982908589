import { Tenant } from "apiv1/payjp";
import { SCTAct } from "apiv1/user";
import React from "react";
import { TabType } from "../DistributorTabMenu/DistributorTabMenu";
import styles from "./DistributorMainContainer.module.css";
import DistributorMainPayout from "./DistributorMainPayout/DistributorMainPayout";
import DistributorMainTerms from "./DistributorMainTerms/DistributorMainTerms";

type DistributorMainContainerProps = {
  tab: TabType;
  tenant: Tenant | "loading" | "notfound";
  sctact: SCTAct | "loading" | "notfound";
  onChangeSctact: (sctact: SCTAct) => void;
};

const DistributorMainContainer: React.FC<DistributorMainContainerProps> = ({
  tab,
  tenant,
  sctact,
  onChangeSctact,
}) => {
  return (
    <div className={styles.container}>
      <div>
        {tab === "bank" ? (
          <DistributorMainPayout tenant={tenant} />
        ) : (
          <DistributorMainTerms
            sctact={sctact}
            onChangeSctact={onChangeSctact}
          />
        )}
      </div>
    </div>
  );
};

export default DistributorMainContainer;
