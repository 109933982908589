import {
  DraftInlineStyleType,
  RawDraftContentBlock,
  RawDraftContentState,
  RawDraftInlineStyleRange,
} from "draft-js";
import {
  SerializedEditorState,
  SerializedLexicalNode,
  SerializedLineBreakNode,
  SerializedParagraphNode,
  SerializedTextNode,
} from "lexical";
import { ScenarioData } from "../datastore";
import { SerializedImageNode } from "../nodes/ImageNode";

type DraftV1 = {
  booklets: SaveableBooklet[];
};

type SaveableBooklet = {
  id: string;
  name: string;
  rawDraftContentState: RawDraftContentState;
};

export const convertFromTaltoDraftV1 = (draft: DraftV1): ScenarioData => {
  const booklets = draft.booklets.map(
    (saveableBooklet): ScenarioData["booklets"][0] => {
      return {
        id: saveableBooklet.id,
        title: saveableBooklet.name,
        data: convertFromDraftJSRawDraft(saveableBooklet.rawDraftContentState),
      };
    }
  );

  return { booklets };
};

export const convertFromDraftJSRawDraft = (
  rawDraftContentState: RawDraftContentState
): SerializedEditorState => {
  const blocks = rawDraftContentState.blocks.map((contentBlock) => {
    switch (contentBlock.type) {
      case "header-one":
      case "header-two":
      case "header-three":
        return headingNodeFromContentBlock(contentBlock);
      case "atomic":
        return imageNodeFromContentBlock(
          contentBlock,
          rawDraftContentState.entityMap
        );
      case "block-note":
        return quoteNodeFromContentBlock(contentBlock);
      default:
        return paragraphNodeFromContentBlock(contentBlock);
    }
  });

  // 注釈ブロックを結合する
  const children = blocks.reduce((nodes, node) => {
    const prevNode = nodes[nodes.length - 1];
    if (node.type === "quote" && prevNode?.type === "quote") {
      const linebreak: SerializedLineBreakNode = {
        type: "linebreak",
        version: 1,
      };

      (prevNode as any).children.push(linebreak, ...node.children);
    } else {
      nodes.push(node);
    }

    return nodes;
  }, [] as Array<SerializedLexicalNode>);

  return {
    root: {
      type: "root",
      version: 1,
      direction: "ltr",
      format: "",
      indent: 0,
      children,
    },
  };
};

const paragraphNodeFromContentBlock = (
  contentBlock: RawDraftContentBlock
): SerializedParagraphNode => {
  const paragraph: SerializedParagraphNode = {
    type: "paragraph",
    version: 1,
    direction: null,
    format: "",
    indent: 0,
    children: [],
  };

  if (contentBlock.text.length > 0) {
    paragraph.children = inlineNodeFromStyleRanges(
      contentBlock.text,
      contentBlock.inlineStyleRanges
    );
    paragraph.direction = "ltr";
  }
  return paragraph;
};

const mappingHeaderTag: Record<string, string> = {
  "header-one": "h1",
  "header-two": "h2",
  "header-three": "h3",
};

const headingNodeFromContentBlock = (contentBlock: RawDraftContentBlock) => {
  const heading = {
    tag: mappingHeaderTag[contentBlock.type] || "h1",
    type: "heading",
    version: 1,
    direction: "ltr",
    format: "",
    indent: 0,
    children: [] as Array<SerializedLexicalNode>,
  };

  if (contentBlock.text.length > 0) {
    heading.children = inlineNodeFromStyleRanges(
      contentBlock.text,
      contentBlock.inlineStyleRanges
    );
  }

  return heading;
};

const imageNodeFromContentBlock = (
  contentBlock: RawDraftContentBlock,
  entityMap: RawDraftContentState["entityMap"]
): SerializedImageNode => {
  const entity = entityMap[contentBlock.entityRanges[0].key];

  return {
    type: "image",
    version: 1,
    src: entity.data.src,
    size: "large",
    format: "",
  };
};

const quoteNodeFromContentBlock = (contentBlock: RawDraftContentBlock) => {
  const quote = {
    type: "quote",
    version: 1,
    direction: "ltr",
    format: "",
    indent: 0,
    children: [] as Array<SerializedLexicalNode>,
  };

  if (contentBlock.text.length > 0) {
    quote.children = inlineNodeFromStyleRanges(
      contentBlock.text,
      contentBlock.inlineStyleRanges
    );
  }

  return quote;
};

const inlineNodeFromStyleRanges = (
  text: string,
  inlineStyleRanges: Array<RawDraftInlineStyleRange>
): Array<SerializedTextNode> => {
  const inlineStyles = decodeInlineStyleRanges(text, inlineStyleRanges);

  const nodes: Array<SerializedTextNode> = [];
  let cursor = 0;
  let styles = inlineStyles[0];
  for (let i = 1; i < text.length; i++) {
    if (!isEqual(styles, inlineStyles[i])) {
      nodes.push(textNodeFromStyle(text.substring(cursor, i), styles));

      cursor = i;
      styles = inlineStyles[i];
    }
  }
  nodes.push(textNodeFromStyle(text.substring(cursor, text.length), styles));

  return nodes;
};

const textNodeFromStyle = (
  text: string,
  styles: Array<DraftInlineStyleType>
): SerializedTextNode => {
  return {
    type: "text",
    version: 1,
    detail: 0,
    format: flasFromStyles(styles),
    mode: "normal",
    style: "",
    text,
  };
};

const decodeInlineStyleRanges = (
  text: string,
  ranges: Array<RawDraftInlineStyleRange>
): Array<Array<DraftInlineStyleType>> => {
  const styles: Array<Array<DraftInlineStyleType>> = Array(text.length).fill(
    []
  );

  ranges.forEach((range) => {
    let cursor = range.offset;
    const end = range.offset + range.length;
    while (cursor < end) {
      styles[cursor] = [...styles[cursor], range.style];
      cursor++;
    }
  });
  return styles;
};

const isEqual = (
  a: Array<DraftInlineStyleType>,
  b: Array<DraftInlineStyleType>
): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
};

const IS_BOLD = 1;
const IS_ITALIC = 1 << 1;
const IS_STRIKETHROUGH = 1 << 2;
const IS_UNDERLINE = 1 << 3;
const IS_CODE = 1 << 4;
// const IS_SUBSCRIPT = 1 << 5;
// const IS_SUPERSCRIPT = 1 << 6;

const TEXT_TYPE_TO_FORMAT: Record<DraftInlineStyleType, number> = {
  BOLD: IS_BOLD,
  CODE: IS_CODE,
  ITALIC: IS_ITALIC,
  STRIKETHROUGH: IS_STRIKETHROUGH,
  // SUBSCRIPT: IS_SUBSCRIPT,
  // SUPERSCRIPT: IS_SUPERSCRIPT,
  UNDERLINE: IS_UNDERLINE,
};

const flasFromStyles = (styles: Array<DraftInlineStyleType>) => {
  return styles.reduce((flag, style) => flag | TEXT_TYPE_TO_FORMAT[style], 0);
};
