import React from "react";
import styles from "./ItemShare.module.css";
import BtnTwitter from "../../../components/Btn/BtnTwitter/BtnTwitter";
import BtnLine from "../../../components/Btn/BtnLine/BtnLine";
import BtnShare from "../../../components/Btn/BtnShare/BtnShare";

const ERROR_MSG =
  "エラー：このページのURLをクリップボードにコピーしようとしましたが、失敗しました。";

const makeLineShareURL = (targetUrl: string): string => {
  const getParams = new URLSearchParams();
  getParams.append("url", targetUrl);

  return `https://social-plugins.line.me/lineit/share?${getParams.toString()}`;
};

const makeTwitterShareURL = (
  targetUrl: string,
  projectName: string
): string => {
  const getParams = new URLSearchParams();
  getParams.append("url", targetUrl);
  getParams.append("text", projectName);

  return `https://twitter.com/intent/tweet?${getParams.toString()}`;
};

type ItemShareProps = {
  projectName: string;
};

const ItemShare: React.VFC<ItemShareProps> = ({ projectName }) => {
  const shareUrl = window.location.href;
  const lineHref = makeLineShareURL(shareUrl);
  const twitterHref = makeTwitterShareURL(shareUrl, projectName);

  const copyProjectUrlToClickboard = () => {
    if (!navigator.clipboard) {
      alert(ERROR_MSG);
    }

    navigator.clipboard
      .writeText(shareUrl)
      .then(() => alert("URLをクリップボードにコピーしました"))
      .catch(() => alert(ERROR_MSG));
  };

  return (
    <div className={styles.container}>
      <p className={styles.txt}>このシナリオをシェアする</p>
      <ul className={styles.list}>
        <li className={styles.btn}>
          <a href={twitterHref} target="_blank" rel="noreferrer">
            <BtnTwitter></BtnTwitter>
          </a>
        </li>
        <li className={styles.btn}>
          <a href={lineHref} target="_blank" rel="noreferrer">
            <BtnLine></BtnLine>
          </a>
        </li>
        <li className={styles.btn} onClick={copyProjectUrlToClickboard}>
          <BtnShare></BtnShare>
        </li>
      </ul>
    </div>
  );
};

export default ItemShare;
