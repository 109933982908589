import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import "./Sidebar.css";
import {
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  currentBookletIdState,
  bookletIdsState,
  bookletNameState,
  Heading,
  headingsState,
  focusNodeState,
  openHeadingsState,
  openSidebarState,
  openSettingsState,
  openPreviewState,
  bookletState,
  openPublishState,
  openSidebarMobileState,
} from "../../recoil/atom";
import { SWITCH_BOOKLET_COMMAND } from "../SwitchPlugin/SwitchPlugin";
import { ReactComponent as IconAdd } from "./IconAdd.svg";
import { ReactComponent as IconArrowClosed } from "./IconArrowClosed.svg";
import { ReactComponent as IconArrowDown } from "./IconArrowDown.svg";
import { ReactComponent as IconArrowOpened } from "./IconArrowOpened.svg";
import { ReactComponent as IconArrowUp } from "./IconArrowUp.svg";
import { ReactComponent as IconClose } from "./IconClose.svg";
import { ReactComponent as IconDelete } from "./IconDelete.svg";
import { ReactComponent as IconPlay } from "./IconPlay.svg";
import { ReactComponent as IconPublish } from "./IconPublish.svg";
import { ReactComponent as IconSettings } from "./IconSettings.svg";
import { MouseEvent } from "react";
import { AUTOSAVE_COMMAND } from "../AutoSavePlugin/AutoSavePlugin";
import { EditorState } from "lexical";
import { projectNameSelector } from "pages/Editor/selector";
import { generateBookletId } from "pages/EditorLexical/shared/booklet";

type IndexBookletRowProps = {
  id: string;
};

const IndexBookletRow = ({ id }: IndexBookletRowProps) => {
  const currentBookletId = useRecoilValue(currentBookletIdState);
  const [editor] = useLexicalComposerContext();
  const name = useRecoilValue(bookletNameState(id));
  const setFocusNode = useSetRecoilState(focusNodeState);
  const setOpenSidebarMobile = useSetRecoilState(openSidebarMobileState);
  const [open, setOpen] = useRecoilState(openHeadingsState(id));

  const onClick = () => {
    setFocusNode(null);
    setOpen(true);
    setOpenSidebarMobile(false);
    editor.dispatchCommand(SWITCH_BOOKLET_COMMAND, id);
  };

  const toggleOpen = (event: MouseEvent) => {
    setOpen(!open);
    event.stopPropagation();
  };

  const Icon = open ? IconArrowOpened : IconArrowClosed;

  const onClickUp = useRecoilCallback(
    ({ snapshot, set }) =>
      async (event: MouseEvent) => {
        event.stopPropagation();

        const ids = await snapshot.getPromise(bookletIdsState);
        const index = ids.indexOf(id);
        if (index <= 0) {
          return;
        }

        const newIds = [...ids];
        newIds[index] = newIds[index - 1];
        newIds[index - 1] = id;
        set(bookletIdsState, newIds);
        editor.dispatchCommand(AUTOSAVE_COMMAND, undefined);
      },
    [id, editor]
  );

  const onClickDown = useRecoilCallback(
    ({ snapshot, set }) =>
      async (event: MouseEvent) => {
        event.stopPropagation();

        const ids = await snapshot.getPromise(bookletIdsState);
        const index = ids.indexOf(id);
        if (index <= -1 || index >= ids.length - 1) {
          return;
        }

        const newIds = [...ids];
        newIds[index] = newIds[index + 1];
        newIds[index + 1] = id;
        set(bookletIdsState, newIds);
        editor.dispatchCommand(AUTOSAVE_COMMAND, undefined);
      },
    [id, editor]
  );

  const onClickDelete = useRecoilCallback(
    ({ snapshot, set }) =>
      async (event: MouseEvent) => {
        event.stopPropagation();

        const currentBookletId = await snapshot.getPromise(
          currentBookletIdState
        );
        const ids = await snapshot.getPromise(bookletIdsState);
        const index = ids.indexOf(id);
        if (index <= -1 || ids.length <= 1) {
          return;
        }

        if (!window.confirm(`冊子「${name}」を削除します。よろしいですか？`)) {
          return;
        }

        const newIds = [...ids];
        newIds.splice(index, 1);
        set(bookletIdsState, newIds);
        if (currentBookletId === id) {
          set(currentBookletIdState, newIds[0]);
        }
        editor.dispatchCommand(AUTOSAVE_COMMAND, undefined);
      },
    [id, name, editor]
  );

  return (
    <li>
      <div
        className="Sidebar__IndexBookletRow_heading"
        role="button"
        data-active={id === currentBookletId}
        onClick={onClick}
      >
        <Icon
          className="Sidebar__IndexBookletRow_headingOpen"
          role="button"
          onClick={toggleOpen}
        />
        <div className="Sidebar__IndexBookletRow_headingName">
          {name || "無題の冊子"}
        </div>
        <div className="Sidebar__IndexBookletRow_buttons">
          <IconArrowUp
            className="Sidebar__IndexBookletRow_button"
            role="button"
            onClick={onClickUp}
          />
          <IconArrowDown
            className="Sidebar__IndexBookletRow_button"
            role="button"
            onClick={onClickDown}
          />
          <IconDelete
            className="Sidebar__IndexBookletRow_button"
            role="button"
            onClick={onClickDelete}
          />
        </div>
      </div>
      <BookletHeadings id={id} open={open} />
    </li>
  );
};

type BookletHeadingsProps = {
  id: string;
  open: boolean;
};

const BookletHeadings = ({ id, open }: BookletHeadingsProps) => {
  const headings = useRecoilValue(headingsState(id));
  if (!open || headings == null) {
    return null;
  }

  return (
    <ul className="Sidebar__BookletHeadings_root">
      {headings.map((heading) => {
        return (
          <IndexHeading key={heading.key} bookletId={id} heading={heading} />
        );
      })}
    </ul>
  );
};

type IndexHeadingProps = {
  bookletId: string;
  heading: Heading;
};

const IndexHeading = ({ bookletId, heading }: IndexHeadingProps) => {
  const [editor] = useLexicalComposerContext();
  const currentBookletId = useRecoilValue(currentBookletIdState);
  const setFocusNode = useSetRecoilState(focusNodeState);
  const setOpenSidebarMobile = useSetRecoilState(openSidebarMobileState);

  const onClick = () => {
    setFocusNode({
      bookletId,
      nodeKey: heading.key,
      behavior: bookletId === currentBookletId ? "smooth" : "auto",
    });
    setOpenSidebarMobile(false);
    if (bookletId !== currentBookletId) {
      editor.dispatchCommand(SWITCH_BOOKLET_COMMAND, bookletId);
    }
  };

  return (
    <li
      className="Sidebar__IndexHeading_heading"
      key={heading.key}
      data-indent={heading.tagType !== "h1"}
      onClick={onClick}
    >
      {heading.name || "見出し"}
    </li>
  );
};

type SidebarProps = {
  setEditorState: (editorState: EditorState) => void;
};

const Sidebar = ({ setEditorState }: SidebarProps) => {
  const [editor] = useLexicalComposerContext();
  const projectName = useRecoilValue(projectNameSelector);
  const bookletIds = useRecoilValue(bookletIdsState);
  const currentBookletId = useRecoilValue(currentBookletIdState);

  const appendBooklet = useRecoilCallback(({ snapshot, set }) => async () => {
    const id = generateBookletId();
    const bookletIds = await snapshot.getPromise(bookletIdsState);
    set(bookletIdsState, [...bookletIds, id]);
    editor.dispatchCommand(AUTOSAVE_COMMAND, undefined);
  });

  const bumpCurrentEditorState = useRecoilCallback(
    ({ set }) =>
      () => {
        const editorState = editor.getEditorState();
        setEditorState(editorState);
        set(bookletState(currentBookletId), editorState.toJSON());
      },
    [editor, currentBookletId]
  );

  const setOpenSettings = useSetRecoilState(openSettingsState);
  const onOpenPublish = useRecoilCallback(
    ({ set }) =>
      () => {
        bumpCurrentEditorState();
        set(openPublishState, true);
      },
    [bumpCurrentEditorState]
  );
  const onOpenPreview = useRecoilCallback(
    ({ set }) =>
      () => {
        bumpCurrentEditorState();
        set(openPreviewState, true);
      },
    [bumpCurrentEditorState]
  );

  const [openSidebar, setOpenSidebar] = useRecoilState(openSidebarState);
  const [openSidebarMobile, setOpenSidebarMobile] = useRecoilState(
    openSidebarMobileState
  );
  const onCloseSidebar = () => {
    setOpenSidebar(false);
    setOpenSidebarMobile(false);
  };

  return (
    <>
      <div
        className="Sidebar__root"
        data-open={openSidebar}
        data-open-mobile={openSidebarMobile}
      >
        <div className="Sidebar__title">
          <div className="Sidebar__projectName">{projectName}</div>
          <IconClose
            className="Sidebar__closeButton"
            role="button"
            onClick={onCloseSidebar}
          />
        </div>
        <div className="Sidebar__index">
          <div className="Sidebar__bookletsHeader">冊子と目次</div>
          <ul className="Sidebar__headings">
            {bookletIds.map((id) => {
              return <IndexBookletRow key={id} id={id} />;
            })}
          </ul>
          <div
            className="Sidebar__button"
            role="button"
            onClick={appendBooklet}
          >
            <IconAdd /> 冊子を追加
          </div>
        </div>
        <div className="Sidebar__settingsArea">
          <div
            className="Sidebar__button"
            role="button"
            onClick={onOpenPreview}
          >
            <IconPlay /> プレビューを表示
          </div>
          <div
            className="Sidebar__button"
            role="button"
            onClick={onOpenPublish}
          >
            <IconPublish /> 作品を公開する
          </div>
          <div
            className="Sidebar__button"
            role="button"
            onClick={() => setOpenSettings(true)}
          >
            <IconSettings /> 設定
          </div>
        </div>
      </div>
      <div
        className="Sidebar__overlay"
        data-open-mobile={openSidebarMobile}
        onClick={onCloseSidebar}
      />
    </>
  );
};

export default Sidebar;
