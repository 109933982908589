import React from "react";
import styles from "./ModalReleased.module.css";
import ImgReleased from "./ImgReleased.png";
import { Link } from "react-router-dom";

type ModalReleasedProps = {
  projectId: string;
};

const ModalReleased: React.FC<ModalReleasedProps> = ({ projectId }) => {
  const projectUrl = `/projects/${projectId}`;

  return (
    <div className={styles.container}>
      <h2 className={styles.ttl}>シナリオが公開されました</h2>
      <div className={styles.box}>
        <div className={styles.img}>
          <img src={ImgReleased} alt="ImgReleased" />
        </div>
        <p className={styles.txt}>
          執筆お疲れ様でした。
          <br />
          完成した喜びをSNSなどでシェアして
          <br />
          たくさんの人に読んでもらいましょう！
        </p>
        <div className={styles.shareArea}>
          <div className={styles.btnItem}>
            <Link to={projectUrl} target="_blank">
              公開された作品を確認する
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReleased;
