import { Booklet, useAppendNewBooklet } from "pages/Editor/EditorProvider";
import React, { useEffect, useState } from "react";
import styles from "./EditorBookletList.module.css";
import { ReactComponent as IconAdd } from "./IconAdd.svg";
import { ReactComponent as IconArrowUp } from "./IconArrowUp.svg";
import { ReactComponent as IconArrowDown } from "./IconArrowDown.svg";
import { ReactComponent as IconArrowOpen } from "./IconArrowOpen.svg";
import { ReactComponent as IconDelete } from "./IconDelete.svg";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { bookletsState, currentBookletIdState } from "pages/Editor/atom";
import produce from "immer";
import { BookletIndexMemo } from "./EditorHeaderList";

const BookletIndex: React.FC<{
  booklet: Booklet;
  active: boolean;
  closeSidebar: () => void;
}> = ({ booklet, active, closeSidebar }) => {
  const [open, setOpen] = useState(false);
  const [currentBookletId, setCurrentBookletId] = useRecoilState(
    currentBookletIdState
  );

  useEffect(() => {
    if (booklet.id === currentBookletId) {
      setOpen(true);
    }
  }, [booklet.id, currentBookletId, setOpen]);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleClickBooklet = (event: React.MouseEvent) => {
    event.preventDefault();
    setCurrentBookletId(booklet.id);
    closeSidebar();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  };

  const handleDeleteBooklet = useRecoilCallback(
    ({ snapshot, set }) =>
      async (event: React.MouseEvent) => {
        event.preventDefault();
        const currentBookletId = await snapshot.getPromise(
          currentBookletIdState
        );
        const booklets = await snapshot.getPromise(bookletsState);
        if (booklets.length <= 1) {
          return;
        }

        if (window.confirm(`冊子『${booklet.name}』を削除しますか?`)) {
          const candidate = booklets.filter((b) => b.id !== booklet.id);
          if (currentBookletId === booklet.id) {
            set(currentBookletIdState, candidate[0].id);
          }
          set(bookletsState, candidate);
        }
      },
    [booklet]
  );

  const handleMoveUp = useRecoilCallback(
    ({ snapshot, set }) =>
      async (event: React.MouseEvent) => {
        event.preventDefault();
        const booklets = await snapshot.getPromise(bookletsState);
        const index = booklets.findIndex((b) => b.id === booklet.id);
        if (index <= 0) {
          return;
        }

        const newBooklets = produce(booklets, (draft) => {
          draft.splice(index, 1);
          draft.splice(index - 1, 0, booklet);
        });
        set(bookletsState, newBooklets);
      },
    [booklet]
  );

  const handleMoveDown = useRecoilCallback(
    ({ snapshot, set }) =>
      async (event: React.MouseEvent) => {
        event.preventDefault();
        const booklets = await snapshot.getPromise(bookletsState);
        const index = booklets.findIndex((b) => b.id === booklet.id);
        if (index < 0 || index >= booklets.length - 1) {
          return;
        }

        const newBooklets = produce(booklets, (draft) => {
          draft.splice(index, 1);
          draft.splice(index + 1, 0, booklet);
        });
        set(bookletsState, newBooklets);
      },
    [booklet]
  );

  return (
    <>
      <h2 className={active ? styles.ttlActive : styles.ttl}>
        <IconArrowOpen
          onClick={toggleOpen}
          className={styles.arrow}
          data-open={open}
        />
        <span className={styles.txt} onClick={handleClickBooklet}>
          {booklet.name}
        </span>
        <div className={styles.hover}>
          <span className={styles.up} title="上に移動">
            <IconArrowUp onClick={handleMoveUp} />
          </span>
          <span className={styles.down} title="下に移動">
            <IconArrowDown onClick={handleMoveDown} />
          </span>
          <span className={styles.delete} title="削除">
            <IconDelete onClick={handleDeleteBooklet} />
          </span>
        </div>
      </h2>
      {open && (
        <BookletIndexMemo bookletId={booklet.id} closeSidebar={closeSidebar} />
      )}
    </>
  );
};

type EditorBookletListProps = {
  closeSidebar: () => void;
};

const EditorBookletList: React.FC<EditorBookletListProps> = ({
  closeSidebar,
}) => {
  const currentBookletId = useRecoilValue(currentBookletIdState);
  const booklets = useRecoilValue(bookletsState);
  const appendNewBooklet = useAppendNewBooklet();

  return (
    <div className={styles.container}>
      {booklets.map((booklet) => {
        return (
          <BookletIndex
            booklet={booklet}
            active={booklet.id === currentBookletId}
            key={booklet.id}
            closeSidebar={closeSidebar}
          />
        );
      })}
      <div className={styles.sectionAdd}>
        <button className={styles.btnAdd} onClick={appendNewBooklet}>
          <IconAdd></IconAdd>新しい冊子を追加
        </button>
      </div>
    </div>
  );
};

export default React.memo(EditorBookletList);
