import DefaultLayout from "components/DefaultLayout";
import ModalEdit from "components/ModalEdit/ModalEdit";
import React from "react";

const MyProjects = () => {
  return (
    <DefaultLayout>
      <ModalEdit />
    </DefaultLayout>
  );
};

export default MyProjects;
