import { openUploadingState } from "pages/EditorLexical/recoil/atom";
import { createPortal } from "react-dom";
import { useRecoilValue } from "recoil";
import "./Uploading.css";

const Uploading = () => {
  const open = useRecoilValue(openUploadingState);

  if (!open) {
    return null;
  }

  const container = document.getElementById("modal-root");
  if (container == null) {
    return null;
  }

  return createPortal(
    <div className="Uploading__root">画像をアップロード中...</div>,
    container
  );
};

export default Uploading;
