import { withoutToken } from "apiv1";
import axios from "axios";
import { FeeType } from "./project";

export type Category = {
  id: number;
  slug: string;
  name: string;
  copyright_notice: string | null;
  banner_path: string | null;
  url_official_site: string | null;
};

export const getCategory = (categoryId: number) => {
  return axios.get<Category>(`/v1/categories/${categoryId}`, withoutToken());
};

export type OfficialProject = {
  id: string;
  name: string;
  cover_url: string | null;
  official: boolean;
  fee_type: FeeType;
  price: number | null;
  published_at: string | null;
};

export type CategoryWithOfficialProject = {
  id: number;
  slug: string;
  name: string;
  copyright_notice: string | null;
  banner_path: string | null;
  url_official_site: string | null;
  official_projects: OfficialProject[];
};

export const getCategoryBySlug = (slug: string) => {
  return axios.get<CategoryWithOfficialProject>(
    `/v1/categories/by_slug/${slug}`,
    withoutToken()
  );
};

export type CategoryName = {
  id: number;
  slug: string;
  name: string;
};

export const getCategories = () => {
  return axios.get<CategoryName[]>(`/v1/categories`, withoutToken());
};

export type WithNomarizedName<T> = T & {
  nomalizedName: string;
};

export const normalizeCategoryName = (name: string): string => {
  return name
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    )
    .replace(/　/g, " ")
    .toLowerCase();
};
