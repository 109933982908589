import React, { useState, useEffect } from "react";
import styles from "./Viewer.module.css";
import ViewerHeader from "./ViewerHeader/ViewerHeader";
import ViewerSidebar from "./ViewerSidebar/ViewerSidebar";
import ViewerMainContainer from "./ViewerMainContainer/ViewerMainContainer";
import { useHistory, useParams } from "react-router";
import { SpinnerFullDisplay } from "components/Spinner";
import NotFound from "pages/NotFound";
import { getProject, Project } from "apiv1/project";
import { getRelease, ReleaseDataV1, ReleaseDataV2 } from "apiv1/release";
import { toPageItem } from "./ViewerMainContainer/Pager";
import { ViewerLexical } from "components/Viewer";
import { resetDocumentTitle } from "utils/title";

type ViewerPageProps = {
  project: Project;
  release: ReleaseDataV1;
  shareBtn: boolean;
  changeDocTitle?: boolean;
  preview: boolean;
  currentBookletId: string;
  setCurrentBookletId: (bookletId: string) => void;
};

export const ViewerPage: React.FC<ViewerPageProps> = ({
  project,
  release,
  shareBtn,
  preview,
  changeDocTitle = false,
  currentBookletId,
  setCurrentBookletId,
}) => {
  const [showIndex, setShowIndex] = useState(false);
  const bookletIndex = release.booklets.findIndex(
    (booklet) => booklet.id === currentBookletId
  );

  const booklet = release.booklets[bookletIndex];
  const prev = toPageItem(release.booklets[bookletIndex - 1]);
  const next = toPageItem(release.booklets[bookletIndex + 1]);

  useEffect(() => {
    if (!changeDocTitle) {
      return;
    }

    if (booklet != null) {
      window.document.title = `${booklet.name} - ${project.name}`;
      return resetDocumentTitle;
    }
  }, [booklet?.name, project.name]);

  if (booklet == null) {
    return <NotFound />;
  }

  const toggleShowIndex = (event: React.MouseEvent) => {
    event.preventDefault();
    setShowIndex(!showIndex);
  };

  const closeIndex = () => {
    setShowIndex(false);
  };

  return (
    <div className={styles.container}>
      <ViewerHeader
        project={project}
        release={release}
        booklet={booklet}
        showIndex={showIndex}
        toggleShowIndex={toggleShowIndex}
        shareBtn={shareBtn}
      />
      <ViewerSidebar
        booklets={release.booklets}
        show={showIndex}
        closeIndex={closeIndex}
        currentBookletId={currentBookletId}
        setCurrentBookletId={setCurrentBookletId}
        preview={preview}
      />
      <ViewerMainContainer
        projectName={project.name}
        booklet={booklet}
        prev={prev}
        next={next}
        changeBooklet={setCurrentBookletId}
      />
    </div>
  );
};

type ViewerParams = {
  projectId: string;
  rev: string;
  bookletId: string;
};

const Viewer = () => {
  const history = useHistory();
  const { projectId, rev, bookletId } = useParams<ViewerParams>();
  const [project, setProject] = useState<Project | "loading" | "notfound">(
    "loading"
  );
  const [release, setRelease] = useState<
    ReleaseDataV1 | ReleaseDataV2 | "loading" | "notfound"
  >("loading");

  const [currentBookletId, setCurrentBookletId] = useState(bookletId);
  const changeBookletId = (bookletId: string) => {
    history.push(bookletId);
    setCurrentBookletId(bookletId);
  };

  useEffect(() => {
    getProject(projectId)
      .then((res) => setProject(res.data))
      .catch(() => setProject("notfound"));
    getRelease(projectId, rev, null)
      .then((release) => {
        setRelease(release.data);
      })
      .catch(() => setRelease("notfound"));
  }, [projectId, rev]);

  if (project === "notfound" || release === "notfound") {
    return <NotFound />;
  } else if (project === "loading" || release === "loading") {
    return <SpinnerFullDisplay />;
  }

  if (release.version === "v2") {
    const enableShareButton =
      project.fee_type !== "all_contents" && project.visibility !== "closed";
    return (
      <ViewerLexical
        projectName={project.name}
        projectId={project.id}
        rev={release.rev}
        bookletId={currentBookletId}
        onChangeBooklet={changeBookletId}
        date={release.created_at}
        scenarioData={release}
        enableShareButton={enableShareButton}
      />
    );
  }

  return (
    <ViewerPage
      project={project}
      release={release}
      currentBookletId={currentBookletId}
      setCurrentBookletId={changeBookletId}
      changeDocTitle={true}
      shareBtn={true}
      preview={false}
    />
  );
};

export default Viewer;
