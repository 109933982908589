import { currentBookletIdState } from "pages/Editor/atom";
import { bookletHeadingsSelector } from "pages/Editor/selector";
import React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import styles from "./EditorBookletList.module.css";

type HeaderProps = {
  bookletId: string;
  blockKey: string;
  additonalPadding: boolean;
  title: string;
  closeSidebar: () => void;
};

const Header: React.FC<HeaderProps> = ({
  bookletId,
  blockKey,
  additonalPadding,
  title,
  closeSidebar,
}) => {
  const setCurrentBookletId = useSetRecoilState(currentBookletIdState);
  const handleClickHeading = (event: React.MouseEvent) => {
    event.preventDefault();
    setCurrentBookletId(bookletId);
    closeSidebar();
    setTimeout(() => {
      const el =
        document.querySelector(`[data-offset-key="${blockKey}-0-0"]`) ||
        document.getElementById(blockKey);
      if (el) {
        const top = el.getBoundingClientRect().top + window.scrollY;
        window.scrollTo({ top: top - 80 });
      }
    });
  };

  return (
    <li
      onClick={handleClickHeading}
      className={additonalPadding ? styles.additonalPadding : ""}
    >
      {title}
    </li>
  );
};

const HeaderMemo = React.memo(Header);

const BookletIndex: React.FC<{
  bookletId: string;
  closeSidebar: () => void;
}> = ({ bookletId, closeSidebar }) => {
  const headerBlocks = useRecoilValue(bookletHeadingsSelector(bookletId));

  if (headerBlocks == null || headerBlocks.length === 0) {
    return <div className={styles.noHeaders}>見出しはありません</div>;
  }

  return (
    <div className={styles.section}>
      <ul className={styles.list}>
        {headerBlocks.map((block) => {
          return (
            <HeaderMemo
              bookletId={bookletId}
              key={block.getKey()}
              blockKey={block.getKey()}
              additonalPadding={block.getType() === "header-two"}
              title={block.getText()}
              closeSidebar={closeSidebar}
            />
          );
        })}
      </ul>
    </div>
  );
};

export const BookletIndexMemo = React.memo(BookletIndex);

// type EditorBookletListProps = {
//   closeSidebar: () => void;
// };

// const EditorHeaderList: React.FC<EditorBookletListProps> = ({
//   closeSidebar,
// }) => {
//   const bookletName = useRecoilValue(currentBookletNameSelector);
//   if (bookletName == null) return null;

//   return (
//     <div className={styles.container}>
//       <p className={styles.activeBooklet}>{bookletName}</p>
//       <BookletIndexMemo closeSidebar={closeSidebar} />
//     </div>
//   );
// };

// export default React.memo(EditorHeaderList);
