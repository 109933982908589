import { EditorState } from "draft-js";
import produce from "immer";
import { DefaultValue, selector, selectorFamily } from "recoil";
import {
  bookletsState,
  currentBookletIdState,
  editedAtState,
  projectState,
  savedAtState,
} from "./atom";
import { Booklet } from "./EditorProvider";

export const projectIdSelector = selector<string>({
  key: "editor/projectIdSelector",
  get: ({ get }) => {
    const { id } = get(projectState);
    return id;
  },
});

export const projectNameSelector = selector<string>({
  key: "editor/projectNameSelector",
  get: ({ get }) => {
    const { name } = get(projectState);
    return name;
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      return;
    }

    const project = get(projectState);
    set(projectState, { ...project, name: newValue });
  },
});

export const editedSelector = selector<boolean>({
  key: "editor/editedSelector",
  get: ({ get }) => {
    const savedAt = get(savedAtState);
    const editedAt = get(editedAtState);
    return Boolean(
      (savedAt && editedAt && savedAt < editedAt) || (!savedAt && editedAt)
    );
  },
});

export const currentBookletSelector = selector<Booklet | undefined>({
  key: "editor/currentBookletSelector",
  get: ({ get }) => {
    const currentBookletId = get(currentBookletIdState);
    const booklets = get(bookletsState);
    return booklets.find((booklet) => booklet.id === currentBookletId);
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue || newValue == null) {
      return;
    }

    const currentBookletId = get(currentBookletIdState);
    const booklets = get(bookletsState);
    const currentIndex = booklets.findIndex(
      (booklet) => booklet.id === currentBookletId
    );
    if (currentIndex < 0) {
      return;
    }

    const newBooklets = produce(booklets, (draft) => {
      draft[currentIndex] = newValue;
    });
    set(bookletsState, newBooklets);
  },
});

export const currentEditorStateSelector = selector<EditorState | undefined>({
  key: "editor/currentEditorStateSelector",
  get: ({ get }) => {
    const booklet = get(currentBookletSelector);
    return booklet?.editorState;
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue || newValue == null) {
      return;
    }

    const currentBookletId = get(currentBookletIdState);
    const booklets = get(bookletsState);
    const currentIndex = booklets.findIndex(
      (booklet) => booklet.id === currentBookletId
    );
    if (currentIndex < 0) {
      return;
    }

    const newBooklets = produce(booklets, (draft) => {
      draft[currentIndex].editorState = newValue;
    });
    set(bookletsState, newBooklets);

    if (
      booklets[currentIndex].editorState.getCurrentContent() !==
      newValue.getCurrentContent()
    ) {
      set(editedAtState, new Date());
    }
  },
});

export const currentBookletNameSelector = selector<string | undefined>({
  key: "editor/currentBookletNameSelector",
  get: ({ get }) => {
    const booklet = get(currentBookletSelector);
    return booklet?.name;
  },
  set: ({ get, set }, newValue) => {
    if (newValue instanceof DefaultValue || newValue == null) {
      return;
    }

    const currentBookletId = get(currentBookletIdState);
    const booklets = get(bookletsState);
    const currentIndex = booklets.findIndex(
      (booklet) => booklet.id === currentBookletId
    );
    if (currentIndex < 0) {
      return;
    }

    const newBooklets = produce(booklets, (draft) => {
      draft[currentIndex].name = newValue;
    });
    set(bookletsState, newBooklets);
  },
});

export const currentBookletHeadingsSelector = selector({
  key: "editor/currentBookletHeadingsSelector",
  get: ({ get }) => {
    const booklet = get(currentBookletSelector);
    if (booklet == null) {
      return;
    }

    const content = booklet.editorState.getCurrentContent();
    const blocks = content.getBlocksAsArray();
    const headerBlocks = blocks.filter((block) =>
      /^header\-(one|two)/.test(block.getType())
    );
    return headerBlocks;
  },
});

export const bookletHeadingsSelector = selectorFamily({
  key: "editor/bookletHeadingsSelector",
  get:
    (bookletId) =>
    ({ get }) => {
      const booklets = get(bookletsState);
      const booklet = booklets.find((booklet) => booklet.id === bookletId);
      if (booklet == null) {
        return;
      }

      const content = booklet.editorState.getCurrentContent();
      const blocks = content.getBlocksAsArray();
      const headerBlocks = blocks.filter((block) =>
        /^header\-(one|two)/.test(block.getType())
      );
      return headerBlocks;
    },
});
