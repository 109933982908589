import React, { useEffect, useState } from "react";
import styles from "./ModalProjectName.module.css";
import Modal from "components/Modal";
import { deleteProject, patchProject } from "apiv1/project";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { projectState } from "pages/Editor/atom";

type ModalProjectNameProps = {
  open: boolean;
  onClose: () => void;
};

const ModalProjectName: React.FC<ModalProjectNameProps> = ({
  open,
  onClose,
}) => {
  const history = useHistory();
  const [project, setProject] = useRecoilState(projectState);
  const [projectName, setProjectName] = useState(project.name);

  useEffect(() => {
    setProjectName(project.name);
  }, [project.name]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(event.target.value);
  };

  const saveProjectName = () => {
    if (projectName.length === 0) {
      return window.alert("作品タイトルは一文字以上としてください");
    }

    patchProject(project.id, { name: projectName })
      .then(() => {
        setProject({
          ...project,
          name: projectName,
        });
        onClose();
      })
      .catch(() =>
        alert(
          "サーバーとの通信に失敗したため、プロジェクト名が変更できませんでした。"
        )
      );
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    saveProjectName();
  };

  const handleDeleteProject = () => {
    if (
      window.confirm(
        `「${project.name}」を削除しますか？\n削除すると復旧させることはできません。`
      )
    ) {
      deleteProject(project.id)
        .then(() => {
          alert("削除しました。TOPページに戻ります");
          history.push("/");
        })
        .catch(() => {
          alert("問題が発生し、削除に失敗しました");
        });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <div className={styles.container}>
        <h2 className={styles.ttl}>作品の編集</h2>
        <div className={styles.body}>
          <label className={styles.label}>タイトルの編集</label>
          <div className={styles.flex}>
            <form onSubmit={handleSubmit}>
              <input
                className={styles.input}
                value={projectName}
                onChange={handleChange}
                type="text"
                autoFocus={true}
              />
            </form>
            <div className={styles.btnArea}>
              <button className={styles.btnSave} onClick={saveProjectName}>
                保存
              </button>
            </div>
          </div>
          <label className={styles.label}>作品の削除</label>
          <div className={styles.flex}>
            <p className={styles.txt}>
              作品を削除します。
              <br className={styles.spi} />
              削除した作品は元に戻せません。
            </p>
            <div className={styles.btnArea}>
              <button
                className={styles.btnDelete}
                onClick={handleDeleteProject}
              >
                削除
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalProjectName;
