import React, { useState, useEffect, useContext, useRef } from "react";
import styles from "./Settings.module.css";
import ImgAccount from "./ImgAccount.jpg";
import { getUser, PatchUser, patchUser, postUserPhoto } from "apiv1/user";
import { AuthContext } from "components/AuthProvider";
import NotFound from "pages/NotFound";
import { SpinnerFullDisplay } from "components/Spinner";
import { getDownloadURL, getStorage, ref } from "@firebase/storage";
import { getAuth, updateProfile } from "@firebase/auth";
import DefaultLayout from "components/DefaultLayout";

const storage = getStorage();
const auth = getAuth();

const ICON_SIZE_LIMIT = 1 * 1024 * 1024; // 1 MiB
const BIO_LIMIT = 200;
const DISPLAY_NAME_LIMIT = 50;

const handleChangeInput =
  (set: (x: string) => void) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    set(event.target.value);
  };

const handleChangeTextArea =
  (set: (x: string) => void) =>
  (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    set(event.target.value);
  };

type PhotoFile = {
  file: File;
  objectURL: string;
};

const Loader = () => {
  return (
    <div className={styles.loader}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

const Settings = () => {
  const user = useContext(AuthContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const [userProfile, setUserProfile] = useState<
    "loaded" | "loading" | "notfound"
  >("loading");

  const [photoURL, setPhotoURL] = useState<string | null>(null);
  const [iconFile, setIconFile] = useState<PhotoFile>();
  const [displayName, setDisplayName] = useState("");
  const [contact, setContact] = useState("");
  const [bio, setBio] = useState("");

  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (user.authed) {
      getUser(user.uid)
        .then((res) => {
          setPhotoURL(res.data.photo_url);
          setDisplayName(res.data.display_name || "");
          setContact(res.data.contact || "");
          setBio(res.data.bio || "");
          setUserProfile("loaded");
        })
        .catch(() => setUserProfile("notfound"));
    }
  }, []);

  if (!user.authed || userProfile === "notfound") {
    return <NotFound />;
  }

  if (userProfile === "loading") {
    return <SpinnerFullDisplay />;
  }

  const submitProfile = async () => {
    const patch: PatchUser = {
      display_name: displayName,
      contact,
      bio,
    };
    if (displayName.length > DISPLAY_NAME_LIMIT) {
      alert(`名前は${DISPLAY_NAME_LIMIT}文字以下としてください`);
      return;
    }

    if (bio.length > BIO_LIMIT) {
      alert(`紹介文は${BIO_LIMIT}文字以下としてください`);
      return;
    }

    setProcessing(true);

    try {
      if (iconFile) {
        const newPhoto = await postUserPhoto(user.uid, iconFile.file);
        const url = await getDownloadURL(ref(storage, newPhoto.data.fileName));
        patch.photo_url = url;
      }

      if (auth.currentUser) {
        await updateProfile(auth.currentUser, {
          displayName: patch.display_name,
          photoURL: patch.photo_url,
        });
      }

      await patchUser(user.uid, patch);

      if (patch.photo_url) setPhotoURL(patch.photo_url);
      if (iconFile) {
        URL.revokeObjectURL(iconFile.objectURL);
        setIconFile(undefined);
      }
      alert("プロフィールを保存しました");
    } catch (err) {
      alert("保存に失敗しました");
    }

    setProcessing(false);
  };

  const selectNewIcon = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChangeIcon = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null || event.target.files.length !== 1) {
      return;
    }

    const file = event.target.files[0];
    if (!file.type.startsWith("image/")) {
      alert("アイコンには画像ファイルを指定してください");
      return;
    }

    if (file.size > ICON_SIZE_LIMIT) {
      alert("アイコンは1MB以下としてください");
      return;
    }

    if (file != null && photoURL != null) {
      URL.revokeObjectURL(photoURL);
    }

    const objectURL = URL.createObjectURL(file);
    setIconFile({ file, objectURL });
  };

  return (
    <DefaultLayout>
      <div>
        <div className={styles.section}>
          <h2 className={styles.ttl}>プロフィール設定</h2>
          <div className={styles.boxImg}>
            <div className={styles.icon}>
              <img
                src={iconFile?.objectURL || photoURL || ImgAccount}
                alt="ImgAccount"
              />
            </div>
            <button className={styles.btnImg} onClick={selectNewIcon}>
              画像を変更
            </button>
            <input
              hidden={true}
              type="file"
              ref={inputRef}
              onChange={handleChangeIcon}
            />
          </div>
          <div className={styles.box}>
            <label className={styles.labelFlex}>
              名前（ニックネーム）
              <span className={styles.spacer}></span>
              <span
                className={styles.coment}
                data-over={displayName.length > DISPLAY_NAME_LIMIT}
              >
                ({displayName.length}/{DISPLAY_NAME_LIMIT})
              </span>
            </label>
            <input
              className={styles.input}
              type="text"
              name="name"
              placeholder="テキストを入力"
              value={displayName}
              onChange={handleChangeInput(setDisplayName)}
            />
          </div>
          <div className={styles.box}>
            <label className={styles.label}>作品への問い合わせ先</label>
            <input
              className={styles.input}
              type="text"
              name="contact"
              placeholder="テキストを入力"
              value={contact}
              onChange={handleChangeInput(setContact)}
            />
            <p className={styles.note}>
              ※
              作品へのお問合せ先などとしてメールアドレスまたはURLを登録できます。
            </p>
          </div>
          <div className={styles.box}>
            <label className={styles.labelFlex}>
              紹介文
              <span className={styles.spacer}></span>
              <span
                className={styles.coment}
                data-over={bio.length > BIO_LIMIT}
              >
                ({bio.length}/{BIO_LIMIT})
              </span>
            </label>
            <textarea
              className={styles.description}
              name="description"
              rows={6}
              value={bio}
              onChange={handleChangeTextArea(setBio)}
            ></textarea>
          </div>
          {!processing ? (
            <button className={styles.btn} onClick={submitProfile}>
              プロフィールを保存
            </button>
          ) : (
            <div className={styles.loading}>
              <span>保存中</span>
              <Loader />
            </div>
          )}
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Settings;
