import React from "react";
import { formatDateHM } from "utils/format";
import styles from "./ModalGiftUrl.module.css";

type ModalGiftUrlProps = {
  title: string;
  category: string;
  thumbnail: string | null;
  orderedAt: Date;
  giftCode: string;
};

const ModalGiftUrl: React.VFC<ModalGiftUrlProps> = ({
  title,
  category,
  thumbnail,
  orderedAt,
  giftCode,
}) => {
  const date = formatDateHM(orderedAt);
  const url = window.location.origin + `/gift/receive?code=${giftCode}`;

  const copyURL = () => {
    if (!navigator.clipboard) {
      alert("クリップボードへのコピーに失敗しました");
    }

    navigator.clipboard
      .writeText(url)
      .then(() => alert("コードをクリップボードにコピーしました"))
      .catch(() => alert("クリップボードへのコピーに失敗しました"));
  };

  return (
    <div className={styles.container}>
      <h2 className={styles.ttl}>ギフトを友達に贈りましょう</h2>
      <div className={styles.box}>
        <div className={styles.flex}>
          <div className={styles.left}>
            {thumbnail ? (
              <div className={styles.img}>
                <img src={thumbnail} alt={title} />
              </div>
            ) : (
              <div className={styles.imgBlank} />
            )}
          </div>
          <div className={styles.right}>
            <p className={styles.genre}>{category}</p>
            <p className={styles.title}>{title}</p>
            <p className={styles.date}>注文日時：{date}</p>
          </div>
        </div>
        <label className={styles.label}>受取用URL</label>
        <p className={styles.url}>{url}</p>
        <button className={styles.button} onClick={copyURL}>
          ギフトURLをコピー
        </button>
      </div>
    </div>
  );
};

export default ModalGiftUrl;
