import { getPayjpTransfers, TransferInfo } from "apiv1/payjp";
import {
  getSalesOrders,
  getSalesSummary,
  Order,
  SalesSummary,
} from "apiv1/sales";
import React, { useEffect, useState } from "react";
import { WithLoad } from "utils/types";
import { Mode } from "../SalesTabMenu/SalesTabMenu";
import styles from "./SalesMainContainer.module.css";
import SalesMainOrders from "./SalesMainOrders/SalesMainOrders";
import SalesMainPayment from "./SalesMainPayment/SalesMainPayment";
import SalesMainProducts from "./SalesMainProducts/SalesMainProducts";

const FIRST_FETCH_ORDER_LIMIT = 25;

type SalesMainContainerProps = {
  uid: string;
  mode: Mode;
};

// const DUMMY: TransferInfo[] = [
//   {
//     amount: 100000,
//     carried_balance: 2000,
//     scheduled_date: "2022-1-30",
//     status: "pending",
//     term_end: "2021-12-31T12:00Z",
//     term_start: "2021-12-01T12:00Z",
//     transfer_amount: null,
//     transfer_date: null,
//   },
//   {
//     amount: 100000,
//     carried_balance: null,
//     scheduled_date: "2021-12-20",
//     status: "paid",
//     term_end: "2021-12-31T12:00Z",
//     term_start: "2021-11-01T12:00Z",
//     transfer_amount: 100000,
//     transfer_date: "2021-12-20",
//   },
//   {
//     amount: 10000,
//     carried_balance: null,
//     scheduled_date: "2022-1-30",
//     status: "failed",
//     term_end: "2021-12-31T12:00Z",
//     term_start: "2021-10-01T12:00Z",
//     transfer_amount: null,
//     transfer_date: null,
//   },
//   {
//     amount: 50000,
//     carried_balance: null,
//     scheduled_date: "2022-1-30",
//     status: "stop",
//     term_end: "2021-12-31T12:00Z",
//     term_start: "2021-09-01T12:00Z",
//     transfer_amount: null,
//     transfer_date: null,
//   },
//   {
//     amount: 30000,
//     carried_balance: null,
//     scheduled_date: "2022-1-30",
//     status: "recombination",
//     term_end: "2021-12-31T12:00Z",
//     term_start: "2021-08-01T12:00Z",
//     transfer_amount: null,
//     transfer_date: null,
//   },
//   {
//     amount: 1000,
//     carried_balance: null,
//     scheduled_date: "2022-1-30",
//     status: "carried_over",
//     term_end: "2021-12-31T12:00Z",
//     term_start: "2021-07-01T12:00Z",
//     transfer_amount: null,
//     transfer_date: null,
//   },
// ];

const SalesMainContainer: React.FC<SalesMainContainerProps> = ({
  uid,
  mode,
}) => {
  const [summaries, setSummaries] =
    useState<WithLoad<SalesSummary[]>>("loading");
  const [orders, setOrders] = useState<WithLoad<Order[]>>("loading");
  const [fetched, setFetched] = useState(false);
  const [transfers, setTransfers] =
    useState<WithLoad<TransferInfo[]>>("loading");
  useEffect(() => {
    getSalesSummary(uid)
      .then((res) => setSummaries(res.data))
      .catch(() => setSummaries("notfound"));
    getSalesOrders(uid, FIRST_FETCH_ORDER_LIMIT)
      .then((res) => {
        setFetched(res.data.length < FIRST_FETCH_ORDER_LIMIT);
        setOrders(res.data);
      })
      .catch(() => setSummaries("notfound"));

    getPayjpTransfers()
      .then((res) => setTransfers(res.data))
      .catch(() => setSummaries("notfound"));
    // setTransfers(DUMMY);
  }, []);

  const fetchAllOrders = async () => {
    const res = await getSalesOrders(uid).catch(() => setSummaries("notfound"));

    if (res) setOrders(res.data);
    setFetched(true);
  };

  switch (mode) {
    case "projects":
      return (
        <div className={styles.container}>
          <div>
            <SalesMainProducts summaries={summaries} />
          </div>
        </div>
      );
    case "orders":
      return (
        <div className={styles.container}>
          <div>
            <SalesMainOrders
              orders={orders}
              fetched={fetched}
              fetchAllOrders={fetchAllOrders}
            />
          </div>
        </div>
      );
    case "transfers":
      return (
        <div className={styles.container}>
          <div>
            <SalesMainPayment transfers={transfers} />
          </div>
        </div>
      );
  }
};

export default SalesMainContainer;
