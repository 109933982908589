import React from "react";
import Spinner from "components/Spinner";
import styles from "./LoadingImage.module.css";

const LoadingImage = () => {
  return (
    <div className={styles.loadingImage}>
      <Spinner />
    </div>
  );
};

export default LoadingImage;
