import { ModalWide } from "components/ModalWide/ModalWide";
import ModalEdit from "components/ModalEdit/ModalEdit";
import React, { useState } from "react";
import TopHeaderPC from "./TopHeaderPC/TopHeaderPC";
import TopHeaderSP from "./TopHeaderSP/TopHeaderSP";

type TopHeaderProps = {};

const TopHeader: React.FC<TopHeaderProps> = () => {
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleCloseModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    setShowModal(false);
  };

  const openUserProjectsModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <TopHeaderPC openUserProjectsModal={openUserProjectsModal}></TopHeaderPC>
      <TopHeaderSP />
      <ModalWide open={showModal} onClose={handleCloseModal}>
        <ModalEdit />
      </ModalWide>
    </>
  );
};

export default TopHeader;
