import { useState, ChangeEvent, useEffect } from "react";
import styles from "./SignUp.module.css";
import { ReactComponent as IconGoogle } from "./IconGoogle.svg";
import { ReactComponent as IconTwitter } from "./IconTwitter.svg";
import { Link, Redirect, useLocation } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  getRedirectResult,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithRedirect,
  TwitterAuthProvider,
} from "firebase/auth";
import { authErrorToJpMessage, signOut, useRedirectRef } from "utils/auth";
import { auth } from "initialize";
import { SpinnerFullDisplay } from "components/Spinner";
import { deleteIncompleteSignup, postUserSignup } from "apiv1/user";
import { getDBUserWithCache } from "components/AuthProvider";
import BtnWithProcessing from "components/Btn/BtnWithProcessing";

const createUser = async (email: string, password: string) => {
  const userCredential = await createUserWithEmailAndPassword(
    auth,
    email,
    password
  ).catch((error: unknown) => {
    window.alert(authErrorToJpMessage(error));
    return Promise.reject(error);
  });

  try {
    await postUserSignup(userCredential.user.uid);
  } catch (error) {
    await deleteIncompleteSignup(userCredential.user.uid).catch(() => {});
    window.alert(
      "アカウント作成中にエラーが発生したため、ページをリロードします。"
    );
    window.location.reload();
    return Promise.reject(error);
  }

  sendEmailVerification(userCredential.user);
};

const SignUp = () => {
  const location = useLocation();
  const redirectRef = useRedirectRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(false);

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleAgreeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAgree(event.target.checked);
  };

  const handleSubmit = async () => {
    await createUser(email, password);
    window.location.assign(redirectRef);
  };

  const handleTwitter = () => {
    signInWithRedirect(auth, new TwitterAuthProvider());
  };

  const handleGoogle = () => {
    signInWithRedirect(auth, new GoogleAuthProvider());
  };

  return (
    <div className={styles.wrap}>
      <div>
        <h2 className={styles.ttl}>新規登録</h2>
        <div className={styles.sns}>
          <button className={styles.btnGoogle} onClick={handleGoogle}>
            <IconGoogle />
            Googleで新規登録
          </button>
          <button className={styles.btnTwitter} onClick={handleTwitter}>
            <IconTwitter />
            Twitterで新規登録
          </button>
        </div>
        <p className={styles.border}>or</p>
        <label className={styles.label}>メールアドレス</label>
        <input
          className={styles.input}
          type="email"
          name="email"
          placeholder="メールアドレスを入力"
          onChange={handleEmailChange}
        />
        <label className={styles.label}>パスワード</label>
        <input
          className={styles.input}
          type="password"
          name="password"
          placeholder="パスワードを入力"
          onChange={handlePasswordChange}
        />
        <div className={styles.checkbox}>
          <input
            className={styles.agree}
            type="checkbox"
            name="agree"
            id="agree"
            onChange={handleAgreeChange}
          />
          <label className={styles.labelTerms} htmlFor="agree">
            <a href="/terms" target="_blank">
              利用規約
            </a>
            と
            <a href="/privacy" target="_blank">
              プライバシーポリシー
            </a>
            に同意する。
          </label>
        </div>
        <p className={styles.note}>
          アカウントを作成すると、
          <a href="/terms" target="_blank">
            利用規約
          </a>
          と
          <a href="/privacy" target="_blank">
            プライバシーポリシー
          </a>
          に同意したことになります。
        </p>
        <BtnWithProcessing
          disabled={!agree}
          onClick={handleSubmit}
          labelProsessing="作成中"
        >
          アカウントを作成
        </BtnWithProcessing>
        <p className={styles.login}>
          <Link to={{ pathname: "/login", search: location.search }}>
            ログイン
          </Link>
          はこちら
        </p>
      </div>
    </div>
  );
};

const SignUpContainer = () => {
  const redirectRef = useRedirectRef();
  const [signupState, setSignupState] = useState<SignupStatus | "loading">(
    "loading"
  );
  useEffect(() => {
    checkSignup()
      .then((state) => setSignupState(state))
      .catch(() => {
        window.alert(
          "ログイン処理でエラーが発生しました。ページをリロードします。"
        );
        signOut().finally(window.location.reload);
      });
  }, []);

  if (signupState === "loading") {
    return <SpinnerFullDisplay />;
  }

  if (signupState === "authed") {
    return <Redirect to={redirectRef} />;
  }

  return <SignUp />;
};

type SignupStatus = "noauth" | "authed";

const checkSignup = async (): Promise<SignupStatus> => {
  if (auth.currentUser == null) {
    return "noauth";
  }

  const hasDBUser = await getDBUserWithCache(auth.currentUser.uid);
  if (hasDBUser) {
    return "authed";
  }

  const result = await getRedirectResult(auth);
  if (result != null) {
    await postUserSignup(auth.currentUser.uid);
    window.location.reload();
    return "authed";
  }

  await deleteIncompleteSignup(auth.currentUser.uid);
  window.location.reload();
  return "noauth";
};

export default SignUpContainer;
