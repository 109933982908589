import React, { useState } from "react";
import styles from "./ItemDescription.module.css";
import { Link } from "react-router-dom";
import BlankThumbnail from "./BlankThumbnail";
import { Visibility } from "apiv1/project";

type ItemDescriptionProps = {
  author: string;
  userName: string | null;
  ownerId: string;
  thumbnail: string | null;
  categoryName: string;
  categorySlug: string;
  categoryId: number;
  title: string;
  views: number;
  playerMin: number | null;
  playerMax: number | null;
  playtimeMin: number | null;
  playtimeMax: number | null;
  tags: string[];
  description: string;
  visibility: Visibility;
};

const ItemDescription: React.VFC<ItemDescriptionProps> = (props) => {
  const ownerNameFilterd =
    props.userName == null || props.userName.length === 0
      ? "頒布元"
      : props.userName;
  const profileURL = `/users/${props.ownerId}`;

  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <p className={styles.writer}>
          {props.author} / <Link to={profileURL}>{ownerNameFilterd}</Link>
        </p>
        <ul className={styles.publish}>
          <TagVisibility visibility={props.visibility} />
        </ul>
      </div>
      {props.thumbnail ? (
        <div className={styles.img}>
          <img src={props.thumbnail} alt={props.title} />
        </div>
      ) : (
        <BlankThumbnail title={props.title} />
      )}
      <p className={styles.genre}>
        <Link to={`/c/${props.categorySlug}`}>{props.categoryName}</Link>
      </p>
      <h1 className={styles.ttl}>{props.title}</h1>
      <ul className={styles.summary}>
        <li>
          閲覧数：<span>{props.views}</span>
        </li>
        <li>
          プレイ人数：
          <Players playerMin={props.playerMin} playerMax={props.playerMax} />
        </li>
        <li>
          予定プレイ時間：
          <Playtime playtime={props.playtimeMax} />
        </li>
      </ul>
      <ul className={styles.tag}>
        {props.tags.map((tag) => {
          const params = new URLSearchParams();
          params.append("q", tag);
          return (
            <li key={tag}>
              <Link to={`/search?${params.toString()}`} target="_blank">
                #{tag}
              </Link>
            </li>
          );
        })}
      </ul>
      <DescriptionText>{props.description}</DescriptionText>
    </div>
  );
};

type PlayersProps = {
  playerMin: number | null;
  playerMax: number | null;
};

const Players: React.VFC<PlayersProps> = ({ playerMin, playerMax }) => {
  if (playerMin == null || playerMax == null) {
    const players = playerMin || playerMax;
    if (players == null || players <= 0) {
      return <>未設定</>;
    }

    if (players >= 99) {
      return <>制限なし</>;
    }

    if (players >= 10) {
      return (
        <>
          <span>10</span>人以上
        </>
      );
    }

    return (
      <>
        <span>{players}</span>人
      </>
    );
  }

  if (playerMin < 1 || playerMax < 1) {
    return <>未設定</>;
  }

  if (playerMin >= 10) {
    return (
      <>
        <span>10</span>人以上
      </>
    );
  }

  if (playerMax === 1) {
    return (
      <>
        <span>1</span>人
      </>
    );
  }

  if (playerMin === playerMax) {
    return (
      <>
        <span>{playerMin}</span>人
      </>
    );
  }

  if (playerMax === 10) {
    return (
      <>
        <span>
          {playerMin}〜{playerMax}
        </span>
        人以上
      </>
    );
  }

  if (playerMax === 99) {
    return (
      <>
        <span>{playerMin}</span>
        人〜制限無し
      </>
    );
  }

  return (
    <>
      <span>
        {playerMin}〜{playerMax}
      </span>
      人
    </>
  );
};

type PlaytimeProps = {
  playtime: number | null;
};

const Playtime: React.VFC<PlaytimeProps> = ({ playtime }) => {
  if (playtime == null) {
    return <>未設定</>;
  } else if (playtime === 1) {
    return (
      <>
        <span>1</span>時間以内
      </>
    );
  } else if (playtime >= 8) {
    return (
      <>
        <span>8</span>時間以上
      </>
    );
  } else {
    return (
      <>
        <span>{playtime}</span>時間
      </>
    );
  }
};

const DescriptionText: React.FC = ({ children }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  if (children == null) {
    return null;
  }

  return (
    <>
      <p className={styles.description} data-open={open}>
        {children}
      </p>
      <button className={styles.more} onClick={toggle}>
        {open ? "シナリオ概要を閉じる" : "もっと見る"}
      </button>
    </>
  );
};

type TagVisibilityProps = {
  visibility: Visibility;
};

const TagVisibility = ({ visibility }: TagVisibilityProps) => {
  switch (visibility) {
    case "closed":
      return <li className={styles.private}>自分だけ公開</li>;
    case "private":
      return <li className={styles.limited}>限定公開</li>;
  }

  return null;
};

export default React.memo(ItemDescription);
