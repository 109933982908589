import React from "react";
import styles from "./BtnTwitter.module.css";
import { ReactComponent as IconTwitter } from "./IconTwitter.svg";

const BtnTwitter = () => {
  return (
    <div>
      <button className={styles.btnTwitter}>
        <IconTwitter></IconTwitter>ツイートする
      </button>
    </div>
  );
};

export default BtnTwitter;
