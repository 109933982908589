import { SaveableBooklet } from "pages/Editor/EditorProvider";
import React from "react";
import SidebarBooklet from "./SidebarBooklet";
import styles from "./ViewerSidebar.module.css";

type ViewerSidebarProps = {
  booklets: SaveableBooklet[];
  currentBookletId: string;
  setCurrentBookletId: (id: string) => void;
  preview: boolean;
  show: boolean;
  closeIndex: () => void;
};

const ViewerSidebar: React.FC<ViewerSidebarProps> = ({
  booklets,
  currentBookletId,
  setCurrentBookletId,
  show,
  closeIndex,
}) => {
  const handleOverlay = (event: React.MouseEvent) => {
    event.preventDefault();
    closeIndex();
  };

  const changeBooklet = (id: string, hash?: string) => {
    setCurrentBookletId(id);
    if (hash) {
      setTimeout(() => {
        const el = document.getElementById(hash);
        if (el != null) {
          const top = el.getBoundingClientRect().top + window.scrollY;
          window.scrollTo({ top: top - 40 - 24 });
        }
      }, 0);
    }
  };

  return (
    <>
      <div className={styles.container} data-open={show}>
        <p className={styles.ttl}>目次</p>
        {booklets.map((booklet) => (
          <SidebarBooklet
            booklet={booklet}
            closeIndex={closeIndex}
            changeBooklet={changeBooklet}
            currentBookletId={currentBookletId}
          />
        ))}
      </div>
      <div
        className={styles.overlay}
        data-open={show}
        onClick={handleOverlay}
      />
    </>
  );
};

export default ViewerSidebar;
