import { deleteProject, patchProject } from "apiv1/project";
import { projectIdSelector, projectNameSelector } from "pages/Editor/selector";
import { FormEvent, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { openSettingsState } from "../../recoil/atom";
import Modal from "../Modal";
import "./Settings.css";

const Settings = () => {
  const [open, setOpen] = useRecoilState(openSettingsState);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <h1 className="Settings__heading">設定</h1>
      <SectionEditTitle />
      <SectionDelete />
    </Modal>
  );
};

const SectionEditTitle = () => {
  const projectId = useRecoilValue(projectIdSelector);
  const [projectName, setProjectName] = useRecoilState(projectNameSelector);
  const ref = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const onSubmitProjectName = (event: FormEvent) => {
    event.preventDefault();

    if (ref.current == null) {
      return;
    }

    const newProjectName = ref.current.value;
    if (projectName === newProjectName) {
      return;
    }

    if (newProjectName.length === 0) {
      window.alert("作品タイトルは一文字以上としてください");
      return;
    }

    patchProject(projectId, { name: newProjectName })
      .then(() => {
        setProjectName(newProjectName);
        window.alert("作品タイトルを変更しました");
      })
      .catch(() =>
        window.alert(
          "サーバーとの通信に失敗したため、プロジェクト名が変更できませんでした。"
        )
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="Settings__section">
      <h2 className="Settings__sectionHeading">タイトルの編集</h2>
      <form className="Settings__combineForm" onSubmit={onSubmitProjectName}>
        <input
          className="Settings__input"
          defaultValue={projectName}
          ref={ref}
          disabled={loading}
        />
        <button
          className="Settings__buttonSave"
          disabled={loading}
          type="submit"
        >
          保存
        </button>
      </form>
    </div>
  );
};

const SectionDelete = () => {
  const history = useHistory();

  const handleDeleteProject = useRecoilCallback(
    ({ snapshot }) =>
      async () => {
        const projectName = await snapshot.getPromise(projectNameSelector);
        const projectId = await snapshot.getPromise(projectIdSelector);
        if (
          !window.confirm(
            `「${projectName}」を削除しますか？\n削除すると復旧させることはできません。`
          )
        ) {
          return;
        }

        deleteProject(projectId)
          .then(() => {
            window.alert("削除しました。TOPページに戻ります");
            history.push("/");
          })
          .catch(() => {
            window.alert("問題が発生し、削除に失敗しました");
          });
      },
    [history]
  );

  return (
    <div className="Settings__section">
      <h2 className="Settings__sectionHeading">作品の削除</h2>
      <div className="Settings__buttonSection">
        <p className="Settings__buttonSection_note">
          作品を削除します。削除した作品は元に戻せません。
        </p>
        <button
          className="Settings__buttonDelete"
          onClick={handleDeleteProject}
        >
          削除
        </button>
      </div>
    </div>
  );
};

export default Settings;
