import React, { useContext, useEffect, useState } from "react";
import styles from "./Orders.module.css";
import Order, { OrdersProps } from "./Order";
import { AuthContext } from "components/AuthProvider";
import NotFound from "pages/NotFound";
import { getUserOrders, getUserReceivedGifts } from "apiv1/user";
import { WithLoad } from "utils/types";
import { SpinnerCenter } from "components/Spinner";
import DefaultLayout from "components/DefaultLayout";

type OrderListProps = {
  uid: string;
};

type OrderCard = OrdersProps & {
  key: string;
};

const desc = (a: OrderCard, b: OrderCard): number => {
  if (a.purchasesedAt < b.purchasesedAt) {
    return 1;
  } else if (b.purchasesedAt < a.purchasesedAt) {
    return -1;
  } else {
    return 0;
  }
};

const OrderList: React.FC<OrderListProps> = ({ uid }) => {
  const [orders, setOrders] = useState<WithLoad<OrderCard[]>>("loading");
  useEffect(() => {
    Promise.all([getUserOrders(uid), getUserReceivedGifts(uid)])
      .then(([resOrder, resGift]) => {
        const ordersOrders = resOrder.data.map((orderInfo): OrderCard => {
          return {
            key: "order-" + orderInfo.order_id,
            kind: "order",
            price: orderInfo.price,
            categoryName: orderInfo.category_name,
            projectName: orderInfo.project_name,
            orderId: orderInfo.order_id,
            couponId: orderInfo.coupon_id,
            giftId: orderInfo.gift_id,
            received: orderInfo.gift_received_at != null,
            purchasesedAt: orderInfo.captured_at,
            thumnail: orderInfo.cover_url,
          };
        });

        const ordersGift = resGift.data.map((orderInfo): OrderCard => {
          return {
            key: "gift-" + orderInfo.gift_id,
            kind: "receivedGift",
            price: 0,
            categoryName: orderInfo.category_name,
            projectName: orderInfo.project_name,
            orderId: orderInfo.gift_id,
            couponId: null,
            giftId: null,
            received: false,
            purchasesedAt: orderInfo.received_at,
            thumnail: orderInfo.cover_url,
          };
        });

        const orders = ordersOrders.concat(ordersGift).sort(desc);
        setOrders(orders);
      })
      .catch(() => setOrders("notfound"));
  }, []);

  if (orders === "notfound") {
    return (
      <p className={styles.notice}>
        購入履歴の取得に失敗しました。ネットワーク接続が正常かご確認ください。
      </p>
    );
  }

  if (orders === "loading") {
    return <SpinnerCenter />;
  }

  if (orders.length === 0) {
    return <p className={styles.notice}>購入したシナリオはありません。</p>;
  }

  return (
    <ul className={styles.list}>
      {orders.map((order) => (
        <Order {...order} />
      ))}
    </ul>
  );
};

const Orders = () => {
  const user = useContext(AuthContext);

  if (!user.authed) return <NotFound />;

  return (
    <DefaultLayout>
      <div className={styles.section}>
        <h2 className={styles.ttl}>購入履歴</h2>
        <OrderList uid={user.uid} />
      </div>
    </DefaultLayout>
  );
};

export default Orders;
