import React, { useState, useRef, useEffect } from "react";
import styles from "./Help.module.css";
import ImgEditorTool from "./ImgEditorTool.jpg";
import ImgGear from "./ImgGear.jpg";
import ImgBooklet from "./ImgBooklet.jpg";
import ImgAutoSave from "./ImgAutoSave.jpg";
import ImgTitleModal from "./ImgTitleModal.jpg";
import ImgAttachment from "./ImgAttachment.jpg";
import ImgGift01 from "./ImgGift01.jpg";
import ImgGift02 from "./ImgGift02.jpg";
import ImgGift03 from "./ImgGift03.jpg";
import ImgGift04 from "./ImgGift04.jpg";
import ImgGift05 from "./ImgGift05.jpg";
import ImgGift06 from "./ImgGift06.jpg";
import ImgGift07 from "./ImgGift07.jpg";
import ImgTicket01 from "./ImgTicket01.jpg";
import ImgTicket02 from "./ImgTicket02.jpg";
import ImgTicket03 from "./ImgTicket03.jpg";
import ImgTicket04 from "./ImgTicket04.jpg";
import { ReactComponent as IconTargetBlank } from "./IconTargetBlank.svg";

type AccordionProps = {
  title: string;
  name: string;
};

const Accordion: React.FC<AccordionProps> = ({ title, name, children }) => {
  const [hidden, setHidden] = useState(true);

  const toggleHidden = () => {
    setHidden(!hidden);
  };

  const targetRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (window.location.hash === `#${name}`) {
      setHidden(false);
      targetRef.current?.scrollIntoView();
    }
  }, [name, targetRef, setHidden]);

  return (
    <li className={styles.item} id={name} ref={targetRef}>
      <h3 className={styles.btn} onClick={toggleHidden} data-open={!hidden}>
        {title}
      </h3>
      <div className={styles.toggle} hidden={hidden}>
        {children}
      </div>
    </li>
  );
};

type HeadingProps = React.HTMLAttributes<HTMLHeadingElement>;

const H2WithFocus: React.FC<HeadingProps> = ({ children, ...props }) => {
  const targetRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (targetRef.current != null && window.location.hash === `#${props.id}`) {
      targetRef.current.scrollIntoView();
    }
  }, [targetRef.current, props.id]);

  return (
    <h2 {...props} ref={targetRef}>
      {children}
    </h2>
  );
};

const Help: React.VFC = () => {
  return (
    <div className={styles.container}>
      <h1 className={styles.ttl}>ヘルプ</h1>
      <p className={styles.lead}>
        TALTOの使い方やよくある質問などをまとめています。
      </p>
      <div>
        <h2 className={styles.category}>アカウントについて</h2>
        <ul className={styles.list}>
          <Accordion
            title="アカウントを作成したら何ができますか？"
            name="what_is_account"
          >
            <p>アカウントを作成すると、以下のことが行えるようになります。</p>
            <ul>
              <li>シナリオを執筆・投稿できる</li>
              <li>プロフィールが設定できる</li>
              <li>シナリオの購入ができる</li>
              <li>シナリオの有料頒布ができる（販売者情報の登録が必要）</li>
              <li>自分の本棚にシナリオをブックマークできる</li>
            </ul>
          </Accordion>
        </ul>
        <h2 className={styles.category}>エディター</h2>
        <ul className={styles.list}>
          <Accordion title="作品名の変更について" name="how_to_title">
            <p>
              サイドバー上部の「新しいシナリオ」横の歯車アイコンをクリックします。（スマートフォンの場合は上部の矢印アイコンをクリックしてサイドバーを表示できます。）
            </p>
            <p>
              <img src={ImgGear} alt="ImgGear" />
            </p>
            <p>
              <img src={ImgTitleModal} alt="ImgTitleModal" />
            </p>
            <p>作品名を変更して保存ボタンを押してください。</p>
          </Accordion>
          <Accordion title="ツールバーについて" name="how_to_title">
            <p>
              エディターの上部に表示されているツールバー（ヘッダーメニュー左側）をクリックすることで、本文を編集できます。
            </p>
            <p>
              <img src={ImgEditorTool} alt="ImgEditorTool" />
            </p>
            <h3 className={styles.sub}>アイコンの説明</h3>
            <p className={styles.indent}>
              H1 = 見出し1
              <br />
              H2 = 見出し2
              <br />
              H3 = 見出し3
              <br />
              ※ = 注釈エリア
              <br />B = 太字
              <br />U = 下線
              <br />
              画像 = 画像の挿入
            </p>
          </Accordion>
          <Accordion title="ショートカット機能について" name="how_to_title">
            <p>ショートカット機能で、見出しや太字への変更ができます。</p>
            <h3 className={styles.sub}>見出し1</h3>
            <p className={styles.indent}>
              Windows：Ctrl + 1<br />
              Mac：command ⌘ + 1
            </p>
            <h3 className={styles.sub}>見出し2</h3>
            <p className={styles.indent}>
              Windows：Ctrl + 2<br />
              Mac：command ⌘ + 2
            </p>
            <h3 className={styles.sub}>見出し3</h3>
            <p className={styles.indent}>
              Windows：Ctrl + 3<br />
              Mac：command ⌘ + 3
            </p>
            <h3 className={styles.sub}>太字</h3>
            <p className={styles.indent}>
              Windows：Ctrl + B<br />
              Mac：command ⌘ + B
            </p>
            <h3 className={styles.sub}>下線</h3>
            <p className={styles.indent}>
              Windows：Ctrl + U<br />
              Mac：command ⌘ + U
            </p>
          </Accordion>
          <Accordion title="冊子について" name="how_to_titleList">
            <p>実際の作品ページでは冊子ごとにページが区切られます。</p>
            <p>
              これによってゲームマスター用の情報と、プレイヤー用の情報を分けて表示（URLの共有）が可能です。
              <br />
              例えば、秘匿内容を一部のプレイヤーのみに配布したい場合などに、この冊子の共有機能を使用することで、セッション準備や進行の負担を減らすことが出来ます。
            </p>
          </Accordion>
          <Accordion
            title="冊子の追加・削除・入れ替えについて"
            name="how_to_libretto"
          >
            <p>
              冊子の追加・削除・入れ替えなどは、エディット画面左のサイドメニュー内（「冊子」タブ選択中）で行えます。
            </p>
            <img src={ImgBooklet} alt="冊子" />
            <h3 className={styles.sub}>冊子の追加</h3>
            <p className={styles.indent}>
              「+新しい冊子を追加」ボタンで、冊子を追加できます。
            </p>
            <h3 className={styles.sub}>冊子の削除</h3>
            <p className={styles.indent}>
              サイドメニュー内の冊子名の右に表示されるゴミ箱アイコンから削除できます。
            </p>
            <h3 className={styles.sub}>冊子の入れ替え</h3>
            <p className={styles.indent}>
              サイドメニュー内の冊子名の右に表示される矢印アイコンから、冊子を上下に入れ替えることができます。
            </p>
          </Accordion>
          <Accordion title="目次について" name="how_to_titleList">
            <p>
              追加した「H1」または「H2」がそのまま自動的に目次として反映されます。
            </p>
            <p>「H3」は目次に反映されません。</p>
          </Accordion>
          <Accordion title="本文中の画像について" name="how_to_image">
            <p>
              エディットエリア内の追加したい箇所にドラッグ&amp;ドロップで追加できます。
            </p>
          </Accordion>
          <Accordion title="自動保存について" name="how_to_save">
            <p>
              TALTOのエディターは自動で保存を行います。
              <br />
              保存されたかどうか不安な場合は、エディター下の「最終保存」の日付の横に、紫色のチェックマークが付いているか確認してください。チェックマークが付いていれば、無事に保存が完了しています。
            </p>
            <p>
              <img src={ImgAutoSave} alt="ImgAutoSave" />
            </p>
            <p className={styles.note}>
              ※
              自動保存はあくまで下書き保存された状態なので、すでに公開された作品に更新した内容を反映したい場合などは「公開」を行ってください。
            </p>
          </Accordion>
          <Accordion title="添付ファイルについて" name="how_to_release">
            <p>
              添付ファイルを追加するには「公開」ボタンを押した後、公開設定画面にて以下のエリアから追加できます。
            </p>
            <img src={ImgAttachment} alt="ImgAttachment" />
            <p>
              ファイルアップローダーにファイル選択またはファイルをドラッグ＆ドロップで添付ファイルを追加できます。
            </p>
            <p className={styles.note}>
              ※
              添付ファイル管理で変更した内容は、設定中の公開範囲に関係なく、作品ページにすぐに反映されます。
              <br />
              （添付ファイルを削除・追加した場合、「公開する」ボタンを押さなくても、作品詳細ページから削除・追加されます。）
            </p>
          </Accordion>
          <Accordion title="公開設定について" name="how_to_release">
            <p>公開範囲には3種類あります。</p>
            <h3 className={styles.sub}>全体に公開</h3>
            <p className={styles.indent}>
              TALTOを使用するすべての人が閲覧できます。
            </p>
            <h3 className={styles.sub}>限定公開</h3>
            <p className={styles.indent}>
              シナリオのURLを共有された一部の人しか閲覧することができません。
              <br />
              また、限定公開に設定したシナリオは、TALTOのトップページや検索結果に表示されません。
            </p>
            <h3 className={styles.sub}>自分だけ公開</h3>
            <p className={styles.indent}>
              シナリオの執筆者（本人）以外が閲覧することはできません。URLの共有もできません。
            </p>
          </Accordion>
          <Accordion title="頒布形式について" name="how_to_release">
            <p>頒布形式には3種類あります。</p>
            <h3 className={styles.sub}>無料</h3>
            <p className={styles.indent}>
              シナリオ本文・添付ファイル含めたすべてが無料で頒布されます。
            </p>
            <h3 className={styles.sub}>添付ファイルのみ有料</h3>
            <p className={styles.indent}>
              シナリオ本文は無料で公開し、添付ファイルのみ有料で頒布されます。
            </p>
            <h3 className={styles.sub}>有料</h3>
            <p className={styles.indent}>
              シナリオ本文・添付ファイル含めたすべてが有料で頒布されます。
            </p>
          </Accordion>
          <Accordion title="作品の削除について" name="help1">
            <p>
              サイドバー上部の「新しいシナリオ」横の歯車アイコンをクリックします。（スマートフォンの場合は上部の矢印アイコンをクリックしてサイドバーを表示できます。）
            </p>
            <p>
              <img src={ImgGear} alt="ImgGear" />
            </p>
            <p>
              <img src={ImgTitleModal} alt="ImgTitleModal" />
            </p>
            <p>削除ボタンから削除が可能です。</p>
            <p className={styles.note}>
              ※ 削除した作品は元に戻すことはできません。ご注意ください。
            </p>
          </Accordion>
        </ul>
        <h2 className={styles.category}>作品の販売について</h2>
        <ul className={styles.list}>
          <Accordion title="注意事項" name="help2">
            <p>
              作品販売に関する注意事項について詳しくは以下のページをご覧ください。
            </p>
            <h3 className={styles.sub}>作品販売に関する注意事項</h3>
            <p className={styles.indent}>
              <a className={styles.link} href="/sales-terms" target="_blank">
                https://talto.cc/sales-terms
              </a>
            </p>
          </Accordion>
          <Accordion title="販売許諾番号について" name="help3">
            <p>販売許諾番号について詳しくは以下のページをご覧ください。</p>
            <h3 className={styles.sub}>販売許諾番号とは</h3>
            <p className={styles.indent}>
              <a
                className={styles.link}
                href="/licenseid-terms"
                target="_blank"
              >
                https://talto.cc/licenseid-terms
              </a>
            </p>
          </Accordion>
          <Accordion
            title="TALTOで公式応援プログラムについて"
            name="how_to_title"
          >
            <p>
              TALTOで公式応援プログラムについて詳しくは以下のページをご覧ください。
            </p>
            <h3 className={styles.sub}>TALTOで公式応援プログラムとは</h3>
            <p className={styles.indent}>
              <a
                className={styles.link}
                href="/supporter-program"
                target="_blank"
              >
                https://talto.cc/supporter-program
              </a>
            </p>
          </Accordion>
        </ul>
        <H2WithFocus id="gift" className={styles.category}>
          ギフトについて
        </H2WithFocus>
        <ul className={styles.list}>
          <Accordion title="ギフトとして購入する方法" name="how_to_buy_a_gift">
            <p>
              <img src={ImgGift01} alt="ImgGift01" />
            </p>
            <p>
              シナリオを購入する際に、『ギフトとして購入する』というボタンからギフト購入が可能です。
            </p>
          </Accordion>
          <Accordion title="ギフトの贈り方" name="how_to_send_a_gift">
            <p>
              <img src={ImgGift02} alt="ImgGift02" />
            </p>
            <p>
              ギフトを贈るにはシナリオ購入後に発行される『ギフトURL』を贈りたい相手に渡してください。ギフトURLは購入後に表示される画面でコピーすることができます。
            </p>
            <p className={styles.note}>
              ※『ギフトURL』の有効期限はありません。
            </p>
          </Accordion>
          <Accordion title="ギフトの受け取り方" name="how_to_receive_a_gift">
            <p>
              <img src={ImgGift03} alt="ImgGift03" />
            </p>
            <p>
              ギフトURLを受け取った方は、URL先に表示されたシナリオをご確認の上『ギフトを受け取る』ボタンをクリックしてください。受け取ったギフトは本棚の『購入した作品』の中に登録されます。
            </p>
          </Accordion>
          <Accordion title="ギフトを受け取れない場合" name="used_gift">
            <p>
              <img src={ImgGift04} alt="ImgGift04" />
            </p>
            <p>
              受け取りが発生するまでは有効です。すでにそのURLが使用されている（ギフトが受け取られている）場合、URLの受け取り機能は失われますので、贈り間違い等にはご注意ください。
            </p>
            <p className={styles.note}>
              ※ギフトが受け取られなかった場合についても、返金対応は致しかねますので予めご了承ください。
            </p>
          </Accordion>
          <Accordion title="ギフトの購入・受取履歴" name="gift_buying_history">
            <p>ギフトの購入・受取は『購入履歴』から確認することができます。</p>
            <p>ギフトURLを忘れた場合もこちらから確認していただけます。</p>
            <h3 className={styles.sub}>未使用の場合</h3>
            <p className={styles.indent}>
              <img src={ImgGift05} alt="ImgGift05" />
            </p>
            <h3 className={styles.sub}>使用済の場合</h3>
            <p className={styles.indent}>
              <img src={ImgGift06} alt="ImgGift06" />
            </p>
            <h3 className={styles.sub}>受け取った方の場合</h3>
            <p className={styles.indent}>
              <img src={ImgGift07} alt="ImgGift07" />
            </p>
          </Accordion>
        </ul>
        <h2 className={styles.category}>シナチケについて</h2>
        <ul className={styles.list}>
          <Accordion
            title="シナチケの発行について"
            name="what_is_scenario_ticket"
          >
            <p>シナチケはシナチケ管理ページで発行することができます。</p>
            <h3 className={styles.sub}>シナチケ管理を開く</h3>
            <p className={styles.indent}>
              <p>シナチケ管理ページはメニューから移動できます。</p>
              <img src={ImgTicket01} alt="ImgTicket01" />
            </p>
            <h3 className={styles.sub}>シナチケの作成</h3>
            <p className={styles.indent}>
              <img src={ImgTicket02} alt="ImgTicket02" />
              <p>
                『シナチケを作成』ボタンを押すと、発行手続を行えます。発行するシナチケの設定をそれぞれ選択・記入してください。
              </p>
            </p>
            <h3 className={styles.sub}>シナチケの種類</h3>
            <p className={styles.indent}>
              <p>シナチケには『無料配布』と『値引き』の2種類があります。</p>
              <p>
                値引きするシナチケを発行する際、値引きする価格をシナリオの価格以下に設定することはできません。
              </p>
            </p>
            <h3 className={styles.sub}>シナチケの配布</h3>
            <p className={styles.indent}>
              <img src={ImgTicket03} alt="ImgTicket03" />
              <p>
                シナチケの作成が完了すると、発行したシナチケの一覧に、先ほど作成したシナチケが表示されます。
              </p>
              <p>
                一覧の一番左『コード』の行に表示されているシナチケコードを購入者の方が使用すると、設定されたチケットの値引き等を利用することができます。
              </p>
            </p>
          </Accordion>
          <Accordion title="シナチケの使い方" name="how_to_use_scenario_ticket">
            <p>
              <img src={ImgTicket04} alt="ImgTicket04" />
            </p>
            <p>
              シナチケは決済画面の『シナチケを使用』というテキストボタンからご利用いただけます。お手持ちのシナチケコードを適用していただいた上で、購入するボタンをクリックしてください。
            </p>
            <p className={styles.note}>
              ※『適用』ボタンを押さずに購入した場合、シナチケを使用しなかった扱いとなりますのでご注意ください。
            </p>
          </Accordion>
          <Accordion
            title="シナチケを無効にする"
            name="how_to_revoke_scenario_ticket"
          >
            <p>
              現在発行中のシナチケを無効にする場合は『シナチケ管理』から『無効にする』ボタンをクリックしてください。
            </p>
            <p>
              一度無効化するとそのシナチケのコードは使用できなくなります。再び同じ内容のシナチケを発行したい場合は、同じ内容のシナチケを新しく作成してください。
            </p>
          </Accordion>
        </ul>
        <h2 className={styles.category}>ジャンルの開設について</h2>
        <div className={styles.inner}>
          <p>
            以下のDiscordサーバーより、ジャンルの開設をご依頼いただくようお願いします。
          </p>
          <p className={styles.note}>
            ※ ご依頼後、確認や作業にお時間をいただく場合がございます。
          </p>
          <h3 className={styles.sub}>TALTO ヘルプサーバー</h3>
          <p className={styles.indent}>
            <a
              className={styles.link}
              href="https://discord.gg/VG4n8zqyNf"
              target="_blank"
              rel="noreferrer"
            >
              https://discord.gg/VG4n8zqyNf<IconTargetBlank></IconTargetBlank>
            </a>
          </p>
        </div>
        <h2 className={styles.category}>支払い方法について</h2>
        <div className={styles.inner}>
          <p>
            支払い方法はクレジットカードのみとなります。
            <br />
            また、クレジットカードをお持ちでない方は、{" "}
            <a href="https://www.kyash.co/" target="_blank" rel="noreferrer">
              Kyash
            </a>
            を使ってお支払いすることも可能です。
          </p>
        </div>
        <h2 className={styles.category}>キャンセル・返金処理について</h2>
        <div className={styles.inner}>
          <p>
            TALTOでは決済の確定度、注文のキャンセル・返金をすることはできません。
            <br />
            返金は販売者から購入者に対し、直接行なっていただく必要がございます。
            <br />
            返金額や返金方法等を含め、購入者とご相談ください。
          </p>
        </div>
      </div>
    </div>
  );
};

export default Help;
