import React, { useState, useCallback } from "react";
import styles from "./ViewerHeader.module.css";
import { ReactComponent as IconAroowRight } from "./IconAroowRight.svg";
import { ReactComponent as IconAroowLeft } from "./IconAroowLeft.svg";
import { Project } from "apiv1/project";
import { SaveableBooklet } from "pages/Editor/EditorProvider";
import { ReleaseDataV1 } from "apiv1/release";
import ModalShare from "../ModalShare";

const formatDate = (day: string) => {
  const d = new Date(Date.parse(day));
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const hour = d.getHours().toString().padStart(2, "0");
  const min = d.getMinutes().toString().padStart(2, "0");
  return `${year}年${month}月${date}日 ${hour}:${min}`;
};

type ViewerHeaderProps = {
  project: Project;
  release: ReleaseDataV1;
  booklet: SaveableBooklet;
  showIndex: boolean;
  toggleShowIndex: (event: React.MouseEvent) => void;
  shareBtn: boolean;
};

const ViewerHeader: React.FC<ViewerHeaderProps> = ({
  project,
  release,
  showIndex,
  toggleShowIndex,
  shareBtn,
}) => {
  const icon = showIndex ? <IconAroowLeft /> : <IconAroowRight />;
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const freeBody = project.fee_type !== "all_contents";
  const showShareButton = shareBtn && freeBody;

  return (
    <div className={styles.container}>
      <div className={styles.scenario}>
        <p className={styles.version}>{formatDate(release.created_at)}</p>
      </div>
      <div className={styles.open}>
        <p className={styles.btnToggle} onClick={toggleShowIndex}>
          {icon}目次
        </p>
      </div>
      <div className={styles.booklet}>
        <h1 className={styles.ttl}>{project.name}</h1>
      </div>
      <div>
        {showShareButton && (
          <>
            <button className={styles.btnShare} onClick={handleOpenModal}>
              共有URLを発行
            </button>
            <ModalShare
              projectName={project.name}
              projectId={project.id}
              rev={release.rev}
              booklets={release.booklets}
              open={openModal}
              needToken={!freeBody}
              onClose={handleCloseModal}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ViewerHeader;
