import React from "react";
import styles from "./ModalItemAttachment.module.css";
import { ReactComponent as IconDownload } from "./IconDownload.svg";
import { Attachment } from "apiv1/project";
import Modal from "components/Modal";
import { getSignedUrlForDownloadingAttachment } from "apiv1/attachment";
import streamSaver from "streamsaver";

streamSaver.mitm = "/streamserver/mitm.html?version=2.0.0";

type ModalItemAttachmentProps = {
  open: boolean;
  onClose: () => void;
  projectId: string;
  attachments: Attachment[];
};

const ModalItemAttachment: React.VFC<ModalItemAttachmentProps> = ({
  open,
  onClose,
  projectId,
  attachments,
}) => {
  if (attachments.length === 0) {
    return null;
  }

  return (
    <Modal open={open} onClose={onClose} title="ファイル一覧">
      <div className={styles.box}>
        <ul className={styles.list}>
          {attachments.map((a) => {
            return <Row key={a.name} projectId={projectId} name={a.name} />;
          })}
        </ul>
      </div>
    </Modal>
  );
};

const saveAs = (url: string, name: string) => {
  const fileStream = streamSaver.createWriteStream(name);

  fetch(url).then((res) => {
    const readableStream = res.body;
    if (readableStream == null) {
      window.alert("ファイルのダウンロードに失敗しました");
      return;
    }

    // more optimized
    if (window.WritableStream && readableStream.pipeTo) {
      return readableStream.pipeTo(fileStream).then(() => {});
    }

    const writer = fileStream.getWriter();

    const reader = readableStream.getReader();
    const pump = () => {
      reader
        .read()
        .then((res) =>
          res.done ? writer.close() : writer.write(res.value).then(pump)
        );
    };

    pump();
  });
};

type RowProps = {
  projectId: string;
  name: string;
};

const Row: React.VFC<RowProps> = ({ projectId, name }) => {
  const handleDownload = () => {
    getSignedUrlForDownloadingAttachment(projectId, name)
      .then((res) => {
        saveAs(res.data.url, name);
      })
      .catch(() => alert("ダウンロードURLの取得に失敗しました"));
  };

  return (
    <li className={styles.item}>
      <p className={styles.name}>{name}</p>
      <button className={styles.button} onClick={handleDownload}>
        <IconDownload />
        ダウンロード
      </button>
    </li>
  );
};

export default React.memo(ModalItemAttachment);
