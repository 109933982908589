import React from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./Card.module.css";
import blank from "components/ItemCard/blank.svg";
import { FeeType } from "apiv1/project";
import { PriceLabel } from "components/ItemCard";

type CardProps = {
  id: string;
  name: string;
  coverUrl: string | null;
  feeType: FeeType;
  price: number | null;
};

const Card: React.FC<CardProps> = ({ id, name, coverUrl, feeType, price }) => {
  const location = useLocation();

  return (
    <li className={styles.cards}>
      <Link
        to={{
          pathname: `/projects/${id}`,
          state: { background: location },
        }}
      >
        <div className={styles.thumnail}>
          <img src={coverUrl || blank} alt={name} />
        </div>
        <h3 className={styles.ttl}>{name}</h3>
        <ul className={styles.pay}>
          <li className={styles.status}>
            <PriceLabel feeType={feeType} />
          </li>
          {price && price !== 0 && (
            <li className={styles.price}>¥{price.toLocaleString("ja-JP")}</li>
          )}
        </ul>
      </Link>
    </li>
  );
};

export default React.memo(Card);
