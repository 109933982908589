import ButtonOutline from "components/Button/ButtonOutline";
import ModalProjectName from "components/ModalProjectName";
import React, { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { projectState } from "../atom";
import EditorBookletList from "./EditorBookletList/EditorBookletList";
import styles from "./EditorSidebar.module.css";

type EditorSidebarProps = {
  show: boolean;
  closeSidebar: () => void;
};

const EditorSidebar: React.FC<EditorSidebarProps> = ({
  show,
  closeSidebar,
}) => {
  const project = useRecoilValue(projectState);
  const [openModalProjectName, setOpenModalProjectName] = useState(false);

  const handleCloseModal = () => {
    setOpenModalProjectName(false);
  };

  const handleOpenModal = () => {
    setOpenModalProjectName(true);
  };

  const confirmConvertToNewEditor = (event: MouseEvent) => {
    if (
      !window.confirm(
        "この下書きを新しいエディタに移行しますか？\n移行すると、従来のエディタでは開けなくなります。"
      )
    ) {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className={styles.container} data-open={show}>
        <h1 className={styles.ttl} onClick={handleOpenModal}>
          {project.name}
        </h1>
        <div className={styles.sidebarHeader}>冊子と目次</div>
        <EditorBookletList closeSidebar={closeSidebar} />
        <div className={styles.footer}>
          <p className={styles.footerNote}>
            より使いやすくなった
            <br />
            TALTOの新しいエディタを
            <br />
            試してみませんか？
          </p>
          <Link to="./edit2" onClick={confirmConvertToNewEditor}>
            <ButtonOutline>新しいエディタに移行する</ButtonOutline>
          </Link>
        </div>
      </div>
      <ModalProjectName
        open={openModalProjectName}
        onClose={handleCloseModal}
      />
      <div className={styles.overlay} data-open={show} onClick={closeSidebar} />
    </>
  );
};

export default EditorSidebar;
