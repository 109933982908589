import { getAuth } from "@firebase/auth";
import { API_BASE_URL } from "config/api";

const auth = getAuth();

export type Attachment = {
  name: string;
  mimetype: string;
  url: string;
  createdAt: string;
};

export type Attachments = {
  items: Attachment[];
};

const fetchWithIdToken = async (
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> => {
  const idToken = await auth.currentUser?.getIdToken();
  if (idToken == null) {
    throw "undefined idToken";
  }

  const mergedInit: RequestInit = {
    ...init,
    mode: "cors",
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${idToken}`,
    },
  };

  return fetch(input, mergedInit);
};

export const uploadAttachment = async (projectId: string, file: File) => {
  return fetchWithIdToken(
    `${API_BASE_URL}/v0/projects/${projectId}/signed_url_for_uploading_attachment`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        filename: file.name,
        mimetype: file.type,
      }),
    }
  )
    .then((res) => res.json())
    .then(({ url }) => {
      return fetch(url, {
        method: "PUT",
        mode: "cors",
        body: file,
      });
    });
};

export const deleteAttachment = async (projectId: string, fileName: string) => {
  const escapedFileName = encodeURIComponent(fileName);
  return fetchWithIdToken(
    `${API_BASE_URL}/v0/projects/${projectId}/attachments/${escapedFileName}`,
    {
      method: "DELETE",
    }
  );
};
