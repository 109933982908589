import Modal from "components/Modal";
import ModalPublish from "components/ModalPublish";
import ModalReleased from "components/ModalReleased/ModalReleased";
import { ModalWide } from "components/ModalWide/ModalWide";
import { openPublishState } from "pages/EditorLexical/recoil/atom";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

const Publish = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [openPublish, setOpenPublish] = useRecoilState(openPublishState);
  const [openPublished, setOpenPublished] = useState(false);

  const onCloseReleaseModal = () => {
    setOpenPublish(false);
  };

  const onReleased = () => {
    setOpenPublish(false);
    setOpenPublished(true);
  };

  return (
    <>
      <ModalWide open={openPublish} onClose={onCloseReleaseModal}>
        <ModalPublish variant="lexical" onReleased={onReleased} />
      </ModalWide>
      <Modal open={openPublished} onClose={() => setOpenPublished(false)}>
        <ModalReleased projectId={projectId} />
      </Modal>
    </>
  );
};

export default Publish;
