import { postAgreementDate } from "apiv1/user";
import { AuthContext, LATEST_TERMS } from "components/AuthProvider";
import Modal from "components/Modal";
import { useContext, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./ModalNoticeNewTerms.module.css";

const IGNORE_PATHNAMES = [
  "/terms",
  "/business-terms",
  "/sales-terms",
  "/licenseid-terms",
  "/supporter-program",
  "/act_on_specified_commercial_transactions",
  "/privacy",
  "/help",
];

const ModalNoticeNewTerms = () => {
  const user = useContext(AuthContext);
  const location = useLocation();
  const [agreed, setAgreed] = useState(false);
  const open = useMemo(
    () =>
      user.authed && Date.parse(user.agreementDate) < LATEST_TERMS && !agreed,
    [user, agreed]
  );

  if (!user.authed || IGNORE_PATHNAMES.includes(location.pathname)) return null;

  const handleAgree = () => {
    postAgreementDate(user.uid).then(() => setAgreed(true));
  };
  return (
    <Modal open={open} closable={false} onClose={() => {}}>
      <div className={styles.container}>
        <h2 className={styles.ttl}>利用規約の確認</h2>
        <div className={styles.box}>
          <p className={styles.txt}>
            事業者規約の制定に伴い、TALTO の
            <Link to="/terms" target="_blank">
              利用規約
            </Link>
            の内容を一部を改訂いたしました。サービスを継続してご利用いただくには、新しい利用規約に同意していただく必要がございます。
          </p>
          <div className={styles.agree}>
            <p>
              変更後の
              <Link to="/terms" target="_blank">
                利用規約
              </Link>
              について
            </p>
            <button className={styles.btn} onClick={handleAgree}>
              同意してサービスを利用する
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNoticeNewTerms;
