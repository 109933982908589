import { auth } from "initialize";
import { useLocation } from "react-router-dom";
import { signOut as signOutFirebase } from "firebase/auth";
import { clearTaltoLocalStorageItems } from "./localstorage";
import { FirebaseError } from "firebase/app";

export const authErrorToJpMessage = (error: unknown): string => {
  if (!(error instanceof FirebaseError)) {
    return `不明なエラーが発生しました。`;
  }

  switch (error.code) {
    case "auth/invalid-email":
      return "メールアドレスの形式に不備があります。入力内容を確認してください。";
    case "auth/invalid-password":
      return "パスワードの形式に不備があります。入力内容を確認してください。";
    case "auth/wrong-password":
    case "auth/user-not-found":
      return "パスワード認証に失敗しました。入力したメールアドレスかパスワードに誤りがないか確認してください。";
    case "auth/email-already-in-use":
      return "登録しようとしたメールアドレスは、すでに使われています。";
    case "auth/weak-password":
      return "パスワードは7文字以上としてください。";
    default:
      return `不明なエラーが発生しました。\n${error.code}:${error.message}`;
  }
};

const REF_WHITELIST = /^\/gift\/receive\?code=[0-9A-Za-z_-]{21}$/;

const validateRef = (ref: string | null): string => {
  if (ref?.match(REF_WHITELIST) != null) {
    return ref;
  } else {
    return "/bookmarks";
  }
};

export const useRedirectRef = (): string => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  return validateRef(search.get("ref"));
};

export const signOut = async () => {
  await signOutFirebase(auth);
  clearTaltoLocalStorageItems();
};
