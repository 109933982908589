import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        <li>
          <a
            href="https://twitter.com/ccfolia"
            target="_blank"
            rel="noreferrer"
          >
            公式 Twitter
          </a>
        </li>
        <li>
          <a href="/terms" target="_blank">
            利用規約
          </a>
        </li>
        <li>
          <a href="/privacy" target="_blank">
            プライバシーポリシー
          </a>
        </li>
      </ul>
      <ul className={styles.list}>
        <li>
          <a href="/sales-terms" target="_blank">
            作品販売に関する注意事項
          </a>
        </li>
        <li>
          <a
            href="/act_on_specified_commercial_transactions"
            target="_blank"
            rel="noopener"
          >
            特定商取引法に基づく表示
          </a>
        </li>
        <li>
          <a href="/help" target="_blank">
            ヘルプ
          </a>
        </li>
      </ul>
      <p className={styles.contact}>お問い合わせ：contact@ccfolia.com </p>
      <p className={styles.copy}>
        Copyright © ️2021 CCFOLIA Inc. All rights reserved.
      </p>
    </div>
  );
};

export default Footer;
