import React from "react";
import styles from "./EditorHeader.module.css";
import { ReactComponent as IconHeadingOne } from "./IconHeadingOne.svg";
import { ReactComponent as IconHeadingTwo } from "./IconHeadingTwo.svg";
import { ReactComponent as IconHeadingThree } from "./IconHeadingThree.svg";
import { ReactComponent as IconNotes } from "./IconNotes.svg";
import { RichUtils } from "draft-js";
import { currentEditorStateSelector } from "pages/Editor/selector";
import { useRecoilCallback } from "recoil";

type BlockType = "header-one" | "header-two" | "header-three" | "block-note";

const BlockIcon: React.FC<{ blockType: BlockType }> = ({ blockType }) => {
  switch (blockType) {
    case "header-one":
      return <IconHeadingOne />;
    case "header-two":
      return <IconHeadingTwo />;
    case "header-three":
      return <IconHeadingThree />;
    default:
      // "block-note"
      return <IconNotes />;
  }
};

type ButtonToggleBlockTypeProps = {
  blockType: BlockType;
  title: string;
  active: boolean;
  focus: () => void;
};

const ButtonToggleBlockType: React.FC<ButtonToggleBlockTypeProps> = ({
  blockType,
  title,
  active,
  focus,
}) => {
  const toggleBlockType = useRecoilCallback(
    ({ snapshot, set }) =>
      async (event: React.MouseEvent) => {
        event.preventDefault();
        const editorState = await snapshot.getPromise(
          currentEditorStateSelector
        );
        if (editorState) {
          set(
            currentEditorStateSelector,
            RichUtils.toggleBlockType(editorState, blockType)
          );
        }
        focus();
      },
    [blockType, focus]
  );

  return (
    <li
      title={title}
      onClick={toggleBlockType}
      className={active ? styles.active : ""}
    >
      <BlockIcon blockType={blockType} />
    </li>
  );
};

export default React.memo(ButtonToggleBlockType);
