import { withIdToken } from "apiv1";
import axios from "axios";
import { SerializedEditorState } from "lexical";
import { RawDraft } from "pages/Editor/EditorProvider";

type Draft = {
  project_id: string;
  version: "v1";
  seq: number;
  data?: RawDraft;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
};

type DraftV2 = {
  project_id: string;
  version: "v2";
  seq: number;
  data: {
    booklets: {
      id: string;
      title: string;
      data: SerializedEditorState | null;
    }[]
  };
  created_at: string;
  updated_at: string;
  deleted_at?: string;
}

export const getDraft = async (projectId: string) => {
  return axios.get<Draft | DraftV2>(
    `/v1/projects/${projectId}/draft`,
    await withIdToken()
  );
};

export type ResPutDraft = {
  seq: number;
};

export const putDraft = async (
  projectId: string,
  rawDraft: RawDraft,
  prevSeq: number
) => {
  return axios.put<ResPutDraft>(
    `/v1/projects/${projectId}/draft`,
    {
      prevSeq,
      rawDraft,
    },
    await withIdToken()
  );
};

export const putDraftV2 = async (
  projectId: string,
  rawDraft: DraftV2["data"],
  prevSeq: number
) => {
  return axios.put<ResPutDraft>(
    `/v1/projects/${projectId}/draft`,
    {
      prevSeq,
      version: "v2",
      rawDraft,
    },
    await withIdToken()
  );
};
