import { ViewerLexical } from "components/Viewer";
import { projectState } from "pages/Editor/atom";
import {
  openPreviewState,
  currentBookletIdState,
} from "pages/EditorLexical/recoil/atom";
import { scenarioDataSelector } from "pages/EditorLexical/recoil/selector";
import { useEffect, useMemo, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "./Preview.css";

const Preview = () => {
  const scenarioData = useRecoilValue(scenarioDataSelector);
  const { name: projectName } = useRecoilValue(projectState);
  const setOpenPreview = useSetRecoilState(openPreviewState);

  const date = useMemo(() => new Date().toISOString(), []);

  useEffect(() => {
    document.body.classList.add("Preview");

    return () => {
      document.body.classList.remove("Preview");
    };
  }, []);

  const editingBookletId = useRecoilValue(currentBookletIdState);
  const [bookletId, setCurrentBookletId] = useState(editingBookletId);

  return (
    <>
      <ViewerLexical
        scenarioData={scenarioData}
        projectName={projectName}
        projectId="xxx"
        rev="xxx"
        bookletId={bookletId}
        onChangeBooklet={setCurrentBookletId}
        date={date}
        enableShareButton={false}
      />
      <button
        className="Preview__buttonClose"
        onClick={() => setOpenPreview(false)}
      >
        プレビューを閉じる
      </button>
    </>
  );
};

const PreviewContainer = () => {
  const open = useRecoilValue(openPreviewState);

  if (!open) {
    return null;
  }

  return <Preview />;
};

export default PreviewContainer;
