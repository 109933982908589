import React, { useState, useEffect, useContext } from "react";
import styles from "./Settings.module.css";
import { ReactComponent as IconGoogle } from "./IconGoogle.svg";
import { ReactComponent as IconTwitter } from "./IconTwitter.svg";
import { AuthContext } from "components/AuthProvider";
import NotFound from "pages/NotFound";
import {
  getAuth,
  linkWithRedirect,
  TwitterAuthProvider,
  unlink,
  GoogleAuthProvider,
  AuthErrorCodes,
  UserInfo,
  EmailAuthProvider,
  updatePassword,
  reauthenticateWithRedirect,
  reauthenticateWithCredential,
  sendEmailVerification,
  reload,
} from "@firebase/auth";
import {
  getRedirectResult,
  linkWithCredential,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import { FirebaseError } from "@firebase/util";
import { useHistory } from "react-router";
import Modal from "components/Modal";
import modalStyles from "./Modal.module.css";
import DefaultLayout from "components/DefaultLayout";

const auth = getAuth();

const handleChangeInput =
  (set: (x: string) => void) =>
  (event: React.ChangeEvent<HTMLInputElement>) => {
    set(event.target.value);
  };

const linked = (
  providerData: UserInfo[] | undefined,
  providerId: string
): UserInfo | undefined => {
  if (providerData == null) return undefined;

  return providerData.find((info) => info.providerId === providerId);
};

type EmailAreaProps = {
  reauthed: boolean;
  onReauth: () => void;
};

const EmailArea: React.FC<EmailAreaProps> = ({ reauthed, onReauth }) => {
  if (!reauthed) {
    return null;
  }

  const emailAuth = linked(
    auth.currentUser?.providerData,
    EmailAuthProvider.PROVIDER_ID
  );

  if (emailAuth) {
    return (
      <EmailUpdate disabled={!reauthed} currentEmail={emailAuth.email || ""} />
    );
  } else {
    return <EmailLink disabled={!reauthed} />;
  }
};

type ReauthAreaProps = {
  onReauth: () => void;
};

const ReauthArea: React.FC<ReauthAreaProps> = ({ onReauth }) => {
  const emailAuth = linked(
    auth.currentUser?.providerData,
    EmailAuthProvider.PROVIDER_ID
  );
  const googleAuth = linked(
    auth.currentUser?.providerData,
    GoogleAuthProvider.PROVIDER_ID
  );
  const TwitterAuth = linked(
    auth.currentUser?.providerData,
    TwitterAuthProvider.PROVIDER_ID
  );

  const [password, setPassword] = useState("");

  const email = auth.currentUser?.email || "";

  const reauthEmail = () => {
    if (auth.currentUser == null) return;
    const credential = EmailAuthProvider.credential(email, password);
    reauthenticateWithCredential(auth.currentUser, credential)
      .then(() => {
        onReauth();
        // alert("再認証が完了しました");
      })
      .catch(() => {
        alert("パスワードでの再認証に失敗しました");
      });
  };

  const reauthGoogle = () => {
    if (auth.currentUser) {
      const provider = new GoogleAuthProvider();
      reauthenticateWithRedirect(auth.currentUser, provider).catch(() =>
        alert("Googleアカウントでの認証に失敗しました")
      );
    }
  };

  const reauthTwitter = () => {
    if (auth.currentUser) {
      const provider = new TwitterAuthProvider();
      reauthenticateWithRedirect(auth.currentUser, provider).catch(() =>
        alert("Twitterアカウントでの認証に失敗しました")
      );
    }
  };

  return (
    <div className={modalStyles.container}>
      <h2 className={modalStyles.ttl}>ログイン情報の編集</h2>
      <div className={modalStyles.body}>
        <div className={styles.box}>
          <p>ログイン情報を編集するには、ユーザー認証が必要です</p>
        </div>
        <div className={styles.reauthBtnArea}>
          {googleAuth && (
            <button className={styles.btnGoogle} onClick={reauthGoogle}>
              <IconGoogle />
              Googleで認証
            </button>
          )}
          {TwitterAuth && (
            <button className={styles.btnTwitter} onClick={reauthTwitter}>
              <IconTwitter />
              Twitterで認証
            </button>
          )}
        </div>
        {emailAuth && (
          <>
            {/* <div className={styles.box}>
              <label className={styles.label}>メールアドレス</label>
              <p>{email}</p>
            </div> */}
            <div className={styles.box}>
              {/* <label className={styles.label}>パスワード</label> */}
              <input
                className={styles.input}
                type="password"
                name="new-password"
                placeholder="パスワードを入力"
                value={password}
                onChange={handleChangeInput(setPassword)}
              />
            </div>
            <div className={styles.btnArea}>
              <button className={styles.btnThinMargin} onClick={reauthEmail}>
                認証
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

type EmailLinkProps = {
  disabled: boolean;
};

const EmailLink: React.FC<EmailLinkProps> = ({ disabled }) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const linkEmail = () => {
    if (auth.currentUser == null) return;
    if (password !== passwordConfirm) {
      alert("異なるパスワードが入力されました");
      return;
    }

    const credential = EmailAuthProvider.credential(email, password);
    linkWithCredential(auth.currentUser, credential)
      .then((user) => {
        sendEmailVerification(user.user);
        alert("メールアドレスとパスワードを登録しました。");
        setPassword("");
        setPasswordConfirm("");
        history.go(0);
      })
      .catch((error: FirebaseError) => {
        if (error.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
          alert(
            "再認証完了から長時間経過したため、ログイン情報の変更に失敗しました。もう一度再認証を実施してください。"
          );
          history.go(0);
        } else if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
          alert("このメールアドレスはすでに別アカウントで利用されています。");
        } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
          alert(
            "不正形式のメールアドレスが入力されました。入力に誤りがないか確認してください。"
          );
        } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
          alert("脆弱なパスワードです。パスワードは7文字以上としてください。");
        } else {
          console.log(error);
          alert(`エラー: ${error.message}`);
        }
      });
  };

  return (
    <>
      <div>
        {/* <p className={styles.border}>メールアドレス・パスワードの登録</p> */}
        <label className={styles.label}>メールアドレス</label>
        <input
          className={styles.input}
          type="email"
          name="email"
          placeholder="email@example.com"
          value={email}
          onChange={handleChangeInput(setEmail)}
          disabled={disabled}
        />
        <label className={styles.label}>パスワード</label>
        <input
          className={styles.input}
          type="password"
          name="new-password"
          placeholder="パスワードを入力"
          value={password}
          onChange={handleChangeInput(setPassword)}
          disabled={disabled}
        />
        <input
          className={styles.input}
          type="password"
          name="new-password-confirm"
          placeholder="パスワードを再入力"
          value={passwordConfirm}
          onChange={handleChangeInput(setPasswordConfirm)}
          disabled={disabled}
        />
        <p className={styles.note}>※ パスワードは7文字以上</p>
      </div>
      <div className={styles.btnArea}>
        <button className={styles.btn} onClick={linkEmail} disabled={disabled}>
          メールアドレスとパスワードを登録
        </button>
      </div>
    </>
  );
};

type EmailUpdateProps = {
  currentEmail: string;
  disabled: boolean;
};

const EmailUpdate: React.FC<EmailUpdateProps> = ({
  currentEmail,
  disabled,
}) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const submitEmail = () => {
    if (auth.currentUser == null) return;
    verifyBeforeUpdateEmail(auth.currentUser, email)
      .then(() => {
        alert(
          "新しいメールアドレスに確認メールを送信しました。メール記載のリンクをクリックして確認を完了させてください。\n\n登録メールアドレスは確認完了後に更新されます。"
        );
      })
      .catch((error: FirebaseError) => {
        if (error.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
          alert(
            "再認証完了から長時間経過したため、ログイン情報の変更に失敗しました。もう一度再認証を実施してください。"
          );
          history.go(0);
        } else if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
          alert("このメールアドレスはすでに別アカウントで利用されています。");
        } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
          alert(
            "不正形式のメールアドレスが入力されました。入力に誤りがないか確認してください。"
          );
        } else {
          console.log(error);
          alert(`エラー: ${error.message}`);
        }
      });
  };

  const submitPassword = () => {
    if (auth.currentUser == null) return;
    if (password !== passwordConfirm) {
      alert("異なるパスワードが入力されました");
      return;
    }
    updatePassword(auth.currentUser, password)
      .then(() => {
        setPassword("");
        setPasswordConfirm("");
        alert("パスワードを変更しました");
      })
      .catch((error: FirebaseError) => {
        if (error.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) {
          alert(
            "再認証完了から長時間経過したため、ログイン情報の変更に失敗しました。もう一度再認証を実施してください。"
          );
          history.go(0);
        } else if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
          alert(
            "脆弱なパスワードです。パスワードは英数字7文字以上としてください。"
          );
        } else {
          alert(`エラー: ${error.message}`);
        }
      });
  };

  return (
    <>
      <div className={styles.boxMarginBottom}>
        {/* <p className={styles.border}>メールアドレスの設定</p> */}
        <label className={styles.label}>新しいメールアドレス</label>
        <input
          className={styles.input}
          type="email"
          name="email"
          placeholder="email@example.com"
          value={email}
          onChange={handleChangeInput(setEmail)}
          disabled={disabled}
        />
        <div className={styles.btnArea}>
          <button
            className={styles.btnSmallMargin}
            onClick={submitEmail}
            disabled={disabled}
          >
            メールアドレスを変更
          </button>
        </div>
      </div>
      <div className={styles.box}>
        {/* <p className={styles.border}>パスワードの設定</p> */}
        <label className={styles.label}>パスワードの変更</label>
        <input
          className={styles.input}
          type="password"
          name="new-password"
          placeholder="新しいパスワードを入力"
          value={password}
          onChange={handleChangeInput(setPassword)}
          disabled={disabled}
        />
        <input
          className={styles.input}
          type="password"
          name="new-password-confirm"
          placeholder="新しいパスワードを再入力"
          value={passwordConfirm}
          onChange={handleChangeInput(setPasswordConfirm)}
          disabled={disabled}
        />
        <p className={styles.note}>※ パスワードは7文字以上</p>
        <div className={styles.btnArea}>
          <button
            className={styles.btnNoMargin}
            onClick={submitPassword}
            disabled={disabled}
          >
            パスワードを変更
          </button>
        </div>
      </div>
    </>
  );
};

const EmailVerify = () => {
  const [seq, setSeq] = useState(0);

  useEffect(() => {
    if (auth.currentUser) {
      reload(auth.currentUser).finally(() => setSeq(seq + 1));
    }
  }, []);

  if (auth.currentUser == null) return null;

  const verifyEmail = () => {
    if (auth.currentUser == null) return;

    sendEmailVerification(auth.currentUser)
      .then(() => {
        alert("検証メールを送信しました");
      })
      .catch((error) => alert(`エラー: ${error.message}`));
  };

  // if (auth.currentUser.email == null) {
  //   return (
  //     <div className={styles.box}>
  //       <p>メールアドレスは設定されていません。</p>
  //     </div>
  //   );
  // }

  return (
    <div>
      {/* <label className={styles.label}>メールアドレス</label>
      <p>{auth.currentUser.email}</p> */}
      <label className={styles.label}>検証状況</label>
      <p>{auth.currentUser.emailVerified ? "検証済み" : "未検証"}</p>
      {!auth.currentUser.emailVerified && auth.currentUser.email != null && (
        <>
          <div className={styles.btnArea}>
            <button className={styles.btnSmallMargin} onClick={verifyEmail}>
              確認用メールを送信
            </button>
          </div>
        </>
      )}
    </div>
  );
};

const GoogleLinkArea = () => {
  const history = useHistory();
  const userInfo = linked(
    auth.currentUser?.providerData,
    GoogleAuthProvider.PROVIDER_ID
  );

  const linkGoogle = () => {
    if (auth.currentUser) {
      const provider = new GoogleAuthProvider();
      linkWithRedirect(auth.currentUser, provider).catch(() =>
        alert("Googleアカウントとの連携に失敗しました")
      );
    }
  };

  const unlinkGoogle = () => {
    if (auth.currentUser == null) {
      return;
    }

    if (auth.currentUser.providerData.length === 1) {
      alert(
        "Googleアカウント以外のログイン手段がないため、連携解除を中止しました。\nGoogleとの連携を解除するには、他のログイン手段を登録してください。"
      );
      return;
    }

    if (!window.confirm("Googleアカウントとの連携を解除しますか？")) {
      return;
    }

    unlink(auth.currentUser, GoogleAuthProvider.PROVIDER_ID)
      .then(() => {
        alert("Googleアカウントとの連携を解除しました");
        history.go(0);
      })
      .catch(() => alert("Googleアカウントの連携解除に失敗しました"));
  };

  if (userInfo) {
    return (
      <p className={styles.removeGoogle}>
        {/* <span className={styles.txt}>連携アカウント：</span> */}
        {userInfo.displayName}
        <br />
        <span className={styles.unlink} onClick={unlinkGoogle}>
          Google連携を解除
        </span>
      </p>
    );
  } else {
    return (
      <div className={styles.btnArea}>
        <button className={styles.btnGoogle} onClick={linkGoogle}>
          <IconGoogle />
          Googleで連携
        </button>
      </div>
    );
  }
};

const TwitterLinkArea = () => {
  const history = useHistory();
  const userInfo = linked(
    auth.currentUser?.providerData,
    TwitterAuthProvider.PROVIDER_ID
  );

  const linkTwitter = () => {
    if (auth.currentUser) {
      const provider = new TwitterAuthProvider();
      linkWithRedirect(auth.currentUser, provider).catch(() =>
        alert("Twitterアカウントとの連携に失敗しました")
      );
    }
  };

  const unlinkTwitter = () => {
    if (auth.currentUser == null) {
      return;
    }

    if (auth.currentUser.providerData.length === 1) {
      alert(
        "Twitter以外のログイン手段がないため、連携解除を中止しました。\nTwitterとの連携と解除するには、他のログイン手段を登録してください。"
      );
      return;
    }

    if (!window.confirm("Twitterアカウントとの連携を解除しますか？")) {
      return;
    }

    unlink(auth.currentUser, TwitterAuthProvider.PROVIDER_ID)
      .then(() => {
        alert("Twitterアカウントとの連携を解除しました");
        history.go(0);
      })
      .catch(() => alert("Twitterアカウントの連携解除に失敗しました"));
  };

  if (userInfo) {
    return (
      <p className={styles.removeTwitter}>
        {userInfo.displayName}
        <br />
        <span className={styles.unlink} onClick={unlinkTwitter}>
          Twitter連携を解除
        </span>
      </p>
    );
  } else {
    return (
      <div className={styles.btnArea}>
        <button className={styles.btnTwitter} onClick={linkTwitter}>
          <IconTwitter />
          Twitterで連携
        </button>
      </div>
    );
  }
};

const AccountSettings = () => {
  const user = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  const [reauthed, setReauthed] = useState(false);

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (result == null) {
          return;
        }

        if (result.operationType === "link") {
          alert("外部アカウント連携が完了しました");
        } else if (result.operationType === "reauthenticate") {
          setReauthed(true);
          // alert("外部アカウントでの再認証が完了しました");
        }
      })
      .catch((error: FirebaseError) => {
        if (error.code === AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE) {
          alert("連携しようとしたアカウントはすでに使われています");
        } else {
          alert(`エラー: ${error.message}`);
        }
      });
  }, []);

  if (!user.authed) {
    return <NotFound />;
  }

  const onReauth = () => {
    setReauthed(true);
    setOpen(false);
  };

  return (
    <DefaultLayout>
      <div className={styles.section}>
        <h2 className={styles.ttl}>アカウント設定</h2>
        <div className={styles.box}>
          {/* <p className={styles.txt}>
              ログインに使うメールアドレスとパスワードを設定します。
              <br />
              登録されたメールアドレスは他のユーザーには表示されません。
            </p> */}
          {!reauthed && (
            <div>
              <label className={styles.label}>メールアドレス</label>
              <p>{auth.currentUser?.email || "未設定"}</p>
              <div className={styles.btnArea}>
                <button
                  className={styles.btnNoMargin}
                  onClick={() => setOpen(true)}
                >
                  メールアドレスとパスワードを変更
                </button>
              </div>
            </div>
          )}
          <Modal open={open} onClose={() => setOpen(false)}>
            <ReauthArea onReauth={onReauth} />
          </Modal>
          <EmailArea reauthed={reauthed} onReauth={onReauth} />
          <p className={styles.border}>メールアドレスの検証</p>
          <p className={styles.txt}>
            確認用メールに記載されたリンクからメールアドレスの登録を完了させてください。登録が完了していない場合、作品販売など一部の機能が利用できないことがあります。
          </p>
          <p className={styles.txt}>
            確認用メールは迷惑メールに分類されてしまうことがあります。届いてない場合は迷惑メール欄もご確認ください。
          </p>
          <EmailVerify />
          <p className={styles.border}>SNSログイン</p>
          <p className={styles.txt}>
            各種 SNS
            アカウントと連携することで、そのアカウントを使ってログインすることができるようになります。
          </p>
          <GoogleLinkArea />
          <TwitterLinkArea />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default AccountSettings;
