import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./ModalPayment.module.css";
import { ReactComponent as IconHelp } from "./IconHelp.svg";
import Btn from "components/Btn";
import { Coupon, getCoupon } from "apiv1/coupons";
import axios from "axios";

type CouponAreaProps = {
  projectId: string;
  onChange: (coupon: Coupon) => void;
  onChangeDirty: (flag: boolean) => void;
  disabled: boolean;
};

const CouponArea: React.VFC<CouponAreaProps> = ({
  projectId,
  onChange,
  onChangeDirty,
  disabled,
}) => {
  const refCouponCodeInput = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);
  const [couponId, setCouponId] = useState("");

  if (disabled) {
    return <div className={styles.couponDisabled} />;
  }

  const handleOpenCoupon = () => {
    setOpen(true);
    setTimeout(() => {
      refCouponCodeInput.current?.focus();
    }, 0);
  };

  const applyCoupon = async () => {
    try {
      const coupon = (await getCoupon(couponId)).data;
      if (coupon.revoked_at != null) {
        alert(`シナチケ "${couponId}" は無効化されており、使用できません`);
        return;
      }

      if (coupon.project_id !== projectId) {
        alert("使用できないシナチケです");
        return;
      }

      onChange(coupon);
      setCouponId("");
      onChangeDirty(false);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response?.status === 404) {
        alert("使用できないシナチケです");
      }
    }
  };

  const handleChangeCouponCode = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const code = event.target.value.toUpperCase();
    setCouponId(code);
    onChangeDirty(code.length !== 0);
  };

  return (
    <div className={styles.couponArea}>
      <div className={styles.couponHeader}>
        <div className={styles.couponLabel} onClick={handleOpenCoupon}>
          シナチケを使用
        </div>
        <Link
          className={styles.couponHelp}
          to={{ pathname: "/help", hash: "what_is_scenario_ticket" }}
          target="_blank"
          title="シナチケとは？"
        >
          <IconHelp />
        </Link>
      </div>
      {open && (
        <div className={styles.couponAreaBody}>
          <input
            className={styles.couponInput}
            value={couponId}
            placeholder="コードを入力"
            onChange={handleChangeCouponCode}
            ref={refCouponCodeInput}
          />
          <div className={styles.couponApplyBtn}>
            <Btn secondary onClick={applyCoupon}>
              適用
            </Btn>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(CouponArea);
