import React, { useState, useContext } from "react";
import styles from "./TopHeaderPC.module.css";
import DefaultUserIcon from "components/images/DefaultUserIcon.jpg";
import { AuthContext } from "components/AuthProvider";
import { Link } from "react-router-dom";
import { HoverMenu } from "components/HeaderUserIcon";

type AuthedMenuProps = {
  openUserProjectsModal: () => void;
};

const AuthedMenu: React.FC<AuthedMenuProps> = ({ openUserProjectsModal }) => {
  const user = useContext(AuthContext);
  const [hiddenMenu, setHiddenMenu] = useState(true);

  if (user.authed) {
    const handleOpenMenu = (event: React.MouseEvent) => {
      event.preventDefault();
      setHiddenMenu(false);
    };

    const handleCloseMenu = (event: React.MouseEvent) => {
      event.preventDefault();
      setHiddenMenu(true);
    };

    const handleOpenModal = (event: React.MouseEvent) => {
      event.preventDefault();
      openUserProjectsModal();
    };

    return (
      <>
        <div className={styles.flex}>
          <Link to="/bookmarks" className={styles.btnLink}>
            <button className={styles.btn}>本棚</button>
          </Link>
          <button className={styles.btn} onClick={handleOpenModal}>
            執筆する
          </button>
          <button className={styles.user} onClick={handleOpenMenu}>
            <img src={user.photoURL || DefaultUserIcon} alt="ImgUser" />
          </button>
        </div>
        <HoverMenu
          open={!hiddenMenu}
          onClose={handleCloseMenu}
          uid={user.uid}
        />
      </>
    );
  } else {
    return (
      <div className={styles.flex}>
        <Link className={styles.btn} to="/login">
          ログイン / 登録
        </Link>
      </div>
    );
  }
};

export default AuthedMenu;
