import { putDraftV2 } from "apiv1/draft";
import { Visibility } from "apiv1/project";
import { BookletInfo, BookletInfos, postReleaseV2 } from "apiv1/release";
import {
  isHeadingNode,
  isParagraphNode,
  isQuoteNode,
  isTextNode,
} from "components/Viewer";
import { SerializedEditorState } from "lexical";
import { useRecoilCallback } from "recoil";
import { ScenarioData } from "../datastore";
import { editedAtState, savedAtState, seqState } from "../recoil/atom";
import { scenarioDataSelector } from "../recoil/selector";

const convertToBookletInfos = (scenarioData: ScenarioData): BookletInfos => {
  return {
    booklets: scenarioData.booklets.map(convertToBookletInfo),
  };
};

const convertToBookletInfo = (
  booklet: ScenarioData["booklets"][0]
): BookletInfo => {
  return {
    id: booklet.id,
    name: booklet.title,
    charCount: countBookletTextLength(booklet.data),
  };
};

const countBookletTextLength = (
  serializedEditorState: SerializedEditorState | null
) => {
  if (serializedEditorState == null) {
    return 0;
  }

  return serializedEditorState.root.children.reduce((sum, node) => {
    if (isHeadingNode(node) || isParagraphNode(node) || isQuoteNode(node)) {
      return (
        sum +
        node.children.reduce(
          (s, node) => (isTextNode(node) ? s + node.text.length : s),
          1
        )
      );
    }

    return sum;
  }, 0);
};

export const useReleaseForLexical = () => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async (projectId: string, visibility: Visibility) => {
        const seq = await snapshot.getPromise(seqState);
        const scenarioData = await snapshot.getPromise(scenarioDataSelector);
        const editedAt = await snapshot.getPromise(editedAtState);

        const res = await putDraftV2(projectId, scenarioData, seq);

        set(seqState, res.data.seq);
        set(savedAtState, editedAt);

        const retCreate = await postReleaseV2(projectId, {
          version: "v2",
          visibility,
          booklets: scenarioData.booklets,
          bookletInfos: convertToBookletInfos(scenarioData),
        });

        return retCreate.data;
      }
  );
};
