import DefaultLayout from "components/DefaultLayout";
import React from "react";
import DistributorSettingPayout from "./DistributorSettingPayout/DistributorSettingPayout";

const PayoutSetting: React.VFC = () => {
  return (
    <DefaultLayout>
      <DistributorSettingPayout />
    </DefaultLayout>
  );
};

export default PayoutSetting;
