import {
  $createLineBreakNode,
  DOMConversionMap,
  DOMConversionOutput,
  ElementNode,
  LexicalNode,
} from "lexical";

export class DivParagraphNode extends ElementNode {
  static getType(): string {
    return "divParagraph";
  }

  static importDOM(): DOMConversionMap | null {
    return {
      div: (node: Node) => {
        return {
          conversion: convertDivParagraphElement,
          priority: 0,
        };
      },
    };
  }
}

function convertDivParagraphElement(node: Node): DOMConversionOutput {
  return {
    after: (childLexicalNodes: Array<LexicalNode>) => {
      const div = node as HTMLDivElement;
      const domParent = div.parentNode;
      if (
        domParent != null &&
        div !== domParent.lastChild &&
        div.style.display !== "inline"
      ) {
        childLexicalNodes.push($createLineBreakNode());
      }
      return childLexicalNodes;
    },
    node: null,
  };
}
