import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { putDraftV2 } from "apiv1/draft";
import axios from "axios";
import {
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  EditorState,
  LexicalCommand,
} from "lexical";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import { useRecoilCallback, useSetRecoilState } from "recoil";
import {
  editedAtState,
  hasSaveErrorState,
  savedAtState,
  seqState,
} from "../../recoil/atom";
import { scenarioDataSelector } from "../../recoil/selector";

export const AUTOSAVE_COMMAND: LexicalCommand<void> = createCommand();

const DELAY_MS = 500;

type AutoSavePluginProps = {
  bookletId: string;
};

let saving = false;

const AutoSavePlugin = ({ bookletId }: AutoSavePluginProps) => {
  const [editor] = useLexicalComposerContext();
  const skipNext = useRef<boolean>(true);
  const setEditedAt = useSetRecoilState(editedAtState);
  const { projectId } = useParams<{ projectId: string }>();
  const aggregateData = useRecoilCallback(
    ({ snapshot, set }) =>
      async (editorState: EditorState, editedAt: Date) => {
        if (saving) {
          return;
        }

        try {
          saving = true;
          const seq = await snapshot.getPromise(seqState);
          const scenarioDataOrg = await snapshot.getPromise(
            scenarioDataSelector
          );
          const scenarioData = {
            booklets: [...scenarioDataOrg.booklets],
          };

          const idx = scenarioData.booklets.findIndex(
            (booklet) => booklet.id === bookletId
          );
          if (idx >= 0) {
            const booklet = scenarioData.booklets[idx];
            scenarioData.booklets[idx] = {
              ...booklet,
              data: editorState.toJSON(),
            };
          }

          await putDraftV2(projectId, scenarioData, seq)
            .then((resp) => {
              set(seqState, resp.data.seq);
              set(savedAtState, editedAt);
              set(hasSaveErrorState, false);
            })
            .catch((error) => {
              const message =
                axios.isAxiosError(error) && error.response?.status === 409
                  ? "他のブラウザでの編集を検知したため、自動保存に失敗しました。編集するには、ページをリロードしてください。"
                  : "保存に失敗しました。";
              window.alert(message);

              set(hasSaveErrorState, true);
            });
        } finally {
          saving = false;
        }
      },
    [bookletId]
  );

  const autosave = useMemo(() => {
    return debounce(aggregateData, DELAY_MS);
  }, [aggregateData]);

  useEffect(() => {
    return editor.registerUpdateListener(
      ({ editorState, dirtyElements, dirtyLeaves }) => {
        // 初期化時にも呼び出されてしまうので、1回だけスキップする
        if (skipNext.current) {
          skipNext.current = false;
          return;
        }

        if (dirtyElements.size === 0 && dirtyLeaves.size === 0) {
          return;
        }

        const editedAt = new Date();
        autosave(editorState, editedAt);
        setEditedAt(editedAt);
      }
    );
  }, [editor, skipNext, setEditedAt, autosave]);

  useEffect(() => {
    return editor.registerCommand<string>(
      AUTOSAVE_COMMAND,
      () => {
        const editedAt = new Date();
        autosave(editor.getEditorState(), editedAt);
        setEditedAt(editedAt);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor, setEditedAt, autosave]);

  return null;
};

export default AutoSavePlugin;
