import React from "react";
import styles from "./Privacy.module.css";

const Privacy = () => {
  return (
    <div className={styles.container}>
      <h1>プライバシーポリシー</h1>
      <p>
        ココフォリア株式会社（以下「運営者」という）では、本ウェブサイト上で提供するサービス（以下「本サービス」という）におけるプライバシー情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」という）を定めます。
      </p>
      <h2>プライバシー情報</h2>
      <ol>
        <li>
          プライバシー情報のうち「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報を指します。
        </li>
        <li>
          プライバシー情報のうち「履歴情報および特性情報」とは、上記に定める「個人情報」以外のものをいい、ご利用頂いたサービスや、ご覧になったページや広告の履歴、ユーザーが検索した検索キーワード、ご利用時間帯、ご利用の方法、ご利用環境、郵便番号や性別、職業、年齢、ユーザーのIPアドレス、ウェブブラウザ情報（ユーザーエージェント）、クッキー情報、携帯端末の個体識別情報などを指します。
        </li>
        <li>
          プライバシー情報のうち、「個人データ」とは、個人情報保護法にいう「個人データ」を指すものとし、個人情報データベース等を構成する個人情報を指します。
        </li>
      </ol>
      <h2>プライバシー情報の取得と利用目的</h2>
      <ol>
        <li>
          運営者がユーザーから取得し、管理するプライバシー情報は、以下を指します。
          <ol>
            <li>
              本サービスの利用にあたりユーザーにご提供いただくプライバシー情報
              <br />
              氏名、生年月日、住所、電話番号、メールアドレス、クレジットカード情報、決済に必要な銀行口座情報、ID、パスワード、連携するSNS情報（TwitterまたはGoogleアカウント）、本サービスのプロフィール欄に記入いただく情報、ユーザーコンテンツの登録情報、SPLL申請番号、特定商取引法上の必要事項、その他本サービスにおいて当社が指定する情報
            </li>
            <li>
              本サービスの利用にあたり運営者が取得するプライバシー情報
              <br />
              端末ID（デバイスID）、IPアドレス、端末位置情報、サーバーア
              クセスログ情報、Cookie、その他本サービスにおいてユーザー
              が送信する情報（ブックマーク履歴、購入履歴、検索履歴、テキストデータ、画像、動画、本サービスへの投稿等）、ユーザーの同意に基づき提携サービス等から取得する情報（提携サービス等におけるユーザーのID等）
            </li>
          </ol>
        </li>
        <li>
          その他、法的な対応が必要となる問い合わせに限り、手続きに必要な個人情報をお尋ねする場合があります。
        </li>
      </ol>
      <h2>プライバシー情報の利用目的</h2>
      <ol>
        <li>
          運営者がプライバシー情報を収集・利用する目的は、以下のとおりです。
          <ol>
            <li>本サービスの利用の登録審査のため</li>
            <li>本サービスの円滑な提供及び本人確認のため</li>
            <li>有料サービスに関する請求及び決済のため</li>
            <li>ユーザーによる不正な利用を防止するため</li>
            <li>本サービスのシステムの改善、運営のため</li>
            <li>ユーザーの利用状況の調査及び分析のため</li>
            <li>ユーザーからのお問合せへの対応のため</li>
            <li>
              本サービスに関する利用規約又は本ポリシーの変更、本サービスの停止・中止・契約解除その他本サービスに関する広告の配信のため
            </li>
          </ol>
        </li>
        <li>
          運営者は、前項の利用目的を、変更前の利用目的と関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には、ユーザーに対し、通知又は本サービス上若しくは運営者の運営するウェブサイトでの掲示その他分かりやすい方法により公表します。
        </li>
      </ol>
      <h2>第三者への提供</h2>
      <ol>
        <li>
          運営者は、個人データに関して、次のいずれかに該当する場合を除き、第三者に個人を特定できる情報を提供することはありません。個人データの管理には細心の注意を払い、本ポリシーに掲げた通りに扱います。
          <ol>
            <li>ユーザーの同意がある場合</li>
            <li>法令に基づき開示することが必要である場合</li>
            <li>
              本ポリシーに定める利用目的の達成に必要な範囲内において個人データの取扱いの全部又は一部を委託することに伴って当該個人データが提供される場合
            </li>
            <li>
              合併その他の事由による事業の承継に伴って個人データが提供される場合
            </li>
            <li>
              特定の者との間で共同して利用される個人データが当該特定の者に提供される場合であって、その旨並びに共同して利用される個人データの項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人データの管理について責任を有する者の氏名又は名称について、あらかじめ、本人に通知し、又は本人が容易に知り得る状態に置いているとき
            </li>
            <li>
              ユーザーが本サービスの利用規約に違反し、運営者又は第三者の権利、財産やサービス等を保護するために、必要であると運営者が判断した場合
            </li>
          </ol>
        </li>
      </ol>
      <h2>情報収集モジュールの利用</h2>
      <ol>
        <li>
          本サービスでは、サービスの利便性向上及び広告効果等の情報の解析のため、Google
          Analyticsを利用してアクセス解析をしています。Google
          Analyticsはクッキーを使用し、個人を特定する情報を含むことなく、本サービスの利用状況を収集し解析します。Google社によるアクセス情報の収集方法および利用方法については、
          <a
            href="https://www.google.com/analytics/Privacy/jp.html"
            target="_blank"
            rel="noreferrer"
          >
            Google アナリティクス利用規約
          </a>
          および
          <a
            href="https://www.google.com/intl/ja/policies/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            Google社プライバシーポリシー
          </a>
          によって定められています。
          <br />
          <span className={styles.note}>
            ※Google Analyticsの無効化は、「
            <a
              href="https://tools.google.com/dlpage/gaoptout?hl=ja"
              target="_blank"
              rel="noreferrer"
            >
              Google Analyticsオプトアウトアドオン
            </a>
            」をダウンロードおよびインストールし、ブラウザのアドオン設定を変更することで可能です。
          </span>
        </li>
        <li>
          前項に伴い、運営者はGoogle
          Inc．（日本国外にあるものを含みます。）に対し、プライバシー情報の提供を行う場合があります。
        </li>
      </ol>
      <h2>安全管理措置</h2>
      <p>
        運営者は、取り扱う個人データについて、漏えい、滅失又は毀損の防止、その他の個人データの安全管理のために必要かつ適切な措置を講じます。また、従業者や委託先（再委託先等を含みます。）に対して、個人データを提供する場合は、必要かつ適切な監督を行います。個人データの安全管理措置に関しての主な内容は以下のとおりです。
      </p>
      <ol>
        <li>
          基本方針の策定
          <br />
          個人データの適正な取扱の確保のための基本方針を策定しています。
        </li>
        <li>
          個人データの取扱いに係る規律の整備
          <br />
          個人データの取得、利用、保存等を行う場合の基本的な取扱方法を整備しています。
        </li>
        <li>
          組織的安全管理措置
          <br />
          整備した取扱方法に従って個人データが取り扱われていることを責任者が確認しています。また、従業者から責任者に対する報告連絡体制を整備しています。
        </li>
        <li>
          人的安全管理措置
          <br />
          個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施しています。また、個人データについての秘密保持に関する事項を社内規則に記載しています。
        </li>
        <li>
          物理的安全管理措置
          <br />
          個人データを取り扱うことのできる従業者及び本人以外が容易に個人データを閲覧できないような措置を実施しています。また、個人データを取り扱う機器、電子媒体及び書類等の盗難又は紛失等を防止するための措置を講じるとともに、事業所内の移動を含め、当該機器、電子媒体等を持ち運ぶ場合、容易に個人データが判明しないよう措置を実施しています。
        </li>
        <li>
          技術的安全管理措置
          <br />
          個人データを取り扱うことのできる機器及び当該機器を取り扱う従業者を明確化し、個人データへの不要なアクセスを防止する措置を実施しています。また、個人データを取り扱う機器を外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入しています。
        </li>
      </ol>
      <h2>本ポリシーの変更</h2>
      <ol>
        <li>
          運営者は、プライバシー情報の取扱いに関する運用状況を適宜見直し、継続的な改善に努めるものとし、必要に応じて、随時本ポリシーを変更することがあります。
        </li>
        <li>
          変更後の本ポリシーについては、本サービス上又は運営者の運営するウェブサイトでの掲示等の分かりやすい方法により告知します。ただし、法令上ユーザーの同意が必要となるような内容の変更を行うときは、別途運営者が定める方法により、ユーザーの同意を取得します。
        </li>
      </ol>
      <h2>免責事項</h2>
      <ol>
        <li>
          次に掲げる場合には、第三者による個人情報の取得に関し、運営者は一切の責任を負いません。
          <ol>
            <li>
              ユーザー本人が当サービスの機能または別の手段を用いて第三者に個人情報を自ら明らかにした場合
            </li>
            <li>
              ユーザーが当サービス上に入力した情報等により、個人が識別できてしまった場合
            </li>
          </ol>
        </li>
      </ol>
      <h2>個人情報の照会・修正・削除</h2>
      <ol>
        <li>
          ユーザーが本人の個人情報の開示・修正・削除などを希望される場合には、後記の問い合わせ窓口までご連絡下さい。本人であることを確認の上、遅滞なく対応させていただきます。ただし、個人情報保護法を含む法令により運営者がこれらの義務を負わない場合については、この限りではありません。
        </li>
      </ol>
      <h2>お問い合わせ</h2>
      <ol>
        <li>
          本ポリシーに関するお問い合わせは、
          <a href="mailto:contact@ccfolia.com?subject=TALTOに関する問い合わせ&amp;body=下記にお問い合わせ内容をご記入ください。%0d%0a▼内容%0d%0a（お問い合わせ/ご意見・ご要望）%0d%0a▼詳しい内容%0d%0aこちらに内容をご記入ください。">
            こちら
          </a>
          までご連絡ください。
        </li>
      </ol>
      <div className={styles.tar}>
        <h2>附則</h2>
        <p>制定：2021年11月1日</p>
        <p>改訂：2021年12月25日</p>
      </div>
    </div>
  );
};

export default Privacy;
