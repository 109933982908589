import React from "react";
import styles from "./TopHeaderPC.module.css";
import UserMenu from "./UserMenu";
import { Link } from "react-router-dom";
import { ReactComponent as IconLogo } from "../IconLogo.svg";

type TopHeaderPCProps = {
  openUserProjectsModal: () => void;
};

const TopHeaderPC: React.FC<TopHeaderPCProps> = ({ openUserProjectsModal }) => {
  return (
    <div className={styles.container}>
      <div className={styles.flex}>
        <h1 className={styles.logo}>
          <Link to="/">
            <IconLogo />
          </Link>
        </h1>
        <p className={styles.copy}>
          TRPGのシナリオを書く・読む・探すためのオンライン保管所
        </p>
      </div>
      <UserMenu openUserProjectsModal={openUserProjectsModal} />
    </div>
  );
};

export default TopHeaderPC;
