import { SCTAct } from "apiv1/user";
import React from "react";
import styles from "./ModalTokusho.module.css";

type ModalTokushoProps = {
  sctact: SCTAct;
};

const ModalTokusho: React.FC<ModalTokushoProps> = ({ sctact }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.ttl}>特定商取引法に基づく表記</h2>
      <div className={styles.box}>
        <ul className={styles.list}>
          <li className={styles.inner}>
            <h4 className={styles.status}>代金等の支払時期</h4>
            <p className={styles.txt}>商品注文時、お支払いが確定します。</p>
          </li>
          <li className={styles.inner}>
            <h4 className={styles.status}>代金等の支払方法</h4>
            <p className={styles.txt}>
              クレジットカード決済
              <br />
              （PAY株式会社が提供する決済システム「PAY.JP」を利用します）
            </p>
          </li>
          <li className={styles.inner}>
            <h4 className={styles.status}>返品に関する事項</h4>
            <p className={styles.txt}>
              商品に欠陥があり、かつTALTOが認める場合を除き、返品には応じられません。
            </p>
          </li>
          <li className={styles.inner}>
            <h4 className={styles.status}>販売者の名称と連絡先</h4>
            <p className={styles.txt}>{sctact.name_and_contact}</p>
          </li>
          <li className={styles.inner}>
            <h4 className={styles.status}>販売価格</h4>
            <p className={styles.txt}>{sctact.price}</p>
          </li>
          <li className={styles.inner}>
            <h4 className={styles.status}>お問合せ先</h4>
            <p className={styles.txt}>{sctact.email}</p>
          </li>
          {sctact.others.length > 0 && (
            <li className={styles.inner}>
              <h4 className={styles.status}>その他</h4>
              <p className={styles.txt}>{sctact.others}</p>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ModalTokusho;
