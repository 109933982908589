import React, { useCallback, useEffect, useState } from "react";
import ProjectCards from "../../../../stories/components/ProjectCards/ProjectCards";
import {
  CardProps,
  toCardProps,
} from "../../../../stories/components/ProjectCards/Card";
import { WithLoad } from "../../../../utils/types";
import { searchProjects } from "apiv1/project";

const PAGE_LIMIT = 12;

type TopItemNewProps = {
  expand: boolean;
  setExpand: (value: boolean) => void;
};

const TopItemNew: React.VFC<TopItemNewProps> = ({ expand, setExpand }) => {
  const [projects, setProjects] = useState<WithLoad<CardProps[]>>("loading");
  const [loadedAll, setLoadedAll] = useState(false);

  useEffect(() => {
    searchProjects({ limit: PAGE_LIMIT })
      .then((res) => {
        const projects = res.data.map(toCardProps);
        setProjects(projects);
      })
      .catch(() => setProjects("notfound"));
  }, []);

  const handleReadMore = useCallback(async () => {
    searchProjects({
      offset: projects.length,
      limit: PAGE_LIMIT,
    }).then((resp) => {
      if (Array.isArray(projects)) {
        const cs = resp.data.map(toCardProps);
        setExpand(true);
        setProjects(projects.concat(cs));

        if (cs.length < PAGE_LIMIT) {
          setLoadedAll(true);
        }
      }
    });
  }, [projects, setProjects, setExpand, setLoadedAll]);

  return (
    <ProjectCards
      title="新着投稿"
      cards={projects}
      expand={expand}
      loadedAll={loadedAll}
      onClickReadMore={handleReadMore}
    />
  );
};

export default TopItemNew;
