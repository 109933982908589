import { withIdToken } from "apiv1";
import axios from "axios";

/* Bookmark */
export type Bookmark = {
  id: string;
  name: string;
  cover_url: string;
  category_name: string;
  created_at: string;
};

export const getBookmarks = async (uid: string) => {
  return axios.get<Bookmark[]>(
    `/v1/users/${uid}/bookmarks`,
    await withIdToken()
  );
};

export const postBookmarks = async (uid: string, projectId: string) => {
  return axios.post(
    `/v1/users/${uid}/bookmarks`,
    {
      project_id: projectId,
    },
    await withIdToken()
  );
};

export const getBookmark = async (uid: string, projectId: string) => {
  return axios.get<Bookmark>(
    `/v1/users/${uid}/bookmarks/${projectId}`,
    await withIdToken()
  );
};

export const deleteBookmarks = async (uid: string, projectId: string) => {
  return axios.delete(
    `/v1/users/${uid}/bookmarks/${projectId}`,
    await withIdToken()
  );
};
