import { EditorPlugin, PluginFunctions } from "@draft-js-plugins/editor";
import {
  AtomicBlockUtils,
  DraftHandleValue,
  EditorState,
  SelectionState,
} from "draft-js";
import { getDownloadURL, getStorage, ref } from "@firebase/storage";
import LoadingImage from "components/LoadingImage";
import { postImage } from "apiv1/project";

const storage = getStorage();

const IMAGE_SIZE_LIMIT = 2 * 1024 * 1024; // 2 MiB

export const uploadImage = (
  file: File,
  projectId: string,
  { getEditorState, setEditorState }: PluginFunctions,
  selection?: SelectionState
): DraftHandleValue => {
  if (!file.type.startsWith("image/")) {
    alert("本文に貼り付けできるのは、画像ファイルのみです");
    return "handled";
  }

  if (file.size > IMAGE_SIZE_LIMIT) {
    alert("本文に貼り付ける画像は2MB以下としてください");
    return "handled";
  }

  const editorState = selection
    ? EditorState.acceptSelection(getEditorState(), selection)
    : getEditorState();

  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    "IMAGE",
    "IMMUTABLE",
    {
      loading: true,
    }
  );

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = AtomicBlockUtils.insertAtomicBlock(
    editorState,
    entityKey,
    " "
  );
  setEditorState(
    EditorState.forceSelection(
      newEditorState,
      newEditorState.getCurrentContent().getSelectionAfter()
    )
  );

  postImage(projectId, file)
    .then(async (res) => {
      const url = await getDownloadURL(ref(storage, res.data.fileName));

      const editorState = getEditorState();
      const contentState = editorState.getCurrentContent();
      contentState.replaceEntityData(entityKey, { src: url });
      setEditorState(
        EditorState.forceSelection(
          editorState,
          editorState.getCurrentContent().getSelectionAfter()
        )
      );
    })
    .catch(console.error);

  return "handled";
};

const createDropUploadPlugin = (projectId: string): EditorPlugin => {
  const handleDroppedFiles = (
    selection: SelectionState,
    files: Array<Blob>,
    pluginFunctions: PluginFunctions
  ): DraftHandleValue => {
    if (files.length === 0) {
      return "not-handled";
    }

    const file = files[0] as File;
    return uploadImage(file, projectId, pluginFunctions, selection);
  };

  return {
    handleDroppedFiles,
  };
};

export const loadingImagePlugin: EditorPlugin = {
  blockRendererFn: (block, { getEditorState }) => {
    if (block.getType() === "atomic") {
      const contentState = getEditorState().getCurrentContent();
      const entity = block.getEntityAt(0);
      if (!entity) return null;
      const data = contentState.getEntity(entity).getData();
      const type = contentState.getEntity(entity).getType();
      if (type === "IMAGE" && data.loading === true) {
        return {
          component: LoadingImage,
          editable: false,
        };
      }
      return null;
    }

    return null;
  },
};

export default createDropUploadPlugin;
