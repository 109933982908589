import React from "react";
import styles from "./BookmarkFillter.module.css";
import BookmarkFillterCategory, {
  BookmarkFillterCategoryProps,
} from "./BookmarkFillterCategory/BookmarkFillterCategory";

const BookmarkFillter: React.FC<BookmarkFillterCategoryProps> = ({
  filter,
  setFilter,
}) => {
  return (
    <div className={styles.container}>
      <BookmarkFillterCategory
        filter={filter}
        setFilter={setFilter}
      ></BookmarkFillterCategory>
    </div>
  );
};

export default BookmarkFillter;
