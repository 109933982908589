import { SalesSummary } from "apiv1/sales";
import { SpinnerCenter } from "components/Spinner";
import React from "react";
import { Link } from "react-router-dom";
import { feeTypeToString, visibilityToString } from "utils/projects";
import { WithLoad } from "utils/types";
import styles from "./SalesMainProducts.module.css";

const toLocaleString = (n: number | string): string => {
  const x = typeof n === "string" ? parseInt(n, 10) : n;
  return x.toLocaleString("ja-JP");
};

type SalesRowProps = {
  summary: SalesSummary;
};

const SalesRow: React.FC<SalesRowProps> = ({ summary }) => {
  const path = `/projects/${summary.project_id}`;
  return (
    <tr className={styles.box}>
      <td className={styles.item}>
        <p className={styles.genre}>{summary.category_name}</p>
        <h3 className={styles.ttl}>{summary.project_name}</h3>
        <p className={styles.url}>
          <Link to={path}>https://talto.cc{path}</Link>
        </p>
      </td>
      <td
        className={styles.publish}
        data-publish-red={summary.visibility !== "public"}
        data-label="公開範囲"
      >
        {summary.deleted_at
          ? "削除済み"
          : visibilityToString(summary.visibility)}
      </td>
      <td className={styles.range} data-label="販売範囲">
        {summary.deleted_at ? "-" : feeTypeToString(summary.fee_type)}
      </td>
      <td className={styles.price} data-label="価格">
        {summary.price == null
          ? "-"
          : `¥${summary.price.toLocaleString("ja-JP")}`}
      </td>
      <td className={styles.figures} data-label="販売数">
        {toLocaleString(summary.count)}
      </td>
      <td className={styles.sales} data-label="総販売金額">
        {summary.total_sales == null
          ? "-"
          : `¥${toLocaleString(summary.total_sales)}`}
      </td>
    </tr>
  );
};

type SalesMainProductsProps = {
  summaries: WithLoad<SalesSummary[]>;
};

const SalesMainProducts: React.FC<SalesMainProductsProps> = ({ summaries }) => {
  if (summaries === "loading") {
    return <SpinnerCenter />;
  }

  if (summaries === "notfound") {
    return (
      <div className={styles.container}>
        <p>
          データを取得できませんでした。接続状況を確認の上、ページのリロードをお試しください。
        </p>
      </div>
    );
  }

  if (summaries.length === 0) {
    return (
      <div className={styles.container}>
        <p className={styles.txt}>販売中の作品はありません。</p>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.thead}>
            <th className={styles.theadItem}>作品名</th>
            <th className={styles.theadPublish}>公開範囲</th>
            <th className={styles.theadRange}>販売範囲</th>
            <th className={styles.theadPrice}>価格</th>
            <th className={styles.theadFigures}>販売数</th>
            <th className={styles.theadSales}>総販売金額</th>
          </tr>
        </thead>
        <tbody>
          {summaries.map((summary) => (
            <SalesRow key={summary.project_id} summary={summary} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SalesMainProducts;
