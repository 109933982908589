import React from "react";
import styles from "./BtnLine.module.css";
import { ReactComponent as IconLine } from "./IconLine.svg";

const BtnLine = () => {
  return (
    <div>
      <button className={styles.btnLine}>
        <IconLine></IconLine>友達に教える
      </button>
    </div>
  );
};

export default BtnLine;
