import React from "react";
import styles from "./ItemCards.module.css";
import { ReactComponent as IconAllPrice } from "./IconAllPrice.svg";
import { ReactComponent as IconFilePrice } from "./IconFilePrice.svg";
import BlankThumnail from "./blank.svg";
import { Link, useLocation } from "react-router-dom";
import { FeeType } from "apiv1/project";

type PriceLabelProps = {
  feeType: FeeType;
};

export const PriceLabel: React.FC<PriceLabelProps> = ({ feeType }) => {
  switch (feeType) {
    case "all_contents":
      return (
        <>
          <IconAllPrice></IconAllPrice>有料
        </>
      );
    case "attachments_only":
      return (
        <>
          <IconFilePrice></IconFilePrice>添付ファイルのみ有料
        </>
      );
    case "free":
      return (
        <>
          <IconAllPrice></IconAllPrice>無料
        </>
      );
  }
};

export type Card = {
  id: string;
  name: string;
  description: string;
  author: string;
  category: string;
  thumnail: string | null;
  tags: string[];
  players: string;
  playTime: string;
  likes: number;
  liked: boolean;
  feeType: FeeType;
  price: number | null;
};

const ItemCard: React.FC<{ card: Card }> = ({ card }) => {
  const location = useLocation();

  return (
    <li className={styles.cards}>
      <Link
        to={{
          pathname: `/projects/${card.id}`,
          state: { background: location },
        }}
      >
        <div className={styles.thumnail}>
          <img
            src={card.thumnail || BlankThumnail}
            alt="thumnail"
            loading="lazy"
          />
        </div>
        <p className={styles.category}>{card.category}</p>
        <h3 className={styles.ttl}>{card.name}</h3>
        <p className={styles.description}>{card.description}</p>
        <ul className={styles.pay}>
          <li className={styles.status}>
            <PriceLabel feeType={card.feeType} />
          </li>
          {card.price && (
            <li className={styles.price}>
              ¥{card.price.toLocaleString("ja-JP")}
            </li>
          )}
        </ul>
      </Link>
    </li>
  );
};

export default ItemCard;
