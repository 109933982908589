import React, { useContext, useEffect, useState } from "react";
import SalesTabMenu, { Mode, SalesHeader } from "./SalesTabMenu/SalesTabMenu";
import SalesMainContainer from "./SalesMainContainer/SalesMainContainer";
import DefaultLayout from "components/DefaultLayout";
import { AuthContext } from "components/AuthProvider";
import NotFound from "pages/NotFound";
import { getSCTAct, SCTAct } from "apiv1/user";
import { getPayjpTenantExist } from "apiv1/payjp";
import SalesMainRequired from "./SalesMainContainer/SalesMainRequired";
import { SpinnerFullDisplay } from "components/Spinner";

const Sales = () => {
  const user = useContext(AuthContext);
  const [mode, setMode] = useState<Mode>("projects");
  const [payjpTenant, setPayjpTenant] = useState<
    "exist" | "loading" | "notfound"
  >("loading");
  const [sctact, setSctact] = useState<SCTAct | "loading" | "notfound">(
    "loading"
  );

  useEffect(() => {
    if (user.authed) {
      getSCTAct(user.uid)
        .then((res) => setSctact(res.data))
        .catch(() => setSctact("notfound"));
      getPayjpTenantExist()
        .then(() => setPayjpTenant("exist"))
        .catch(() => setPayjpTenant("notfound"));
    }
  }, []);

  if (!user.authed) {
    return <NotFound />;
  }

  if (payjpTenant === "loading" && sctact === "loading") {
    return <SpinnerFullDisplay />;
  }

  if (
    !user.emailVerified ||
    payjpTenant === "notfound" ||
    sctact === "notfound"
  ) {
    return (
      <DefaultLayout>
        <SalesHeader />
        <SalesMainRequired
          emailVerified={user.emailVerified}
          tenantRegisterd={payjpTenant !== "notfound"}
          sctactRegisterd={sctact !== "notfound"}
        />
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <SalesTabMenu mode={mode} setMode={setMode} />
      <SalesMainContainer uid={user.uid} mode={mode} />
    </DefaultLayout>
  );
};

export default Sales;
