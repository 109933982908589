import React from "react";
import styles from "./CardSkeleton.module.css";

const CardSkeleton: React.VFC = () => {
  return (
    <li className={styles.item}>
      <div className={styles.thumbnail}></div>
      <p className={styles.ttl}></p>
      <p className={styles.genre}></p>
      <p className={styles.name}></p>
    </li>
  );
};

export default React.memo(CardSkeleton);
