import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $isHeadingNode, HeadingNode } from "@lexical/rich-text";
import { $getRoot, EditorState, RootNode } from "lexical";
import debounce from "lodash/debounce";
import { useCallback, useEffect, useMemo } from "react";
import { useSetRecoilState } from "recoil";
import { Heading, headingsState } from "../../recoil/atom";

const DELAY_MS = 500;

type HeadingWatchPluginProps = {
  bookletId: string;
};

export const headingsFromRootNode = (rootNode: RootNode): Heading[] => {
  const headingNodes = rootNode
    .getChildren()
    .filter(
      (node): node is HeadingNode =>
        $isHeadingNode(node) &&
        (node.getTag() === "h1" || node.getTag() === "h2")
    );

  const headings: Heading[] = headingNodes.map((heading) => {
    return {
      key: heading.getKey(),
      tagType: heading.getTag(),
      name: heading.getTextContent(),
    };
  });

  return headings;
};

const HeadingWatchPlugin = ({ bookletId }: HeadingWatchPluginProps) => {
  const [editor] = useLexicalComposerContext();
  const setHeadings = useSetRecoilState(headingsState(bookletId));

  const updateHeading = useCallback(
    (editorState: EditorState) => {
      editorState.read(() => {
        const root = $getRoot();
        setHeadings(headingsFromRootNode(root));
      });
    },
    [setHeadings]
  );

  const update = useMemo(() => {
    return debounce(updateHeading, DELAY_MS);
  }, [updateHeading]);

  useEffect(() => {
    return editor.registerUpdateListener(
      ({ editorState, dirtyElements, dirtyLeaves }) => {
        if (dirtyElements.size === 0 && dirtyLeaves.size === 0) {
          return;
        }

        update(editorState);
      }
    );
  }, [editor, update]);

  return null;
};

export default HeadingWatchPlugin;
