import React, { useState, useCallback } from "react";
import styles from "./Modal.module.css";
import { ReactComponent as IconClose } from "./IconClose.svg";
import ReactDOM from "react-dom";

export const useModal = (): [boolean, () => void, () => void] => {
  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return [open, onOpen, onClose];
};

type ModalProps = {
  open: boolean;
  title?: string;
  scrollable?: boolean;
  closable?: boolean;
  onClose: (event: React.MouseEvent) => void;
};

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  title,
  scrollable = false,
  closable = true,
  onClose,
}) => {
  const container = document.getElementById("modal-root");
  if (container == null) {
    return null;
  }

  const className = scrollable ? styles.overflowScroll : "";

  return ReactDOM.createPortal(
    <>
      <div className={styles.modal}>
        {closable && (
          <div onClick={onClose} hidden={!open}>
            <div className={styles.close}>
              <span>閉じる</span>
              <IconClose></IconClose>
            </div>
          </div>
        )}
        <div className={styles.bg} hidden={!open}>
          {title && <h2 className={styles.ttl}>{title}</h2>}
          <div className={className}>{children}</div>
        </div>
      </div>
      <div className={styles.overlay} hidden={!open} onClick={onClose}></div>
    </>,
    container
  );
};

export default Modal;
