import React, { useState, useContext } from "react";
import styles from "./UserMenu.module.css";
import DefaultUserIcon from "components/images/DefaultUserIcon.jpg";
import { AuthContext } from "components/AuthProvider";
import { Link } from "react-router-dom";
import { HoverMenu } from "components/HeaderUserIcon";

const AuthedMenu: React.VFC = () => {
  const user = useContext(AuthContext);
  const [hiddenMenu, setHiddenMenu] = useState(true);

  if (user.authed) {
    const handleOpenMenu = (event: React.MouseEvent) => {
      event.preventDefault();
      setHiddenMenu(false);
    };

    const handleCloseMenu = (event: React.MouseEvent) => {
      event.preventDefault();
      setHiddenMenu(true);
    };

    return (
      <>
        <div className={styles.flex}>
          <button className={styles.user} onClick={handleOpenMenu}>
            <img src={user.photoURL || DefaultUserIcon} alt="ImgUser" />
          </button>
        </div>
        <HoverMenu
          open={!hiddenMenu}
          onClose={handleCloseMenu}
          uid={user.uid}
          isSp
        />
      </>
    );
  } else {
    return (
      <div className={styles.flex}>
        <Link className={styles.btn} to="/login">
          ログイン
        </Link>
      </div>
    );
  }
};

export default AuthedMenu;
