import { registerHistory } from "@lexical/history";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { HistoryState } from "@lexical/react/LexicalHistoryPlugin";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  EditorState,
  LexicalCommand,
  LexicalEditor,
} from "lexical";
import { useEffect } from "react";
import { useRecoilCallback } from "recoil";
import { bookletState, currentBookletIdState } from "../../recoil/atom";

export const SWITCH_BOOKLET_COMMAND: LexicalCommand<string> = createCommand();

const refreshHistoryState = (
  historyState: HistoryState,
  editor: LexicalEditor
) => {
  if (historyState.current) {
    historyState.current.editor = editor;
  }

  for (const h of historyState.redoStack) {
    h.editor = editor;
  }

  for (const h of historyState.undoStack) {
    h.editor = editor;
  }
};

type SwitchPluginProps = {
  bookletId: string;
  setEditorState: (editorState: EditorState) => void;
  getHisotyState: () => HistoryState;
  setHisotyState: (history: HistoryState) => void;
};

const SwitchPlugin = ({
  bookletId,
  setEditorState,
  getHisotyState,
  setHisotyState,
}: SwitchPluginProps) => {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    const history = getHisotyState();
    refreshHistoryState(history, editor);

    editor.dispatchCommand(CAN_UNDO_COMMAND, history.undoStack.length > 0);
    editor.dispatchCommand(CAN_REDO_COMMAND, history.redoStack.length > 0);

    return registerHistory(editor, history, 1000);
  }, [getHisotyState, editor]);

  const switchBooklet = useRecoilCallback(
    ({ set }) =>
      (nextBookletId: string) => {
        const editorState = editor.getEditorState();
        setEditorState(editorState);

        set(bookletState(bookletId), editorState.toJSON());
        set(currentBookletIdState, nextBookletId);
      },
    [editor, bookletId, setEditorState, getHisotyState, setHisotyState]
  );

  useEffect(() => {
    return editor.registerCommand<string>(
      SWITCH_BOOKLET_COMMAND,
      (bookletId) => {
        switchBooklet(bookletId);
        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor, switchBooklet]);

  return null;
};

export default SwitchPlugin;
