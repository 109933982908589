import React from "react";
import styles from "./EditorHeader.module.css";
import { ReactComponent as IconBold } from "./IconBold.svg";
import { ReactComponent as IconUnderline } from "./IconUnderline.svg";
import { RichUtils } from "draft-js";
import { currentEditorStateSelector } from "pages/Editor/selector";
import { useRecoilCallback } from "recoil";

type InlineStyle = "BOLD" | "UNDERLINE";

const StyleIcon: React.FC<{ inlineStyle: InlineStyle }> = ({ inlineStyle }) => {
  switch (inlineStyle) {
    case "BOLD":
      return <IconBold />;
    default:
      // "UNDERLINE"
      return <IconUnderline />;
  }
};

type ButtonToggleInlineStyleProps = {
  inlineStyle: InlineStyle;
  title: string;
  active: boolean;
  focus: () => void;
};

const ButtonToggleInlineStyle: React.FC<ButtonToggleInlineStyleProps> = ({
  inlineStyle,
  title,
  active,
  focus,
}) => {
  const toggleInlineStyle = useRecoilCallback(
    ({ snapshot, set }) =>
      async (event: React.MouseEvent) => {
        event.preventDefault();
        const editorState = await snapshot.getPromise(
          currentEditorStateSelector
        );
        if (editorState) {
          set(
            currentEditorStateSelector,
            RichUtils.toggleInlineStyle(editorState, inlineStyle)
          );
        }
      },
    [inlineStyle, focus]
  );

  return (
    <li
      title={title}
      onClick={toggleInlineStyle}
      className={active ? styles.active : ""}
    >
      <StyleIcon inlineStyle={inlineStyle} />
    </li>
  );
};

export default React.memo(ButtonToggleInlineStyle);
