import React from "react";
import styles from "./BookmarkCards.module.css";
import blank from "../../../../components/ItemCard/blank.svg";
import { Link, useLocation } from "react-router-dom";

type BookmarkCardProps = {
  id: string;
  name: string;
  category_name: string | null;
  cover_url: string;
};

const BookmarkCard: React.FC<BookmarkCardProps> = (props) => {
  const location = useLocation();

  return (
    <li className={styles.cards}>
      <Link
        to={{
          pathname: `/projects/${props.id}`,
          state: { background: location },
        }}
      >
        <div className={styles.thumnail}>
          <img src={props.cover_url || blank} alt={props.name} />
        </div>
        <p className={styles.category}>{props.category_name}</p>
        <h3 className={styles.ttl}>{props.name}</h3>
      </Link>
    </li>
  );
};

export type BookmarkCardsProps = {
  cards: BookmarkCardProps[];
};

const BookmarkCards: React.FC<BookmarkCardsProps> = ({ cards }) => {
  if (cards.length === 0) {
    return (
      <div className={styles.container}>
        <div className={styles.list}>登録した作品はありません</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {cards.map((card) => {
          return <BookmarkCard key={card.id} {...card} />;
        })}
      </div>
    </div>
  );
};

export default BookmarkCards;
