import React from "react";
import { EditorPlugin, PluginFunctions } from "@draft-js-plugins/editor";
import {
  ContentState,
  DraftEditorCommand,
  DraftHandleValue,
  EditorState,
  Modifier,
  SelectionState,
} from "draft-js";

// Headerブロックで改行したときに、一方をスタイルなしブロックにする
const handleReturn = (
  event: React.KeyboardEvent,
  editorState: EditorState,
  pluginFunctions: PluginFunctions
): DraftHandleValue => {
  const selectionStartKey = editorState.getSelection().getStartKey();
  const selectedBlock = editorState
    .getCurrentContent()
    .getBlockForKey(selectionStartKey);

  // Alt + Enter と Shift + Enter で前の行のスタイルを引き継ぐ
  if (
    selectedBlock.getType() === "unstyled" ||
    event.shiftKey ||
    event.altKey
  ) {
    return "not-handled";
  }

  const contentState = Modifier.splitBlock(
    editorState.getCurrentContent(),
    editorState.getSelection()
  );

  const beforeBlockIsBlank =
    contentState.getBlockForKey(selectionStartKey).getText() === "";
  const targetSelection = beforeBlockIsBlank
    ? contentState.getSelectionBefore()
    : contentState.getSelectionAfter();

  const newContentState = Modifier.setBlockType(
    contentState,
    targetSelection,
    "unstyled"
  );
  const newState = EditorState.push(
    editorState,
    newContentState,
    "split-block"
  );
  const selected = EditorState.acceptSelection(
    newState,
    contentState.getSelectionAfter()
  );
  pluginFunctions.setEditorState(selected);
  return "handled";
};

// 行頭でバックスペースを押した時の挙動を改善する
const handleKeyCommand = (
  command: DraftEditorCommand,
  editorState: EditorState,
  eventTimeStamp: number,
  pluginFunctions: PluginFunctions
): DraftHandleValue => {
  if (command !== "backspace") {
    return "not-handled";
  }

  const selection = editorState.getSelection();

  if (selection.getStartOffset() !== 0 || !selection.isCollapsed()) {
    return "not-handled";
  }

  const contentState = editorState.getCurrentContent();
  const beforeBlock = contentState.getBlockBefore(selection.getStartKey());
  const thisBlock = contentState.getBlockForKey(selection.getStartKey());

  // todo: 文頭の一文字目
  // 前の行が存在しないか画像の場合、デフォルトの挙動に任せる
  if (beforeBlock == null || beforeBlock.getType() === "atomic") {
    return "not-handled";
  }

  // 前の行と block の種類が違う場合、カーソルだけ移動する
  if (
    thisBlock.getType() !== beforeBlock.getType() &&
    thisBlock.getLength() > 0 &&
    beforeBlock.getLength() > 0
  ) {
    const newSelection = selection.merge({
      anchorKey: beforeBlock.getKey(),
      anchorOffset: beforeBlock.getText().length,
      focusKey: beforeBlock.getKey(),
      focusOffset: beforeBlock.getText().length,
    });
    const selected = EditorState.forceSelection(editorState, newSelection);
    pluginFunctions.setEditorState(selected);
    return "handled";
  }

  // headerの前が空行の場合、その空行を削除してheaderを維持する
  if (beforeBlock.getLength() === 0) {
    const removeBlockKey = beforeBlock.getKey();
    const removedBlockBlockArray = contentState
      .getBlocksAsArray()
      .filter((block) => block.getKey() !== removeBlockKey);

    const newContentState = ContentState.createFromBlockArray(
      removedBlockBlockArray,
      contentState.getEntityMap()
    );
    const newState = EditorState.push(
      editorState,
      newContentState,
      "remove-range"
    );
    const newSelection = SelectionState.createEmpty(thisBlock.getKey());
    const selected = EditorState.forceSelection(newState, newSelection);
    pluginFunctions.setEditorState(selected);
    return "handled";
  }

  // 行頭で Backspace を押した場合、unstyled ではなく改行削除
  if (selection.getStartOffset() === 0) {
    const newSelection = selection.merge({
      focusKey: beforeBlock.getKey(),
      focusOffset: beforeBlock.getText().length,
      isBackward: true,
    });
    const newState = EditorState.push(
      editorState,
      Modifier.removeRange(contentState, newSelection, "backward"),
      "remove-range"
    );
    pluginFunctions.setEditorState(newState);
    return "handled";
  }

  return "handled";
};

const betterHeaderPlugin: EditorPlugin = {
  handleReturn,
  handleKeyCommand,
};

export default betterHeaderPlugin;
