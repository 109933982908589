import React from "react";
import style from "./Spinner.module.css";

const Spinner = () => {
  return <div className={style.spinner} />;
};

export const SpinnerCenter = () => {
  return (
    <div className={style.spinnerCenter}>
      <Spinner />
    </div>
  );
};

export const SpinnerFullDisplay = () => {
  return (
    <div className={style.spinnerFull}>
      <Spinner />
    </div>
  );
};

export default Spinner;
