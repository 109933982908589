import React from "react";
import styles from "./BtnShare.module.css";
import { ReactComponent as IconCopy } from "./IconCopy.svg";

const BtnShare = () => {
  return (
    <div>
      <button className={styles.btnShare}>
        <IconCopy></IconCopy>
      </button>
    </div>
  );
};

export default BtnShare;
