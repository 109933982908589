import React from "react";
import styles from "./Asct.module.css";

const Asct = () => {
  return (
    <div className={styles.container}>
      <h1>特定商取引法に基づく表示</h1>
      <h2>代金等の支払時期</h2>
      <h3>当社の提供するサービス：</h3>
      <p>商品注文時、お支払いが確定します。</p>
      <h3>各販売者の提供するサービス：</h3>
      <p>
        各販売者のプロフィールページに記載されている「特商法に基づく表記」をご確認ください。
      </p>
      <h2>代金等の支払方法</h2>
      <h3>当社の提供するサービス：</h3>
      <p>売上金の中から自動的に徴収されます。</p>
      <h3>各販売者の提供するサービス：</h3>
      <p>クレジットカード決済</p>
      <h2>返品に関する事項</h2>
      <h3>当社の提供するサービス：</h3>
      <p>
        プラットフォームサービスにつき、サービス提供開始後、キャンセルは受付けておりません。
      </p>
      <h3>各販売者の提供するサービス：</h3>
      <p>
        各販売者のプロフィールページに記載されている「特商法に基づく表記」をご確認ください。
      </p>
      <h2>販売者の名称と連絡先</h2>
      <h3>名称：</h3>
      <p>ココフォリア株式会社</p>
      <h3>本社所在地：</h3>
      <p>東京都練馬区東大泉3丁目29番21号</p>
      <h3>お問合せ先：</h3>
      <p>
        contact@ccfolia.com
        <br />
        お電話によるお問合せをご希望の方は、上記問合せ窓口よりご請求ください。
      </p>
      <h2>販売価格</h2>
      <h3>プラットフォーム利用料：</h3>
      <p>販売者の定める商品価格の5%+¥50（税込）</p>
      <h3>各販売者の提供するサービス：</h3>
      <p>作品詳細表示の価格をご覧ください。</p>
      <h2>商品代金以外の必要料金（配送料等）</h2>
      <h3>当社の提供するサービス：</h3>
      <p>サイトの閲覧、コンテンツのダウンロード等の際に通信料が発生します。</p>
      <h3>各販売者の提供するサービス：</h3>
      <p>
        各販売者のプロフィールページに記載されている「特商法に基づく表記」をご確認ください。
      </p>
      <h2>商品引渡時期</h2>
      <p>お支払い後、すぐにご利用いただけます。</p>
      <h2>申込方法・有効期限</h2>
      <p>当社ウェブサイト上の申込みとなります。有効期限はありません。</p>
      <h2>TALTOを利用するために必要な条件等</h2>
      <p>下記ブラウザでの利用を推奨します。</p>
      <ul>
        <li>Google Chrome 最新版</li>
      </ul>
    </div>
  );
};

export default Asct;
