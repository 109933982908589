import { withIdToken, withoutToken } from "apiv1";
import axios from "axios";

export type GiftedProjectInfo = {
  project_id: string;
  project_name: string;
  category_name: string;
  cover_url: string | null;
  owner_id: string;
};

export const getGiftProject = async (giftId: string) => {
  return axios.get<GiftedProjectInfo>(
    `/v1/gifts/${giftId}/project`,
    await withoutToken()
  );
};

export const postReceiveGift = async (giftId: string) => {
  return axios.post<unknown>(
    `/v1/gifts/${giftId}/receive`,
    undefined,
    await withIdToken()
  );
};
