import { getAuth } from "@firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "./config/firebase";
import { getStorage } from "@firebase/storage";
import { PAYJP_API_PUBLIC_KEY } from "config/payjp";

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const payjp = Payjp(PAYJP_API_PUBLIC_KEY);
