import React from "react";
import styles from "./ModalPayment.module.css";
import { deletePayjpCustomerCard, MaskedCard } from "apiv1/payjp";

type SelectCardProps = {
  cards: MaskedCard[] | undefined;
  cardId: string;
  onChange: (cardId: string) => void;
  onChangeCards: (cards: MaskedCard[]) => void;
};

const SelectCard: React.VFC<SelectCardProps> = ({
  cards = [],
  cardId,
  onChange,
  onChangeCards,
}) => {
  const handleChangeCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  const handleDeleteCard =
    (card: MaskedCard) => async (event: React.MouseEvent) => {
      event.preventDefault();
      const month = card.exp_month.toString().padStart(2, "0");
      const year = (card.exp_year % 100).toString().padStart(2, "0");
      const msg = `${card.brand} 下4桁 ${card.last4} 有効期限 ${month}/${year}\nこちらのカードをアカウントから削除してよろしいですか？`;
      if (window.confirm(msg)) {
        await deletePayjpCustomerCard(card.id).catch((err) => {
          alert("カードの削除に失敗しました");
          throw err;
        });
        alert("カードを削除しました");

        onChangeCards(cards.filter((c) => c.id !== card.id));
      }
    };

  return (
    <>
      <h4 className={styles.subttl}>カードを選択</h4>
      {cards.map((card) => {
        const month = card.exp_month.toString().padStart(2, "0");
        const year = (card.exp_year % 100).toString().padStart(2, "0");
        return (
          <label className={styles.radioArea} key={card.id}>
            <input
              className={styles.radio}
              type="radio"
              name="card"
              value={card.id}
              checked={cardId === card.id}
              onChange={handleChangeCard}
            />
            {card.brand} 下4桁 {card.last4}{" "}
            <span className={styles.expiry}>
              有効期限 {month}/{year}
            </span>
            <button className={styles.delete} onClick={handleDeleteCard(card)}>
              削除
            </button>
          </label>
        );
      })}
      <label className={styles.radioArea}>
        <input
          className={styles.radio}
          type="radio"
          name="card"
          value="new"
          checked={cardId === "new"}
          onChange={handleChangeCard}
        />
        新しいカード
      </label>
    </>
  );
};

export default SelectCard;
