import { SaveableBooklet } from "pages/Editor/EditorProvider";
import React from "react";
import Pager, { PageItem } from "./Pager";
import styles from "./ViewerMainContainer.module.css";
import ViewerMainSection from "./ViewerMainSection/ViewerMainSection";

type ViewerMainContainerProps = {
  projectName: string;
  booklet: SaveableBooklet;
  prev?: PageItem;
  next?: PageItem;
  changeBooklet: (id: string) => void;
};

const ViewerMainContainer: React.FC<ViewerMainContainerProps> = ({
  projectName,
  booklet,
  prev,
  next,
  changeBooklet,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <ViewerMainSection projectName={projectName} booklet={booklet} />
        <Pager prev={prev} next={next} changeBooklet={changeBooklet} />
      </div>
    </div>
  );
};

export default ViewerMainContainer;
