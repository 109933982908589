import { SaveableBooklet } from "pages/Editor/EditorProvider";
import React, { useEffect, useState } from "react";
import styles from "./ViewerSidebar.module.css";

import { ReactComponent as IconArrowOpen } from "./IconArrowOpen.svg";
import { ReactComponent as IconArrowClose } from "./IconArrowClose.svg";

type SidebarBookletProps = {
  booklet: SaveableBooklet;
  currentBookletId: string;
  changeBooklet: (id: string, hash?: string) => void;
  closeIndex: () => void;
};

const SidebarBooklet: React.FC<SidebarBookletProps> = ({
  booklet,
  currentBookletId,
  changeBooklet,
  closeIndex,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (booklet.id === currentBookletId) {
      setShow(true);
    }
  }, [currentBookletId]);

  const headings = booklet.rawDraftContentState.blocks.filter((b) =>
    /^header\-(one|two)/.test(b.type)
  );

  const handleClick = (key: string) => () => {
    changeBooklet(booklet.id, key);
    closeIndex();
  };

  const handleTitleClick = () => {
    changeBooklet(booklet.id);
    closeIndex();
  };

  const toggleShow = () => setShow(!show);

  return (
    <>
      <p
        className={styles.bookletTitle}
        data-active={booklet.id === currentBookletId}
      >
        {show ? (
          <IconArrowOpen onClick={toggleShow} />
        ) : (
          <IconArrowClose onClick={toggleShow} />
        )}
        <span onClick={handleTitleClick}>{booklet.name}</span>
      </p>
      <ul className={styles.list} hidden={!show}>
        {headings.map((h) => {
          const className = h.type === "header-one" ? styles.anc : styles.ancH2;
          return (
            <li className={className}>
              <span onClick={handleClick(h.key)}>{h.text}</span>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default SidebarBooklet;
