import {
  createEmptyHistoryState,
  HistoryState,
} from "@lexical/react/LexicalHistoryPlugin";
import { getDraft } from "apiv1/draft";
import { $getRoot, createEditor, EditorState } from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { RecoilRoot, useRecoilCallback, useRecoilValue } from "recoil";
import Editor from "./components/Editor";
import { NODES } from "./components/Editor/Editor";
import EditorSkeleton from "./components/Editor/EditorSkeleton";
import { headingsFromRootNode } from "./components/HeadingWatchPlugin/HeadingWatchPlugin";
import {
  bookletIdsState,
  bookletNameState,
  bookletState,
  currentBookletIdState,
  headingsState,
  openHeadingsState,
  openPreviewState,
  seqState,
} from "./recoil/atom";
import { convertFromTaltoDraftV1 } from "./shared/convert";
import "./EditorLexical.css";
import Preview from "./components/Preview";
import { getProject } from "apiv1/project";
import { projectState } from "pages/Editor/atom";
import NotFound from "pages/NotFound";
import DocumentTitle from "./components/DocumentTitle/DocumentTitle";
import { ImageNode } from "./nodes/ImageNode";
import { generateBookletId } from "./shared/booklet";

const EditorMain = () => {
  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    document.body.style.overscrollBehavior = "none";
    document.body.classList.add("EditorLexical");

    return () => {
      document.body.style.overscrollBehavior = "";
      document.body.classList.remove("EditorLexical");
    };
  }, []);

  useEffect(() => {
    ImageNode.setProjectId(projectId);
  }, [projectId]);

  const editorStates = useRef(new Map<string, EditorState>());
  const historyStates = useRef(new Map<string, HistoryState>());
  const currentBookletId = useRecoilValue(currentBookletIdState);
  const openPreview = useRecoilValue(openPreviewState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const setEditorState = useCallback(
    (editorState: EditorState) => {
      editorStates.current.set(currentBookletId, editorState);
    },
    [editorStates, currentBookletId]
  );

  const getHisotyState = useCallback(() => {
    const history = historyStates.current.get(currentBookletId);
    if (history) {
      return history;
    }

    const emptyHistory = createEmptyHistoryState();
    historyStates.current.set(currentBookletId, emptyHistory);
    return emptyHistory;
  }, [historyStates, currentBookletId]);

  const setHisotyState = useCallback(
    (history: HistoryState) => {
      historyStates.current.set(currentBookletId, history);
    },
    [historyStates, currentBookletId]
  );

  const fetchData = useRecoilCallback(
    ({ set }) =>
      () => {
        const promiseGetProject = getProject(projectId)
          .then((resp) => {
            set(projectState, resp.data);
          })
          .catch(() => setIsNotFound(true));

        const promiseGetDraft = getDraft(projectId)
          .then((resp) => {
            const draft = resp.data;
            if (draft.data == null) {
              const id = generateBookletId();
              set(bookletIdsState, [id]);
              set(currentBookletIdState, id);
              return;
            }

            const scenarioData =
              draft.version === "v1"
                ? convertFromTaltoDraftV1(draft.data)
                : draft.data;

            const editor = createEditor({ nodes: NODES });
            for (const booklet of scenarioData.booklets) {
              // Math.randで作っていた分を直す
              if (booklet.id.startsWith("0.")) {
                booklet.id = generateBookletId();
              }

              if (booklet.data) {
                const editorState = editor.parseEditorState(booklet.data);
                editorStates.current.set(booklet.id, editorState);
                editorState.read(() => {
                  const root = $getRoot();
                  set(headingsState(booklet.id), headingsFromRootNode(root));
                });
              }
              set(bookletNameState(booklet.id), booklet.title);
              set(bookletState(booklet.id), booklet.data);
            }

            const ids = scenarioData.booklets.map((b) => b.id);
            if (ids.length === 0) {
              return;
            }

            set(seqState, draft.seq);
            set(bookletIdsState, ids);
            set(currentBookletIdState, ids[0]);
            set(openHeadingsState(ids[0]), true);
          })
          .catch(() => {
            setIsNotFound(true);
          });

        Promise.all([promiseGetProject, promiseGetDraft]).finally(() => {
          setIsLoaded(true);
        });
      },
    [projectId]
  );

  useEffect(fetchData, [fetchData]);

  if (isNotFound) {
    return <NotFound />;
  }

  if (!isLoaded) {
    return <EditorSkeleton />;
  }

  if (openPreview) {
    return <Preview />;
  }

  return (
    <>
      <Editor
        key={currentBookletId}
        bookletId={currentBookletId}
        editorState={editorStates.current.get(currentBookletId)}
        setEditorState={setEditorState}
        getHisotyState={getHisotyState}
        setHisotyState={setHisotyState}
      />
      <DocumentTitle />
    </>
  );
};

const EditorLexical = () => {
  return (
    <RecoilRoot>
      <EditorMain />
    </RecoilRoot>
  );
};

export default EditorLexical;
