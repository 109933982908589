import { MouseEvent, ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "./Modal.css";

type ModalProps = {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
};

const Modal = ({ open, onClose, children }: ModalProps) => {
  useModal(open);

  if (!open) {
    return null;
  }

  const container = document.getElementById("modal-root");
  if (container == null) {
    return null;
  }

  const stopPropagation = (event: MouseEvent) => {
    event.stopPropagation();
  };

  return createPortal(
    <div className="Modal__overlay" onClick={onClose}>
      <div className="Modal__body" onClick={stopPropagation}>
        {children}
      </div>
    </div>,
    container
  );
};

const getScrollbarWidth = (): number => {
  const scrollDiv = document.createElement("div");
  scrollDiv.style.cssText =
    "position: absolute; top: -9999px; width: 50px; height: 50px; overflow: scroll;";
  document.body.appendChild(scrollDiv);
  const scrollbarWidth =
    scrollDiv.getBoundingClientRect().width - scrollDiv.clientWidth;
  document.body.removeChild(scrollDiv);
  return scrollbarWidth;
};

const appendScrollbarPadding = () => {
  const rect = document.body.getBoundingClientRect();
  const isBodyOverflowing =
    Math.round(rect.left + rect.right) < window.innerWidth;

  if (isBodyOverflowing) {
    const scrollbarWidth = getScrollbarWidth();
    document.body.style.setProperty(
      "padding-right",
      `${scrollbarWidth}px`,
      "important"
    );
  }
};

const removeBodyStyleProperties = (nested: boolean) => () => {
  if (!nested) {
    document.body.style.removeProperty("overflow");
    document.body.style.removeProperty("padding-right");
  }
};

export const useModal = (open: boolean) => {
  const nested = useRef(false);
  useEffect(() => {
    if (open) {
      nested.current = document.body.style.overflow !== "";
      appendScrollbarPadding();
      document.body.style.overflow = "hidden";
      return removeBodyStyleProperties(nested.current);
    } else {
      removeBodyStyleProperties(nested.current)();
    }
  }, [open]);
};

export default Modal;
