import { selector } from "recoil";
import { ScenarioData } from "../datastore";
import {
  bookletIdsState,
  bookletNameState,
  bookletState,
  editedAtState,
  savedAtState,
} from "./atom";

export const scenarioDataSelector = selector<ScenarioData>({
  key: "editorLexical/scenarioDataSelector",
  get: ({ get }) => {
    const ids = get(bookletIdsState);
    const booklets = [];
    for (const id of ids) {
      const title = get(bookletNameState(id));
      const data = get(bookletState(id));

      booklets.push({
        id,
        title,
        data,
      });
    }

    return {
      booklets,
    };
  },
});

export const isSavedSelector = selector({
  key: "editorLexical/isSavedSelector",
  get: ({ get }) => {
    const editedAt = get(editedAtState);
    const savedAt = get(savedAtState);

    if (editedAt == null) {
      return true;
    }

    if (savedAt == null) {
      return false;
    }

    return savedAt >= editedAt;
  },
});
