import { auth } from "initialize";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { clearTaltoLocalStorageItems } from "utils/localstorage";

const Logout: React.VFC = () => {
  const history = useHistory();

  useEffect(() => {
    auth.signOut().finally(() => {
      clearTaltoLocalStorageItems();
      history.push("/");
    });
  }, []);
  return null;
};

export default Logout;
