import React, { useCallback, useState } from "react";
import styles from "../../pages/Viewer/ViewerHeader/ViewerHeader.module.css";
import { ReactComponent as IconAroowRight } from "./IconAroowRight.svg";
import { ReactComponent as IconAroowLeft } from "./IconAroowLeft.svg";
import ModalShare, { BookletInfo } from "pages/Viewer/ModalShare";

const formatDate = (day: string) => {
  const d = new Date(Date.parse(day));
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const hour = d.getHours().toString().padStart(2, "0");
  const min = d.getMinutes().toString().padStart(2, "0");
  return `${year}年${month}月${date}日 ${hour}:${min}`;
};

type ViewerHeaderProps = {
  projectName: string;
  projectId: string;
  rev: string;
  booklets: BookletInfo[];
  date: string;
  showIndex: boolean;
  enableShareButton: boolean;
  toggleShowIndex: (event: React.MouseEvent) => void;
};

const ViewerHeader: React.FC<ViewerHeaderProps> = ({
  projectName,
  projectId,
  rev,
  booklets,
  date,
  showIndex,
  enableShareButton,
  toggleShowIndex,
}) => {
  const icon = showIndex ? <IconAroowLeft /> : <IconAroowRight />;
  const [openShareModal, setOpenShareModal] = useState(false);
  const onCloseModal = useCallback(() => {
    setOpenShareModal(false);
  }, [setOpenShareModal]);

  return (
    <div className={styles.container}>
      <div className={styles.scenario}>
        <p className={styles.version}>{formatDate(date)}</p>
      </div>
      <div className={styles.open}>
        <p className={styles.btnToggle} onClick={toggleShowIndex}>
          {icon}目次
        </p>
      </div>
      <div className={styles.booklet}>
        <h1 className={styles.ttl}>{projectName}</h1>
      </div>
      <div>
        {enableShareButton && (
          <>
            <button
              className={styles.btnShare}
              onClick={() => setOpenShareModal(true)}
            >
              共有URLを発行
            </button>
            <ModalShare
              projectName={projectName}
              projectId={projectId}
              rev={rev}
              booklets={booklets}
              open={openShareModal}
              needToken={false}
              onClose={onCloseModal}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ViewerHeader;
