import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection } from "lexical";
import { useEffect } from "react";

const AutoScrollPlugin = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      const selection = editorState.read(() => $getSelection());
      if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
        return;
      }

      const anchorElement = editor.getElementByKey(selection.anchor.key);
      if (anchorElement === null) {
        return;
      }

      const rect = anchorElement.getBoundingClientRect();
      if (rect.bottom > window.innerHeight - 30) {
        const diff = rect.bottom - (window.innerHeight - 30);
        window.scrollBy({ top: diff });
      } else if (rect.top < 40) {
        const diff = rect.top - 40;
        window.scrollBy({ top: diff - 100 });
      }
    });
  }, [editor]);

  return null;
};

export default AutoScrollPlugin;
