import { withIdToken, withoutToken } from "apiv1";
import axios from "axios";

export type CouponType = "free" | "fixed_amount_off" | "percent_off";

export type CouponInfo = {
  id: string;
  coupon_type: CouponType;
  amount: number | null;
  percent: number | null;
  project_id: string;
  project_name: string;
  cover_url: string | null;
  created_at: string;
  revoked_at: string | null;
};

type CouponBase = {
  id: string;
  project_id: string;
  created_at: string;
  revoked_at: string | null;
};

type CouponFree = CouponBase & {
  coupon_type: "free";
  amount: null;
  percent: null;
};

type CouponFixAmountOff = CouponBase & {
  coupon_type: "fixed_amount_off";
  amount: number;
  percent: null;
};

type CouponPercentOff = CouponBase & {
  coupon_type: "percent_off";
  amount: null;
  percent: number;
};

export type Coupon = CouponFree | CouponFixAmountOff | CouponPercentOff;

export const getUserCoupons = async (uid: string) => {
  return axios.get<CouponInfo[]>(
    `/v1/users/${uid}/coupons`,
    await withIdToken()
  );
};

export const postCoupons = async (
  project_id: string,
  coupon_type: CouponType,
  amount: number | undefined
) => {
  return axios.post<Coupon>(
    "/v1/coupons/",
    {
      project_id,
      coupon_type,
      amount,
    },
    await withIdToken()
  );
};

export const getCoupon = async (id: string) => {
  return axios.get<Coupon>(`/v1/coupons/${id}`, await withoutToken());
};

export const postCouponsRevoke = async (id: string) => {
  return axios.post<unknown>(
    `/v1/coupons/${id}/revoke`,
    undefined,
    await withIdToken()
  );
};
