import React, { useEffect, useState } from "react";
import ProjectCards from "../../../../stories/components/ProjectCards/ProjectCards";
import {
  CardProps,
  toCardProps,
} from "../../../../stories/components/ProjectCards/Card";
import { WithLoad } from "../../../../utils/types";
import { getPickupProjects } from "apiv1/recommend";
import shuffle from "lodash/shuffle";

const TopItemPickUp: React.VFC = () => {
  const [projects, setProjects] = useState<WithLoad<CardProps[]>>("loading");
  useEffect(() => {
    getPickupProjects()
      .then((res) => {
        const projects = shuffle(res.data.map(toCardProps));
        setProjects(projects);
      })
      .catch(() => setProjects("notfound"));
  }, []);

  return <ProjectCards title="ピックアップ" cards={projects} />;
};

export default TopItemPickUp;
