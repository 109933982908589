import { HeadingTagType } from "@lexical/rich-text";
import { SerializedEditorState } from "lexical";
import { atom, atomFamily } from "recoil";

export const currentBookletIdState = atom<string>({
  key: "editorLexical/currentBookletId",
  default: "xxx",
});

export const bookletIdsState = atom<string[]>({
  key: "editorLexical/bookletIds",
  default: ["xxx"],
});

export const bookletState = atomFamily<SerializedEditorState | null, string>({
  key: "editorLexical/booklet",
  default: null,
});

export const bookletNameState = atomFamily<string, string>({
  key: "editorLexical/bookletName",
  default: "",
});

export type Heading = {
  key: string;
  tagType: HeadingTagType;
  name: string;
};

export const headingsState = atomFamily<Heading[], string>({
  key: "editorLexical/headings",
  default: [],
});

export const openHeadingsState = atomFamily<boolean, string>({
  key: "editorLexical/openHeadings",
  default: false,
});

export type FocusInfo = {
  bookletId: string;
  nodeKey: string;
  behavior?: ScrollBehavior;
};

export const focusNodeState = atom<FocusInfo | null>({
  key: "editorLexical/focusNodeState",
  default: null,
});

export const hasSaveErrorState = atom<boolean>({
  key: "editorLexical/hasSaveError",
  default: false,
});

export const editedAtState = atom<Date | null>({
  key: "editorLexical/editedAt",
  default: null,
});

export const savedAtState = atom<Date | null>({
  key: "editorLexical/savedAt",
  default: null,
});

export const seqState = atom<number>({
  key: "editorLexical/seq",
  default: 0,
});

export const openSidebarState = atom<boolean>({
  key: "editorLexical/openSidebar",
  default: true,
});

export const openSidebarMobileState = atom<boolean>({
  key: "editorLexical/openSidebarMobile",
  default: false,
});

export const openSettingsState = atom<boolean>({
  key: "editorLexical/openSettings",
  default: false,
});

export const openPublishState = atom<boolean>({
  key: "editorLexical/openPublish",
  default: false,
});

export const openUploadingState = atom<boolean>({
  key: "editorLexical/openUploading",
  default: false,
});

export const openPreviewState = atom<boolean>({
  key: "editorLexical/openPreview",
  default: false,
});
