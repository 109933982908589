import { Order } from "apiv1/sales";
import React from "react";
import { formatDate } from "utils/format";
import { paymentMethod } from "utils/order";
import styles from "./ModalSalesOrder.module.css";

type ModalSalesOrderProps = {
  order: Order | undefined;
};

const ModalSalesOrder: React.FC<ModalSalesOrderProps> = ({ order }) => {
  if (order == null) {
    return null;
  }

  const income = order.price - order.talto_fee - order.license_fee;

  return (
    <div className={styles.container}>
      <h2 className={styles.ttl}>注文番号：{order.order_id}</h2>
      <div className={styles.box}>
        <label className={styles.label}>支払済み</label>
        {/* <label className={styles.label} data-unpaid="true">
          支払待ち
        </label> */}
        <h3 className={styles.name}>{order.project_name}</h3>
        <ul className={styles.list}>
          <li className={styles.inner}>
            <h4 className={styles.status}>支払い方法</h4>
            <p className={styles.txt}>
              {paymentMethod(order.price, order.coupon_id)}
            </p>
          </li>
          <li className={styles.inner}>
            <h4 className={styles.status}>販売価格</h4>
            <div className={styles.right}>
              <p className={styles.txtPrice}>
                ¥{order.price.toLocaleString("ja-JP")}
              </p>
              <dl className={styles.breakdown}>
                <dt>販売手数料</dt>
                <dd>¥{order.talto_fee.toLocaleString("ja-JP")}</dd>
              </dl>
              {order.license_fee > 0 && (
                <dl className={styles.breakdown}>
                  <dt>販売許諾料</dt>
                  <dd>¥{order.license_fee.toLocaleString("ja-JP")}</dd>
                </dl>
              )}
              <dl className={styles.breakdown}>
                <dt>販売利益</dt>
                <dd>¥{income.toLocaleString("ja-JP")}</dd>
              </dl>
              <ul className={styles.note}>
                <li>※ 個人間の取引の場合、消費税はありません。</li>
                <li>
                  ※
                  作品が購入された際に販売価格の5%と50円を販売手数料をいただいております。
                </li>
                <li>
                  ※
                  販売許諾番号が指定された作品には、販売手数料とは別に販売許諾料をいただいております。販売許諾料は、TALTOから各権利者へ支払われます。
                </li>
              </ul>
            </div>
          </li>
          {/* <li className={styles.inner}>
            <h4 className={styles.status}>注文日時</h4>
            <p className={styles.txt}>2022/04/05 09:03:50</p>
          </li> */}
          <li className={styles.inner}>
            <h4 className={styles.status}>支払完了日時</h4>
            <p className={styles.txt}>
              {formatDate(new Date(order.created_at))}
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ModalSalesOrder;
