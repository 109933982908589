import React from "react";
import styles from "./InvalidGift.module.css";

const InvalidGift: React.VFC = () => {
  return (
    <>
      <h1 className={styles.header}>このギフトURLは無効です</h1>
      <p className={styles.text}>
        こちらのURLはご利用いただけません。
        <br />
        以下の理由でURLが無効になっている可能性があります。
      </p>
      <ul className={styles.list}>
        <li>・ すでにギフトの受け取りが済んでいる</li>
        <li>・ URLの入力やリンク先が間違っている</li>
      </ul>
    </>
  );
};

export default InvalidGift;
