import React from "react";
import styles from "./EditorAttachmentList.module.css";
import { ReactComponent as IconDelete } from "./IconDelete.svg";
import { ReactComponent as IconFile } from "./IconFile.svg";
import { ReactComponent as IconPicture } from "./IconPicture.svg";
import { ReactComponent as IconMovie } from "./IconMovie.svg";
import { ReactComponent as IconMusic } from "./IconMusic.svg";
import { ReactComponent as IconPdf } from "./IconPdf.svg";
import { ReactComponent as IconZip } from "./IconZip.svg";
import { Attachment } from "api";

const formatDate = (day: string) => {
  const d = new Date(Date.parse(day));
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const hour = d.getHours().toString().padStart(2, "0");
  const min = d.getMinutes().toString().padStart(2, "0");
  return `${year}年${month}月${date}日 ${hour}:${min}`;
};

const AttachmentIcon: React.FC<{ mimetype: string }> = ({ mimetype }) => {
  if (mimetype.startsWith("image/")) {
    return <IconPicture />;
  } else if (mimetype.startsWith("video/")) {
    return <IconMovie />;
  } else if (mimetype.startsWith("audio/")) {
    return <IconMusic />;
  } else if (mimetype === "application/zip") {
    return <IconZip />;
  } else if (mimetype === "application/pdf") {
    return <IconPdf />;
  } else {
    return <IconFile />;
  }
};

const AttachmentItem: React.FC<{
  item: Attachment;
  dropItem: (fileName: string) => Promise<void>;
}> = ({ item, dropItem }) => {
  const handleDelete = () => {
    if (
      window.confirm(
        `添付ファイル『${item.name}』を削除しますか？\n削除すると閲覧者がファイルをダウンロードできなくなります`
      )
    ) {
      dropItem(item.name).catch(() => alert("ファイルの削除に失敗しました"));
    }
  };

  return (
    <li className={styles.flex}>
      <div className={styles.imgPicture}>
        <AttachmentIcon mimetype={item.mimetype} />
      </div>
      <div className={styles.description}>
        <div className={styles.nameRow}>
          <p className={styles.name}>{item.name}</p>
          <button className={styles.delete} onClick={handleDelete}>
            <IconDelete />
          </button>
        </div>
        <div className={styles.date}>{formatDate(item.createdAt)}</div>
      </div>
    </li>
  );
};

const EditorAttachmentList: React.FC<{
  items: Attachment[];
  dropItem: (fileName: string) => Promise<void>;
}> = ({ items, dropItem }) => {
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {items.map((item) => (
          <AttachmentItem item={item} key={item.name} dropItem={dropItem} />
        ))}
      </ul>
    </div>
  );
};

export default EditorAttachmentList;
