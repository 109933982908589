import React from "react";
import styles from "./SalesTabMenu.module.css";

export type Mode = "projects" | "orders" | "transfers";

type SalesTabMenuProps = {
  mode: Mode;
  setMode: (mode: Mode) => void;
};

const btnClassName = (current: Mode, tab: Mode) => {
  return current === tab ? styles.active : "";
};

export const SalesHeader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.ttl}>売上管理</h1>
      </div>
      <div className={styles.navBlank}></div>
    </div>
  );
};

const SalesTabMenu: React.FC<SalesTabMenuProps> = ({ mode, setMode }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.ttl}>売上管理</h1>
      </div>
      <div className={styles.nav}>
        <ul className={styles.list}>
          <li
            className={btnClassName(mode, "projects")}
            onClick={() => setMode("projects")}
          >
            販売作品一覧
          </li>
          <li
            className={btnClassName(mode, "orders")}
            onClick={() => setMode("orders")}
          >
            注文履歴
          </li>
          <li
            className={btnClassName(mode, "transfers")}
            onClick={() => setMode("transfers")}
          >
            入金一覧
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SalesTabMenu;
