import React, { useState, useEffect, useRef } from "react";
import styles from "./GenerateThumbnail.module.css";
import Image1 from "./images/1-100.jpg";
import Image2 from "./images/2-100.jpg";
import Image3 from "./images/3-100.jpg";
import Image5 from "./images/5-100.jpg";
import Image6 from "./images/6-100.jpg";
import Image7 from "./images/7-100.jpg";
import Image10 from "./images/10-100.jpg";
import Image11 from "./images/11-100.jpg";
import Image12 from "./images/12-100.jpg";
import Image13 from "./images/13-100.jpg";
import Image14 from "./images/14-100.jpg";
import Image15 from "./images/15-100.jpg";
import Image16 from "./images/16-100.jpg";
import Image17 from "./images/17-100.jpg";
import Image18 from "./images/18-100.jpg";
import Image19 from "./images/19-100.jpg";
import Image20 from "./images/20-100.jpg";
import Image21 from "./images/21.png";
import Image22 from "./images/22.png";
import Image23 from "./images/23.png";
import Image24 from "./images/24.png";
import Image25 from "./images/25.png";
import Image26 from "./images/26.png";

import SampleImage1 from "./imageSamples/1-100.jpg";
import SampleImage2 from "./imageSamples/2-100.jpg";
import SampleImage3 from "./imageSamples/3-100.jpg";
import SampleImage5 from "./imageSamples/5-100.jpg";
import SampleImage6 from "./imageSamples/6-100.jpg";
import SampleImage7 from "./imageSamples/7-100.jpg";
import SampleImage10 from "./imageSamples/10-100.jpg";
import SampleImage11 from "./imageSamples/11-100.jpg";
import SampleImage12 from "./imageSamples/12-100.jpg";
import SampleImage13 from "./imageSamples/13-100.jpg";
import SampleImage14 from "./imageSamples/14-100.jpg";
import SampleImage15 from "./imageSamples/15-100.jpg";
import SampleImage16 from "./imageSamples/16-100.jpg";
import SampleImage17 from "./imageSamples/17-100.jpg";
import SampleImage18 from "./imageSamples/18-100.jpg";
import SampleImage19 from "./imageSamples/19-100.jpg";
import SampleImage20 from "./imageSamples/20-100.jpg";
import SampleImage21 from "./imageSamples/21.jpg";
import SampleImage22 from "./imageSamples/22.jpg";
import SampleImage23 from "./imageSamples/23.jpg";
import SampleImage24 from "./imageSamples/24.jpg";
import SampleImage25 from "./imageSamples/25.jpg";
import SampleImage26 from "./imageSamples/26.jpg";

import { ReactComponent as IconUnion } from "./IconUnion.svg";
import { ImageFile } from "../InputThumbnail";
import {
  centerBigTemplateBlack,
  centerBigTemplateWhite,
  centerNeonTemplate,
  centerTemplate,
  centerTemplate2,
  centerWhiteTemplate,
  emokloreTemplate,
  // emokloreTemplate,
  horrorTemplate,
  leftRightTemplate,
  leftTemplate,
  presentTemplate,
  rightTemplate,
} from "./textStyle";

const THUMBNAIL_SIZE_LIMIT = 2 * 1024 * 1024; // 2 MiB

type ModalGenerateThumbnailProps = {
  name: string;
  categoryName: string;
  setCoverImageFile: (x: ImageFile | undefined) => void;
};

type typeTextStyle = {
  position: {
    x: number;
    y: number;
  };
  textMaxWidth: number;
  horizontalAlign: "left" | "center" | "right";
  verticalAlign: "top" | "center" | "bottom";
  maxLine: number;
  font: string;
  fontSize: number;
  fontStyle?: string;
  color: string;
  linePadding: number;
};

export type typeTextStyles = {
  name: typeTextStyle;
  categoryName?: typeTextStyle;
};

type typeThumbnailTemplate = {
  name: string;
  backgroundImage: string;
  sampleImage: string;
  textStyles: typeTextStyles;
};

const thumbnailTemplate: typeThumbnailTemplate[] = [
  {
    name: "center-yellow",
    backgroundImage: Image1,
    sampleImage: SampleImage1,
    textStyles: centerTemplate,
  },
  {
    name: "center-blue",
    backgroundImage: Image2,
    sampleImage: SampleImage2,
    textStyles: centerTemplate,
  },
  {
    name: "center-red",
    backgroundImage: Image3,
    sampleImage: SampleImage3,
    textStyles: centerTemplate,
  },
  {
    name: "center-green",
    backgroundImage: Image5,
    sampleImage: SampleImage5,
    textStyles: centerTemplate,
  },
  {
    name: "center-6",
    backgroundImage: Image6,
    sampleImage: SampleImage6,
    textStyles: centerTemplate,
  },
  {
    name: "center-7",
    backgroundImage: Image7,
    sampleImage: SampleImage7,
    textStyles: centerTemplate,
  },
  {
    name: "center-10",
    backgroundImage: Image10,
    sampleImage: SampleImage10,
    textStyles: centerTemplate,
  },
  {
    name: "right-1",
    backgroundImage: Image11,
    sampleImage: SampleImage11,
    textStyles: rightTemplate,
  },
  {
    name: "right-2",
    backgroundImage: Image13,
    sampleImage: SampleImage13,
    textStyles: rightTemplate,
  },
  {
    name: "right-3",
    backgroundImage: Image14,
    sampleImage: SampleImage14,
    textStyles: rightTemplate,
  },
  {
    name: "right-4",
    backgroundImage: Image15,
    sampleImage: SampleImage15,
    textStyles: rightTemplate,
  },
  {
    name: "right-5",
    backgroundImage: Image16,
    sampleImage: SampleImage16,
    textStyles: rightTemplate,
  },
  {
    name: "emoklore",
    backgroundImage: Image17,
    sampleImage: SampleImage17,
    textStyles: emokloreTemplate,
  },
  {
    name: "19",
    backgroundImage: Image19,
    sampleImage: SampleImage19,
    textStyles: leftRightTemplate,
  },
  {
    name: "center-big-circle",
    backgroundImage: Image12,
    sampleImage: SampleImage12,
    textStyles: centerBigTemplateBlack,
  },
  {
    name: "center-big-red-dot",
    backgroundImage: Image20,
    sampleImage: SampleImage20,
    textStyles: centerBigTemplateWhite,
  },
  {
    name: "horror",
    backgroundImage: Image18,
    sampleImage: SampleImage18,
    textStyles: horrorTemplate,
  },
  {
    name: "21",
    backgroundImage: Image21,
    sampleImage: SampleImage21,
    textStyles: centerTemplate2,
  },
  {
    name: "22",
    backgroundImage: Image22,
    sampleImage: SampleImage22,
    textStyles: presentTemplate,
  },
  {
    name: "23",
    backgroundImage: Image23,
    sampleImage: SampleImage23,
    textStyles: presentTemplate,
  },
  {
    name: "24",
    backgroundImage: Image24,
    sampleImage: SampleImage24,
    textStyles: centerNeonTemplate,
  },
  {
    name: "25",
    backgroundImage: Image25,
    sampleImage: SampleImage25,
    textStyles: centerWhiteTemplate,
  },
  {
    name: "26",
    backgroundImage: Image26,
    sampleImage: SampleImage26,
    textStyles: leftTemplate,
  },
];

const fixedFillText = (ctx: any, text: any, style: typeTextStyle) => {
  const width = style.textMaxWidth || 1280;
  const maxLine = style.maxLine === 0 ? 100 : style.maxLine;
  const frontKinsokuChars = [
    "、",
    "。",
    '"',
    ")",
    "]",
    "}",
    "）",
    "」",
    "｝",
    "!",
    "?",
    "！",
    "？",
  ];
  const endKinsokuChars = ["(", "[", "{", "「", "（", "｛"];
  let column = [""];
  let line = 0;

  ctx.font = `${style.fontStyle || ""} ${style.fontSize}px ${style.font}`;
  ctx.fillStyle = style.color;

  const dotLen = ctx.measureText("...").width;

  for (let i = 0; i < text.length; i++) {
    const char = text.charAt(i);
    const tmpText =
      line < maxLine - 1 ? column[line] + char : column[line] + char + "...";
    if (char === "\n" || ctx.measureText(tmpText).width > width) {
      const frontKinsoku = frontKinsokuChars.includes(text.charAt(i));
      const endKinsoku = endKinsokuChars.includes(text.charAt(i - 1));

      if (frontKinsoku) {
        column[line] = column[line].slice(0, -1);
        line++;
        column[line] = "" + text.charAt(i - 1) + char;
        continue;
      }

      if (endKinsoku) {
        column[line] += text.charAt(i);
        continue;
      }

      line++;
      column[line] = "";
    }
    column[line] += char;
  }

  let paddingX = 0;
  let paddingY = 0;
  const len = column.length > maxLine ? maxLine : column.length;
  for (let i = 0; i < len; i++) {
    let lineWidth = ctx.measureText(column[i]).width;
    lineWidth = i === maxLine - 1 ? lineWidth + dotLen : lineWidth;
    if (style.horizontalAlign === "right") {
      paddingX = lineWidth - width;
    } else if (style.horizontalAlign === "center") {
      paddingX = lineWidth / 2;
    } else {
      paddingX = 0;
    }

    if (style.verticalAlign === "bottom") {
      paddingY = style.fontSize * len + style.linePadding * (len - 1);
    } else if (style.verticalAlign === "center") {
      paddingY = (style.fontSize * len + style.linePadding * (len - 1)) / 2;
    }

    if (i === maxLine - 1 && lineWidth > width * 0.8) {
      column[maxLine - 1] = column[maxLine - 1] + "...";
    }

    ctx.fillText(
      column[i],
      style.position.x - paddingX,
      style.position.y +
        style.fontSize +
        (style.fontSize + style.linePadding) * i -
        paddingY
    );
  }
};

const GenerateThumbnail: React.FC<ModalGenerateThumbnailProps> = ({
  name,
  categoryName,
  setCoverImageFile,
}) => {
  const canvasRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [index, setIndex] = useState<number>();

  const getContext = (): CanvasRenderingContext2D => {
    const canvas: any = canvasRef.current;
    return canvas.getContext("2d");
  };

  const setCoverImage = () => {
    const canvas: any = canvasRef.current;
    canvas.toBlob((blob: Blob) => {
      const file = new File([blob], "thumbnail.png", {
        type: "image/png",
      });
      const objectURL = URL.createObjectURL(blob);
      setCoverImageFile({ file, objectURL });
    });
  };

  useEffect(() => {
    if (index != null) {
      const ctx: CanvasRenderingContext2D = getContext();
      const background = new Image();
      const template = thumbnailTemplate[index];
      ctx.fillStyle = "rgba(255, 255, 255, 1)";
      ctx.textAlign = "left";
      ctx.fillRect(0, 0, 1280, 720);
      background.src = template.backgroundImage;
      background.onload = () => {
        ctx.drawImage(background, 0, 0);
        fixedFillText(ctx, name, template.textStyles.name);
        if (template.textStyles.categoryName) {
          fixedFillText(ctx, categoryName, template.textStyles.categoryName);
        }
        setCoverImage();
      };
    }
  }, [index, name, categoryName]);

  const handleNew = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files == null || event.target.files.length === 0) {
      return;
    }

    const file = event.target.files[0];
    event.target.value = "";
    if (file.size > THUMBNAIL_SIZE_LIMIT) {
      alert("表紙画像は2MB以下としてください");
      return;
    }

    const objectURL = URL.createObjectURL(file);
    setCoverImageFile({ file, objectURL });
    setIndex(undefined);
  };

  return (
    <>
      <canvas
        className={styles.canvas}
        width={1280}
        height={720}
        ref={canvasRef}
      />
      <div className={styles.templates}>
        <div
          className={styles.new}
          title="表紙画像をアップロード"
          onClick={handleNew}
        >
          <IconUnion />
          <input ref={inputRef} type="file" onChange={handleChangeFile} />
        </div>
        {thumbnailTemplate.map((item, index) => {
          return (
            <img
              src={item.sampleImage}
              key={item.name}
              className={styles.btn}
              onClick={() => setIndex(index)}
              loading="lazy"
            />
          );
        })}
      </div>
    </>
  );
};

export default GenerateThumbnail;
