import React, { UIEventHandler, useRef, useState } from "react";
import styles from "./ProjectCards.module.css";
import Card, { CardProps } from "./Card";
import { WithLoad } from "../../../utils/types";
import throttle from "lodash/throttle";
import { ReactComponent as IconArrowRight } from "./IconArrowRightS.svg";
import { ReactComponent as IconArrowLeft } from "./IconArrowLeftS.svg";
import CardSkeleton from "./CardSkeleton";

type ProjectCardsProps = {
  title: string;
  cards: WithLoad<CardProps[]>;
  expand?: boolean;
  loadedAll?: boolean;
  onClickReadMore?: () => void;
};

const throttled = throttle(
  (
    elem: HTMLUListElement,
    setBegin: (x: boolean) => void,
    setEnd: (x: boolean) => void
  ) => {
    setBegin(elem.scrollLeft === 0);
    setEnd(elem.scrollLeft + elem.clientWidth + 10 >= elem.scrollWidth);
  },
  500 // 0.5sec
);

const ProjectCards: React.VFC<ProjectCardsProps> = ({
  title,
  cards,
  expand = false,
  loadedAll = false,
  onClickReadMore,
}) => {
  const ref = useRef<HTMLUListElement>(null);
  const [begin, setBegin] = useState<boolean>(true);
  const [end, setEnd] = useState<boolean>(false);
  const listClassName = expand ? styles.listGrid : styles.listInline;

  if (cards === "loading") {
    return (
      <section className={styles.container}>
        <div className={styles.flex}>
          <h1 className={styles.category}>{title}</h1>
        </div>
        <ul className={listClassName} data-noscroll={true}>
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
          <CardSkeleton />
        </ul>
      </section>
    );
  }

  if (cards === "notfound") {
    return (
      <section className={styles.container}>
        <div className={styles.flex}>
          <h1 className={styles.category}>{title}</h1>
        </div>
        <p>データの取得に失敗しました</p>
      </section>
    );
  }

  const handleScrollLeft = () => {
    if (ref.current != null) {
      const width = ref.current.clientWidth;
      ref.current.scrollBy({
        left: -(width * 9) / 10,
        behavior: "smooth",
      });
    }
  };

  const handleScrollRight = () => {
    if (ref.current != null) {
      const width = ref.current.clientWidth;
      ref.current.scrollBy({
        left: (width * 9) / 10,
        behavior: "smooth",
      });
    }
  };

  const handleScroll: UIEventHandler<HTMLUListElement> = () => {
    if (ref.current != null) {
      throttled(ref.current, setBegin, setEnd);
    }
  };

  return (
    <section className={styles.container}>
      <div className={styles.flex}>
        <h1 className={styles.category}>{title}</h1>
        {!expand && onClickReadMore && (
          <button className={styles.buttonPC} onClick={onClickReadMore}>
            もっと見る
          </button>
        )}
      </div>
      <div className={styles.listWrap}>
        <ul className={listClassName} ref={ref} onScroll={handleScroll}>
          {cards.map((card) => {
            return <Card key={card.projectId} {...card} />;
          })}
        </ul>
        {!expand && (
          <>
            <button
              className={styles.scrollLeft}
              onClick={handleScrollLeft}
              disabled={begin}
            >
              <IconArrowLeft />
            </button>
            <button
              className={styles.scrollRight}
              onClick={handleScrollRight}
              disabled={end}
            >
              <IconArrowRight />
            </button>
          </>
        )}
      </div>
      {expand && !loadedAll && onClickReadMore && (
        <button className={styles.buttonMoreBottom} onClick={onClickReadMore}>
          もっと見る
        </button>
      )}
    </section>
  );
};

export default React.memo(ProjectCards);
