import React from "react";
import styles from "./ViewerMainSection.module.css";
import { DraftInlineStyleType, RawDraftContentBlock } from "draft-js";
import { BlockNode, decodeBlock, EntityMap } from "./generateInlineTree";
import { SaveableBooklet } from "pages/Editor/EditorProvider";

const InlineStyled: React.FC<{
  contentBlock: RawDraftContentBlock;
  entityMap: EntityMap;
}> = ({ contentBlock, entityMap }) => {
  const block = decodeBlock(contentBlock, entityMap);
  if (block.length < 1) return <br />;
  return (
    <>
      {block.map((node, idx) => (
        <Node node={node} key={idx} />
      ))}
    </>
  );
};

const Node: React.FC<{ node: BlockNode }> = ({ node }) => {
  const leafs = node.leafs.map((leaf) => (
    <Leaf types={leaf.types}>{leaf.text}</Leaf>
  ));
  if (node.type === "LINK") {
    return (
      <a
        className={styles.anchor}
        href={node.url}
        target="_blank"
        rel="noopener"
      >
        {leafs}
      </a>
    );
  } else {
    return <>{leafs}</>;
  }
};

const Leaf: React.FC<{ types: DraftInlineStyleType[] }> = ({
  types,
  children,
}) => {
  let ret = <>{children}</>;
  if (types.includes("BOLD")) {
    ret = <span className={styles.bold}>{ret}</span>;
  }
  if (types.includes("UNDERLINE")) {
    ret = <span className={styles.underline}>{ret}</span>;
  }

  return ret;
};

type NestedRawDraftContentBlock = RawDraftContentBlock[];

const toNestedRawDraftContentBlocks = (
  blocks: RawDraftContentBlock[]
): NestedRawDraftContentBlock[] => {
  const nestedBlocks = [];
  let b = null;
  let lastBlocks = null;
  for (let i = 0; i < blocks.length; i++) {
    b = blocks[i];
    lastBlocks = nestedBlocks[nestedBlocks.length - 1];
    if (
      b.type.startsWith("block-") &&
      lastBlocks &&
      lastBlocks[0]?.type === b.type
    ) {
      lastBlocks.push(b);
    } else {
      nestedBlocks.push([b]);
    }
  }
  return nestedBlocks;
};

type ViewerMainSectionProps = {
  projectName: string;
  booklet: SaveableBooklet;
};

const ViewerMainSection: React.FC<ViewerMainSectionProps> = ({
  projectName,
  booklet,
}) => {
  const nestedBlocks = React.useMemo(
    () => toNestedRawDraftContentBlocks(booklet.rawDraftContentState.blocks),
    [booklet.rawDraftContentState.blocks]
  );
  return (
    <div className={styles.container}>
      <p className={styles.projectTitle}>{projectName}</p>
      <p className={styles.libretto}>{booklet.name}</p>
      {nestedBlocks.map((blocks) => {
        const b = blocks[0];
        if (b.type === "header-one") {
          return (
            <h1 className={styles.h1} id={b.key} key={b.key}>
              <InlineStyled
                contentBlock={b}
                entityMap={booklet.rawDraftContentState.entityMap}
              />
            </h1>
          );
        } else if (b.type === "header-two") {
          return (
            <h2 className={styles.h2} id={b.key} key={b.key}>
              <InlineStyled
                contentBlock={b}
                entityMap={booklet.rawDraftContentState.entityMap}
              />
            </h2>
          );
        } else if (b.type === "header-three") {
          return (
            <h3 className={styles.h3} key={b.key}>
              <InlineStyled
                contentBlock={b}
                entityMap={booklet.rawDraftContentState.entityMap}
              />
            </h3>
          );
        } else if (b.type === "block-note") {
          return (
            <div className={styles.notes} key={b.key}>
              {blocks.map((bb) => (
                <div>
                  <InlineStyled
                    key={bb.key}
                    contentBlock={bb}
                    entityMap={booklet.rawDraftContentState.entityMap}
                  />
                </div>
              ))}
            </div>
          );
        } else if (b.type === "atomic") {
          const entityRange = b.entityRanges[0];
          if (entityRange == null) {
            return null;
          }

          const key = entityRange.key.toString();
          const entity = booklet.rawDraftContentState.entityMap[key];
          if (entity.type === "IMAGE") {
            return (
              <div className={styles.img} key={b.key}>
                <img src={entity.data.src} alt="img" />
              </div>
            );
          } else {
            return (
              <p className={styles.txt} key={b.key}>
                {b.text}
              </p>
            );
          }
        } else {
          return (
            <div className={styles.txt} key={b.key}>
              <InlineStyled
                contentBlock={b}
                entityMap={booklet.rawDraftContentState.entityMap}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export default ViewerMainSection;
