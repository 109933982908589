import React from "react";
import styles from "./BookmarkFillterCategory.module.css";

export type Filter = "bookmarks" | "purchases" | "userProjects";

export type BookmarkFillterCategoryProps = {
  filter: Filter;
  setFilter: (filter: Filter) => void;
};

const BookmarkFillterCategory: React.FC<BookmarkFillterCategoryProps> = ({
  filter,
  setFilter,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.ttl}>本棚</h1>
      </div>
      <div className={styles.nav}>
        <ul className={styles.list}>
          <li
            className={filter === "bookmarks" ? styles.active : ""}
            onClick={() => setFilter("bookmarks")}
          >
            本棚
          </li>
          <li
            className={filter === "purchases" ? styles.active : ""}
            onClick={() => setFilter("purchases")}
          >
            購入した作品
          </li>
          <li
            className={filter === "userProjects" ? styles.active : ""}
            onClick={() => setFilter("userProjects")}
          >
            自分の作品
          </li>
        </ul>
      </div>
    </div>
  );
};

export default BookmarkFillterCategory;
