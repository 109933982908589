export const handlerClipboardWrite = (text: string) => () => {
  if (!navigator.clipboard) {
    alert("クリップボードへのコピーに失敗しました");
  }

  navigator.clipboard
    .writeText(text)
    .then(() => alert("クリップボードにコピーしました"))
    .catch(() => alert("クリップボードへのコピーに失敗しました"));
};
