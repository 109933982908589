import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import styles from "./ProjectCards.module.css";
import { SearchedProject } from "apiv1/project";

export type CardProps = {
  projectId: string;
  title: string;
  category: string;
  author: string;
  price: number | null;
  thumbnail: string | null;
};

const Card: React.VFC<CardProps> = (props) => {
  const location = useLocation();

  return (
    <li className={styles.item}>
      <Link
        className={styles.link}
        to={{
          pathname: `/projects/${props.projectId}`,
          state: { background: location },
        }}
      >
        <div className={styles.thumbnail}>
          <SafeImg src={props.thumbnail} alt={props.title} />
          {props.price && (
            <p className={styles.price}>
              {props.price.toLocaleString("ja-JP")}円
            </p>
          )}
        </div>
        <p className={styles.ttl}>{props.title}</p>
        <p className={styles.genre}>{props.category}</p>
        <p className={styles.name}>{props.author}</p>
      </Link>
    </li>
  );
};

type SafeImgProps = {
  src: string | null;
  alt: string;
};

const SafeImg: React.VFC<SafeImgProps> = ({ src, alt }) => {
  const [thumbnail, setThumbnail] = useState<string | null>();
  const { ref, inView } = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      setThumbnail(src);
    }
  }, [inView, src, setThumbnail]);

  if (thumbnail == null) {
    return <div ref={ref} className={styles.blankThumbnail} />;
  } else {
    return <img src={thumbnail} alt={alt} loading="lazy" />;
  }
};

export const toCardProps = (project: SearchedProject): CardProps => {
  return {
    projectId: project.id,
    title: project.name,
    category: project.category_name,
    author: project.author,
    price: project.fee_type === "all_contents" ? project.price : null,
    thumbnail: project.cover_url,
  };
};

export default React.memo(Card);
