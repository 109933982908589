import "./Footer.css";

const FooterSkeleton = () => {
  return (
    <div className="Footer__root" data-open-sidebar="true">
      <div className="Footer__container">
        <div className="Footer__lastSaved">最終保存 -</div>
        <div className="Footer__count">0文字</div>
      </div>
    </div>
  );
};

export default FooterSkeleton;
