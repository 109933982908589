import React from "react";
import styles from "./ModalShare.module.css";
import { ReactComponent as IconBooklet } from "./IconBooklet.svg";
import { postReadToken } from "apiv1/project";
import { logEvent } from "@firebase/analytics";
import { analytics } from "initialize";
import ModalLazy from "components/Modal/ModalLazy";

const ERROR_MSG =
  "エラー：共有用URLをクリップボードにコピーしようとしましたが、失敗しました。";

type ShareBookletProps = {
  projectName: string;
  bookletName: string;
  projectId: string;
  rev: string;
  bookletId: string;
  needToken: boolean;
};

const ShareBooklet: React.FC<ShareBookletProps> = ({
  projectName,
  bookletName,
  projectId,
  rev,
  bookletId,
  needToken,
}) => {
  const copyShareUrl = async () => {
    if (navigator.clipboard == null) {
      return alert(ERROR_MSG);
    }

    const url = new URL(window.location.href);
    url.pathname = `/projects/${projectId}/rev/${rev}/booklets/${bookletId}/shared`;
    if (needToken) {
      try {
        const res = await postReadToken(projectId, rev);
        url.searchParams.set("token", res.data.token);
      } catch {
        alert(ERROR_MSG);
        return;
      }
    }

    const urlWithToken = url.toString();

    navigator.clipboard
      .writeText(urlWithToken)
      .then(() => alert("共有用URLをクリップボードにコピーしました"))
      .catch(() => alert(ERROR_MSG));

    logEvent(analytics, "click_booklet_share_button", {
      project_id: projectId,
      project_name: projectName,
      rev: rev,
      booklet_id: bookletId,
      booklet_name: bookletName,
    });
  };

  return (
    <div className={styles.shareBooklet}>
      <IconBooklet />
      <div className={styles.bookletName}>{bookletName}</div>
      <button className={styles.btn} onClick={copyShareUrl}>
        この冊子を共有
      </button>
    </div>
  );
};

export type BookletInfo = {
  id: string;
  name: string;
};

type ModalShareProps = {
  projectName: string;
  projectId: string;
  rev: string;
  booklets: BookletInfo[];
  needToken: boolean;
  open: boolean;
  onClose: () => void;
};

const ModalShare: React.FC<ModalShareProps> = ({
  projectName,
  projectId,
  rev,
  booklets,
  needToken,
  open,
  onClose,
}) => {
  return (
    <ModalLazy open={open} onClose={onClose}>
      <div className={styles.container}>
        <h2 className={styles.ttl}>共有する冊子を選択</h2>
        <div className={styles.body}>
          <h3 className={styles.projectName}>{projectName}</h3>
          <div>
            {booklets.map((booklet) => (
              <ShareBooklet
                key={booklet.id}
                projectName={projectName}
                bookletName={booklet.name}
                projectId={projectId}
                rev={rev}
                bookletId={booklet.id}
                needToken={needToken}
              />
            ))}
          </div>
          <div className={styles.notation}>
            <h4>共有URLについて</h4>
            <p>
              ※
              共有URLを受け取った相手は共有された冊子のみ閲覧できます。資料を配布する際にご利用ください。
            </p>
          </div>
        </div>
      </div>
    </ModalLazy>
  );
};

export default React.memo(ModalShare);
