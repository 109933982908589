import React, { useState, useContext } from "react";
import styles from "./HeaderUserIcon.module.css";
import DefaultUserIcon from "components/images/DefaultUserIcon.jpg";
import { Link } from "react-router-dom";
import { AuthContext } from "components/AuthProvider";

type HoverMenuProps = {
  open: boolean;
  onClose: (event: React.MouseEvent) => void;
  uid: string;
  variant?: "small" | "normal";
  isSp?: boolean;
};

export const HoverMenu = ({
  open,
  onClose,
  uid,
  variant = "normal",
  isSp = false,
}: HoverMenuProps) => {
  if (!open) {
    return null;
  }

  return (
    <>
      <div
        className={styles.userMenu}
        hidden={!open}
        data-sp={isSp}
        data-variant={variant}
      >
        <ul className={styles.userMenuContent}>
          <li>
            <Link to="/bookmarks">本棚</Link>
          </li>
          <li>
            <Link to="/my_projects">執筆作品一覧</Link>
          </li>
          <li>
            <Link to="/orders">購入履歴</Link>
          </li>
        </ul>
        <ul className={styles.userMenuContent}>
          <li>
            <Link to={`/users/${uid}`}>プロフィール</Link>
          </li>
          <li>
            <Link to="/settings/profile">プロフィール設定</Link>
          </li>
          <li>
            <Link to="/settings/account">アカウント設定</Link>
          </li>
        </ul>
        <ul className={styles.userMenuContent}>
          <li>
            <Link to="/sales">売上管理</Link>
          </li>
          <li>
            <Link to="/settings/sales">販売者情報設定</Link>
          </li>
          <li>
            <Link to="/scenario-ticket">シナチケ管理</Link>
          </li>
        </ul>
        <ul className={styles.userMenuContent}>
          <li>
            <Link to="/">トップページ</Link>
          </li>
          <li>
            <Link to="/help">ヘルプ</Link>
          </li>
          <li>
            <Link to="/logout">ログアウト</Link>
          </li>
        </ul>
      </div>
      <div className={styles.overlay} hidden={!open} onClick={onClose} />
    </>
  );
};

type HeaderUserIconProps = {
  variant?: "small" | "normal";
};

const HeaderUserIcon = ({ variant = "normal" }: HeaderUserIconProps) => {
  const user = useContext(AuthContext);
  const [hiddenMenu, setHiddenMenu] = useState(true);
  const openMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setHiddenMenu(false);
  };
  const closeMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setHiddenMenu(true);
  };

  if (!user.authed) {
    return null;
  }

  return (
    <>
      <button className={styles.user} onClick={openMenu} data-variant={variant}>
        <img src={user.photoURL || DefaultUserIcon} alt="ImgUser" />
      </button>
      <HoverMenu
        open={!hiddenMenu}
        onClose={closeMenu}
        uid={user.uid}
        variant={variant}
      />
    </>
  );
};

export default React.memo(HeaderUserIcon);
