import { EditorState, EditorThemeClasses } from "lexical";
import { ChangeEvent } from "react";

import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";

import Toolbar from "../Toolbar";
import "./ContentEditable.css";
import "./Editor.css";
import "./EditorTheme.css";
import Sidebar from "../Sidebar";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentBookletIdState,
  bookletNameState,
  openSidebarState,
} from "../../recoil/atom";
import SwitchPlugin from "../SwitchPlugin";
import Footer from "../Footer";
import AutoSavePlugin from "../AutoSavePlugin";
import { HistoryState } from "@lexical/react/LexicalHistoryPlugin";
import HeadingWatchPlugin from "../HeadingWatchPlugin";
import AutoFocusPlugin from "../AutoFocusPlugin";
import ImagesPlugin from "../../nodes/ImageNode/ImagesPlugin";
import { ImageNode } from "../../nodes/ImageNode";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { AUTOSAVE_COMMAND } from "../AutoSavePlugin/AutoSavePlugin";
import AutoScrollPlugin from "../AutoScrollPlugin";
import { DivParagraphNode } from "../../nodes/DivParagraphNode";
import Uploading from "../Uploading";
import Publish from "../Publish";
import Settings from "../Settings";

export const NODES = [HeadingNode, QuoteNode, ImageNode, DivParagraphNode];

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error: Error) {
  console.error(error);
}

const theme: EditorThemeClasses = {
  paragraph: "EditorTheme__paragraph",
  heading: {
    h1: "EditorTheme__h1",
    h2: "EditorTheme__h2",
    h3: "EditorTheme__h3",
  },
  quote: "EditorTheme__quote",
  text: {
    underline: "EditorTheme__textUnderline",
  },
};

type EditorProps = {
  bookletId: string;
  editorState?: EditorState;
  setEditorState: (editorState: EditorState) => void;
  getHisotyState: () => HistoryState;
  setHisotyState: (history: HistoryState) => void;
};

const Editor = ({
  bookletId,
  editorState,
  setEditorState,
  getHisotyState,
  setHisotyState,
}: EditorProps) => {
  const openSidebar = useRecoilValue(openSidebarState);
  const initialConfig = {
    namespace: "MyEditor",
    theme,
    nodes: NODES,
    editorState,
    onError,
  };

  return (
    <div className="Editor__root" data-open-sidebar={openSidebar}>
      <div className="Editor__container">
        <LexicalComposer initialConfig={initialConfig}>
          <InputBookletName />
          <RichTextPlugin
            contentEditable={
              <ContentEditable className="ContentEditable__root" />
            }
            placeholder={
              <div className="Editor__placeholder">ものがたりの始まり</div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <ClearEditorPlugin />
          <AutoFocusPlugin bookletId={bookletId} />
          <Toolbar />
          <Sidebar setEditorState={setEditorState} />
          <Footer />
          <AutoSavePlugin bookletId={bookletId} />
          <AutoScrollPlugin />
          <HeadingWatchPlugin bookletId={bookletId} />
          <SwitchPlugin
            bookletId={bookletId}
            setEditorState={setEditorState}
            getHisotyState={getHisotyState}
            setHisotyState={setHisotyState}
          />
          <ImagesPlugin />
          <Uploading />
          <Publish />
          <Settings />
        </LexicalComposer>
      </div>
    </div>
  );
};

const InputBookletName = () => {
  const [editor] = useLexicalComposerContext();
  const currentBookletId = useRecoilValue(currentBookletIdState);
  const [name, setName] = useRecoilState(bookletNameState(currentBookletId));
  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    editor.dispatchCommand(AUTOSAVE_COMMAND, undefined);
  };
  return (
    <input
      className="Editor__InputBookletName_root"
      placeholder="冊子タイトル"
      value={name}
      onChange={onChange}
    />
  );
};

export default Editor;
