import { ReactComponent as IconAddImage } from "./IconAddImage.svg";
import { ReactComponent as IconBold } from "./IconBold.svg";
import { ReactComponent as IconEnter } from "./IconEnter.svg";
import { ReactComponent as IconH1 } from "./IconH1.svg";
import { ReactComponent as IconH2 } from "./IconH2.svg";
import { ReactComponent as IconH3 } from "./IconH3.svg";
import { ReactComponent as IconMenu } from "./IconMenu.svg";
import { ReactComponent as IconNote } from "./IconNote.svg";
import { ReactComponent as IconRedo } from "./IconRedo.svg";
import { ReactComponent as IconUnderline } from "./IconUnderline.svg";
import { ReactComponent as IconUndo } from "./IconUndo.svg";
import "./Toolbar.css";

const ToolbarSkeleton = () => {
  return (
    <div className="Toolbar__root" data-open-sidebar="true">
      <IconMenu className="Toolbar__menuButton" />
      <div className="Toolbar__container">
        <div className="Toolbar__buttonGroup">
          <IconUndo className="Toolbar__button" data-disabled={true} />
          <IconRedo className="Toolbar__button" data-disabled={true} />
        </div>
        <div className="Toolbar__buttonGroup">
          <IconH1 className="Toolbar__button" />
          <IconH2 className="Toolbar__button" />
          <IconH3 className="Toolbar__button" />
          <IconNote className="Toolbar__button" />
          <IconEnter className="Toolbar__button" />
        </div>
        <div className="Toolbar__buttonGroup">
          <IconBold className="Toolbar__button" />
          <IconUnderline className="Toolbar__button" />
        </div>
        <div className="Toolbar__buttonGroup">
          <IconAddImage className="Toolbar__button" />
        </div>
      </div>
    </div>
  );
};

export default ToolbarSkeleton;
