import React from "react";
import styles from "./ProfileCards.module.css";
import BlankThumnail from "components/ItemCard/blank.svg";
import { Link, useLocation } from "react-router-dom";

type CardProps = {
  projectId: string;
  thumnail: string | null;
  name: string;
  category: string;
};

export const Card: React.FC<CardProps> = ({
  projectId,
  thumnail,
  name,
  category,
}) => {
  const location = useLocation();

  return (
    <li className={styles.cards}>
      <Link
        to={{
          pathname: `/projects/${projectId}`,
          state: { background: location },
        }}
      >
        <div className={styles.thumnail}>
          <img src={thumnail || BlankThumnail} alt={name} />
        </div>
        <p className={styles.category}>{category}</p>
        <h3 className={styles.ttl}>{name}</h3>
      </Link>
    </li>
  );
};

export type ProfileCardsProps = {
  projects: CardProps[];
};

const ProfileCards: React.FC<ProfileCardsProps> = ({ projects }) => {
  if (projects.length === 0) {
    return (
      <div className={styles.container}>
        <p className={styles.noProjects}>公開中の作品はありません</p>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <ul className={styles.list}>
        {projects.map((card) => {
          return <Card {...card} />;
        })}
      </ul>
    </div>
  );
};

export default ProfileCards;
