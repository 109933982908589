import React from "react";
import styles from "./BookmarkList.module.css";
import BookmarkCards, {
  BookmarkCardsProps,
} from "./BookmarkCards/BookmarkCards";

const BookmarkList: React.FC<BookmarkCardsProps> = ({ cards }) => {
  return (
    <div className={styles.container}>
      <BookmarkCards cards={cards}></BookmarkCards>
    </div>
  );
};

export default BookmarkList;
