import React from "react";
import styles from "./ProjectCards.module.css";
import Card, { CardProps } from "./Card";

type ProjectCardsGridProps = {
  cards: CardProps[];
};

const ProjectCardsGrid: React.VFC<ProjectCardsGridProps> = ({ cards }) => {
  return (
    <section className={styles.container}>
      <ul className={styles.listGrid}>
        {cards.map((card) => {
          return <Card key={card.projectId} {...card} />;
        })}
      </ul>
    </section>
  );
};

export default React.memo(ProjectCardsGrid);
