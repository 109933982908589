import React from "react";
import { formatDate } from "utils/format";
import styles from "./Orders.module.css";
import BlankThumnail from "components/ItemCard/blank.svg";
import { paymentMethod } from "utils/order";
import { ReactComponent as IconLink } from "./IconLink.svg";
import { handlerClipboardWrite } from "utils/clipboard";

export type OrdersProps = {
  kind: "order" | "receivedGift";
  price: number;
  categoryName: string;
  projectName: string;
  orderId: string;
  couponId: string | null;
  purchasesedAt: string;
  thumnail: string | null;
  giftId: string | null;
  received: boolean;
};

const Orders: React.FC<OrdersProps> = ({
  kind,
  price,
  categoryName,
  projectName,
  orderId,
  couponId,
  giftId,
  received,
  purchasesedAt,
  thumnail,
}) => {
  const formatedPurchasesedAt = formatDate(new Date(purchasesedAt));
  const giftUrl = window.location.origin + `/gift/receive?code=${giftId}`;
  if (kind === "receivedGift") {
    return (
      <li className={styles.item}>
        <div className={styles.left}>
          <div className={styles.thumnail}>
            <img
              src={thumnail || BlankThumnail}
              alt={projectName}
              loading="lazy"
            />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.flex}>
            <label className={styles.label}>受け取り済み</label>
            <p className={styles.price}>¥{price.toLocaleString("ja-JP")}</p>
          </div>
          <p className={styles.genre}>{categoryName}</p>
          <h3 className={styles.name}>{projectName}</h3>
          <p className={styles.number}>ギフト番号: {orderId}</p>
          <ul className={styles.status}>
            <li>お支払方法: ギフト受け取り</li>
            <li>受け取り日時: {formatedPurchasesedAt}</li>
          </ul>
        </div>
      </li>
    );
  }

  const onCopy = handlerClipboardWrite(giftUrl);

  return (
    <li className={styles.item}>
      <div className={styles.left}>
        <div className={styles.thumnail}>
          <img
            src={thumnail || BlankThumnail}
            alt={projectName}
            loading="lazy"
          />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.flex}>
          <div className={styles.left}>
            <label className={styles.label}>入金済み</label>
            {giftId && <label className={styles.label}>ギフト</label>}
          </div>
          <p className={styles.price}>¥{price.toLocaleString("ja-JP")}</p>
        </div>
        <p className={styles.genre}>{categoryName}</p>
        <h3 className={styles.name}>{projectName}</h3>
        <p className={styles.number}>注文番号: {orderId}</p>
        <ul className={styles.status}>
          <li>お支払方法: {paymentMethod(price, couponId)}</li>
          <li>購入日時: {formatedPurchasesedAt}</li>
        </ul>
        {giftId && (
          <>
            <p className={styles.gift}>
              ギフトURL: {received ? "使用済み" : <span>未使用</span>}
            </p>
            {!received && (
              <button
                title="URLをコピー"
                className={styles.url}
                onClick={onCopy}
              >
                <IconLink /> {giftUrl}
              </button>
            )}
          </>
        )}
      </div>
    </li>
  );
};

export default Orders;
