import React from "react";
import { Link } from "react-router-dom";
import styles from "./ItemFloatingAction.module.css";

type ItemFloatingActionProps = {
  variant: "read" | "buy";
  bookletURL: string;
  onClickBuy: () => void;
};

const ItemFloatingAction: React.VFC<ItemFloatingActionProps> = ({
  variant,
  bookletURL,
  onClickBuy,
}) => {
  if (variant === "buy") {
    return (
      <div className={styles.floating}>
        <button className={styles.button} onClick={onClickBuy}>
          購入する
        </button>
      </div>
    );
  }

  return (
    <div className={styles.floating}>
      <Link className={styles.link} to={bookletURL} target="_blank">
        <button className={styles.button}>シナリオを読む</button>
      </Link>
    </div>
  );
};

export default ItemFloatingAction;
