import React, { useState, useEffect, useMemo, useCallback } from "react";
import styles from "./Top.module.css";
import TopFillter from "./TopFillter/TopFillter";
import Spinner from "components/Spinner";
import DefaultLayout from "components/DefaultLayout";
import { useLocation } from "react-router-dom";
import OfficialCards from "./OfficialCards";
import TopItemNew from "./TopItem/TopItemNew/TopItemNew";
import TopItemAttention from "./TopItem/TopItemAttention/TopItemAttention";
import TopItemPickUp from "./TopItem/TopItemPickUp/TopItemPickUp";
import ProjectCardsGrid from "stories/components/ProjectCards/ProjectCardsGrid";
import { CardProps, toCardProps } from "stories/components/ProjectCards/Card";
import { Params } from "./TopFillter/TopFillterSearch/TopFillterSearch";
import produce from "immer";
import TopItemEvent from "./TopItem/TopItemEvent/TopItemEvent";
import { OfficialProject } from "apiv1/category";
import { SearchParams, searchProjects } from "apiv1/project";

type LocationState = {
  keyword?: string;
  categoryId?: number;
};

type SearchAreaProps = {
  categoryId?: number;
  loading?: boolean;
  categoryName?: string;
  officialProjects?: OfficialProject[];
};

const isEmptyQuery = (params: Params): boolean => {
  return (
    (params.q == null || params.q === "") &&
    params.category == null &&
    params.playersMin == null &&
    params.playersMax == null &&
    params.playTime == null &&
    params.feeType == null
  );
};

export const SearchArea: React.FC<SearchAreaProps> = ({
  categoryId,
  loading,
  categoryName = "",
  officialProjects = [],
}) => {
  const location = useLocation<LocationState | undefined>();
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const q = location.state?.keyword || query.get("q") || "";

  const [params, setParams] = useState<Params>({ q, category: categoryId });

  useEffect(() => {
    setParams(
      produce(params, (draft) => {
        draft.q = q;
      })
    );
  }, [q, categoryId]);

  useEffect(() => {
    setParams(
      produce(params, (draft) => {
        draft.category = categoryId;
      })
    );
  }, [categoryId]);

  return (
    <>
      <TopFillter params={params} setParams={setParams} />
      <SearchResult
        pending={Boolean(loading)}
        query={params}
        categoryName={categoryName}
        officialProjects={officialProjects}
      />
    </>
  );
};

type SearchResultProps = {
  pending: boolean;
  query: SearchParams;
  categoryName: string;
  officialProjects: OfficialProject[];
};

export const SearchResult: React.FC<SearchResultProps> = ({
  pending,
  query,
  categoryName,
  officialProjects,
}) => {
  const [cards, setCards] = useState<CardProps[]>([]);
  const [appendCards, setAppendCards] = useState<CardProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingAdditional, setLoadingAdditional] = useState<boolean>(false);
  const [canMore, setCanMore] = useState<boolean>(true);
  const [closedOfficialProjects, setClosedOfficialProjects] =
    useState<boolean>(false);

  useEffect(() => {
    if (!pending) {
      searchProjects(query)
        .then((resp) => {
          const cs = resp.data.map(toCardProps);
          setCanMore(cs.length >= 30);
          setAppendCards([]);
          setCards(cs);
        })
        .finally(() => setLoading(false));
    }
  }, [pending, query]);

  const handleMore = (event: React.MouseEvent) => {
    setLoadingAdditional(true);
    searchProjects({
      ...query,
      offset: cards.length,
    })
      .then((resp) => {
        const cs = resp.data.map(toCardProps);
        setCanMore(cs.length >= 30);
        if (officialProjects.length === 0 || closedOfficialProjects) {
          setCards([...cards, ...cs]);
        } else {
          setAppendCards([...appendCards, ...cs]);
        }
      })
      .finally(() => setLoadingAdditional(false));
  };

  const handleCloseOfficialProjects = useCallback(() => {
    setClosedOfficialProjects(true);
    setCards([...cards, ...appendCards]);
    setAppendCards([]);
  }, [cards, appendCards, setCards, setAppendCards, setClosedOfficialProjects]);

  const btnClassName =
    !loading && !loadingAdditional && canMore ? styles.moreArea : styles.hidden;
  const loadingClassName = loading ? styles.loading : styles.hidden;
  const loadingAdditionalClassName = loadingAdditional
    ? styles.loading
    : styles.hidden;

  if (loading) {
    return (
      <div className={loadingClassName}>
        <Spinner />
      </div>
    );
  }

  if (cards.length === 0) {
    return (
      <div className={styles.container}>
        <p>検索に該当する作品はありませんでした。</p>
      </div>
    );
  }

  return (
    <>
      <ProjectCardsGrid cards={cards} />
      <div className={loadingClassName}>
        <Spinner />
      </div>
      <OfficialCards
        categoryName={categoryName}
        projects={officialProjects}
        closed={closedOfficialProjects}
        close={handleCloseOfficialProjects}
      />
      <ProjectCardsGrid cards={appendCards} />
      <div className={loadingAdditionalClassName}>
        <Spinner />
      </div>
      <div className={btnClassName}>
        <button className={styles.button} onClick={handleMore}>
          さらに表示
        </button>
      </div>
    </>
  );
};

const Body: React.VFC = () => {
  const [params, setParams] = useState<Params>({ q: "" });
  const [expand, setExpand] = useState(false);
  const hasQuery = !isEmptyQuery(params);

  const className = hasQuery ? styles.hidden : undefined;

  return (
    <>
      <TopFillter params={params} setParams={setParams} />
      {hasQuery && (
        <SearchResult
          query={params}
          categoryName=""
          pending={false}
          officialProjects={[]}
        />
      )}
      <div className={className}>
        <TopItemNew expand={expand} setExpand={setExpand} />
        {!expand && (
          <>
            <TopItemAttention />
            <TopItemPickUp />
            <TopItemEvent />
          </>
        )}
      </div>
    </>
  );
};

const Top: React.VFC = () => {
  return (
    <DefaultLayout>
      <Body />
    </DefaultLayout>
  );
};

export default Top;
