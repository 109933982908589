import React from "react";
import styles from "./ProfileList.module.css";
import ProfileCards, { ProfileCardsProps } from "./ProfileCards/ProfileCards";

const ProfileList: React.FC<ProfileCardsProps> = ({ projects }) => {
  return (
    <div className={styles.container}>
      <ProfileCards projects={projects} />
    </div>
  );
};

export default ProfileList;
