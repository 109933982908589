import React from "react";
import { ReactComponent as IconArrowRightS } from "./IconArrowRightS.svg";
import { ReactComponent as IconArrowLeftS } from "./IconArrowLeftS.svg";
import styles from "./Pager.module.css";
import { SaveableBooklet } from "pages/Editor/EditorProvider";

export type PageItem = {
  id: string;
  name: string;
};

export const toPageItem = (
  booklet: SaveableBooklet | undefined
): PageItem | undefined => {
  if (booklet) {
    return { id: booklet.id, name: booklet.name };
  }
};

type PagerProps = {
  prev?: PageItem;
  next?: PageItem;
  changeBooklet: (id: string) => void;
};

const Pager: React.FC<PagerProps> = ({ prev, next, changeBooklet }) => {
  if (prev == null && next == null) {
    return null;
  }

  const toPrev = () => {
    if (prev) {
      changeBooklet(prev.id);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
  };

  const toNext = () => {
    if (next) {
      changeBooklet(next.id);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 0);
    }
  };

  return (
    <div className={styles.pager}>
      {prev && (
        <div className={styles.link} onClick={toPrev}>
          <IconArrowLeftS />
          {prev.name}
        </div>
      )}
      <div className={styles.spacer}></div>
      {next && (
        <div className={styles.link} onClick={toNext}>
          {next.name}
          <IconArrowRightS />
        </div>
      )}
    </div>
  );
};

export default Pager;
