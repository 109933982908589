import { useState, useEffect, useContext } from "react";
import BookmarkFillter from "./BookmarkFillter/BookmarkFillter";
import BookmarkList from "./BookmarkList/BookmarkList";
import styles from "./Bookmark.module.css";

import { Bookmark as B, getBookmarks } from "apiv1/bookmark";
import { AuthContext } from "components/AuthProvider";
import { SpinnerCenter } from "components/Spinner";
import { Filter } from "./BookmarkFillter/BookmarkFillterCategory/BookmarkFillterCategory";
import DefaultLayout from "components/DefaultLayout";
import { WithLoad } from "utils/types";
import { NotFoundBody } from "pages/NotFound";
import { getPurchasedProjects } from "apiv1/user";
import { getUserProjects } from "apiv1/project";

const BookmarkBody = () => {
  const user = useContext(AuthContext);
  const [filter, setFilter] = useState<Filter>("bookmarks");
  const [bookmarks, setBookmarks] = useState<WithLoad<B[]>>("loading");
  const [purchases, setPurchases] = useState<WithLoad<B[]>>("loading");
  const [userProjects, setUserProjects] = useState<WithLoad<B[]>>("loading");

  useEffect(() => {
    if (user.authed) {
      getBookmarks(user.uid)
        .then((res) => setBookmarks(res.data))
        .catch(() => setBookmarks("notfound"));
      getPurchasedProjects(user.uid)
        .then((res) => setPurchases(res.data))
        .catch(() => setPurchases("notfound"));
      getUserProjects(user.uid)
        .then((res) => {
          const projects = res.data.filter(
            (project) => project.released_at != null
          );
          projects.sort((a, b) =>
            a.released_at && b.released_at && a.released_at > b.released_at
              ? -1
              : 1
          );
          setUserProjects(projects);
        })
        .catch(() => setUserProjects("notfound"));
    }
  }, [user.authed]);

  if (
    bookmarks === "notfound" ||
    purchases === "notfound" ||
    userProjects === "notfound"
  ) {
    return <NotFoundBody />;
  }

  if (
    bookmarks === "loading" ||
    purchases === "loading" ||
    userProjects === "loading"
  ) {
    return (
      <div className={styles.spinner}>
        <SpinnerCenter />
      </div>
    );
  }

  let cards = [];
  switch (filter) {
    case "bookmarks":
      cards = bookmarks;
      break;
    case "purchases":
      cards = purchases;
      break;
    case "userProjects":
      cards = userProjects;
      break;
  }

  return (
    <>
      <BookmarkFillter filter={filter} setFilter={setFilter} />
      <BookmarkList cards={cards} />
    </>
  );
};

const Bookmark = () => {
  return (
    <DefaultLayout>
      <BookmarkBody />
    </DefaultLayout>
  );
};

export default Bookmark;
