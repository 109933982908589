import { Link } from "react-router-dom";
import styles from "./Terms.module.css";

const Terms = () => {
  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <ul className={styles.sidebarList}>
          <li className={styles.sidebarItem}>
            <Link to="/terms" data-active="true">
              利用規約
            </Link>
          </li>
          <li className={styles.sidebarItem}>
            <Link to="/business-terms">事業者規約</Link>
          </li>
        </ul>
      </div>
      <div className={styles.main}>
        <h1>利用規約</h1>
        <p>
          本利用規約（以下「本規約」といいます。）には、ココフォリア株式会社（以下「運営者」といいます。）が提供するサービス「TALTO」（以下「本サービス」といいます。）の権利義務関係が定められています。ユーザーは、本規約に同意したうえで、本サービスを利用するものとします。
        </p>

        <h2>用語の定義</h2>
        <p>本規約において使用する用語は、次に定めるとおりとします。</p>
        <ul>
          <li>
            「本サイト」とは、運営者が運営する「TALTO」と称するウェブサイト（URL：https://talto.cc）をいいます。
          </li>
          <li>
            「本サービス」とは、本サイト上で提供されるすべてのサービスをいいます。
          </li>
          <li>
            「登録ユーザー」とは、本サービスの一部の機能を利用するために必要な申請を行い運営者に承認され、本規約に同意することにより運営者との間で本サービス利用契約（以下「本契約」といいます。）を締結した者をいいます。
          </li>
          <li>
            「ゲストユーザー」とは、前項に定める登録を行わずに、本規約に同意することにより運営者との間で本サービス利用契約（以下「本契約」といいます。）を締結し、本サービスの一部を利用する者をいいます。
          </li>
          <li>
            「事業者ユーザー」とは、登録ユーザーのうち、運営者が事業者ユーザーとして指定し、別途運営者が定める「事業者規約」に同意することにより運営者との間で事業者契約を締結したユーザーをいいます。
          </li>
          <li>
            「ユーザー」とは、登録ユーザー並びにゲストユーザーの総称です。
          </li>
          <li>
            「登録SNSアカウント」とは、本サービスの提供を受ける目的で、ユーザーが運営者に提供したSNSアカウントの情報をいいます。
          </li>
          <li>
            「ユーザーコンテンツ」とは、ユーザーが投稿及び販売することのできる、ユーザーが創作したTRPGのゲームシナリオをいいます。
          </li>
        </ul>

        <h2>適用</h2>
        <ul>
          <li>
            本規約は、本サービスの提供条件及び本サービスの利用に関する運営者とユーザーとの間の権利義務関係を定めることを目的とし、ユーザーと運営者との間の本サービスの利用に関わる一切の関係に適用されます。
          </li>
          <li>
            運営者が本ウェブサイト上で掲載する本サービス利用に関するルールは、その名称の如何にかかわらず、本規約の一部を構成するものとします。
          </li>
          <li>
            本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、運営者が本規約の規定に優先して適用すると明示しない限り、本規約の規定が優先して適用されるものとします。
          </li>
        </ul>

        <h2>規約の変更</h2>
        <ul>
          <li>
            運営者は以下の場合に、本規約を変更することができます。その際には、変更後の本規約の効力発生時期、本規約を変更する旨及び変更後の本規約の内容を本サービス上で決められた方式により適切に表示し周知するものとします。
            <ul>
              <li>本規約の変更が、ユーザーの一般の利益に適合するとき</li>
              <li>
                本規約の変更が、本契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更にかかる事情に照らして合理的なものであるとき
              </li>
            </ul>
          </li>
          <li>
            前項に定めるほか、運営者は、運営者が定めた方法でユーザーの同意を得ることにより、本規約を変更することができます。
          </li>
          <li>
            変更後の本規約の効力発生日以降にユーザーが本サービスの利用を継続したときは、ユーザーは、本規約の変更に同意したものとみなします。
          </li>
        </ul>

        <h2>ユーザーの行為</h2>
        <ul>
          <li>
            ユーザーは、本規約及び運営者が別途定める本サービスの利用条件等に従って、本サービスを利用するものとします。
          </li>
          <li>
            ユーザーは、本サービスの利用に際して必要な端末機器、ソフトウェア、通信手段等を自らの責任と費用において用意し、適切に設置及び操作することとします。
          </li>
          <li>
            ユーザーは、自己の利用環境に応じて、コンピュータウィルスの感染の防止、不正アクセス及び情報漏洩の防止等のセキュリティ対策を講じるものとします。
          </li>
          <li>
            ユーザーの利用端末、通信環境によっては、本サービスが利用できない可能性があることを予め了承するものとします。
          </li>
          <li>
            ユーザーが本サービスに作成、登録、アップロードしたコンテンツ（テキスト、画像、動画、音楽、その他付帯してユーザーが登録できるものすべて）に関しては、各自の責任において管理するものとし、コンテンツのバックアップについてはユーザーの責任とします。
          </li>
          <li>
            ユーザーは、本サービスに作成、登録、アップロードしたコンテンツについて、公序良俗に違反するものである等、運営者が不適切であると判断した場合には、かかるコンテンツが削除される可能性があることを予め了承するものとします。
          </li>
        </ul>

        <h2>料金</h2>
        <ul>
          <li>ユーザーは、本サービスの一部を無料で利用することができます。</li>
          <li>
            登録ユーザーは、本サービス上において、ユーザーコンテンツを有料で販売（以下、登録ユーザーが有料で販売するユーザーコンテンツを「有料コンテンツ」、本サービスのうち有料で販売する際に利用する機能を「有料サービス」といいます。）することができます。
          </li>
          <li>
            登録ユーザーは、有料サービスにおいて、別途運営者が定める方法に従い、有料コンテンツの販売価格をユーザー自身で決めることができます。
          </li>
          <li>
            登録ユーザーは、有料コンテンツの販売を行った場合、販売価格のうち別途運営者が定める金額を、販売手数料として、別途運営者が定める方法に従い、運営者に支払うものとします。
          </li>
          <li>
            登録ユーザーが販売手数料の支払いを遅滞した場合、登録ユーザーは年１４．６％の割合による遅延損害金を運営者に支払うものとします。
          </li>
          <li>
            運営者は、運営者が必要と認めた場合は、販売手数料の金額を変更することができます。その場合運営者は、変更の効力発生日の２週間前までに、変更する旨及び変更後の内容とその効力発生日を、本サイト又は本サービス上に掲示し、又はユーザーに電子メール等の電磁的方法で通知します。ただし、法令上ユーザーの同意が必要となる変更の場合は、運営者は、運営者が定めた方法でユーザーの同意を得るものとします。
          </li>
          <li>
            運営者は、有料サービス、その他本サービスの利用に関する支払の決済及び情報処理に、第三者による決済処理サービスを利用する場合があります。登録ユーザーは、決済処理サービスの利用にあたり、当該第三者の利用規約をよく確認、同意した上で利用しなければなりません。
          </li>
          <li>
            運営者は、有料サービス、その他本サービスの利用に関する支払の決済及び情報処理について、収納代行をする場合があります。販売する登録ユーザー及び債権者は、運営者に対して代理受領権限を付与することに予め同意するものとします。その場合、購入する登録ユーザーが、運営者に対して料金を支払った時点で、当該登録ユーザーの金銭債務は消滅するものとします。
          </li>
        </ul>

        <h2>本サイトの登録</h2>
        <ul>
          <li>
            本サービスの全部又は一部のサービスの利用を希望する者は、本規約の内容に同意した上で、運営者の定める所定の方法により、本サイトに登録の申請を行うものとします。
          </li>
          <li>
            運営者の提供する方法で申請し、運営者に承認された時点で登録ユーザーになります。
          </li>
          <li>
            未成年者が本サービスの登録をする場合には、法定代理人の同意を得た上で登録の申請を行うものとします。未成年者が登録を申請した時点で、本サービスの登録、本規約への同意及び本利用契約の締結について、法定代理人の同意があったものとみなします。本規約の同意時に未成年であったユーザーが成年に達した後に本サービスを利用した場合、当該ユーザーは、本サービスに関する一切の法律行為を追認したものとみなされます。
          </li>
          <li>
            ユーザーの申請が次のいずれかに該当する場合は、運営者の任意の判断により、理由を開示することなく申請を拒否及びアカウント情報を削除することができます。
            <ul>
              <li>登録ユーザーが、本条第1項の方法によらずに登録を行った場合</li>
              <li>本条又はその他の運営者の定める登録要件を満たさない場合</li>
              <li>
                運営者に提供された登録情報の全部又は一部につき虚偽、誤記又は記載漏れがあった場合
              </li>
              <li>
                未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合
              </li>
              <li>
                反社会的勢力に所属し若しくは所属していた場合、又はこれらと何らかの交流若しくは関与等がある場合
              </li>
              <li>
                登録ユーザーが、過去に本規約又はその他の利用規約等に違反したことを理由として強制退会処分その他何らかの処分を受けた者である場合
              </li>
              <li>本規約に違反する行為を行い又は行うおそれがある場合</li>
              <li>その他、運営者が不適切と判断した場合</li>
            </ul>
          </li>
        </ul>

        <h2> アカウント情報の保有</h2>
        <ul>
          <li>
            登録ユーザーは1人につき1つのアカウントを保有するものとします。ただし、本サービスでの活動に必要性が認められる場合においては、その他の利用規約に反しない範囲において運営者が承諾した場合、複数アカウントの保有を認めるものとします。
          </li>
          <li>
            登録ユーザーはいかなる場合においても、アカウント情報を第三者に譲渡又は貸与することはできません。
          </li>
          <li>
            登録ユーザーがアカウント情報を紛失、削除した場合、本サイトに登録された全データを利用することができません。また、これにより生じた損害に対し運営者は一切責任を負いません。
          </li>
        </ul>

        <h2>登録SNSアカウントの管理等</h2>
        <ul>
          <li>
            登録ユーザーは、ユーザー本人の所有するSNSアカウントで本サイトに登録し利用するものとします。
          </li>
          <li>
            登録に利用した登録SNSアカウントが自己の管理に属さなくなった場合には、自己の管理で保存データをバックアップし、自己の管理に属する登録SNSアカウントで本サイトに登録を行い利用するものとします。
          </li>
          <li>
            登録ユーザーは、自己の登録SNSアカウントの不正利用の防止に努めるとともに、その管理について一切の責任を負うものとします。
          </li>
          <li>
            自己の登録SNSアカウントが第三者に利用されたことによって生じた損害等につきましては、運営者はいかなる責任も負いません。
          </li>
        </ul>

        <h2>登録ユーザーの行為</h2>
        <ul>
          <li>
            登録ユーザーは、ユーザーコンテンツを投稿又は販売するにあたり、ユーザーコンテンツが適法かつ適切なものであり、第三者の知的財産権を侵害していないことを保証するものとします。
          </li>
          <li>
            登録ユーザーは、本サービス上で有料コンテンツを販売する場合、販売元である登録ユーザーを特定する情報を別途運営者が定める方法により登録しなければなりません。
          </li>
          <li>
            登録ユーザーは、本サービス上で有料コンテンツを販売する場合、特定商取引法、割賦販売法、不当景品および不当表示販売法その他関係法令を遵守するものとします。
          </li>
          <li>
            登録ユーザーは、本契約の有効期間中、登録情報等に変更があった場合、運営者指定の方法により当該変更事項を遅滞なく運営者に通知しなければなりません。
          </li>
        </ul>

        <h2>SPLLライセンス対象の有料サービスについて</h2>
        <ul>
          <li>
            登録ユーザーは、販売する有料コンテンツがTRPGライツ事務局の定めるスモールパブリッシャーリミテッドライセンス（以下、「SPLL」といいます。）の適用を受けると判断した場合等、その必要に応じて、登録ユーザー自身で、SPLLの対象となる著作物に対し、適切に申請番号を取得していることを保証するものとします。
          </li>
          <li>
            登録ユーザーは、有料コンテンツを販売するにあたり、事前に取得したSPLL申請番号の視認性を十分に確保した状態で周知する必要があります。
          </li>
          <li>
            登録ユーザーは、本契約の有効期間中、登録情報、SPLL申請番号その他運営者に対して提供する一切の情報の正確性を保証するものとします。
          </li>
        </ul>

        <h2>禁止事項</h2>
        <p>
          ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為又は該当すると運営者が判断する行為をしてはなりません。
        </p>
        <ul>
          <li>本規約に違反する行為</li>
          <li>法令又は公序良俗に違反する行為</li>
          <li>犯罪に関連する行為</li>
          <li>運営者のサーバー又はネットワークの機能を破壊、妨害等する行為</li>
          <li>運営者のサービスの運営を妨害するおそれのある行為</li>
          <li>
            本サービスに関連するシステムへの不正侵入、クラッキング、リバース・エンジニアリング、逆コンパイル、逆アセンブル等、本サービスを妨害する行為、又はそのおそれのある行為
          </li>
          <li>
            運営者又は第三者の知的所有権（著作権、意匠権、実用新案権、商標権、特許権、ノウハウが含まれるがこれに限定されない）、名誉、プライバシーその他第三者の権利又は利益を侵害する行為、又はそのおそれのある行為
          </li>
          <li>他のユーザーの情報収集</li>
          <li>第三者に成りすます行為</li>
          <li>本サービスネットワーク又はシステム等に過度な負荷をかける行為</li>
          <li>
            運営者のサービスに関連して、反社会的勢力に対して直接又は間接に利益を供与する行為
          </li>
          <li>運営者又は第三者を誹謗中傷する行為</li>
          <li>運営者又は第三者に不利益、損害、不快感等を与える行為</li>
          <li>面識のない第三者との出会いを目的とする行為</li>
          <li>
            本規約において明示的に認められる場合又は別途運営者が認める場合を除き、本サービスを商業目的、又は第三者に利益を与える目的で利用する行為
          </li>
          <li>
            運営者又は他のユーザーに対して以下に該当し又は該当すると運営者が判断する情報を送信する行為（ただし、ユーザーコンテンツ内に記載することを一概に禁止するものではありません。）
            <ul>
              <li>
                運営者、他のユーザー又はその他の第三者の名誉又は信用を毀損する表現を含む情報
              </li>
              <li>暴力的又は残虐な表現を含む情報</li>
              <li>わいせつな表現を含む情報</li>
              <li>差別を助長する表現を含む情報</li>
              <li>自殺、自傷行為等を助長する表現を含む情報</li>
              <li>薬物の不適切な利用を助長する表現を含む情報</li>
              <li>反社会的な表現を含む情報</li>
              <li>
                スパム、チェーンメール等の、第三者への情報の拡散を求める情報
              </li>
              <li>違法な勧誘、宣伝等を含む情報</li>
              <li>他人に不快感を与える表現を含む情報</li>
              <li>
                コンピュータ・ウィルスその他の有害なコンピュータ・プログラムを含む情報
              </li>
              <li>布教及び宗教の勧誘を目的とする情報</li>
            </ul>
          </li>
          <li>その他、運営者が不適切と判断する行為</li>
        </ul>

        <h2>違反行為等への対応</h2>
        <p>
          ユーザーが本規約又はその他の利用規約等に違反したと認められる場合、その他運営者が必要と認める場合は、運営者は当該ユーザーに対し以下の措置を講ずることがあります。ただし、運営者はその義務を負うものではありません。
        </p>
        <ul>
          <li>
            本規約又はその他の利用規約等に違反する行為等を止め、同様の行為を繰り返さないことを要求すること。
          </li>
          <li>投稿データの自発的訂正を求めること。</li>
          <li>
            投稿データの全部若しくは一部を削除し、公開範囲を変更し、または閲覧できない状態（非公開）にすること。
          </li>
          <li>
            違反事実の本サービス内外での開示（刑事事件その他に該当する可能性がある場合の警察その他の公的機関への通報を含む）。
          </li>
          <li>一時的に本サービスを利用停止とすること。</li>
          <li>
            本サービスの利用契約（本契約を含みますがこれに限られません。）を解除すること。
          </li>
          <li>
            前項の措置によりユーザーに不利益又は損害が発生した場合においても、運営者は一切の責任を負いません。
          </li>
          <li>
            ユーザーは、運営者が本規約に基づいて行った本規約又はその他の利用規約等に違反する行為等への対処について、異議を申し立てることはできないものとします。
          </li>
          <li>
            ユーザーによって運営者に損害が発生している場合、運営者が本条第１項の措置をとったかどうかにかかわらず、ユーザーは運営者に対し、その損害を賠償しなければなりません。
          </li>
        </ul>

        <h2>本サービスの提供の変更等</h2>
        <ul>
          <li>
            運営者は、ユーザーに通知することなく、本サービスの内容を変更又は提供を中止することができるものとします。
          </li>
          <li>
            運営者は、ユーザーが本サービスを利用して投稿ないしアップロードした文章、画像、映像等について、サービスの保守や改良などに必要な範囲で複製、削除等することがあります。
          </li>
          <li>
            運営者は、本条第１項並びに第２項において運営者が行った措置に基づきユーザーに生じた損害について運営者の責めに帰すべき事由がある場合を除き、一切の責任を負いません。
          </li>
        </ul>

        <h2>利用者情報の取扱い</h2>
        <p>
          運営者によるユーザーの利用者情報の取扱いについては、別途プライバシーポリシーの定めによるものとし、ユーザーはこのプライバシーポリシーに従って運営者がユーザーの利用者情報を取扱うことについて同意するものとします。
        </p>

        <h2>知的財産権の帰属及び使用許諾</h2>
        <ul>
          <li>
            ユーザーは、文章、画像や映像等の情報を、法的、又は既存の権利者に許可される用途・手段でのみ本サービスで利用、投稿ないしアップロードできるものとします。
          </li>
          <li>
            ユーザーが本サービスを利用して投稿ないしアップロードした文章、画像、映像等の著作権及びロゴ等の商標権については、当該ユーザーその他既存の権利者に留保されるものとします。ただし、運営者は、本サービスを利用して投稿ないしアップロードされた文章、画像、映像、ロゴ等について、本サービスの改良、品質の向上、又は不備の是正並びに本サービスに必要な範囲（運営者による特集ページ等の作成も含みます。）で利用できるものとし、ユーザーはこの利用に関して同意するものとします。これらは、運営者によるコンテンツの直接的な利用を想定するものではなく、本サービスのスクリーンショットを生成する際に投稿されたデータが含まれてしまう可能性や、投稿されたデータに対してシステム上での圧縮処理など加工が行われることを想定しています。
          </li>
          <li>
            前項本文の定めるものを除き、本サービス及び本サービスに関連する一切の情報についての著作権及びその他の知的財産権はすべて運営者又は運営者にその利用を許諾した権利者に帰属します。
          </li>
        </ul>

        <h2>解除</h2>
        <ul>
          <li>
            ユーザーが以下の各号のいずれかの事由に該当する場合、運営者は、ユーザーに事前に通知又は催告することなく、ユーザーによる本サービスの利用を一時的に制限し、又は本利用契約を解除することができます。
            <ul>
              <li>
                本規約のいずれかの条項又は保証した事項に違反した場合（民法第541条ただし書は適用しません。）
              </li>
              <li>第４条第２項各号の登録拒否事由のいずれかに該当した場合</li>
              <li>
                支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始若しくはこれらに類する手続の開始の申立てがあった場合
              </li>
              <li>
                死亡し、又は後見開始、保佐開始若しくは補助開始の審判を受けた場合
              </li>
              <li>
                運営者からの問い合わせその他の回答を求める連絡に対して７日間以上応答がない場合
              </li>
              <li>6か月以上本サービスの利用がない場合</li>
              <li>その他、運営者がユーザーとして不適当と判断した場合</li>
            </ul>
          </li>
          <li>
            ユーザーは、前項各号のいずれかに該当した場合、運営者に対して負っている債務の一切について当然に期限の利益を失い、直ちに運営者に対してすべての債務の支払を行わなければなりません。
          </li>
          <li>
            運営者は、本条に基づき運営者が行った措置によりユーザーに生じた損害について一切の責任を負わず、有料サービスの利用料の返金を行いません。
          </li>
        </ul>

        <h2>免責及び補償</h2>
        <ul>
          <li>
            運営者は、本サービスを現状のまま提供するものであり、運営者は、明示又は黙示を問わず、本サービスがユーザーの特定の目的に適合すること、ユーザーの期待する正確性、有用性、真実性、商品性、目的適合性等を有すること、及び法令等に適合すること等について、何ら保証するものではありません。
          </li>
          <li>
            運営者は、登録情報その他のユーザーのいかなる情報も保存する義務を負いません。ユーザーに関する情報のバックアップは投稿したユーザーの責任とし、運営者はバックアップの義務を負わないものとします。ユーザーに関する情報の誤記、消失等によりユーザーに生じた損害についても、運営者は一切責任を負いません。
          </li>
          <li>
            運営者は、本サービスと連携する外部サービスの提供者である第三者が提供するサービス、情報、個人情報の管理等について一切の責任を負いません。
          </li>
          <li>
            運営者は、いかなる状況においても、本契約に規定する本サービス若しくはユーザーのアカウントへのハッキング、改ざんその他の不正なアクセス又は利用により生じたいかなる損害、損失又は権利侵害についても、一切の責任を負いません。
          </li>
          <li>
            運営者は、本サービスの利用に関連して他のユーザー又はその他第三者との間で生じた取引、連絡、紛争等については、自己の費用負担と責任において対応及び解決するものとし、運営者は一切の責任を負いません。ただし、運営者が本サービスの円滑な運営のために必要であると判断した場合には、運営者は解決のために必要かつ適切な措置をとるものとします。
          </li>
          <li>
            いかなる場合であっても、本サービス又は本規約に関連してユーザーに損害が生じた場合であって、運営者がユーザーに対し契約上又は不法行為上その他理由の如何を問わず責任を負う場合における運営者の責任の総額は、運営者の故意又は重過失に基づく場合を除き、かかる損害を引き起こした事由の直近３か月間に本契約に基づき当該ユーザーから運営者が実際に受領した利用料金の合計金額を超えないものとし、また、特別損害、付随的損害、間接損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。
          </li>
          <li>
            ユーザーは、ユーザーの本規約に反する行為に関連して運営者又はその関係者が損失、支出、損害、債務等（合理的な弁護士費用及び裁判費用を含みます。）を負担した場合、その一切について、補償するものとします。
          </li>
        </ul>

        <h2>秘密保持</h2>
        <ul>
          <li>
            ユーザーは、運営者がユーザーに対して秘密に取り扱うことを求めて開示した情報について、運営者の書面による事前承諾がある場合を除き、秘密に取り扱うことを確認し、了承します。
          </li>
          <li>
            ユーザーは、運営者から求められた場合はいつでも、運営者の指示に従い、遅滞なく、前項の秘密情報及び当該情報を記載又は記録した書面その他の記録媒体物並びにそのすべての複製物等を返却又は廃棄しなければなりません。
          </li>
        </ul>

        <h2>契約上の地位の譲渡等</h2>
        <p>
          ユーザーは、運営者の書面による事前の承諾なく、本契約上の地位又は本規約に基づく権利若しくは義務を第三者に譲渡し、又は担保に供することはできません。
        </p>

        <h2>分離可能性</h2>
        <p>
          本規約の一部が法令等により無効と判断された場合であっても、無効部分以外の規定は引き続き有効に存続します。また、無効部分は、有効とするために必要最小限の範囲で修正され、意図した法律的効果と経済的効果が最大限確保されるよう解釈されるものとします。
        </p>

        <h2>言語、準拠法、裁判管轄</h2>
        <ul>
          <li>本規約は日本語を正文とします。</li>
          <li>
            抵触法の原則にかかわらず、本規約は日本法に準拠して解釈されるものとします。
          </li>
          <li>
            本サービスに起因又は関連してユーザーと運営者との間に生じた紛争については東京地方裁判所又は東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
          </li>
        </ul>

        <h2>附則</h2>
        <p>
          本規約は、正式サービスのローンチに併せて改訂したものです。Openβテスト版をご利用いただいているユーザーの方々も、改めてご同意頂く必要があります。
        </p>
        <div className={styles.tar}>
          <p>制定：2021年11月1日</p>
          <p>改訂：2021年12月25日</p>
          <p>　　　2023年4月9日</p>
        </div>
      </div>
    </div>
  );
};

export default Terms;
