import { FeeType, Visibility } from "apiv1/project";

export const PLAYERS_MIN_OPTIONS = [
  { value: null, text: "未設定" },
  { value: 1, text: "1人" },
  { value: 2, text: "2人" },
  { value: 3, text: "3人" },
  { value: 4, text: "4人" },
  { value: 5, text: "5人" },
  { value: 6, text: "6人" },
  { value: 7, text: "7人" },
  { value: 8, text: "8人" },
  { value: 9, text: "9人" },
  { value: 10, text: "10人以上" },
];

export const PLAYERS_MAX_OPTIONS = [
  { value: null, text: "未設定" },
  { value: 1, text: "1人" },
  { value: 2, text: "2人" },
  { value: 3, text: "3人" },
  { value: 4, text: "4人" },
  { value: 5, text: "5人" },
  { value: 6, text: "6人" },
  { value: 7, text: "7人" },
  { value: 8, text: "8人" },
  { value: 9, text: "9人" },
  { value: 10, text: "10人以上" },
  { value: 99, text: "制限なし" },
];

export const players = (
  players_min: number | null,
  players_max: number | null
): string => {
  const min = PLAYERS_MIN_OPTIONS.find((o) => o.value === players_min);
  const max = PLAYERS_MAX_OPTIONS.find((o) => o.value === players_max);

  if (min == null || max == null || min.value == null || max.value == null) {
    return "未設定";
  }

  if (min.value === 10) {
    return min.text;
  }
  if (max.value === 1) {
    return max.text;
  }

  if (min.value === max.value) {
    return min.text;
  }

  return `${min.text}〜${max.text}`;
};

export const PLAYING_HOUR_OPTIONS = [
  { value: null, text: "未設定" },
  { value: 1, text: "1時間以内" },
  { value: 2, text: "2時間" },
  { value: 3, text: "3時間" },
  { value: 4, text: "4時間" },
  { value: 5, text: "5時間" },
  { value: 6, text: "6時間" },
  { value: 7, text: "7時間" },
  { value: 8, text: "8時間以上" },
];

export const playingHours = (playingHoursMax: number | null) => {
  const hours = PLAYING_HOUR_OPTIONS.find((o) => o.value === playingHoursMax);
  return hours?.text || "未設定";
};

export const feeTypeToString = (feeType: FeeType): string => {
  switch (feeType) {
    case "free":
      return "無料";
    case "all_contents":
      return "有料";
    case "attachments_only":
      return "添付ファイルのみ有料";
  }
};

export const visibilityToString = (visibility: Visibility): string => {
  switch (visibility) {
    case "closed":
      return "自分だけ公開";
    case "private":
      return "限定公開";
    case "public":
      return "全体公開";
  }
};
