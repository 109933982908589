import React from "react";
import styles from "./ModalPayment.module.css";

type PriceCardProps = {
  projectName: string;
  categoryName: string;
  price: number;
  priceBeforeDiscount?: number;
};

const PriceCard: React.VFC<PriceCardProps> = ({
  projectName,
  categoryName,
  price,
  priceBeforeDiscount,
}) => {
  return (
    <div className={styles.result}>
      <p className={styles.genre}>{categoryName}</p>
      <h3 className={styles.name}>{projectName}</h3>
      <ul className={styles.pay}>
        <li className={styles.amount}>お支払い金額</li>
        <li className={styles.price}>
          {priceBeforeDiscount && (
            <span className={styles.basePrice}>
              <del>¥{priceBeforeDiscount.toLocaleString("ja-JP")}</del>
            </span>
          )}
          ¥{price.toLocaleString("ja-JP")}
        </li>
      </ul>
    </div>
  );
};

export default React.memo(PriceCard);
