import { Tenant } from "apiv1/payjp";
import { SpinnerCenter } from "components/Spinner";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./DistributorMainPayout.module.css";

type DistributorMainPayoutProps = {
  tenant: Tenant | "loading" | "notfound";
};

const DistributorMainPayout: React.FC<DistributorMainPayoutProps> = ({
  tenant,
}) => {
  if (tenant === "loading") {
    return (
      <div className={styles.container}>
        <SpinnerCenter />
      </div>
    );
  }

  if (tenant === "notfound") {
    return (
      <div className={styles.container}>
        <div className={styles.note}>
          <p>
            振込先情報は未登録です。販売を行うには銀行口座を登録してください。
          </p>
        </div>
        <div className={styles.btnWrap}>
          <Link to="/settings/sales/payout" className={styles.btn}>
            振込先を登録
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.note}>
        <p>振込先情報に不備があると、売上を振り込めない可能性がございます。</p>
      </div>
      <label className={styles.label}>振込方法</label>
      <p className={styles.txt}>銀行振込</p>
      <label className={styles.label}>金融機関コード</label>
      <p className={styles.txt}>{tenant.bank_code}</p>
      {/* <label className={styles.label}>金融機関名</label>
      <p className={styles.txt}>ダミーテキスト銀行</p> */}
      <label className={styles.label}>支店コード</label>
      <p className={styles.txt}>{tenant.bank_branch_code}</p>
      {/* <label className={styles.label}>支店名</label>
      <p className={styles.txt}>ダミー支店</p> */}
      <label className={styles.label}>預金種別</label>
      <p className={styles.txt}>{tenant.bank_account_type}</p>
      <label className={styles.label}>口座番号</label>
      <p className={styles.txt}>{tenant.bank_account_number}</p>
      <label className={styles.label}>口座名義カナ</label>
      <p className={styles.txt}>{tenant.bank_account_holder_name}</p>
      <div className={styles.btnWrap}>
        <Link to="/settings/sales/payout" className={styles.btn}>
          振込先を変更
        </Link>
      </div>
    </div>
  );
};

export default DistributorMainPayout;
