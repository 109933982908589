import { withIdToken, withIdTokenIfAuthed } from "apiv1";
import axios from "axios";
import { API_BASE_URL } from "config/api";
import { SaveableBooklet } from "pages/Editor/EditorProvider";
import { ScenarioData } from "pages/EditorLexical/datastore";
import { Visibility } from "./project";

// GET: /v1/projects/${projectId}/releases
export type BookletInfo = {
  id: string;
  name: string;
  charCount: number;
};

export type BookletInfos = {
  booklets: BookletInfo[];
};

export type Release = {
  project_id: string;
  rev: string;
  booklet_infos: BookletInfos;
  created_at: string;
  deleted_at: string | null;
};

export const getReleases = async (projectId: string) => {
  return axios.get<Release[]>(`/v1/projects/${projectId}/releases`, {
    baseURL: API_BASE_URL,
  });
};

// POST: /v1/projects/${projectId}/releases
export type BodyPostRelease = {
  version: "v1";
  visibility: Visibility;
  booklets: SaveableBooklet[];
  bookletInfos: BookletInfos;
};

export type ResBodyPostRelease = {
  rev: string;
};

export const postRelease = async (projectId: string, body: BodyPostRelease) => {
  return axios.post<ResBodyPostRelease>(
    `/v1/projects/${projectId}/releases`,
    body,
    await withIdToken()
  );
};

export type BodyPostReleaseV2 = {
  version: "v2";
  visibility: Visibility;
  booklets: ScenarioData["booklets"];
  bookletInfos: BookletInfos;
};

export const postReleaseV2 = async (
  projectId: string,
  body: BodyPostReleaseV2
) => {
  return axios.post<ResBodyPostRelease>(
    `/v1/projects/${projectId}/releases`,
    body,
    await withIdToken()
  );
};

// GET: /v1/projects/${projectId}/releases/${rev}
export type ReleaseDataV1 = {
  version?: "v1";
  project_id: string;
  rev: string;
  booklets: SaveableBooklet[];
  created_at: string;
};

export type ReleaseDataV2 = {
  version: "v2";
  project_id: string;
  rev: string;
  booklets: ScenarioData["booklets"];
  created_at: string;
};

export const getRelease = async (
  projectId: string,
  rev: string,
  token: string | null
) => {
  return axios.get<ReleaseDataV1 | ReleaseDataV2>(
    `/v1/projects/${projectId}/releases/${rev}`,
    await withIdTokenIfAuthed({ params: { token } })
  );
};
