import React, { useMemo, useRef, MutableRefObject } from "react";
import { useSaveDraftForce } from "pages/Editor/EditorProvider";
import Editor from "@draft-js-plugins/editor";
import { DraftHandleValue, KeyBindingUtil } from "draft-js";

import createDropUploadPlugin, { loadingImagePlugin } from "plugins/dropUpload";
import createImagePlugin from "@draft-js-plugins/image";
import betterHeaderPlugin from "./betterHeaderPlugin";

import styles from "./EditorEditArea.module.css";
import "draft-js/dist/Draft.css";
import "./draftjsOverride.css";
import Immutable from "immutable";
import shortcutPlugin from "plugins/keyboardShortcut";
import { createPastePlugin } from "plugins/betterPaste";
import BlockNote from "./BlockNote";
import { useRecoilState, useRecoilValue } from "recoil";
import { currentBookletIdState, projectState } from "pages/Editor/atom";
import {
  currentBookletNameSelector,
  currentEditorStateSelector,
} from "pages/Editor/selector";

const { hasCommandModifier } = KeyBindingUtil;

const keyBindingFn = (e: any): string | null | undefined => {
  if (e.keyCode === 83 /* `S` key */ && hasCommandModifier(e)) {
    return "taltoeditor-save";
  }
};

const blockRenderMap = Immutable.Map({
  "header-one": {
    element: "h1",
  },
  "header-two": {
    element: "h2",
  },
  "header-three": {
    element: "h3",
  },
  "block-note": {
    element: "div",
    wrapper: <BlockNote />,
  },
  unstyled: {
    element: "div",
    aliasedElements: [
      "p",
      "h3",
      "h4",
      "h5",
      "h6",
      "li",
      "ul",
      "ol",
      "blockquote",
      "figure",
      "pre",
    ],
  },
});

const styleMap = {
  ITALIC: {},
};

interface EditorEditAreaProps {
  editorRef: MutableRefObject<Editor | null>;
}

const EditorEditArea: React.FC<EditorEditAreaProps> = ({ editorRef }) => {
  const project = useRecoilValue(projectState);
  const currentBookletId = useRecoilValue(currentBookletIdState);
  const [currentEditorState, setCurrentEditorState] = useRecoilState(
    currentEditorStateSelector
  );
  const [bookletName, setBookletName] = useRecoilState(
    currentBookletNameSelector
  );
  const ref = useRef<Editor | null>(null);
  const refFunc = (node: Editor) => {
    ref.current = node;
    editorRef.current = node;
  };
  const { plugins } = useMemo(() => {
    const dropUploadPlugin = createDropUploadPlugin(project.id);
    const imagePlugin = createImagePlugin();
    const pastePlugin = createPastePlugin(ref);

    return {
      plugins: [
        shortcutPlugin,
        imagePlugin,
        loadingImagePlugin,
        dropUploadPlugin,
        betterHeaderPlugin,
        pastePlugin,
      ],
    };
  }, [project.id]);

  const saveDraftForce = useSaveDraftForce();

  if (currentEditorState == null || bookletName == null) {
    return null;
  }

  const handleChangeBookletName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setBookletName(event.target.value);
  };

  const handleKeyCommand = (command: string): DraftHandleValue => {
    if (command === "taltoeditor-save") {
      saveDraftForce();
      return "handled";
    }
    return "not-handled";
  };

  return (
    <div className={styles.editArea}>
      <div className={styles.libretto}>
        <input
          className={styles.input}
          type="text"
          name=""
          placeholder="冊子タイトル"
          value={bookletName}
          onChange={handleChangeBookletName}
        />
      </div>
      <Editor
        editorState={currentEditorState}
        onChange={setCurrentEditorState}
        keyBindingFn={keyBindingFn}
        handleKeyCommand={handleKeyCommand}
        placeholder="本文"
        plugins={plugins}
        key={currentBookletId}
        defaultBlockRenderMap={false}
        blockRenderMap={blockRenderMap}
        customStyleMap={styleMap}
        ref={refFunc}
      />
    </div>
  );
};

export default EditorEditArea;
