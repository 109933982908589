import "./ContentEditable.css";
import "./Editor.css";
import ToolbarSkeleton from "../Toolbar/ToolbarSkeleton";
import SidebarSkeleton from "../Sidebar/SidebarSkeleton";
import FooterSkeleton from "../Footer/FooterSkeleton";

const EditorSkeleton = () => {
  return (
    <div className="Editor__root" data-open-sidebar="true">
      <input className="Editor__InputBookletName_root" disabled />
      <div className="Editor__container">
        <div className="ContentEditable__root" />
        <ToolbarSkeleton />
        <SidebarSkeleton />
        <FooterSkeleton />
      </div>
    </div>
  );
};

export default EditorSkeleton;
