import React from "react";
import styles from "./Terms.module.css";

const SalesTerms = () => {
  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <h1>作品販売に関する注意事項</h1>
        <p>
          TRPGシナリオ投稿サービス「TALTO」は、誰もが安心してTRPGシナリオの執筆活動ができる場所として長く継続することを目標としています。この場所が、執筆者とその活動を支えるユーザー全体のためのものであるという理念の元で、サービスの運営・維持に必要となる手数料や決まりごとなどを定めています。
        </p>

        <h2>販売設定価格</h2>
        <p>¥100 ~ ¥10,000 の間で設定することができます。</p>

        <h2>販売手数料</h2>
        <p>
          販売価格のうち<b> 5% + ¥50 </b>
          <br />
          <small>
            TALTOで公式応援プログラムに参加作品及び販売許諾番号が入力されている場合には、上記に加え販売価格のうち販売手数料を除いた
            10% を「TALTO」が代理で徴収します。
            <br />
            <a href="/licenseid-terms" target="_blank">
              販売許諾番号とは
            </a>
            <br />
            <a href="/supporter-program" target="_blank">
              TALTOで公式応援プログラムとは
            </a>
          </small>
        </p>

        <h2>販売できない作品</h2>
        <ul>
          <li>
            18歳未満の青少年への販売を制限する作品
            <br />
            <small>
              該当作品に関しては、一般的な映画やゲームソフトなどの表記を参考にご判断ください。
            </small>
          </li>
          <li>
            TRPGシナリオの範疇から著しく逸脱していると見做される作品 <br />
            <small>TRPGに関連しないコンテンツなど。</small>
          </li>
          <li>
            商業作品
            <br />
            <small>
              商業作品の取扱いをご希望の場合は、別途お問合せください。
            </small>
          </li>
          <li>
            その他、
            <a href="/terms" target="_blank">
              利用規約
            </a>
            によって禁止されているコンテンツを含む作品 <br />
            <small></small>
          </li>
        </ul>

        <h2>購入された作品の取扱い</h2>
        <p>
          購入者は、購入した作品の公開設定や内容が変更された場合でも、少なくとも購入時点での情報を継続して閲覧することができるようになっています。
          <br />
          <small>
            取引が成立した上で一方的な取り下げが成立してしまうと問題が発生しやすいため、購入者に関しては作品を継続して閲覧できる仕様となっています。
          </small>
        </p>

        <h2>売上の入金</h2>
        <p>
          末締め翌月末払い
          <br />
          <small>
            例：8月1日 ~ 8月31日の売上は、9月末日に自動で入金されます。
            <br />
            売上が振込最低金額（¥10,000）に満たない場合、入金は翌月に持ち越しとなります。
          </small>
        </p>

        <h2>振込手数料</h2>
        <p>
          振込時 ¥250円
          <br />
          <small>
            売上金は決済手数料、振込手数料を差し引いた上で、ご指定いただいた銀行口座へお振り込みいたします。
          </small>
        </p>

        <h2>組戻手数料</h2>
        <p>
          振込エラー時 ¥660円
          <br />
          <small>
            口座名義相違などにより振込エラーとなった場合に売上から差し引かれます。口座情報をご登録いただく際はお間違いのないようご注意ください。
          </small>
        </p>
      </div>
    </div>
  );
};

export default SalesTerms;
