import React from "react";
import styles from "./Select.module.css";

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  fullwidth?: boolean;
};

const Select: React.FC<SelectProps> = ({ fullwidth, children, ...props }) => {
  return (
    <div className={styles.selectArea} data-fullwidth={fullwidth}>
      <select
        {...props}
        className={
          props.value != props.defaultValue ? styles.active : undefined
        }
      >
        {children}
      </select>
    </div>
  );
};

export default Select;
