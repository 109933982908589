import React, { useState, useEffect, useContext } from "react";
import styles from "./ModalEdit.module.css";
import { AuthContext } from "components/AuthProvider";
import { Link, useHistory } from "react-router-dom";
import {
  FeeType,
  postProject,
  Visibility,
  getUserProjects,
  UserProjectInfo,
} from "apiv1/project";
import { feeTypeToString, visibilityToString } from "utils/projects";
import { SpinnerCenter } from "components/Spinner";

type ScenarioProps = {
  id: string;
  name: string;
  categoryName: string;
  visibility: Visibility;
  feeType: FeeType;
  thumnail: string | null;
  releasedAt: string | null;
};

const Scenario: React.FC<ScenarioProps> = ({
  id,
  name,
  thumnail,
  categoryName,
  visibility,
  feeType,
  releasedAt,
}) => {
  return (
    <li>
      <Link to={`/projects/${id}/edit`}>
        <div className={styles.box}>
          <div className={styles.item}>
            <div className={styles.txt}>
              <p className={styles.genre}>{categoryName}</p>
              <h3 className={styles.name}>{name}</h3>
            </div>
            {thumnail && (
              <div className={styles.thumnail}>
                <div className={styles.img}>
                  <img src={thumnail} alt="thumnail" loading="lazy" />
                </div>
              </div>
            )}
          </div>
          <div
            className={styles.publish}
            data-checked={visibility !== "public"}
          >
            {releasedAt ? visibilityToString(visibility) : "執筆中"}
          </div>
          <div className={styles.range}>{feeTypeToString(feeType)}</div>
        </div>
      </Link>
    </li>
  );
};

const ModalEdit = () => {
  const user = useContext(AuthContext);
  const history = useHistory();
  const [projects, setProjects] = useState<
    UserProjectInfo[] | "loading" | "notfound"
  >("loading");
  useEffect(() => {
    if (user.authed && projects === "loading") {
      getUserProjects(user.uid)
        .then((resp) => {
          setProjects(resp.data);
        })
        .catch(() => setProjects("notfound"));
    }
  }, [user.authed]);

  const handleCreateProject = (event: React.MouseEvent) => {
    event.preventDefault();

    postProject()
      .then((res) => {
        history.push(`/projects/${res.data.id}/edit`);
      })
      .catch((err) => {
        console.error(err);
        alert("シナリオの新規作成に失敗しました");
      });
  };

  if (projects === "loading") {
    return (
      <div className={styles.loading}>
        <SpinnerCenter />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div className={styles.head}>
          <h2 className={styles.ttl}>執筆作品一覧</h2>
          <button className={styles.btn} onClick={handleCreateProject}>
            新しいシナリオを作成
          </button>
        </div>
        <ul className={styles.thead}>
          <li className={styles.theadItem}>作品名</li>
          <li className={styles.theadPublish}>公開範囲</li>
          <li className={styles.theadRange}>販売範囲</li>
        </ul>
        {projects === "notfound" ? (
          <p>作品一覧の取得に失敗しました</p>
        ) : (
          <ul className={styles.list}>
            {projects.map((project) => {
              return (
                <Scenario
                  key={project.id}
                  id={project.id}
                  name={project.name}
                  thumnail={project.cover_url}
                  visibility={project.visibility}
                  feeType={project.fee_type}
                  categoryName={project.category_name}
                  releasedAt={project.released_at}
                />
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ModalEdit;
