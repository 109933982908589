import React, { UIEventHandler, useEffect, useRef, useState } from "react";
import styles from "./TopFillterGenre.module.css";
import { ReactComponent as IconArrowRight } from "./IconArrowRight.svg";
import { ReactComponent as IconArrowLeft } from "./IconArrowLeft.svg";
import { Link } from "react-router-dom";
import { getHotCategories, HotCategory } from "apiv1/recommend";
import throttle from "lodash/throttle";

let cache: HotCategory[] = [];

const defaultCategories: HotCategory[] = [
  {
    name: "新クトゥルフ神話TRPG",
    slug: "call_of_cthulhu_7th",
  },
  {
    name: "エモクロアTRPG",
    slug: "emoklore",
  },
  {
    name: "忍術バトルRPG シノビガミ",
    slug: "shinobigami",
  },
  {
    name: "ストリテラ　オモテとウラのRPG",
    slug: "storyteller",
  },
  {
    name: "虚構侵蝕TRPG",
    slug: "kyoko_shinsyoku",
  },
  {
    name: "ダブルクロス The 3rd Edition",
    slug: "double_cross",
  },
  {
    name: "キズナバレット",
    slug: "kizuna_bullet",
  },
  {
    name: "ラヴクラフト・ミステリーTRPG Kutulu",
    slug: "kutulu",
  },
  {
    name: "マルチジャンル・ホラーRPG インセイン",
    slug: "insane",
  },
  {
    name: "ソード・ワールド2.5",
    slug: "sword_world_2.5",
  },
];

const throttled = throttle(
  (
    elem: HTMLDivElement,
    setBegin: (x: boolean) => void,
    setEnd: (x: boolean) => void
  ) => {
    setBegin(elem.scrollLeft === 0);
    setEnd(elem.scrollLeft + elem.clientWidth + 10 >= elem.scrollWidth);
  },
  500 // 0.5sec
);

const TopFillterGenre: React.VFC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [hotCategories, setHotCategories] = useState<HotCategory[]>(cache);

  const [begin, setBegin] = useState<boolean>(true);
  const [end, setEnd] = useState<boolean>(false);

  useEffect(() => {
    if (hotCategories.length === 0) {
      getHotCategories()
        .then((res) => {
          if (res.data.length === 0) {
            throw new Error("empty hot categories");
          }
          cache = res.data;
          setHotCategories(res.data);
        })
        .catch(() => {
          cache = defaultCategories;
          setHotCategories(defaultCategories);
        });
    }
  }, []);

  const scrollToRight = () => {
    if (ref.current) {
      const width = ref.current.clientWidth;
      ref.current.scrollBy({
        left: (width * 9) / 10,
        behavior: "smooth",
      });
    }
  };

  const scrollToLeft = () => {
    if (ref.current) {
      const width = ref.current.clientWidth;
      ref.current.scrollBy({
        left: -(width * 9) / 10,
        behavior: "smooth",
      });
    }
  };

  const handleScroll: UIEventHandler<HTMLDivElement> = () => {
    if (ref.current != null) {
      throttled(ref.current, setBegin, setEnd);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.categories} ref={ref} onScroll={handleScroll}>
        <div className={styles.box}>
          <div className={styles.list}>
            <div className={styles.ttl}>注目ジャンル</div>
            {hotCategories.map((category) => {
              return (
                <Link
                  key={category.slug}
                  className={styles.category}
                  to={`/c/${category.slug}`}
                >
                  {category.name}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      {!end && (
        <>
          <div className={styles.overlayRight}></div>
          <div className={styles.arrowRight}>
            <IconArrowRight onClick={scrollToRight} />
          </div>
        </>
      )}
      {!begin && (
        <>
          <div className={styles.overlayLeft}></div>
          <div className={styles.arrowLeft}>
            <IconArrowLeft onClick={scrollToLeft} />
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(TopFillterGenre);
