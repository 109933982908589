import { Coupon } from "apiv1/coupons";

export const priceWithCoupon = (price: number, coupon: Coupon): number => {
  if (coupon.coupon_type === "fixed_amount_off") {
    return price - coupon.amount;
  } else if (coupon.coupon_type === "percent_off") {
    return price - Math.ceil((price * coupon.percent) / 100);
  } else {
    /* (coupon.coupon_type === "free") */
    return 0;
  }
};
