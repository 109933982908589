import React, { useState } from "react";
import styles from "./BtnWithProcessing.module.css";

export type BtnWithProcessingProps = {
  labelProsessing: string;
  fullWidth?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<any>;
};

const BtnWithProcessing: React.FC<BtnWithProcessingProps> = ({
  labelProsessing,
  fullWidth = false,
  secondary = false,
  disabled,
  onClick,
  children,
}) => {
  const [prosessing, setProsessing] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setProsessing(true);
    onClick(event).finally(() => setProsessing(false));
  };

  if (prosessing) {
    return (
      <div className={styles.loading} data-full-width={fullWidth}>
        <span>{labelProsessing}</span>
        <div className={styles.loader}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  } else {
    return (
      <button
        className={styles.btn}
        data-full-width={fullWidth}
        onClick={handleClick}
        data-secondary={secondary}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
};

export default BtnWithProcessing;
