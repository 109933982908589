import { OfficialProject } from "apiv1/category";
import React, { UIEventHandler, useEffect, useRef, useState } from "react";
import Card from "./Card";
import styles from "./Card.module.css";
import { ReactComponent as IconClosed } from "./IconClosed.svg";
import { ReactComponent as IconArrowPrev } from "./IconArrowPrev.svg";
import { ReactComponent as IconArrowNext } from "./IconArrowNext.svg";
import throttle from "lodash/throttle";

type OfficialCardsProps = {
  categoryName: string;
  projects: OfficialProject[];
  closed: boolean;
  close: () => void;
};

const throttled = throttle(
  (
    elem: HTMLUListElement,
    setBegin: (x: boolean) => void,
    setEnd: (x: boolean) => void
  ) => {
    setBegin(elem.scrollLeft === 0);
    setEnd(elem.scrollLeft + elem.clientWidth + 10 >= elem.scrollWidth);
  },
  500 // 0.5sec
);

const OfficialCards: React.FC<OfficialCardsProps> = ({
  projects,
  categoryName,
  closed,
  close,
}) => {
  const ref = useRef<HTMLUListElement>(null);
  const [begin, setBegin] = useState<boolean>(true);
  const [end, setEnd] = useState<boolean>(false);

  useEffect(() => {
    return () => throttled.cancel();
  }, []);

  if (projects.length === 0 || closed) {
    return null;
  }

  const scrollPrev = () => {
    if (ref.current != null) {
      ref.current.scrollBy({
        left: -ref.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollNext = () => {
    if (ref.current != null) {
      ref.current.scrollBy({
        left: ref.current.clientWidth,
        behavior: "smooth",
      });
    }
  };

  const handleScroll: UIEventHandler<HTMLUListElement> = () => {
    if (ref.current != null) {
      throttled(ref.current, setBegin, setEnd);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.officialArea}>
        <div className={styles.slideHeader}>
          <h2 className={styles.slideTtl}>{categoryName} 公式シナリオ</h2>
          <ul className={styles.slideAction}>
            <li>
              <button
                className={styles.slideBtnPrev}
                onClick={scrollPrev}
                data-end={begin}
              >
                <IconArrowPrev></IconArrowPrev>
              </button>
            </li>
            <li>
              <button
                className={styles.slideBtnNext}
                onClick={scrollNext}
                data-end={end}
              >
                <IconArrowNext></IconArrowNext>
              </button>
            </li>
            <li>
              <button className={styles.slideBtn} onClick={close}>
                <IconClosed></IconClosed>
              </button>
            </li>
          </ul>
        </div>
        <ul className={styles.list} ref={ref} onScroll={handleScroll}>
          {projects.map((project) => (
            <Card
              key={project.id}
              id={project.id}
              name={project.name}
              coverUrl={project.cover_url}
              feeType={project.fee_type}
              price={project.price}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OfficialCards;
