import { projectNameSelector } from "pages/Editor/selector";
import { memo, useEffect } from "react";
import { useRecoilValue } from "recoil";
import { resetDocumentTitle } from "utils/title";

const DocumentTitle = () => {
  const projectName = useRecoilValue(projectNameSelector);
  useEffect(() => {
    window.document.title = `${projectName} - TALTO Editor`;
    return resetDocumentTitle;
  }, [projectName]);

  return null;
};

export default memo(DocumentTitle);
