import { postSCTAct, SCTAct } from "apiv1/user";
import { AuthContext } from "components/AuthProvider";
import { SpinnerCenter } from "components/Spinner";
import React, { useContext, useEffect, useState } from "react";
import styles from "./DistributorMainTerms.module.css";
import { ReactComponent as IconTargetBlank } from "./IconTargetBlank.svg";

type DistributorMainTermsProps = {
  sctact: SCTAct | "loading" | "notfound";
  onChangeSctact: (sctact: SCTAct) => void;
};

const DEFAULT_NAME_AND_CONTACT =
  "省略した記載については、電子メール等の請求により遅滞なく開示いたします。";
const DEFAULT_PRICE = "販売価格は、消費税を含む価格で表示されています。";

const validateSCTAct = (sctact: SCTAct): string | undefined => {
  const errors: string[] = [];
  if (sctact.name_and_contact.length === 0) {
    errors.push("販売者の名称と連絡先");
  }
  if (sctact.price.length === 0) {
    errors.push("販売価格");
  }
  if (sctact.email.length === 0) {
    errors.push("お問合せ先");
  }

  if (errors.length > 0) {
    return `以下の項目を入力してください。\n\n${errors.join("\n")}`;
  }

  return;
};

const DistributorMainTerms: React.FC<DistributorMainTermsProps> = ({
  sctact,
  onChangeSctact,
}) => {
  const user = useContext(AuthContext);
  const [nameAndContact, setNameAndContact] = useState(
    DEFAULT_NAME_AND_CONTACT
  );
  const [price, setPrice] = useState(DEFAULT_PRICE);
  const [email, setEmail] = useState("");
  const [others, setOthers] = useState("");

  useEffect(() => {
    if (sctact === "loading" || sctact === "notfound") {
      return;
    }
    setNameAndContact(sctact.name_and_contact);
    setPrice(sctact.price);
    setEmail(sctact.email);
    setOthers(sctact.others);
  }, [sctact]);

  if (sctact === "loading") {
    return <SpinnerCenter />;
  }

  const handleChange =
    (set: (s: string) => void) =>
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      set(event.target.value);
    };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (user.authed) {
      const newTerm: SCTAct = {
        uid: user.uid,
        name_and_contact: nameAndContact,
        price,
        email,
        others,
      };
      const error = validateSCTAct(newTerm);
      if (error) {
        alert(error);
        return;
      }

      postSCTAct(user.uid, newTerm)
        .then(() => {
          alert("保存しました");
          onChangeSctact(newTerm);
        })
        .catch(() =>
          alert("保存に失敗しました。入力項目に誤りがないか確認してください。")
        );
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.note}>
        <p>
          「特定商品取引法に基づく表記」は、インターネット上で通信販売を行うにあたって必要となる表記事項です。販売を行う販売者においては、表記をお願いいたします。
          <br />
          こちらに記載いただいた事項はいずれも販売者ページにおいて表示されます。
        </p>
        <p>
          通信販売に関する特定商取引法の規定については、以下のページをご参照ください。
          <br />
          特定商取引法ガイド：
          <a
            href="http://www.no-trouble.go.jp/"
            target="_blank"
            rel="noreferrer"
          >
            http://www.no-trouble.go.jp/<IconTargetBlank></IconTargetBlank>
          </a>
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <label className={styles.label}>代金等の支払時期</label>
        <p className={styles.txt}>商品注文時、お支払いが確定します。</p>
        <label className={styles.label}>代金等の支払方法</label>
        <p className={styles.txt}>
          クレジットカード決済（PAY株式会社が提供する決済システム「PAY.JP」を利用します）
        </p>
        <label className={styles.label}>返品に関する事項</label>
        <p className={styles.txt}>
          商品に欠陥があり、かつTALTOが認める場合を除き、返品には応じられません。
        </p>
        <label className={styles.label}>
          販売者の名称と連絡先 <span className={styles.required}>※必須</span>
        </label>
        <textarea
          className={styles.description}
          name="nameAndContact"
          value={nameAndContact}
          onChange={handleChange(setNameAndContact)}
          required
          rows={3}
        ></textarea>
        <p className={styles.comments}>
          ※連絡先の記載の省略は、販売者様のご判断でお願いいたします。ユーザーが問い合わせできるよう、メールアドレスについては記載をお願いいたします。
        </p>
        <label className={styles.label}>
          販売価格 <span className={styles.required}>※必須</span>
        </label>
        <textarea
          className={styles.description}
          name="price"
          value={price}
          onChange={handleChange(setPrice)}
          required
          rows={3}
        ></textarea>
        <label className={styles.label}>
          お問合せ先 <span className={styles.required}>※必須</span>
        </label>
        <input
          className={styles.input}
          type="email"
          name="email"
          onChange={handleChangeEmail}
          value={email}
          required
          placeholder="contact@example.com"
        />
        <label className={styles.label}>その他</label>
        <textarea
          className={styles.description}
          name="others"
          value={others}
          onChange={handleChange(setOthers)}
          rows={3}
        ></textarea>
        <button className={styles.btn}>保存する</button>
      </form>
    </div>
  );
};

export default DistributorMainTerms;
