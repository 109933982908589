import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import * as H from "history";

import AuthProvider from "./components/AuthProvider";
import ModalNoticeNewTerms from "./components/ModalNoticeNewTerms";

import Top from "./pages/Top";
import Signup from "./pages/SignUp";
import Login from "./pages/Login";
import Editor from "./pages/Editor";
import Viewer from "./pages/Viewer/Viewer";
import ItemPage, { ItemView } from "pages/Item/Item";
import Search from "pages/Top/Search";
import ModalWide from "components/ModalWide/ModalWide";
import Bookmark from "pages/Bookmark/Bookmark";
import Logout from "pages/Logout";
import Terms from "pages/Terms/Terms";
import Privacy from "pages/Privacy/Privacy";
import Help from "pages/Help/Help";
import NotFound from "pages/NotFound";
import Profile from "pages/Profile";
import Settings from "pages/Settings";
import Distributor from "pages/Distributor";
import PayoutSetting from "pages/Distributor/PayoutSetting";
import Orders from "pages/Orders";
import AccountSettings from "pages/AccountSettings";
import Sales from "pages/Sales";
import MyProjects from "pages/MyProjects";
import SalesTerms from "pages/Terms/SalesTerms";
import LicenseIdTerms from "pages/Terms/LicenseIdTerms";
import SupporterProgramTerms from "pages/Terms/SupporterProgramTerms";
import Category from "pages/Category";
import ViewerSingleBooklet from "pages/Viewer/ViewerSingleBooklet";
import Asct from "pages/Asct";
import Coupons from "pages/Coupons";
import ReceiveGift from "pages/ReceiveGift";
import EditorLexical from "pages/EditorLexical";
import BusinessTerms from "pages/Terms/BusinessTerms";

const Main = () => {
  const location = useLocation<{ background: H.Location }>();
  const background = location.state?.background;

  return (
    <>
      <Switch location={background || location}>
        <Route exact path="/">
          <Top />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/logout">
          <Logout />
        </Route>
        <Route exact path={"/c/:categorySlug"}>
          <Category />
        </Route>
        <Route exact path={["/search", "/c/:categorySlug"]}>
          <Search />
        </Route>
        <Route exact path="/bookmarks">
          <Bookmark />
        </Route>
        <Route exact path="/my_projects">
          <MyProjects />
        </Route>
        <Route exact path="/projects/:projectId">
          <ItemPage />
        </Route>
        <Route exact path="/projects/:projectId/edit">
          <Editor />
        </Route>
        <Route exact path="/projects/:projectId/edit2">
          <EditorLexical />
        </Route>
        <Route exact path="/projects/:projectId/rev/:rev/booklets/:bookletId">
          <Viewer />
        </Route>
        <Route
          exact
          path="/projects/:projectId/rev/:rev/booklets/:bookletId/shared"
        >
          <ViewerSingleBooklet />
        </Route>
        <Route exact path="/gift/receive">
          <ReceiveGift />
        </Route>
        <Route exact path="/orders">
          <Orders />
        </Route>
        <Route exact path="/sales">
          <Sales />
        </Route>
        <Route exact path="/scenario-ticket">
          <Coupons />
        </Route>
        <Route exact path="/users/:uid">
          <Profile />
        </Route>
        <Route exact path="/settings/profile">
          <Settings />
        </Route>
        <Route exact path="/settings/account">
          <AccountSettings />
        </Route>
        <Route exact path="/settings/sales">
          <Distributor />
        </Route>
        <Route exact path="/settings/sales/payout">
          <PayoutSetting />
        </Route>
        <Route exact path="/terms">
          <Terms />
        </Route>
        <Route exact path="/business-terms">
          <BusinessTerms />
        </Route>
        <Route exact path="/sales-terms">
          <SalesTerms />
        </Route>
        <Route exact path="/licenseid-terms">
          <LicenseIdTerms />
        </Route>
        <Route exact path="/supporter-program">
          <SupporterProgramTerms />
        </Route>
        <Route exact path="/privacy">
          <Privacy />
        </Route>
        <Route exact path="/act_on_specified_commercial_transactions">
          <Asct />
        </Route>
        <Route exact path="/help">
          <Help />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
      <ModalNoticeNewTerms />
      {background && (
        <Route exact path="/projects/:projectId">
          <ModalWide>
            <ItemView />
          </ModalWide>
        </Route>
      )}
    </>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Main />
      </Router>
    </AuthProvider>
  );
};

export default App;
