import {
  DraftInlineStyleType,
  RawDraftContentBlock,
  RawDraftEntity,
  RawDraftEntityRange,
  RawDraftInlineStyleRange,
} from "draft-js";
import isEqual from "lodash/isEqual";

type Block = BlockNode[];

export type BlockNode = NodePlain | NodeLink;

type NodePlain = {
  type: "plain";
  leafs: Leaf[];
};

type NodeLink = {
  type: "LINK";
  url: string;
  leafs: Leaf[];
};

type Leaf = {
  types: DraftInlineStyleType[];
  text: string;
};

export type EntityMap = { [key: string]: RawDraftEntity };
type InlineStyles = DraftInlineStyleType[][];

export const decodeBlock = (
  block: RawDraftContentBlock,
  entityMap: EntityMap
): Block => {
  const inlineStyles = decodeInlineStyleRanges(
    block.text,
    block.inlineStyleRanges
  );
  const inlineEntities = decodeEntityRanges(block.text, block.entityRanges);
  return buildBlock(block.text, entityMap, inlineStyles, inlineEntities);
};

const decodeInlineStyleRanges = (
  text: string,
  ranges: Array<RawDraftInlineStyleRange>
): Array<Array<DraftInlineStyleType>> => {
  const styles: Array<Array<DraftInlineStyleType>> = Array(text.length).fill(
    []
  );

  ranges.forEach((range) => {
    let cursor = range.offset;
    const end = range.offset + range.length;
    while (cursor < end) {
      styles[cursor] = [...styles[cursor], range.style];
      cursor++;
    }
  });
  return styles;
};

const decodeEntityRanges = (
  text: string,
  ranges: Array<RawDraftEntityRange>
): Array<string | null> => {
  const entities: Array<string | null> = Array(text.length).fill(null);

  /* Linkを未実装にするために無視 */
  // ranges.forEach((range) => {
  //   let cursor = range.offset;
  //   const end = range.offset + range.length;
  //   while (cursor < end) {
  //     entities[cursor] = range.key.toString();
  //     cursor++;
  //   }
  // });

  return entities;
};

const buildBlock = (
  text: string,
  entityMap: EntityMap,
  inlineStyles: DraftInlineStyleType[][],
  inlineEntities: Array<string | null>
): Block => {
  if (text.length === 0) {
    return [];
  }

  const nodes = [];
  let cursor = 0;
  let key = inlineEntities[0];

  for (let i = 1; i < text.length; i++) {
    if (key !== inlineEntities[i]) {
      const entity = key != null ? entityMap[key] : null;
      const node = createNode(
        entity,
        text.substr(cursor, i - cursor),
        inlineStyles.slice(cursor, i)
      );
      nodes.push(node);

      cursor = i;
      key = inlineEntities[i];
    }
  }

  const entity = key != null ? entityMap[key] : null;
  const node = createNode(
    entity,
    text.substr(cursor),
    inlineStyles.slice(cursor)
  );
  nodes.push(node);

  return nodes;
};

const createNode = (
  entity: RawDraftEntity | null,
  text: string,
  inlineStyles: DraftInlineStyleType[][]
): BlockNode => {
  const leafs = buildLeafs(text, inlineStyles);
  if (entity != null) {
    return {
      type: "LINK",
      url: entity.data.url,
      leafs,
    };
  } else {
    return {
      type: "plain",
      leafs,
    };
  }
};

const buildLeafs = (text: string, inlineStyles: InlineStyles): Leaf[] => {
  const leafs: Leaf[] = [];
  let cursor = 0;
  let styles = inlineStyles[0];
  for (let i = 1; i < text.length; i++) {
    if (!isEqual(styles, inlineStyles[i])) {
      leafs.push({
        text: text.substr(cursor, i - cursor),
        types: styles,
      });

      cursor = i;
      styles = inlineStyles[i];
    }
  }
  leafs.push({
    text: text.substr(cursor),
    types: styles,
  });

  return leafs;
};
