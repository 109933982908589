import React from "react";
import styles from "./DistributorTabMenu.module.css";
import { ReactComponent as IconAlert } from "./IconAlert.svg";

export type TabType = "bank" | "transactionAct";

type DistributorTabMenuProps = {
  active: TabType;
  onChange: (tabType: TabType) => void;
  warnBank: boolean;
  warnTransactionAct: boolean;
};

const DistributorTabMenu: React.FC<DistributorTabMenuProps> = ({
  active,
  onChange,
  warnBank,
  warnTransactionAct,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.ttl}>販売者情報設定</h1>
      </div>
      <div className={styles.nav}>
        <ul className={styles.list}>
          <li
            className={active === "bank" ? styles.active : ""}
            onClick={() => onChange("bank")}
          >
            口座番号
            {warnBank && (
              <span className={styles.alert}>
                <IconAlert />
              </span>
            )}
          </li>
          <li
            className={active === "transactionAct" ? styles.active : ""}
            onClick={() => onChange("transactionAct")}
          >
            特定商取引法に
            <br className={styles.spi} />
            基づく表記
            {warnTransactionAct && (
              <span className={styles.alert}>
                <IconAlert />
              </span>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DistributorTabMenu;
