import React from "react";
import { Booklet } from "pages/Editor/EditorProvider";
import { ReactComponent as IconCheck } from "./IconCheck.svg";
import EditorFloatingLoader from "./EditorFloatingLoader";
import styles from "./EditorFloating.module.css";
import { useRecoilState, useRecoilValue } from "recoil";
import { previewState, savedAtState } from "pages/Editor/atom";
import { currentBookletSelector, editedSelector } from "pages/Editor/selector";

const formatDate = (d: Date) => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  const hour = d.getHours().toString().padStart(2, "0");
  const min = d.getMinutes().toString().padStart(2, "0");
  const sec = d.getSeconds().toString().padStart(2, "0");
  return `${year}年${month}月${date}日 ${hour}:${min}:${sec}`;
};

const countChars = (booklet?: Booklet): number => {
  if (booklet == null) {
    return 0;
  }

  const blocks = booklet.editorState.getCurrentContent().getBlocksAsArray();
  return blocks.reduce((cur, block) => {
    return block.getType() === "atomic" ? cur : cur + block.getLength();
  }, 0);
};

const SavedAt: React.FC = () => {
  const rawSavedAt = useRecoilValue(savedAtState);
  const edited = useRecoilValue(editedSelector);

  const savedAt = rawSavedAt ? formatDate(rawSavedAt) : "-";

  return (
    <p className={styles.log}>
      最終保存 {savedAt}
      {edited ? (
        <EditorFloatingLoader />
      ) : (
        <IconCheck className={styles.savedIcon} />
      )}
    </p>
  );
};

const SwitchPreview: React.FC = () => {
  const [preview, setPreview] = useRecoilState(previewState);

  const turnOnPreview = (event: React.MouseEvent) => {
    event.preventDefault();
    setPreview(true);
  };
  const turnOffPreview = (event: React.MouseEvent) => {
    event.preventDefault();
    setPreview(false);
  };

  return (
    <ul className={styles.list}>
      <li>
        <button
          className={preview ? styles.btnInActive : styles.btnActive}
          onClick={turnOffPreview}
        >
          執筆
        </button>
      </li>
      <li>
        <button
          className={preview ? styles.btnActive : styles.btnInActive}
          onClick={turnOnPreview}
        >
          プレビュー
        </button>
      </li>
    </ul>
  );
};

const CharCount: React.FC = () => {
  const booklet = useRecoilValue(currentBookletSelector);
  const chars = countChars(booklet);

  return (
    <p className={styles.count}>
      <span>{chars}</span> 文字
    </p>
  );
};

const EditorFloating = () => {
  const preview = useRecoilValue(previewState);

  return (
    <div className={styles.container} data-preview={preview}>
      <SavedAt />
      <SwitchPreview />
      <CharCount />
    </div>
  );
};

export default EditorFloating;
