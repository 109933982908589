import { AxiosRequestConfig } from "axios";
import { auth } from "initialize";

export const withIdToken = async (config: AxiosRequestConfig = {}) => {
  const idToken = await auth.currentUser?.getIdToken();
  if (idToken == null) {
    throw "undefined idToken";
  }

  const configWithIdToken: AxiosRequestConfig = {
    ...config,
    headers: {
      ...config?.headers,
      Authorization: `Bearer ${idToken}`,
    },
  };

  return configWithIdToken;
};

export const withIdTokenIfAuthed = async (config: AxiosRequestConfig = {}) => {
  const idToken = await auth.currentUser?.getIdToken();
  const authorization =
    idToken != null ? { Authorization: `Bearer ${idToken}` } : undefined;

  const configWithIdToken: AxiosRequestConfig = {
    ...config,
    headers: {
      ...config?.headers,
      ...authorization,
    },
  };

  return configWithIdToken;
};

export const withoutToken = (config: AxiosRequestConfig = {}) => {
  return config;
};
